import React, { useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import isMobilePhone from "validator/lib/isMobilePhone";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormHelperText,
  FormLabel
} from "@mui/material";

/* CUSTOM LIBRARIES */
import FinishModal from "../common/modals/finishModal";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { transition } from "../../../controller/utils";
import {
  useEditUserMutation,
  useGetUserQuery
} from "../../../api/authApiSlice";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../redux/confirmModalSlice";
import { useDispatch } from "react-redux";

export default function MisDatosPersonales(props) {
  const [datosUsuarioCpy, setDatosUsuarioCpy] = useState({});
  const [continueValueCpy, setContinueValueCpy] = useState("");
  const {
    create = false,
    dense = false,
    continueValue = continueValueCpy,
    setContinueValue = setContinueValueCpy,
    datosUsuario = datosUsuarioCpy,
    setDatosUsuario = setDatosUsuarioCpy
  } = props;
  const navigate = useNavigate();
  const littleSize = littleSizeFunc();
  const dispatch = useDispatch();

  const { currentData, isSuccess, isLoading, isFetching, refetch } = create
    ? {
        currentData: null,
        isSuccess: null,
        isLoading: null,
        isFetching: null,
        refetch: null
      }
    : useGetUserQuery(null, {
        refetchOnMountOrArgChange: true
      });

  const [editUser, editUserState] = useEditUserMutation();

  const [openFinishModal, setOpenFinishModal] = useState(false);

  const [valueNombre, setValueNombre] = useState("");
  const [valueApellidoPaterno, setValueApellidoPaterno] = useState("");
  const [valueApellidoMaterno, setValueApellidoMaterno] = useState("");
  const [valueCorreo, setValueCorreo] = useState("");
  const [valueTelefono, setValueTelefono] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");

  const [errorNombre, setErrorNombre] = useState("");
  const [errorApellidoPaterno, setErrorApellidoPaterno] = useState("");
  const [errorApellidoMaterno, setErrorApellidoMaterno] = useState("");
  const [errorCorreo, setErrorCorreo] = useState("");
  const [errorTelefono, setErrorTelefono] = useState("");
  const [errorDireccion, setErrorDireccion] = useState("");

  useEffect(() => {
    if (!create) {
      if (isSuccess && currentData) {
        setDatosUsuario({ ...currentData });
        setValueNombre(currentData.name);
        setValueApellidoPaterno(currentData.firstLastname);
        setValueApellidoMaterno(currentData.secondLastname);
        setValueCorreo(currentData.email);
        setValueTelefono(currentData.phone);
        setValueDireccion(currentData.address);
      }
    }
  }, [currentData, isSuccess, isLoading, isFetching]);

  useEffect(() => {
    if (create && Object.keys(datosUsuario).length > 0) {
      setValueNombre(datosUsuario.name);
      setValueApellidoPaterno(datosUsuario.firstLastname);
      setValueApellidoMaterno(datosUsuario.secondLastname);
      setValueCorreo(datosUsuario.email);
      setValueTelefono(datosUsuario.phone);
      setValueDireccion(datosUsuario.address);
    }
  }, []);

  useEffect(() => {
    if (create) {
      if (
        !errorNombre &&
        !errorApellidoPaterno &&
        !errorApellidoMaterno &&
        !errorCorreo &&
        !errorTelefono &&
        !errorDireccion &&
        valueNombre &&
        valueApellidoPaterno &&
        valueApellidoMaterno &&
        valueCorreo &&
        valueTelefono &&
        valueDireccion
      ) {
        setDatosUsuario({
          email: valueCorreo,
          name: valueNombre,
          firstLastname: valueApellidoPaterno,
          secondLastname: valueApellidoMaterno,
          phone: valueTelefono,
          address: valueDireccion
        });
        setContinueValue(true);
      } else {
        setContinueValue(false);
      }
    } else {
      if (datosUsuario) {
        if (
          !errorNombre &&
          !errorApellidoPaterno &&
          !errorApellidoMaterno &&
          !errorCorreo &&
          !errorTelefono &&
          !errorDireccion &&
          (datosUsuario.name !== valueNombre ||
            datosUsuario.firstLastname !== valueApellidoPaterno ||
            datosUsuario.secondLastname !== valueApellidoMaterno ||
            datosUsuario.email !== valueCorreo ||
            datosUsuario.phone !== valueTelefono ||
            datosUsuario.address !== valueDireccion)
        ) {
          setDatosUsuario({
            id: datosUsuario.id,
            email: valueCorreo,
            name: valueNombre,
            firstLastname: valueApellidoPaterno,
            secondLastname: valueApellidoMaterno,
            phone: valueTelefono,
            address: valueDireccion
          });
          setContinueValue(true);
        } else {
          setContinueValue(false);
        }
      }
    }
  }, [
    valueNombre,
    valueApellidoPaterno,
    valueApellidoMaterno,
    valueCorreo,
    valueTelefono,
    valueDireccion
  ]);

  const handleNotEmpty = (value, setValue, setError, error) => {
    setValue(value);
    if (isEmpty(value)) {
      setError(error);
    } else {
      setError("");
    }
  };
  const handleTelChange = (value) => {
    let tel = value.includes("+56") ? value : `+56${value}`;
    setValueTelefono(tel);
    if (isMobilePhone(tel, "es-CL")) {
      setErrorTelefono("");
    } else {
      setErrorTelefono("Escriba un número de teléfono válido");
    }
  };
  const handleMailChange = (value) => {
    setValueCorreo(value);
    if (isEmail(value)) {
      setErrorCorreo("");
    } else {
      setErrorCorreo("Escriba un correo válido");
    }
  };
  const handleClickVolver = (event) => {
    navigate("/Mi perfil");
  };

  const handleSave = (event) => {
    setOpenFinishModal(true);
  };
  const handleContinue = async () => {
    if (!create) {
      try {
        const userD = await editUser({
          id: datosUsuario.id,
          email: valueCorreo,
          name: valueNombre,
          firstLastname: valueApellidoPaterno,
          secondLastname: valueApellidoMaterno,
          phone: valueTelefono,
          address: valueDireccion
        }).unwrap();
        dispatch(
          setSuccessText(`¡Tus cambios se han actualizado de forma exitosa!`)
        );
        refetch();
        navigate("/Mi perfil");
      } catch (err) {
        dispatch(
          setErrorText(
            `Hubo un problema al momento de actualizar los datos de usuario\n\nIntente nuevamente más tarde o contactese con nosotros`
          )
        );
      }
      dispatch(setOpenConfirmModal(true));
      setOpenFinishModal(false);
    }
  };
  const handleBack = () => {
    setOpenFinishModal(false);
  };

  /* CSS */
  const todo = {
    padding: "0 0 0 0",
    minHeight: dense ? "" : "58vh",
    height: dense ? "" : "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: dense ? "2vh" : "4vh",
    justifyContent: "space-around"
  };
  const contenido = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "6.47vh 3.47vw",
    justifyContent: littleSize ? "center" : "flex-start",
    alignItems: "center",
    overflow: "hidden",
    height: "100%",
    display: "flex",
    overflowY: "hidden",
    padding: "11px 0",
    width: "100%"
  };
  const inputBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: littleSize ? "fit-content" : "40%",
    // maxWidth: "460px",
    minWidth: "350px",
    gap: "10px",
    justifyContent: littleSize ? "center" : "space-between",
    alignItems: "center"
  };
  const text = {
    width: "inherit",
    fontWeight: 600
  };
  const textFieldBox = {
    // maxWidth: "300px",
    width: "14.58vw",
    minWidth: "210px"
  };
  const textField = {
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400
    }
  };
  const textFieldTelLeft = {
    width: "58px",
    height: "inherit",
    borderRadius: "4.34415px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      borderBottomRightRadius: "0%",
      borderTopRightRadius: "0%",
      "& .MuiOutlinedInput-input": {
        paddingLeft: "0px",
        paddingRight: "0px"
      }
    }
  };
  const textFieldTelRight = {
    width: "calc(100% - 58px)",
    height: "inherit",
    borderRadius: "4.34415px",
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      borderBottomLeftRadius: "0%",
      borderTopLeftRadius: "0%"
    }
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px"
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    ":hover": {
      border: "2px solid #1876D1"
    }
  };
  const button = {
    textTransform: "none",
    borderRadius: "10px",
    fontWeight: 600,
    minWidth: "110px",
    background: "#1876D1"
  };
  const titleFinishModal = {
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "140%",
    color: "#818181",
    textAlign: "center"
  };

  return (
    <Box sx={todo}>
      <Box sx={contenido}>
        <Box sx={inputBox}>
          {littleSize ? null : <FormLabel sx={text}>Nombre</FormLabel>}
          <Box sx={textFieldBox}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              required
              id="nombre"
              label="Nombre"
              type="text"
              value={valueNombre}
              onChange={(e) =>
                handleNotEmpty(
                  e.target.value,
                  setValueNombre,
                  setErrorNombre,
                  "Escriba su nombre"
                )
              }
            />
            <FormHelperText error>{errorNombre}</FormHelperText>
          </Box>
        </Box>
        <Box sx={inputBox}>
          {littleSize ? null : (
            <FormLabel sx={text}>Apellido paterno</FormLabel>
          )}
          <Box sx={textFieldBox}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              required
              id="apellidoPaterno"
              label="Apellido paterno"
              type="text"
              value={valueApellidoPaterno}
              onChange={(e) =>
                handleNotEmpty(
                  e.target.value,
                  setValueApellidoPaterno,
                  setErrorApellidoPaterno,
                  "Escriba su apellido paterno"
                )
              }
            />
            <FormHelperText error>{errorApellidoPaterno}</FormHelperText>
          </Box>
        </Box>
        <Box sx={inputBox}>
          {littleSize ? null : (
            <FormLabel sx={text}>Apellido materno</FormLabel>
          )}
          <Box sx={textFieldBox}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              required
              id="apellidoMaterno"
              label="Apellido materno"
              type="text"
              value={valueApellidoMaterno}
              onChange={(e) =>
                handleNotEmpty(
                  e.target.value,
                  setValueApellidoMaterno,
                  setErrorApellidoMaterno,
                  "Escriba su apellido materno"
                )
              }
            />
            <FormHelperText error>{errorApellidoMaterno}</FormHelperText>
          </Box>
        </Box>
        <Box sx={inputBox}>
          {littleSize ? null : (
            <FormLabel sx={text}>Correo electrónico</FormLabel>
          )}
          <Box sx={textFieldBox}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              required
              id="correo"
              label="Correo electrónico"
              type="text"
              value={valueCorreo}
              onChange={(e) => handleMailChange(e.target.value)}
            />
            <FormHelperText error>{errorCorreo}</FormHelperText>
          </Box>
        </Box>
        <Box sx={inputBox}>
          {littleSize ? null : <FormLabel sx={text}>Teléfono</FormLabel>}
          <Box sx={textFieldBox}>
            <TextField
              sx={textFieldTelLeft}
              size={"small"}
              fullWidth
              disabled
              label="+56"
            />
            <TextField
              sx={textFieldTelRight}
              size="small"
              fullWidth
              required
              id="telefono"
              label="Telefono"
              type="tel"
              value={valueTelefono.replace("+56", "")}
              onChange={(e) => handleTelChange(e.target.value)}
            />
            <FormHelperText error>{errorTelefono}</FormHelperText>
          </Box>
        </Box>
        <Box sx={inputBox}>
          {littleSize ? null : <FormLabel sx={text}>Dirección</FormLabel>}
          <Box sx={textFieldBox}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              required
              id="direccion"
              label="Dirección"
              type="text"
              value={valueDireccion}
              onChange={(e) =>
                handleNotEmpty(
                  e.target.value,
                  setValueDireccion,
                  setErrorDireccion,
                  "Escriba su dirección"
                )
              }
            />
            <FormHelperText error>{errorDireccion}</FormHelperText>
          </Box>
        </Box>
      </Box>
      {create ? null : (
        <Box sx={buttonBox}>
          <Button
            sx={buttonVolver}
            variant="outlined"
            onClick={handleClickVolver}
          >
            Volver
          </Button>
          <Button
            disabled={!continueValue}
            sx={button}
            variant="contained"
            onClick={(e) => handleSave(e)}
          >
            Actualizar
          </Button>
        </Box>
      )}
      <FinishModal
        openModal={openFinishModal}
        setOpenModal={setOpenFinishModal}
        handleContinue={handleContinue}
        handleBack={handleBack}
        buttonText="Continuar"
      >
        <Typography sx={titleFinishModal}>
          ¿Estás seguro/a que quieres continuar con los cambios en tus datos
          personales?
        </Typography>
      </FinishModal>
    </Box>
  );
}
