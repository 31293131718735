import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Input,
  FormControl,
  Typography,
  Button,
  IconButton,
  SvgIcon
} from "@mui/material";

/* ICONS */
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

/* CUSTOM LIBRARIES */
import CustomAlert from "../modals/customAlert";

/* CONTROLLER VARIABLES */
import { MAX_UPLOAD_FILES, FileIcon } from "../../../../controller/utils";

export default function DragDropFile(props) {
  const {
    uploadedFiles = [],
    setUploadedFiles = () => {},
    setContinueValue,
    maxCount = MAX_UPLOAD_FILES
  } = props;

  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const [fileLimit, setFileLimit] = useState(false);

  const [alertText, setAlertText] = useState("");
  const [openAlertModal, setOpenAlertModal] = useState(false);

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      setContinueValue(true);
    } else {
      setContinueValue(false);
    }
  }, [uploadedFiles]);

  // Si se borra un archivo se activa el boton para subir
  useEffect(() => {
    if (uploadedFiles.length < maxCount) {
      setFileLimit(false);
    }
  }, [uploadedFiles]);

  const handleAlert = (text) => {
    setAlertText(text);
    setOpenAlertModal(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    // var reader = new FileReader();

    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        if (file.type !== "application/pdf") {
          inputRef.current.value = "";
          handleAlert(`Solo se admite formato PDF`);
          limitExceeded = true;
        } else {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.addEventListener(
            "load",
            () => {
              file.blob = reader.result;
            },
            false
          );
          uploaded.push(file);
          if (uploaded.length === maxCount) {
            setFileLimit(true);
          }
          if (uploaded.length > maxCount) {
            inputRef.current.value = "";
            handleAlert(
              `Solo puedes subir un máximo de ${maxCount} archivo${
                maxCount > 1 ? "s" : ""
              }`
            );
            setFileLimit(false);
            limitExceeded = true;
            return true;
          }
        }
      } else {
        inputRef.current.value = "";
        limitExceeded = true;
        handleAlert(`Este archivo ya se encuentra subido`);
      }
    });
    if (!limitExceeded) {
      setUploadedFiles(uploaded);
    }
  };
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const chosenFiles = Array.prototype.slice.call(e.dataTransfer.files);
      handleUploadFiles(chosenFiles);
    }
  };
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const chosenFiles = Array.prototype.slice.call(e.target.files);
      handleUploadFiles(chosenFiles);
    }
  };
  const onUploadClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };
  const onDeleteClick = (e, name) => {
    e.preventDefault();
    // if (!cargaValue) {
    inputRef.current.value = "";
    // setCurrentLoadingDocument("");
    const uploaded = [...uploadedFiles];
    uploaded.splice(
      uploaded.findIndex((f) => f.name === name),
      1
    );
    setUploadedFiles(uploaded);
    // }
  };
  const onCancelClick = (e, name) => {
    e.preventDefault();
    const uploaded = [...uploadedFiles];
    uploaded.splice(
      uploaded.findIndex((f) => f.name === name),
      1
    );
    setUploadedFiles(uploaded);
  };

  /* CSS */
  const formFileUpload = {
    minHeight: "25vh",
    height: "fit-content",
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "2px",
    borderRadius: "20px",
    borderStyle: dragActive ? "solid" : "dashed",
    borderColor: "#1876D1",
    background: dragActive ? "rgba(24, 118, 209, 0.2)" : ""
  };
  const insideBox = {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "8vh 0px",
    opacity: dragActive ? "0.1" : "1",
    gap: "17px"
  };
  const inputFileUpload = {
    height: "100%",
    width: "100%",
    display: "none"
  };
  const icon = {
    color: "#1876D1",
    height: "65px",
    width: "65px",
    stroke: "#FCFCFC",
    strokeWidth: 1
  };
  const addIcon = {
    visibility: dragActive ? "visible" : "hidden",
    opacity: dragActive ? "1" : "0",
    position: "absolute",
    color: "#1876D1",
    height: "65px",
    width: "65px"
  };
  const text = {
    color: "#1876D1",
    fontWeight: "bold",
    fontSize: "18px"
  };
  const button = {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    textTransform: "none",
    background: "#1876D1",
    borderRadius: "10px",
    fontWeight: 600,
    fontSize: "16px"
  };
  const insideBoxFiles = {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "22px",
    gap: "11px"
  };
  const uploadingFileBox = {
    position: "relative",
    border: "solid transparent",
    borderRadius: "10px",
    // background: `linear-gradient(#FCFBFB, #FCFBFB), conic-gradient(from 180deg at 50% 50%, #1876D1 ${cargaValue}%, #FCFBFB ${
    //   cargaValue + 1
    // }%)`,
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    padding: "2px"
  };
  const uploadingFileBoxWhite = {
    fontWeight: 600,
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    padding: "5px",
    gap: "11px"
  };
  const docIcons = {
    color: "#1876D1",
    height: "25px",
    width: "25px"
  };

  return (
    <FormControl
      sx={formFileUpload}
      onSubmit={handleSubmit}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      disabled={fileLimit}
    >
      <Input
        inputRef={inputRef}
        type="file"
        sx={inputFileUpload}
        multiple
        inputProps={{
          multiple: true
        }}
        onChange={handleChange}
        disabled={fileLimit}
      />
      {uploadedFiles.length > 0 ? (
        <Box sx={insideBoxFiles}>
          {uploadedFiles.map((file) => (
            // currentLoadingDocument === file.name ? (
            //   <Box key={file.name} sx={uploadingFileBox}>
            //     <Box key={file.name} sx={uploadingFileBoxWhite}>
            //       <SvgIcon sx={docIcons}>{FileIcon(file)}</SvgIcon>
            //       <Typography>{file.name}</Typography>
            //       {/* <Typography>{cargaValue}%</Typography> */}
            //       <IconButton onClick={(e) => onCancelClick(e, file.name)}>
            //         <CancelOutlinedIcon sx={docIcons} />
            //       </IconButton>
            //     </Box>
            //   </Box>
            // ) : waitingUploadFiles.includes(file.name) ? (
            //   <Box key={file.name} sx={uploadingFileBoxWhite}>
            //     <SvgIcon sx={docIcons}>{FileIcon(file)}</SvgIcon>
            //     <Typography>{file.name}</Typography>
            //     <IconButton onClick={(e) => onCancelClick(e, file.name)}>
            //       <CancelOutlinedIcon sx={docIcons} />
            //     </IconButton>
            //   </Box>
            // ) :
            <Box key={file.name} sx={uploadingFileBoxWhite}>
              <SvgIcon sx={docIcons}>{FileIcon(file)}</SvgIcon>
              <Typography>{file.name}</Typography>
              <IconButton onClick={(e) => onDeleteClick(e, file.name)}>
                <DeleteOutlinedIcon sx={docIcons} />
              </IconButton>
            </Box>
          ))}
          {/* {cargaValue ? (
            <Button
              sx={button}
              variant="contained"
              type="button"
              onClick={onCancelClick}
            >
              Cancelar
            </Button>
          ) : ( */}
          <Button
            sx={button}
            variant="contained"
            disabled={fileLimit}
            onClick={onUploadClick}
          >
            Subir otro archivo
          </Button>
          {/* )} */}
        </Box>
      ) : (
        <Box sx={insideBox}>
          <UploadFileOutlinedIcon sx={icon} />
          <Box>
            <Typography sx={text}>
              {maxCount > 1
                ? "Arrastra acá tus documentos"
                : "Arrastra acá tu documento"}
            </Typography>
            <Typography sx={text}>o</Typography>
          </Box>
          <Button
            sx={button}
            variant="contained"
            disabled={fileLimit}
            onClick={onUploadClick}
          >
            {maxCount > 1
              ? "Selecciona los documentos"
              : "Selecciona el documento"}
          </Button>
        </Box>
      )}
      <AddOutlinedIcon sx={addIcon} />
      <CustomAlert openModal={openAlertModal} setOpenModal={setOpenAlertModal}>
        {alertText}
      </CustomAlert>
    </FormControl>
  );
}
