import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Button, Typography, Box, Modal } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";

/* CUSTOM LIBRARIES */
import CustomTable from "../common/components/customTable";
import FinishModal from "../common/modals/finishModal";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { transition } from "../../../controller/utils";
import { headCellsProblemasSubontratista } from "../../../controller/listas";
import { useSelector } from "react-redux";
import { currentEmpresa, currentFactura } from "../../../redux/entidadesSlice";
import { useLazyGetDocumentosProblemasQuery } from "../../../api/docsApiSlice";

/* TEST DATA */
import { tabla_problemas } from "../../../controller/testData";

export default function ProblemasTableModal(props) {
  const { openModal, setOpenModal, subtitle, doneNavigation = false } = props;
  const navigate = useNavigate();
  const littleSize = littleSizeFunc();
  const selectedFactura = useSelector(currentFactura);

  const [triggerGetData, { isFetching, isLoading }] =
    useLazyGetDocumentosProblemasQuery();

  const [continueValue, setContinueValue] = useState(false);

  const [lineaDocumentoSeleccionada, setLineaDocumentoSeleccionada] =
    useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [listaDocumentos, setListaDocumentos] = useState([]);

  useEffect(() => {
    if (openModal) {
      handleGetProblemasData();
    }
  }, [openModal]);

  const handleGetProblemasData = async () => {
    const { data, isSuccess } = await triggerGetData(selectedFactura.id, false);
    if (isSuccess) {
      setListaDocumentos(data.data);
    }
  };

  const handleCloseModal = () => {
    setContinueValue(false);
    setOpenModal(false);
  };

  const handleContinue = () => {
    setOpenModal(false);
  };

  /* CSS */
  const datosBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    minWidth: "250px",
    maxWidth: "1000px",
    height: "fit-content",
    maxHeight: "98vh",
    display: "flex",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: "6vh 3.542vw ",
    overflowY: "hidden",
    gap: "5vh"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const titleBox = {
    width: "100%",
    gap: "11px",
    display: "flex",
    alignItems: littleSize ? "center" : "start",
    justifyContent: "flex-start",
    flexDirection: "column"
  };
  const titleStyle = {
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "140%",
    textAlign: littleSize ? "center" : ""
  };
  const subtitulo = {
    fontWeight: 400,
    fontSize: "20px",
    textAlign: littleSize ? "center" : ""
  };
  const selectDatosBox = {
    overflowY: "auto"
  };
  const textStyleFinishModal = {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "20px",
    color: "#818181"
  };

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={datosBox}>
        <Box sx={closeButtonBox}>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={titleBox}>
          <Typography sx={titleStyle}>Documentos rechazados</Typography>
          <Typography sx={subtitulo}>Faena: {subtitle}</Typography>
        </Box>
        <Box sx={selectDatosBox}>
          <CustomTable
            headCells={headCellsProblemasSubontratista}
            rows={listaDocumentos}
            setRows={setListaDocumentos}
            variant="facturacion"
            setLineaSeleccionada={setLineaDocumentoSeleccionada}
            setOpenErrorModal={setOpenErrorModal}
            search={false}
            isLoading={isLoading}
          />
        </Box>
        <FinishModal
          openModal={openErrorModal}
          setOpenModal={setOpenErrorModal}
        >
          <Typography sx={textStyleFinishModal}>
            La firma en el documento adjunto no corresponde con la firma la
            cédula de identidad del trabajador.
          </Typography>
        </FinishModal>
      </Box>
    </Modal>
  );
}
