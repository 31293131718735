import React, { useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmpty from "validator/lib/isEmpty";
import {
  IconButton,
  TextField,
  Button,
  FormHelperText,
  FormGroup,
  Box,
  Typography,
  Modal,
  CircularProgress
} from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";

/* CUSTOM LIBRARIES */
import CustomSelect from "../components/customSelect";
import DragDropFile from "../components/dragAndDrop";
import CustomStepper from "../components/customStepper";

/* TEST DATA */
import { listaDocumentosRechazados } from "../../../../controller/testData";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../../controller/windowSize";
import { transition } from "../../../../controller/utils";
import {
  listaMotivos,
  listaTiposContacto,
  stepsSupportCenter
} from "../../../../controller/listas";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../../redux/confirmModalSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentType,
  selectCurrentUser
} from "../../../../redux/authSlice";
import {
  useLazyGetEmpresaUserQuery,
  useLazyGetSubcontratistasQuery
} from "../../../../api/empresasApiSlice";
import { useLazyGetFaenasQuery } from "../../../../api/faenasApiSlice";
import { useLazyGetUserQuery } from "../../../../api/authApiSlice";
import LoadingRocket from "../things/loadingRocket";
import { useLazyGetDocumentosProblemasFaenaQuery } from "../../../../api/docsApiSlice";
import { useCreateTicketMutation } from "../../../../api/ticketsApiSlice";

function FirstPage(props) {
  const {
    handleContinue,
    continueValue,
    setContinueValue,
    valueNombre,
    valueCorreo,
    valueTelefono,
    empresa,
    setEmpresa,
    faena,
    setFaena,
    listaEmpresas,
    listaFaenas
  } = props;

  const littleSize = littleSizeFunc();
  const userType = useSelector(selectCurrentType);
  const user = useSelector(selectCurrentUser);

  const [listaEmpresasCopy, setListaEmpresasCopy] = useState([]);
  const [listaFaenasCopy, setListaFaenasCopy] = useState([]);

  const [errorCliente, setErrorCliente] = useState("");
  const [errorFaena, setErrorFaena] = useState("");

  useEffect(() => {
    setContinueValue(
      valueNombre && valueCorreo && valueTelefono && empresa && faena
    );
  }, [empresa, faena]);

  useEffect(() => {
    setListaFaenasCopy(listaFaenas);
    setListaEmpresasCopy(listaEmpresas);
  }, [listaFaenas, listaEmpresas]);

  const handleSubmit = (event) => event.preventDefault();
  const handleContinueDisable = () => {
    userType === "B"
      ? setErrorCliente("Elija subcontratista")
      : setErrorCliente("Elija cliente");
    setErrorFaena("Elija faena");
  };

  const handleSetEmpresa = (value) => {
    setEmpresa(value);
    let lista = listaFaenas.filter((faena) => faena.empresa === value);
    if (![...lista].includes(faena)) {
      setFaena("");
    }
    setListaFaenasCopy(lista);
  };

  const handleSetFaena = (value) => {
    setFaena(value);
    let faena = listaFaenas.find((f) => f.id === value);
    let empresa = listaEmpresas.find((e) => faena.empresa === e.id);
    setEmpresa(empresa.id);
  };

  /* CSS */
  const datosBox = {
    display: "flex",
    flexDirection: "column",
    gap: "3.882vh",
    alignItems: littleSize ? "center" : ""
  };
  const datos = {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    alignItems: littleSize ? "center" : ""
  };
  const tituloDatos = {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "140%",
    color: "#1876D1"
  };
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : ""
  };
  const selectDatosBox = {
    flexDirection: "row",
    gap: "0px",
    maxWidth: "15.6vw",
    width: "14.58vw",
    minWidth: "210px"
  };
  const textField = {
    height: "36px",
    background: "#FFFFFF",
    // fontWeight: 400,
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px"
      // fontWeight: 400,
    }
  };
  const textFieldTelLeft = {
    width: "58px",
    height: "36px",
    borderRadius: "4.34415px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      borderBottomRightRadius: "0%",
      borderTopRightRadius: "0%",
      "& .MuiOutlinedInput-input": {
        paddingLeft: "0px",
        paddingRight: "0px"
      }
    }
  };
  const textFieldTelRight = {
    width: "calc(100% - 58px)",
    height: "36px",
    borderRadius: "4.34415px",
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      borderBottomLeftRadius: "0%",
      borderTopLeftRadius: "0%"
    }
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px"
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1",
    minWidth: "110px"
  };

  return (
    <Box sx={datosBox} component="form" onSubmit={handleSubmit}>
      <Box sx={datos}>
        <Typography sx={tituloDatos}>Datos de empresa</Typography>
        <Box sx={selectDatosBoxBox}>
          <Box sx={selectDatosBox} component={FormGroup}>
            <CustomSelect
              req={true}
              label={
                userType === "B"
                  ? "Seleccionar Sucontratado"
                  : "Seleccionar Cliente"
              }
              fontWeightValue={400}
              value={empresa}
              setValue={handleSetEmpresa}
              errorText={empresa ? "" : errorCliente}
            >
              {listaEmpresasCopy}
            </CustomSelect>
          </Box>
          <Box sx={selectDatosBox} component={FormGroup}>
            <CustomSelect
              req={true}
              label="Seleccionar Faena"
              fontWeightValue={400}
              value={faena}
              setValue={handleSetFaena}
              errorText={faena ? "" : errorFaena}
            >
              {listaFaenasCopy}
            </CustomSelect>
          </Box>
        </Box>
      </Box>
      <Box sx={datos}>
        <Typography sx={tituloDatos}>Datos de contacto</Typography>
        <Box sx={selectDatosBoxBox}>
          <Box sx={selectDatosBox} component={FormGroup}>
            <TextField
              sx={textField}
              disabled
              size="small"
              fullWidth
              required
              id="nombre"
              label="Nombre"
              type="text"
              value={valueNombre}
            />
          </Box>
          <Box sx={selectDatosBox} component={FormGroup}>
            <TextField
              sx={textField}
              disabled
              size="small"
              fullWidth
              required
              id="correo"
              label="Correo"
              type="email"
              value={valueCorreo}
            />
          </Box>
          <Box sx={selectDatosBox} component={FormGroup}>
            <TextField
              sx={textFieldTelLeft}
              size={"small"}
              fullWidth
              disabled
              label="+56"
            />
            <TextField
              sx={textFieldTelRight}
              disabled
              size="small"
              fullWidth
              required
              id="telefono"
              label="Telefono"
              type="tel"
              value={valueTelefono.replace("+56", "")}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={buttonBox}>
        <Button
          sx={button}
          disableElevation
          variant="contained"
          type="submit"
          onClick={continueValue ? handleContinue : handleContinueDisable}
          disabled={!continueValue}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
}

function SecondPage(props) {
  const {
    handleContinue,
    handleBack,
    continueValue,
    setContinueValue,
    setOpenModal,
    valueParrafo,
    setValueParrafo,
    contactoValue,
    setContactoValue,
    motivoValue,
    setMotivoValue,
    docRechazado,
    setDocRechazado,
    faena
  } = props;
  const littleSize = littleSizeFunc();

  const [triggerGetDocs, { isLoading }] =
    useLazyGetDocumentosProblemasFaenaQuery();

  const [listaDocsRechazados, setListaDocsRechazados] = useState([]);

  useEffect(() => {
    if (motivoValue === "Documento rechazado") {
      setContinueValue(
        valueParrafo && contactoValue && motivoValue && docRechazado
      );
    } else {
      setContinueValue(valueParrafo && contactoValue && motivoValue);
    }
  }, [valueParrafo, contactoValue, motivoValue, docRechazado]);

  useEffect(() => {
    if (contactoValue === "Llamada") {
      handleOpenModal();
    }
  }, [contactoValue]);

  useEffect(() => {
    if (motivoValue === "Documento rechazado") {
      handleGetDocsRechazados();
    }
  }, [motivoValue]);

  const handleGetDocsRechazados = async () => {
    const { data, isSuccess } = await triggerGetDocs(faena, false);
    if (isSuccess) {
      let lista = [];
      data?.data.map((t) => {
        lista.push({
          id: t.id,
          value: t.nombre_tipo_documento
        });
      });
      setListaDocsRechazados(lista);
    }
  };

  const handleParrafoValue = (value) => {
    if (valueParrafo.length <= 255 && value.length <= 255) {
      setValueParrafo(value);
    }
  };

  const handleOpenModal = () => setOpenModal(true);

  /* CSS */
  const datosBox = {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: "3.882vh",
    alignItems: littleSize ? "center" : ""
  };
  const datos = {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: "25px",
    alignItems: littleSize ? "center" : ""
  };
  const tituloDatos = {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "140%",
    color: "#1876D1"
  };
  const selectDatosBoxBox = {
    height: "fit-content",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : ""
  };
  const selectDatosBox = {
    gap: "0px",
    width: "fit-content",
    minWidth: "210px"
  };
  const selectDatosBoxDocs = {
    gap: "0px",
    width: "fit-content",
    width: "420px"
  };
  const textField = {
    width: "100%",
    height: "fit-content",
    background: "#FFFFFF",
    fontWeight: 600,
    " .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400
    }
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px"
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    visibility: littleSize ? "visible" : "",
    ":hover": {
      border: "2px solid #1876D1"
    }
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1"
  };

  return (
    <Box sx={datosBox}>
      <Box sx={datos}>
        <Box sx={selectDatosBoxBox}>
          <Box sx={selectDatosBox}>
            <CustomSelect
              req={true}
              label="Tipo de contacto"
              fontWeightValue={400}
              value={contactoValue}
              setValue={setContactoValue}
            >
              {listaTiposContacto}
            </CustomSelect>
          </Box>
          <Box sx={selectDatosBox}>
            <CustomSelect
              req={true}
              label="Motivo de contacto"
              fontWeightValue={400}
              value={motivoValue}
              setValue={setMotivoValue}
            >
              {listaMotivos}
            </CustomSelect>
          </Box>
          {motivoValue === "Documento rechazado" ? (
            <Box sx={selectDatosBoxDocs}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <CustomSelect
                  req={true}
                  label="Documento rechazado"
                  fontWeightValue={400}
                  value={docRechazado}
                  setValue={setDocRechazado}
                >
                  {listaDocsRechazados}
                </CustomSelect>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box sx={datos}>
        <Typography sx={tituloDatos}>
          Detalle lo más posible el problema a continuación:
        </Typography>
        <Box sx={selectDatosBoxBox}>
          <TextField
            sx={textField}
            size="medium"
            fullWidth
            required
            multiline
            rows={5}
            id="parrafo"
            label="Detalle del problema"
            type="text"
            value={valueParrafo}
            onChange={(e) => handleParrafoValue(e.target.value)}
            helperText={
              <Typography variant="body1" style={{ color: "#818181" }}>
                {valueParrafo.length} / 255
              </Typography>
            }
          />
        </Box>
      </Box>
      <Box sx={buttonBox}>
        <Button sx={buttonVolver} variant="outlined" onClick={handleBack}>
          Volver
        </Button>
        <Button
          sx={button}
          disableElevation
          variant="contained"
          type="submit"
          onClick={handleContinue}
          disabled={!continueValue}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
}

function ThirdPage(props) {
  const {
    continueValue,
    handleContinue,
    handleBack,
    uploadedFiles,
    setUploadedFiles
  } = props;
  const littleSize = littleSizeFunc();

  /* CSS */
  const datosBox = {
    display: "flex",
    flexDirection: "column",
    gap: "3.882vh",
    alignItems: littleSize ? "center" : ""
  };
  const tituloDatos = {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "140%",
    color: "#1876D1"
  };
  const selectDatosBoxBox = {
    height: "fit-content",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : ""
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px"
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    visibility: littleSize ? "visible" : "",
    ":hover": {
      border: "2px solid #1876D1"
    }
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1"
  };

  return (
    <Box sx={datosBox}>
      <Typography sx={tituloDatos}>
        Seleccione los documentos de apoyo a subir (hasta 3)
      </Typography>
      <Box sx={selectDatosBoxBox}>
        <DragDropFile
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          setContinueValue={() => {}}
          maxCount={3}
        />
      </Box>
      <Box sx={buttonBox}>
        <Button sx={buttonVolver} variant="outlined" onClick={handleBack}>
          Volver
        </Button>
        <Button
          sx={button}
          disableElevation
          variant="contained"
          type="submit"
          onClick={handleContinue}
          disabled={!continueValue}
        >
          Crear Ticket
        </Button>
      </Box>
    </Box>
  );
}

export default function SupportPopUp(props) {
  const { setOpenSupp } = props;
  const littleSize = littleSizeFunc();
  const dispatch = useDispatch();
  const userType = useSelector(selectCurrentType);

  const [triggerGetFaenas, getFaenasStatus] = useLazyGetFaenasQuery();
  const [triggerGetUsuario, getUsuarioStatus] = useLazyGetUserQuery();
  const [createTicket, createSatatus] = useCreateTicketMutation();

  const [stepCount, setStepCount] = useState(0);
  const [continueValue, setContinueValue] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // States for the first page
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [listaFaenas, setListaFaenas] = useState([]);
  const [valueNombre, setValueNombre] = useState("");
  const [valueCorreo, setValueCorreo] = useState("");
  const [valueTelefono, setValueTelefono] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [faena, setFaena] = useState("");

  // States for the second page
  const [valueParrafo, setValueParrafo] = useState("");
  const [contactoValue, setContactoValue] = useState("");
  const [motivoValue, setMotivoValue] = useState("");
  const [docRechazado, setDocRechazado] = useState("");

  // States for the third page
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    if (stepCount === 3) {
      handleCreateTicket();
    }
    if (stepCount === 2) {
      setContinueValue(true);
    }
  }, [stepCount]);

  useEffect(() => {
    if (setOpenSupp) {
      handleGetFaenasData();
      handleGetUsuarioData();
    }
  }, [setOpenSupp]);

  useEffect(() => {
    setIsLoading(getFaenasStatus.isLoading && getUsuarioStatus.isLoading);
  }, [getFaenasStatus, getUsuarioStatus]);

  const handleGetFaenasData = async () => {
    const { data, isSuccess } = await triggerGetFaenas(null, false);
    if (isSuccess) {
      let lista_e = [];
      let lista_f = [];
      data?.data.map((t) => {
        let id_empresa =
          userType == "B" ? t.empresa_outsourcing : t.empresa_mandante;
        let nombre_empresa =
          userType == "B"
            ? t.nombre_empresa_outsourcing
            : t.nombre_empresa_mandante;
        if (!lista_e.find((e) => e.value === empresa)) {
          lista_e.push({
            id: id_empresa,
            value: nombre_empresa
          });
        }
        lista_f.push({
          id: t.id,
          value: t.nombre,
          empresa: id_empresa
        });
      });
      let jsonObject = lista_e.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      lista_e = Array.from(uniqueSet).map(JSON.parse);
      setListaEmpresas(lista_e);
      setListaFaenas(lista_f);
    }
  };

  const handleGetUsuarioData = async () => {
    const { data, isSuccess } = await triggerGetUsuario(null, false);
    if (isSuccess) {
      setValueNombre(
        data.name + " " + data.firstLastname + " " + data.secondLastname
      );
      setValueCorreo(data.email);
      setValueTelefono(data.phone);
    }
  };

  const handleCreateTicket = async () => {
    try {
      const response = await createTicket({
        tipo_contacto: contactoValue,
        motivo: motivoValue,
        detalles: valueParrafo,
        faena: faena,
        documento_1: uploadedFiles[0] ? uploadedFiles[0].blob : null,
        documento_1_name: uploadedFiles[0] ? uploadedFiles[0].name : null,
        documento_2: uploadedFiles[1] ? uploadedFiles[1].blob : null,
        documento_2_name: uploadedFiles[1] ? uploadedFiles[1].name : null,
        documento_3: uploadedFiles[2] ? uploadedFiles[2].blob : null,
        documento_3_name: uploadedFiles[2] ? uploadedFiles[2].name : null,
        documento_rechazado: docRechazado
      }).unwrap();
      dispatch(setSuccessText("¡Tu ticket ha sido creado de forma exitosa!"));
      setOpenSupp(false);
    } catch (err) {
      console.error(err);
      dispatch(
        setErrorText(
          "¡Hubo un error con la creación de el ticket de ayuda, intente nuevamente o contáctese con nosotros!"
        )
      );
    }
    dispatch(setOpenConfirmModal(true));
    setStepCount(0);
  };

  const handleContinue = () => {
    setContinueValue(false);
    setStepCount(stepCount + 1);
  };
  const handleCloseModal = () => setOpenModal(false);
  const handleClose = () => setOpenSupp(false);
  const handleBack = () => setStepCount(stepCount - 1);
  const handleSubmit = (e) => e.preventDefault();

  /* CSS */
  const container = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    minWidth: "250px",
    maxWidth: "1100px",
    height: "fit-content",
    maxHeight: "98vh",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: "6vh 3.542vw 0px 3.542vw"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const tituloBox = {
    padding: "0px 0px 5vh 0px"
  };
  const titulo = {
    fontWeight: 700,
    fontSize: "32px",
    textAlign: littleSize ? "center" : ""
    // lineHeight: '120%',
  };
  const subtitulo = {
    fontWeight: 400,
    fontSize: "20px",
    textAlign: littleSize ? "center" : ""
    // lineHeight: '120%',
  };
  const datosButtonBox = {
    display: "flex",
    width: "100%",
    alignItems: littleSize ? "center" : "",
    justifyContent: "center",
    flexDirection: "column",
    overflowY: "auto",
    gap: "5vh",
    padding: "5vh 0px 5vh 0px"
  };
  const modalBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    minWidth: "250px",
    maxWidth: "1100px",
    height: "fit-content",
    maxHeight: "80%",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: littleSize
      ? "2vh 2.77778vw 2vh 2.77778vw"
      : "7.06vh 2.77778vw 4.206vh 2.77778vw",
    gap: littleSize ? "1vh" : "3.882vh"
  };
  const buttonModalBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  };
  const buttonModal = {
    fontWeight: 600,
    borderRadius: "10px",
    textTransform: "none"
  };

  return (
    <div>
      <Box sx={container} component="form" onSubmit={handleSubmit}>
        <Box sx={closeButtonBox}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={tituloBox}>
          <Typography sx={titulo}>Centro de ayuda</Typography>
          <Typography sx={subtitulo}>Crear ticket de ayuda</Typography>
        </Box>
        <Box>
          <CustomStepper stepCount={stepCount}>
            {stepsSupportCenter}
          </CustomStepper>
        </Box>
        <Box sx={datosButtonBox}>
          {!isLoading ? (
            stepCount === 0 ? (
              <FirstPage
                handleContinue={handleContinue}
                continueValue={continueValue}
                setContinueValue={setContinueValue}
                valueNombre={valueNombre}
                setValueNombre={setValueNombre}
                valueCorreo={valueCorreo}
                setValueCorreo={setValueCorreo}
                valueTelefono={valueTelefono}
                setValueTelefono={setValueTelefono}
                empresa={empresa}
                setEmpresa={setEmpresa}
                faena={faena}
                setFaena={setFaena}
                listaEmpresas={listaEmpresas}
                setListaEmpresas={setListaEmpresas}
                listaFaenas={listaFaenas}
                setListaFaenas={setListaFaenas}
              />
            ) : stepCount === 1 ? (
              <SecondPage
                handleBack={handleBack}
                continueValue={continueValue}
                handleContinue={handleContinue}
                setContinueValue={setContinueValue}
                setOpenModal={setOpenModal}
                valueParrafo={valueParrafo}
                setValueParrafo={setValueParrafo}
                contactoValue={contactoValue}
                setContactoValue={setContactoValue}
                motivoValue={motivoValue}
                setMotivoValue={setMotivoValue}
                docRechazado={docRechazado}
                setDocRechazado={setDocRechazado}
                faena={faena}
              />
            ) : (
              <ThirdPage
                handleBack={handleBack}
                handleContinue={handleContinue}
                continueValue={continueValue}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
              />
            )
          ) : (
            <LoadingRocket dialog={false} dark tiny />
          )}
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box sx={modalBox}>
            <Typography>
              <p>¡Muchas gracias por tu contacto!</p>
              <p>
                En un plazo <b>máximo de 24 horas</b>, uno de nuestros
                ejecutivos se pondrá en contacto contigo al teléfono{" "}
                <b>{valueTelefono}</b> registrado en el paso anterior.
              </p>
              <p>
                Para agilizar más el proceso, por favor{" "}
                <b>danos más detalle del problema</b> en el espacio para
                mensajes dispuesto en la pantalla.
              </p>
            </Typography>
            <Box sx={buttonModalBox}>
              <Button
                sx={buttonModal}
                variant="contained"
                onClick={handleCloseModal}
              >
                Entendido
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
