import React from "react";
import { Box, Tooltip } from "@mui/material";
import * as MaterialIcons from "@mui/icons-material";

const GenerateIcon = (variation, props = {}) => {
  const IconName = MaterialIcons[variation];
  if (IconName === undefined) {
    return null;
  } else {
    return <IconName {...props} />;
  }
};

export default function TipoTrabajadoresIcons(props) {
  /* CSS */
  const cellBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "5px",
    margin: "0px",
    marginLeft: "auto",
    marginRight: "auto"
  };
  const iconBox = {
    width: "fit-content",
    height: "fit-content",
    color: "#1876D1"
  };

  return (
    <Box sx={cellBox}>
      {props.children.map((child) => (
        <Tooltip
          title={child.nombre}
          placement="top-end"
          enterDelay={300}
          arrow
        >
          <Box sx={iconBox}>{GenerateIcon(child.icono)}</Box>
        </Tooltip>
      ))}
    </Box>
  );
}
