import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

/* CUSTOM LIBRARIES */
import CustomTable from "../common/components/customTable";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { headCellsClientes } from "../../../controller/listas";
import { POLLING_INTERVAL, transition } from "../../../controller/utils";
import { useGetClientesQuery } from "../../../api/empresasApiSlice";
import { useOutletContext } from "react-router-dom";

export default function Clientes() {
  const { setSubtitleValue } = useOutletContext();
  const { currentData, isSuccess, isLoading, isFetching, refetch } =
    useGetClientesQuery(null, {
      pollingInterval: POLLING_INTERVAL
    });

  const [lineaDocumentoSeleccionada, setLineaDocumentoSeleccionada] =
    useState("");
  const [listaClientes, setListaClientes] = useState([]);

  useEffect(() => {
    setSubtitleValue("");
  }, []);

  useEffect(() => {
    if (isSuccess && currentData) {
      setListaClientes([...currentData.data]);
    }
  }, [currentData, isSuccess, isLoading, isFetching]);

  /* CSS */
  const contenido = {
    minHeight: "58vh",
    height: "fit-content",
    maxHeight: "84vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "5.17vh",
    transition: transition
  };

  return listaClientes ? (
    <Box sx={contenido}>
      <CustomTable
        wide
        headCells={headCellsClientes}
        rows={listaClientes}
        setRows={setListaClientes}
        variant="clientes"
        setLineaSeleccionada={setLineaDocumentoSeleccionada}
        isLoading={isLoading}
      />
    </Box>
  ) : null;
}
