import { Box, Dialog, Typography } from "@mui/material";
import rocketGif from "../../../../images/logo/rocket.gif";

export default function LoadingRocket(props) {
  const {
    dialog = true,
    dark = false,
    isLoading,
    tiny = false,
    texto = "Cargando...",
  } = props;

  const loadingBox = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  const loadingGifStyle = {
    width: tiny ? "30%" : "100%",
    height: tiny ? "30%" : "100%",
    filter: dark
      ? // ? "brightness(0.4) invert(50%)"
        "invert(31%) sepia(93%) saturate(1222%) hue-rotate(189deg) brightness(98%) contrast(90%)"
      : "brightness(0.4) invert(100%)",
    // "brightness(0.4) contrast(60%) sepia(500%) hue-rotate(200deg) saturate(2000%)",
  };
  const loadingTextStyle = {
    width: "100%",
    textAlign: "center",
    fontSize: "24px",
    fontWeight: 600,
    // color: dark ? "#818181" : "#FCFBFB"
    color: dark ? "#1876D1" : "#FCFBFB",
  };

  return dialog ? (
    <Dialog
      open={isLoading}
      disableEscapeKeyDown
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <Box sx={loadingBox}>
        <Box
          component="img"
          loading="lazy"
          sx={loadingGifStyle}
          src={rocketGif}
        />
        <Typography sx={loadingTextStyle}>{texto}</Typography>
      </Box>
    </Dialog>
  ) : (
    <Box>
      <Box sx={loadingBox}>
        <Box
          component="img"
          loading="lazy"
          sx={loadingGifStyle}
          src={rocketGif}
        />
        <Typography sx={loadingTextStyle}>{texto}</Typography>
      </Box>
    </Box>
  );
}
