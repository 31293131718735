import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Button, Box, Modal, Typography } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "../../../animations/Warning Icon/warningIcon";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../../controller/windowSize";
import ErrorIcon from "../../../animations/Error Icon/errorIcon";

export default function CustomAlert(props) {
  const { openModal, setOpenModal, error = false } = props;
  const littleSize = littleSizeFunc();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  /* CSS */
  const modalBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    minWidth: "200px",
    maxWidth: "600px",
    height: "fit-content",
    maxHeight: "98vh",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: "2vh 2vw"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const contentWithText = {
    gap: "1vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflowY: "auto"
  };
  const textStyle = {
    fontSize: "18px",
    textAlign: "center",
    whiteSpace: "pre-line"
  };

  return (
    <Modal
      open={openModal}
      onClose={(e) => setOpenModal(false)}
      disableRestoreFocus
    >
      <Box sx={modalBox}>
        <Box sx={closeButtonBox}>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={contentWithText}>
          {error ? <ErrorIcon /> : <WarningIcon />}
          <Typography sx={textStyle}>{props.children}</Typography>
        </Box>
      </Box>
    </Modal>
  );
}
