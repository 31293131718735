import React, { useEffect, useMemo, useRef, useState } from "react";
import snakeCase from "lodash/snakeCase";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import isMobilePhone from "validator/lib/isMobilePhone";
import { Form, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormHelperText,
  ListItemButton,
  Card,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import * as MaterialIcons from "@mui/icons-material";

/* ICONS */

/* CUSTOM LIBRARIES */

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import {
  useAddTipoDocumentoMutation,
  useLazyGetTipoDocumentosTrabajadorQuery,
} from "../../../api/tipodocsApiSlice";

/* TEST DATA */

// const icons = Object.entries(MaterialIcons)
//   .filter(([key, value], i) => key.endsWith("Outlined"))
//   .map(([key, value]) => key);

const GenerateIcon = (variation, props = {}) => {
  const IconName = MaterialIcons[variation];

  const icon = {
    color: "#1876D1",
    width: "35px",
    maxWidth: "35px",
    minWidth: "35px",
    height: "35px",
    maxHeight: "35px",
    minHeight: "35px",
  };

  return <IconName sx={icon} {...props} />;
};

export default function NuevoTipoTrabajador(props) {
  const { setContinueValue, open, setTipoTrabajador } = props;
  const navigate = useNavigate();
  const littleSize = littleSizeFunc();

  const icons = useRef([], []);

  const [add, addResult] = useAddTipoDocumentoMutation();
  const [triggerGetData, { isLoading, isFetching }] =
    useLazyGetTipoDocumentosTrabajadorQuery();

  const [openFinishModal, setOpenFinishModal] = useState(false);
  const [showOtro, setShowOtro] = useState(false);
  const [docs, setDocs] = useState([]);

  const [valueNombre, setValueNombre] = useState("");
  const [valueOtroDocumento, setValueOtroDocumento] = useState("");
  const [valueOtroDocumentoAbr, setValueOtroDocumentoAbr] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");
  const [selectedDocs, setSelectedDocs] = useState([]);

  const [errorNombre, setErrorNombre] = useState("");
  const [errorOtroDocumentoAbr, setErrorOtroDocumentoAbr] = useState("");
  const [errorOtroDocumento, setErrorOtroDocumento] = useState("");

  useEffect(() => {
    setContinueValue(
      !errorNombre && valueNombre && selectedIcon && selectedDocs.length > 0
    );
  }, [errorNombre, valueNombre, selectedIcon, selectedDocs]);

  useEffect(() => {
    if (!errorNombre) {
      setTipoTrabajador({
        nombre: valueNombre,
        icono: selectedIcon,
        tipo_documentos: selectedDocs,
      });
    }
  }, [errorNombre, valueNombre, selectedIcon, selectedDocs]);

  useEffect(() => {
    if (open) {
      icons.current = getMaterialIcons();
      handleGetTipoDocsData();
    }
  }, [open]);

  const getMaterialIcons = () =>
    Object.entries(MaterialIcons)
      .filter(([key, value], i) => key.endsWith("Outlined"))
      .map(([key, value]) => key);
  const handleGetTipoDocsData = async () => {
    const { data, isSuccess } = await triggerGetData(false);
    if (isSuccess) {
      setDocs(data.data);
    } else {
      setDocs([{ id: "", nombre: "", abreviacion: "" }]);
    }
  };
  const handleAnadirDoc = async (event) => {
    let d = [...docs];
    if (
      !d.some((doc) => {
        let re = new RegExp(`^${doc.title}$`, "gi");
        let re2 = new RegExp(`^${doc.abreviacion}$`, "gi");
        return re.test(valueOtroDocumento) && re2.test(valueOtroDocumentoAbr);
      })
    ) {
      let otroDocumento = {
        nombre: `${valueOtroDocumento}`,
        abreviacion: `${valueOtroDocumentoAbr}`,
      };
      try {
        d = await add(otroDocumento).unwrap();
        setDocs(d.data);
      } catch (e) {
        console.error(e);
      }
      handleClickDoc(event, otroDocumento);
      setValueOtroDocumentoAbr("");
      setValueOtroDocumento("");
    } else {
      setErrorOtroDocumento("Documento con ese nombre ya existe.");
    }
  };
  const handleClickIcon = (event, title) => {
    setSelectedIcon(title);
  };
  const handleChangeValueOtroDocAbr = (value) => {
    setErrorOtroDocumentoAbr("");
    setValueOtroDocumentoAbr(value);
  };
  const handleChangeValueOtroDoc = (value) => {
    setErrorOtroDocumento("");
    setValueOtroDocumento(value);
  };
  const handleShowOtro = (event) => {
    event.preventDefault();
    setShowOtro(!showOtro);
  };
  const handleClickDoc = (event, doc) => {
    let selected = [...selectedDocs];
    if (selected.indexOf(doc.id) === -1) {
      selected.push(doc.id);
    } else {
      selected.splice(selected.indexOf(doc.id), 1);
    }
    setSelectedDocs(selected);
  };
  const handleNotEmpty = (value, setValue, setError, error) => {
    setValue(value);
    if (isEmpty(value)) {
      setError(error);
    } else {
      setError("");
    }
  };

  /* CSS */
  const contenido = {
    display: "flex",
    flexDirection: "column",
    gap: "4.47vh 3.47vw",
    justifyContent: "flex-start",
    flexWrap: littleSize ? "unwrap" : "wrap",
    alignItems: "center",
    overflow: littleSize ? "auto" : "hidden",
    overflow: "auto",
    height: "100%",
    padding: "6vh 0",
    width: "100%",
  };
  const inputBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: littleSize ? "wrap" : "nowrap",
    width: "40%",
    height: "fit-content",
    gap: "22px",
    justifyContent: littleSize ? "center" : "space-between",
    alignItems: "start",
  };
  const inputBoxDocs = {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    height: "fit-content",
    gap: "22px",
    justifyContent: littleSize ? "center" : "space-between",
    // overflow: "hidden",
    alignItems: "start",
  };
  const text = {
    width: "fit-content",
    fontWeight: 600,
    color: "#818181",
  };
  const textFieldBox = {
    width: "100%",
    minWidth: "210px",
  };
  const textField = {
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400,
    },
  };
  const iconListBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    minWidth: "210px",
    maxHeight: "400px",
    minHeight: "100px",
    height: "30vh",
    flexWrap: "wrap",
    overflowY: "auto",
  };
  const icon = {
    display: "flex",
    justifyContent: "center",
    width: "55px",
    color: "#1876D1",
    borderRadius: "10px",
    "&:hover": {
      opacity: 0.9,
    },
    "&.Mui-selected": {
      border: "2px solid #1876D1",
      "&:hover": {
        opacity: 0.9,
      },
    },
  };
  const docsListBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    width: "90%",
    minWidth: "210px",
    height: "100%",
    flexWrap: "wrap",
    // overflowY: "auto",
    gap: "5px",
  };
  const docCard = {
    color: "#1876D1",
    background: "#FCFCFC",
    borderRadius: "20px",
    boxShadow: 0,
    border: "2px solid #1876D1",
    "&.MuiListItemButton-dense": {
      width: "fit-content",
      maxWidth: "fit-content",
    },
    "&:hover": {
      boxShadow: 0,
      opacity: 0.9,
    },
    "&.Mui-selected": {
      boxShadow: 0,
      background: "#1876D1",
      color: "#FCFCFC",
      "&:hover": {
        boxShadow: 0,
        background: "#1876D1",
        color: "#FCFCFC",
        opacity: 0.9,
      },
    },
  };
  const docCardText = {
    fontWeight: 500,
  };
  const textOtroBox = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: "11px",
    minWidth: "210px",
    width: "100%",
  };
  const button = {
    minWidth: "160px",
    textTransform: "none",
    borderRadius: "10px",
    fontWeight: 600,
  };

  const iconList = useMemo(
    () =>
      icons.current.map((iconName) => (
        <ListItemButton
          sx={icon}
          selected={iconName === selectedIcon}
          key={iconName}
          onClick={(event) => handleClickIcon(event, iconName)}
        >
          {GenerateIcon(iconName)}
        </ListItemButton>
      )),
    [selectedIcon, icons.current]
  );

  return (
    <Box sx={contenido}>
      <Box sx={inputBox}>
        <Typography sx={text}>Nombre tipo trabajador:</Typography>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            autoComplete="off"
            id="nombre"
            label="Nombre"
            type="text"
            value={valueNombre}
            onChange={(e) =>
              handleNotEmpty(
                e.target.value,
                setValueNombre,
                setErrorNombre,
                "Escriba el nombre"
              )
            }
          />
          <FormHelperText error>{errorNombre}</FormHelperText>
        </Box>
      </Box>
      <Box sx={inputBox}>
        <Typography sx={text}>Icono tipo trabajador:</Typography>
        <Box sx={iconListBox}>
          {icons.current.length > 0 ? iconList : <CircularProgress />}
        </Box>
      </Box>
      <Box sx={inputBoxDocs}>
        <Typography sx={text}>Documentos del tipo de trabajador:</Typography>
        <Box sx={docsListBox}>
          {docs.length > 0 ? (
            docs.map((doc) => (
              <Tooltip
                title={doc.nombre}
                placement="top-end"
                arrow
                enterDelay="300"
                followCursor
              >
                <ListItemButton
                  sx={docCard}
                  dense
                  component={Card}
                  selected={selectedDocs.indexOf(doc.id) > -1}
                  key={doc.id}
                  onClick={(event) => handleClickDoc(event, doc)}
                >
                  <Typography sx={docCardText}>{doc.abreviacion}</Typography>
                </ListItemButton>
              </Tooltip>
            ))
          ) : (
            <CircularProgress />
          )}
          <Tooltip
            title={"Agregar otro documento..."}
            placement="top-end"
            arrow
            enterDelay="300"
            followCursor
          >
            <ListItemButton
              sx={docCard}
              dense
              component={Card}
              selected={showOtro}
              key={"otro"}
              onClick={(event) => handleShowOtro(event)}
            >
              <Typography sx={docCardText}>Otro...</Typography>
            </ListItemButton>
          </Tooltip>
          {showOtro ? (
            <Box sx={textOtroBox}>
              <Box>
                <Box>
                  <TextField
                    sx={textField}
                    variant="standard"
                    autoFocus
                    fullWidth
                    autoComplete="off"
                    id="otro"
                    label="Tipo documento"
                    type="text"
                    value={valueOtroDocumento}
                    onChange={(e) => handleChangeValueOtroDoc(e.target.value)}
                  />
                  <FormHelperText error>{errorOtroDocumento}</FormHelperText>
                </Box>
                <Box>
                  <TextField
                    sx={textField}
                    variant="standard"
                    autoFocus
                    fullWidth
                    autoComplete="off"
                    id="otro-a"
                    label="Abreviación"
                    type="text"
                    value={valueOtroDocumentoAbr}
                    onChange={(e) =>
                      handleChangeValueOtroDocAbr(e.target.value)
                    }
                  />
                  <FormHelperText error>{errorOtroDocumentoAbr}</FormHelperText>
                </Box>
              </Box>
              <Button
                disabled={valueOtroDocumento.length > 0 ? false : true}
                sx={button}
                variant="contained"
                onClick={handleAnadirDoc}
              >
                Añadir
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
