import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  trabajador: { id: "", nombre: "", documentos: [] },
  empresa: { id: "", value: "", documentos: [] },
  faena: { id: "", value: "", documentos: [] },
  factura: { id: "", value: "", documentos: [] }
};

export const entidadadesSlice = createSlice({
  name: "entidades",
  initialState: initialState,
  reducers: {
    setTrabajador: (state, action) => {
      state.trabajador = action.payload;
    },
    setEmpresa: (state, action) => {
      state.empresa = action.payload;
    },
    setFaena: (state, action) => {
      state.faena = action.payload;
    },
    setFactura: (state, action) => {
      state.factura = action.payload;
    }
  }
});

export const { setTrabajador, setEmpresa, setFaena, setFactura } =
  entidadadesSlice.actions;
export default entidadadesSlice.reducer;

export const currentTrabajador = (state) => state.entidades.trabajador;
export const currentEmpresa = (state) => state.entidades.empresa;
export const currentFaena = (state) => state.entidades.faena;
export const currentFactura = (state) => state.entidades.factura;
