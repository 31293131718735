import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IconButton,
  Button,
  Typography,
  Box,
  Modal,
  TextField,
  Grid,
  List,
  Card,
  CardHeader,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider
} from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";

/* CUSTOM LIBRARIES */

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { transition } from "../../../controller/utils";
import { useDispatch, useSelector } from "react-redux";
import { currentFaena } from "../../../redux/entidadesSlice";
import LoadingRocket from "../common/things/loadingRocket";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../redux/confirmModalSlice";
import { useUpdateFaenaMutation } from "../../../api/faenasApiSlice";
import { useLazyGetEmpresaQuery } from "../../../api/empresasApiSlice";
import CustomSelect from "../common/components/customSelect";
import { selectCurrentType } from "../../../redux/authSlice";
import { useLazyGetTrabajadoresEditarFaenaQuery } from "../../../api/trabajadoresApiSlice";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const objectsEqual = (o1, o2) =>
  Object.keys(o1).length === Object.keys(o2).length &&
  Object.keys(o1).every((p) => o1[p] === o2[p]);

export default function EditarFaenaModal(props) {
  const { openModal, setOpenModal, refetch } = props;
  const userType = useSelector(selectCurrentType);
  const selectedFaena = useSelector(currentFaena);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const littleSize = littleSizeFunc();

  const [triggerGetDataEmpresa, empresaDataResult] =
    useLazyGetEmpresaQuery("ambas");

  const [triggerGetTrabajadores, trabajadoresDataResult] =
    useLazyGetTrabajadoresEditarFaenaQuery();

  const [updatePost, updateResult] = useUpdateFaenaMutation();

  const [continueValue, setContinueValue] = useState(false);

  const [nombreFaena, setNombreFaena] = useState();
  const [mandante, setMandante] = useState();
  const [outsourcing, setOutsourcing] = useState();
  const [listaMandantes, setListaMandantes] = useState([]);
  const [listaOutsourcing, setListaOutsourcing] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [checked, setChecked] = useState([]);
  const [trabajadoresSinFaena, setTrabajadoresSinFaena] = useState([]);
  const [trabajadoresEnFaena, setTrabajadoresEnFaena] = useState([]);
  const [trabajadoresEnFaenaInicial, setTrabajadoresEnFaenaInicial] = useState(
    []
  );

  const sinChecked = intersection(checked, trabajadoresSinFaena);
  const enChecked = intersection(checked, trabajadoresEnFaena);

  useEffect(() => {
    setIsLoading(
      trabajadoresDataResult.isLoading || empresaDataResult.isLoading
    );
  }, [trabajadoresDataResult.isLoading, empresaDataResult.isLoading]);

  useEffect(() => {
    if (openModal) {
      handleGetEmpresasData();
      handleGetTrabajadoresData();
      setNombreFaena(selectedFaena.nombre);
      setMandante(selectedFaena.empresa_mandante);
      setOutsourcing(selectedFaena.empresa_outsourcing);
    }
  }, [openModal]);

  useEffect(() => {
    setContinueValue(
      nombreFaena != selectedFaena.nombre ||
        mandante != selectedFaena.empresa_mandante ||
        outsourcing != selectedFaena.empresa_outsourcing ||
        !objectsEqual(trabajadoresEnFaenaInicial, trabajadoresEnFaena)
    );
  }, [nombreFaena, mandante, outsourcing, trabajadoresEnFaena]);

  const handleGetEmpresasData = async () => {
    const { data, isSuccess } = await triggerGetDataEmpresa("ambas");
    if (isSuccess) {
      setListaMandantes([...data.data.mandante]);
      setListaOutsourcing([...data.data.outsourcing]);
    }
  };

  const handleGetTrabajadoresData = async () => {
    const { data, isSuccess } = await triggerGetTrabajadores(selectedFaena.id);
    if (isSuccess) {
      setTrabajadoresSinFaena([...data.data.sin_faena]);
      setTrabajadoresEnFaena([...data.data.en_faena]);
      setTrabajadoresEnFaenaInicial([...data.data.en_faena]);
    }
  };

  const handleCloseModal = () => {
    refetch();
    setOutsourcing("");
    setMandante("");
    setNombreFaena("");
    setListaOutsourcing([]);
    setListaMandantes([]);
    setTrabajadoresSinFaena([]);
    setTrabajadoresEnFaena([]);
    setTrabajadoresEnFaenaInicial([]);
    setContinueValue(false);
    setOpenModal(false);
  };

  const handleGuardar = async () => {
    try {
      await updatePost({
        id: selectedFaena.id,
        nombre: nombreFaena,
        empresa_outsourcing: outsourcing,
        empresa_mandante: mandante,
        trabajadores: trabajadoresEnFaena,
        trabajadores_sin: trabajadoresSinFaena
      }).unwrap();
      dispatch(
        setSuccessText("¡Tus cambios han sido guardados de forma exitosa!")
      );
      handleCloseModal();
    } catch (e) {
      console.error(e);
      dispatch(
        setErrorText(
          "¡Hubo un error, intente nuevamente o contáctese con nosotros!"
        )
      );
    }
    dispatch(setOpenConfirmModal(true));
  };

  const handleSetMandante = (value) => {
    setMandante(value);
  };

  const handleSetOutsourcing = (value) => {
    setOutsourcing(value);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      let toCheck = union(checked, items);
      toCheck = toCheck.filter((e) => e.estado === "Validado");
      setChecked(toCheck);
    }
  };

  const handleCheckedRight = () => {
    setTrabajadoresEnFaena(trabajadoresEnFaena.concat(sinChecked));
    setTrabajadoresSinFaena(not(trabajadoresSinFaena, sinChecked));
    setChecked(not(checked, sinChecked));
  };

  const handleCheckedLeft = () => {
    setTrabajadoresSinFaena(trabajadoresSinFaena.concat(enChecked));
    setTrabajadoresEnFaena(not(trabajadoresEnFaena, enChecked));
    setChecked(not(checked, enChecked));
  };

  /* CSS */
  const modalBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxWidth: "1300px",
    height: "fit-content",
    maxHeight: "98vh",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: "5.882vh 3.472vw ",
    gap: "11px"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const izquierdaModal = {
    display: "flex",
    flexDirection: "column",
    gap: "11px"
  };
  const derechaModal = {
    display: "flex",
    flexDirection: "column",
    gap: "11px",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "2px"
  };
  const emptyBox = {
    height: "43px"
  };
  const horizontalBox = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: littleSize ? "center" : "space-around",
    alignItems: "start",
    flexDirection: "row",
    overflowY: "auto",
    gap: "22px",
    height: "100%"
  };
  const content = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflowY: "auto",
    height: "100%"
  };
  const title = {
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "140%"
  };
  const subtitle = {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "150%",
    alignItems: littleSize ? "center" : "",
    textAlign: littleSize ? "center" : ""
  };
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "3vh 3vw",
    justifyContent: littleSize ? "center" : "flex-start",
    alignItems: "center"
  };
  const selectDatosBox = {
    flexDirection: "column",
    gap: "0px",
    maxWidth: "300px",
    width: "14.58vw",
    minWidth: "210px"
  };
  const textField = {
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400
    },
    ".Mui-disabled": {
      opacity: 0.9
    }
  };
  const datosBox = {
    transition: transition,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: "3.882vh",
    alignItems: littleSize ? "center" : "",
    padding: "1vh 0 1vh 0"
  };
  const buttonBox = {
    display: "flex",
    alignItems: "center",
    justifyContent: littleSize ? "center" : "flex-end",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "17px",
    margin: "0 0 1px 0"
  };
  const button = {
    minWidth: "160px",
    textTransform: "none",
    borderRadius: "10px",
    fontWeight: 600
  };
  const cardStyle = {
    borderRadius: "20px",
    background: "#FFFFFF"
  };
  const cardHeaderStyle = { px: 2, py: 1 };
  const listStyle = {
    width: 200,
    height: 230,
    overflow: "auto"
  };
  const buttonChangeTrabajador = {
    my: 0.5,
    borderRadius: "20px",
    borderColor: "#1876D1"
  };

  const customList = (title, items) => (
    <Card sx={cardStyle}>
      <CardHeader
        sx={cardHeaderStyle}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List sx={listStyle} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value)}
              disabled={value.estado !== "Validado"}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${value.nombre}`}
                secondary={`${value.rut}`}
              />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return selectedFaena.id !== "" && !isLoading ? (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={modalBox}>
        <Box sx={closeButtonBox}>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={horizontalBox}>
          <Box sx={izquierdaModal}>
            <Typography sx={title}>Editar</Typography>
            <Typography sx={subtitle}>Faena: {selectedFaena.nombre}</Typography>
            <Box sx={content}>
              <Box sx={datosBox}>
                <Box sx={selectDatosBox}>
                  <TextField
                    sx={textField}
                    req={true}
                    size="small"
                    fullWidth
                    required
                    id="nombre_faena"
                    label="Nombre Faena"
                    type="text"
                    value={nombreFaena}
                    onChange={(e) => setNombreFaena(e.target.value)}
                  />
                </Box>
                <Box sx={selectDatosBoxBox}>
                  <Box sx={selectDatosBox}>
                    <CustomSelect
                      req={true}
                      label="Seleccionar Mandante"
                      value={mandante}
                      setValue={handleSetMandante}
                      valueName="nombre"
                      disabled={userType === "A" ? true : false}
                    >
                      {listaMandantes}
                    </CustomSelect>
                  </Box>
                  {userType === "A" ? null : (
                    <Box sx={selectDatosBox}>
                      <CustomSelect
                        req={true}
                        label="Seleccionar Outsourcing"
                        value={outsourcing}
                        setValue={handleSetOutsourcing}
                        valueName="nombre"
                      >
                        {listaOutsourcing}
                      </CustomSelect>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={content}>
            <Box sx={derechaModal}>
              <Box sx={emptyBox} />
              <Grid item xs={2}>
                <Typography sx={subtitle}>
                  Añadir o sacar trabajadores
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  {customList("Sin faena", trabajadoresSinFaena)}
                </Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      sx={buttonChangeTrabajador}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedRight}
                      disabled={sinChecked.length === 0}
                      aria-label="move selected trabajadoresEnFaena"
                    >
                      &gt;
                    </Button>
                    <Button
                      sx={buttonChangeTrabajador}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedLeft}
                      disabled={enChecked.length === 0}
                      aria-label="move selected trabajadoresSinFaena"
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  {customList("En la faena", trabajadoresEnFaena)}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box sx={buttonBox}>
          <Button
            sx={button}
            variant="contained"
            onClick={handleGuardar}
            disableElevation
            disabled={!continueValue}
          >
            Guardar cambios
          </Button>
        </Box>
      </Box>
    </Modal>
  ) : (
    <LoadingRocket />
  );
}
