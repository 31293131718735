import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";

/* CUSTOM LIBRARIES */
import DatosEmpresa from "../common/components/datosEmpresa";

/* ICONS */
import FinishModal from "../common/modals/finishModal";
import LoadingRocket from "../common/things/loadingRocket";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { transition } from "../../../controller/utils";
import {
  useGetEmpresaUserQuery,
  useUpdateEmpresaUserMutation,
} from "../../../api/empresasApiSlice";
import { useDispatch } from "react-redux";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText,
} from "../../../redux/confirmModalSlice";

export default function DatosEmpresaPage() {
  const navigate = useNavigate();
  const littleSize = littleSizeFunc();
  const dispatch = useDispatch();

  const { currentData, isSuccess, isLoading, isFetching, refetch } =
    useGetEmpresaUserQuery(null, {
      refetchOnMountOrArgChange: true,
    });
  const [editEmpresa, editUserState] = useUpdateEmpresaUserMutation();

  const [openFinishModal, setOpenFinishModal] = useState(false);
  const [continueValue, setContinueValue] = useState(false);
  const [datosEmpresa, setDatosEmpresa] = useState(null);

  useEffect(() => {
    if (isSuccess && currentData) {
      setDatosEmpresa({ ...currentData.data });
    }
  }, [currentData, isSuccess, isLoading, isFetching]);

  const handleClickVolver = (event) => {
    navigate("/Mi perfil");
  };
  const handleContinue = async () => {
    try {
      const userD = await editEmpresa(datosEmpresa).unwrap();
      dispatch(
        setSuccessText(`¡Tus cambios se han actualizado de forma exitosa!`)
      );
      refetch();
      navigate("/Mi perfil");
    } catch (err) {
      console.error(err);
      dispatch(
        setErrorText(
          `Hubo un problema al momento de actualizar los datos de la empresa\n\nIntente nuevamente más tarde o contactese con nosotros`
        )
      );
    }
    dispatch(setOpenConfirmModal(true));
    setOpenFinishModal(false);
  };
  const handleBack = () => {
    setOpenFinishModal(false);
  };

  const todo = {
    padding: "0 0 0 0",
    minHeight: "58vh",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    gap: "4vh 0",
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px",
    padding: "0 0 34px 0",
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    ":hover": {
      border: "2px solid #1876D1",
    },
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    minWidth: "110px",
    background: "#1876D1",
  };
  const titleFinishModal = {
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "140%",
    textAlign: "center",
    color: "#818181",
  };

  return isLoading || !datosEmpresa ? (
    <Box sx={todo}>
      <LoadingRocket dialog={false} dark tiny />
    </Box>
  ) : (
    <Box sx={todo}>
      <DatosEmpresa
        setContinueValue={setContinueValue}
        datosEmpresa={datosEmpresa}
        setDatosEmpresa={setDatosEmpresa}
      />
      <Box sx={buttonBox}>
        <Button
          sx={buttonVolver}
          variant="outlined"
          onClick={handleClickVolver}
        >
          Volver
        </Button>
        <Button
          disabled={!continueValue}
          sx={button}
          variant="contained"
          onClick={(e) => setOpenFinishModal(true)}
        >
          Actualizar
        </Button>
      </Box>
      <FinishModal
        openModal={openFinishModal}
        setOpenModal={setOpenFinishModal}
        handleContinue={handleContinue}
        handleBack={handleBack}
        buttonText="Continuar"
      >
        <Typography sx={titleFinishModal}>
          ¿Estás seguro que quieres continuar con los cambios en los datos de
          empresa?
        </Typography>
      </FinishModal>
    </Box>
  );
}
