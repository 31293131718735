import React, { useState, useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { Typography, Box, Modal, Button } from "@mui/material";
import MuiLink from "@mui/material/Link";

/* CUSTOM LIBRARIES */
import CustomTable from "../common/components/customTable";
import CustomSelect from "../common/components/customSelect";
import FinishModal from "../common/modals/finishModal";
import ProblemasTableModal from "./problemasTableModal";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import {
  transition,
  MONTHSNUM,
  POLLING_INTERVAL
} from "../../../controller/utils";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../redux/confirmModalSlice";
import { useDispatch } from "react-redux";
import {
  useFacturarMutation,
  useGetFaenasFacturacionQuery
} from "../../../api/faenasApiSlice";
import { headCellsEstadoSubcontratista } from "../../../controller/listas";
import { setFactura } from "../../../redux/entidadesSlice";

export default function EstadoSubcontratistas() {
  const { setSubtitleValue } = useOutletContext();
  const navigate = useNavigate();
  const littleSize = littleSizeFunc();
  const dispatch = useDispatch();

  const { currentData, isSuccess, isLoading, isFetching } =
    useGetFaenasFacturacionQuery(null, {
      pollingInterval: POLLING_INTERVAL
    });

  const [facturar, resultFacturar] = useFacturarMutation();

  const [subcontratista, setSubcontratista] = useState("");
  const [año, setAño] = useState("");
  const [listaAños, setListaAños] = useState([]);
  const [mes, setMes] = useState("");
  const [listaMeses, setListaMeses] = useState([]);
  const [listaSubcontratista, setListaSubcontratista] = useState([]);
  const [lineaDocumentoSeleccionada, setLineaDocumentoSeleccionada] =
    useState("");
  const [openProblemasModal, setOpenProblemasModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openFacturarModal, setOpenFacturarModal] = useState(false);
  const [listaDocumentosFacturacion, setListaDocumentosFacturacion] = useState(
    []
  );
  const [
    listaDocumentosFacturacionFiltrada,
    setListaDocumentosFacturacionFiltrada
  ] = useState([]);

  useEffect(() => {
    setSubtitleValue("");
  }, []);

  useEffect(() => {
    if (isSuccess && currentData) {
      let lista = [];
      var lista_años = [];
      var lista_meses = [];
      var lista_docs = [];
      currentData?.data.map((t) => {
        lista.push({
          id: t.nombre_empresa_outsourcing,
          value: t.nombre_empresa_outsourcing
        });
        lista_años.push({
          id: t.fecha.split("-")[0],
          value: t.fecha.split("-")[0]
        });
        lista_meses.push({
          id: MONTHSNUM[t.fecha.split("-")[1]],
          value: MONTHSNUM[t.fecha.split("-")[1]]
        });
        let doc = { ...t };
        doc["año-mes"] = `${t.fecha.split("-")[0]} - ${
          MONTHSNUM[t.fecha.split("-")[1]]
        }`;
        lista_docs.push(doc);
      });
      let jsonObject = lista.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      lista = Array.from(uniqueSet).map(JSON.parse);
      setListaSubcontratista(lista);

      jsonObject = lista_años.map(JSON.stringify);
      uniqueSet = new Set(jsonObject);
      lista_años = Array.from(uniqueSet).map(JSON.parse);
      setListaAños(lista_años);

      jsonObject = lista_meses.map(JSON.stringify);
      uniqueSet = new Set(jsonObject);
      lista_meses = Array.from(uniqueSet).map(JSON.parse);
      setListaMeses(lista_meses);

      setListaDocumentosFacturacion([...lista_docs]);
      setListaDocumentosFacturacionFiltrada([...lista_docs]);
    }
  }, [currentData, isSuccess, isLoading, isFetching]);

  // Filtros
  useEffect(() => {
    if (isSuccess && currentData) {
      let listaDocs =
        listaDocumentosFacturacion.lenght >
        listaDocumentosFacturacionFiltrada.lenght
          ? [...listaDocumentosFacturacionFiltrada]
          : [...listaDocumentosFacturacion];
      if (subcontratista) {
        listaDocs = listaDocs
          .map((docObj) => {
            if (docObj.nombre_empresa_outsourcing === subcontratista) {
              return docObj;
            }
            return null;
          })
          .filter((docObj) => docObj !== null);
        setListaDocumentosFacturacionFiltrada(listaDocs);
      }
      if (año) {
        listaDocs = listaDocs
          .map((docObj) => {
            if (docObj.fecha.split("-")[0] === año) {
              return docObj;
            }
            return null;
          })
          .filter((docObj) => docObj !== null);
        setListaDocumentosFacturacionFiltrada(listaDocs);
      }
      if (mes) {
        listaDocs = listaDocs
          .map((docObj) => {
            if (docObj.fecha.split("-")[1] === mes) {
              return docObj;
            }
            return null;
          })
          .filter((docObj) => docObj !== null);
        setListaDocumentosFacturacionFiltrada(listaDocs);
      } else {
        setListaDocumentosFacturacionFiltrada(listaDocs);
      }
    }
  }, [subcontratista, año, mes, currentData, isFetching, isSuccess]);

  const handleModalProblemas = () => {
    setOpenProblemasModal(true);
    setOpenErrorModal(false);
  };

  const handleFacturar = async () => {
    try {
      const response = await facturar({
        id: lineaDocumentoSeleccionada.id
      }).unwrap();
      if (response == "Facturado") {
        dispatch(
          setSuccessText(
            `Fecha ${lineaDocumentoSeleccionada.fecha} para faena "${lineaDocumentoSeleccionada.nombre_faena}" del subcontratado "${lineaDocumentoSeleccionada.nombre_empresa_outsourcing}" facturada!`
          )
        );
      }
    } catch (e) {
      dispatch(
        setErrorText(
          `Hubo un error al intentar como facturado, intente nuevamente o contactece con nosotros.`
        )
      );
    }
    dispatch(setOpenConfirmModal(true));
  };

  const handleLimpiaFiltros = () => {
    setSubcontratista("");
    setAño("");
    setMes("");
  };

  const setSelectedFactura = (value) => {
    dispatch(setFactura({ ...value }));
    setLineaDocumentoSeleccionada({ ...value });
  };

  /* CSS */
  const contenido = {
    minHeight: "58vh",
    height: "fit-content",
    maxHeight: "84vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "5.17vh",
    transition: transition
  };
  const barraFiltros = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "11px"
  };
  const filtrosBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "11px",
    alignItems: "center"
  };
  const formControl = {
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    height: "40px",
    width: "16vw",
    minWidth: "210px"
  };
  const textStyleFinishModal = {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "20px",
    color: "#818181"
  };
  const linkText = {
    color: "#1876D1",
    fontSize: "20px",
    fontWeight: "600",
    "&:hover": {
      cursor: "pointer"
    }
  };
  const buttonLimparFiltros = {
    height: "fit-content",
    width: "fit-content",
    transition: transition,
    textTransform: "none ",
    borderRadius: "12px",
    background: "#1876D1",
    color: "#FCFBFB",
    fontWeight: 800,
    fontSize: "12px",
    gap: "5px"
  };

  return (
    <Box sx={contenido}>
      <Box sx={barraFiltros}>
        {subcontratista || mes || año ? (
          <Box sx={formControl}>
            <Button
              onClick={handleLimpiaFiltros}
              disableElevation={true}
              variant="contained"
              sx={buttonLimparFiltros}
            >
              <FilterListOffOutlinedIcon />
              Restablecer filtros
            </Button>
          </Box>
        ) : null}
        <Box sx={filtrosBox}>
          <Box sx={formControl}>
            <CustomSelect
              label="Filtrar por empresa"
              value={subcontratista}
              setValue={setSubcontratista}
            >
              {listaSubcontratista}
            </CustomSelect>
          </Box>
          <Box sx={formControl}>
            <CustomSelect label="Filtrar por año" value={año} setValue={setAño}>
              {listaAños}
            </CustomSelect>
          </Box>
          <Box sx={formControl}>
            <CustomSelect label="Filtrar por mes" value={mes} setValue={setMes}>
              {listaMeses}
            </CustomSelect>
          </Box>
        </Box>
      </Box>
      <CustomTable
        headCells={headCellsEstadoSubcontratista}
        rows={listaDocumentosFacturacionFiltrada}
        setRows={setListaDocumentosFacturacionFiltrada}
        variant="facturacion"
        setLineaSeleccionada={setSelectedFactura}
        setOpenErrorModal={setOpenErrorModal}
        setOpenFacturarModal={setOpenFacturarModal}
        isLoading={isLoading}
      />
      <ProblemasTableModal
        openModal={openProblemasModal}
        setOpenModal={setOpenProblemasModal}
        subtitle={`${lineaDocumentoSeleccionada.nombre_faena} - ${lineaDocumentoSeleccionada.nombre_empresa_outsourcing}`}
      />
      <FinishModal openModal={openErrorModal} setOpenModal={setOpenErrorModal}>
        <Typography sx={textStyleFinishModal}>
          <Typography sx={textStyleFinishModal}>
            {/* Hay 10 archivos asociados a trabajadores de la empresa subcontratista que no han sido cargados/aprobados. */}
            {lineaDocumentoSeleccionada.razon_rechazo}
          </Typography>
          <MuiLink
            sx={linkText}
            underline="none"
            onClick={handleModalProblemas}
          >
            Revísalos aquí.
          </MuiLink>
        </Typography>
      </FinishModal>
      <FinishModal
        openModal={openFacturarModal}
        setOpenModal={setOpenFacturarModal}
        handleContinue={handleFacturar}
        buttonText="Confirmar"
      >
        <Typography sx={textStyleFinishModal}>
          ¿Está seguro de marcar la fecha {lineaDocumentoSeleccionada.fecha} en
          la faena "{lineaDocumentoSeleccionada.nombre_faena}"" de la empresa
          subcontratada "{lineaDocumentoSeleccionada.nombre_empresa_outsourcing}
          " como facturada?
        </Typography>
      </FinishModal>
    </Box>
  );
}
