import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton, Button, Typography, Box, Modal } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";

/* CUSTOM LIBRARIES */
import DragDropFile from "../components/dragAndDrop";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../../controller/windowSize";
import { transition } from "../../../../controller/utils";
import { useDispatch } from "react-redux";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../../redux/confirmModalSlice";
import { useAddDocumentoMutation } from "../../../../api/docsApiSlice";
import LoadingRocket from "../things/loadingRocket";

export default function SubirDocumentoModal(props) {
  const {
    id,
    openModal,
    setOpenModal,
    title,
    subtitle,
    doneNavigation = false,
    maxCount
  } = props;

  const navigate = useNavigate();
  const littleSize = littleSizeFunc();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [addDocumento, uploadState] = useAddDocumentoMutation();

  const [continueValue, setContinueValue] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  // useEffect(() => {
  //   if (continueValue && !openModal) {

  //   }
  // }, [continueValue, openModal]);

  const handleCloseModal = () => {
    setContinueValue(false);
    setOpenModal(false);
    setUploadedFiles([]);
    doneNavigation ? navigate(doneNavigation) : navigate(pathname);
  };
  const handleContinue = async () => {
    try {
      const response = await addDocumento({
        id: id,
        titulo: uploadedFiles[0].name,
        blob: uploadedFiles[0].blob
      }).unwrap();
      dispatch(
        setSuccessText("¡Tu documento ha sido cargado de forma exitosa!")
      );
    } catch (err) {
      console.error(err);
      dispatch(
        setErrorText(
          "¡Hubo un error con la subida de su documento, intente nuevamente o contáctese con nosotros!"
        )
      );
    }
    dispatch(setOpenConfirmModal(true));
    handleCloseModal();
  };

  /* CSS */
  const datosBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    minWidth: "250px",
    maxWidth: "1000px",
    height: "fit-content",
    maxHeight: "98vh",
    display: "flex",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: "6vh 3.542vw ",
    overflowY: "auto",
    gap: "9vh"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const titleBox = {
    width: "100%",
    gap: "11px",
    display: "flex",
    alignItems: littleSize ? "center" : "start",
    justifyContent: "flex-start",
    flexDirection: "column"
  };
  const titleStyle = {
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "140%",
    textAlign: littleSize ? "center" : ""
  };
  const subtitulo = {
    fontWeight: 400,
    fontSize: "20px",
    textAlign: littleSize ? "center" : ""
    // lineHeight: '120%',
  };
  const selectDatosBoxBox = {
    height: "fit-content",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    // flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : "",
    alignItems: "center"
  };
  const dragAndDropBox = {
    width: props.children ? "50%" : "100%"
  };
  const textBox = {
    width: "50%"
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px"
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    visibility: littleSize ? "visible" : "",
    ":hover": {
      border: "2px solid #1876D1"
    }
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1",
    minWidth: "110px"
  };

  return uploadState.isLoading ? (
    <LoadingRocket />
  ) : (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={datosBox}>
        <Box sx={closeButtonBox}>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={titleBox}>
          <Typography sx={titleStyle}>
            {title ? <b>{title}</b> : <b>Subir Documento</b>}
          </Typography>
          <Typography sx={subtitulo}>{subtitle}</Typography>
        </Box>
        <Box sx={selectDatosBoxBox}>
          <Box sx={dragAndDropBox}>
            <DragDropFile
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              setContinueValue={setContinueValue}
              maxCount={maxCount}
            />
          </Box>
          {props.children ? (
            <Box sx={textBox}>
              <Typography>{props.children}</Typography>
            </Box>
          ) : null}
        </Box>
        <Box sx={buttonBox}>
          <Button
            sx={buttonVolver}
            variant="outlined"
            onClick={handleCloseModal}
          >
            Cancelar
          </Button>
          <Button
            sx={button}
            disableElevation
            variant="contained"
            type="submit"
            disabled={!continueValue}
            onClick={handleContinue}
          >
            Subir
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
