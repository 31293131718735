import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import entidadesReducer from "./entidadesSlice";
import confirmModalReducer from "./confirmModalSlice";
import modalsReducer from "./modalsSlice";
import { apiSlice } from "../api/apiSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    entidades: entidadesReducer,
    confirmModal: confirmModalReducer,
    modals: modalsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

// setupListeners(store.dispatch)
