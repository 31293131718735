import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import img404 from "../../images/svg/404.svg";

export default function NotFound() {
  const navigate = useNavigate();
  /* CSS */
  const contenido = {
    minHeight: "58vh",
    height: "fit-content",
    maxHeight: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  };
  const left = {
    height: "fit-content",
    width: "40%",
    marginBottom: "auto",
    marginTop: "auto",
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
    gap: "2vh",
    alignItems: "center",
  };
  const title = {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "75px",
    fill: "none",
    stroke: "#150035",
    textAlign: "center",
    strokeWidth: "2px",
    strokeLinejoin: "round",
    textShadow: "1px 4px 1px #150035",
    "-webkit-text-fill-color": "transparent",
    "-webkit-text-stroke": "1px",
  };
  const text = {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "18px",
    fill: "none",
    stroke: "#150035",
    strokeWidth: "2px",
    strokeLinejoin: "round",
    textAlign: "center",
  };
  const right = {
    width: "60%",
  };
  const image = {
    width: "100%",
    height: "auto",
  };

  return (
    <Box sx={contenido}>
      <Box sx={left}>
        <Typography sx={title}>Error 404</Typography>
        <Typography sx={text}>
          ¿Tal vez esta página fue movida? ¿Se eliminó? ¿Aún no existe? ¿Nunca
          existió?
        </Typography>
        <Typography sx={text}>
          Mejor vamos al{" "}
          <Typography sx={text} component={Link} to="/Dashboard">
            inicio
          </Typography>{" "}
          y probemos desde allí.
        </Typography>
        <Typography sx={text}>
          O volvamos a la{" "}
          <Typography sx={text} component={Link} onClick={(e) => navigate(-1)}>
            pagina anterior
          </Typography>{" "}
        </Typography>
      </Box>
      <Box sx={right}>
        <Box
          component="img"
          loading="lazy"
          sx={image}
          alt="imagen"
          src={img404}
        />
      </Box>
    </Box>
  );
}
