import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  Link,
} from "react-router-dom";
import {
  TextField,
  FormHelperText,
  Button,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";

/* IMAGES */
import logoName from "../../images/logo/bluename_noBack.png";

/* ICONS */
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

/* CUSTOM LIBRARIES */
import ContraseñaSegura from "../Drawer Views/common/things/contrasenaSegura";
import StrongMeter from "../Drawer Views/common/things/strongMeter";
import isStrongPassword from "validator/lib/isStrongPassword";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../controller/windowSize";
import { transition } from "../../controller/utils";
import {
  useActivateUserMutation,
  useResetPasswordDataMutation,
} from "../../api/authApiSlice";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../api/apiSlice";

export default function ResetPassword() {
  let params = useParams();
  const dispatch = useDispatch();
  const [reset, { isLoading }] = useResetPasswordDataMutation();
  const navigate = useNavigate();
  const littleSize = littleSizeFunc();
  const [
    currentValueUsr,
    setCurrentValueUsr,
    openSnackbar,
    setOpenSnackbar,
    snackbarMessage,
    setSnackbarMessage,
    snackbarColor,
    setSnackbarColor,
  ] = useOutletContext();

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [passwordVisibility2, setPasswordVisibility2] = useState(false);

  const [labelMarginPsw, setLabelMarginPsw] = useState(30);
  const [focusedPsw, setFocusedPsw] = useState(false);
  const [currentValuePsw, setCurrentValuePsw] = useState("");
  const [currentValuePsw2, setCurrentValuePsw2] = useState("");
  const [securityValue, setSecurityValue] = useState(0);

  const [labelMarginPsw2, setLabelMarginPsw2] = useState(30);
  const [focusedPsw2, setFocusedPsw2] = useState(false);

  const [errorValueCode, setErrorValueCode] = useState("");
  const [errorValuePsw, setErrorValuePsw] = useState("");
  const [errorValuePsw2, setErrorValuePsw2] = useState("");

  const [isHover, setIsHover] = useState(false);

  const handlePasswordStrong = (value) => {
    let strongValue = isStrongPassword(value, { returnScore: true });
    setCurrentValuePsw(value);
    setSecurityValue(strongValue);
    if (value.length < 8) {
      setErrorValuePsw("Tu contraseña debe contener al menos 8 caracteres.");
    } else if (strongValue < 45) {
      setErrorValuePsw("Su contraseña no es suficientemente segura.");
    } else {
      setErrorValuePsw("");
    }
  };
  const handlePasswordStrong2 = (value) => {
    setCurrentValuePsw2(value);
    if (value === currentValuePsw) {
      setErrorValuePsw2("");
    }
  };
  const handleFocusPsw = (state) => {
    setFocusedPsw(state);
    if (state) {
      setLabelMarginPsw(0);
    } else {
      if (currentValuePsw === "") {
        setLabelMarginPsw(30);
      }
    }
  };
  const handleFocusPsw2 = (state) => {
    setFocusedPsw2(state);
    if (state) {
      setLabelMarginPsw2(0);
    } else {
      if (currentValuePsw2 !== currentValuePsw) {
        setErrorValuePsw2("Contraseña no coincide");
      }
      if (currentValuePsw2 === "") {
        setLabelMarginPsw2(30);
      }
    }
  };
  const handleError = (text) => {
    setSnackbarColor("#DE5C5C");
    setOpenSnackbar(true);
    setSnackbarMessage(text);
    // setSnackbarMessage(
    //   "Lo sentimos, no pudimos encontrar una cuenta asociada al usuario y contraseña que ingresaste. Por favor, revisa tu usuario y contraseña e inténtalo nuevamente."
    // );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // esperar que se haya actulizado la contraseña
    dispatch(apiSlice.util.resetApiState());
    localStorage.clear();
    if (
      currentValuePsw &&
      currentValuePsw2 &&
      !errorValuePsw &&
      !errorValuePsw2
    ) {
      try {
        const _ = await reset({
          uid: params.uid,
          token: params.token,
          new_password: currentValuePsw,
          re_new_password: currentValuePsw2,
        }).unwrap();
        setSnackbarColor("#6DCDAA");
        setOpenSnackbar(true);
        setSnackbarMessage(
          "¡Listo! Tu contraseña ha sido actualizada correctamente."
        );
        navigate("/auth/login");
      } catch (err) {
        console.log(err);
        handleError(
          "Hubo un problema con el cambio de constraseña de su cuenta, intente nuevamente o contactese con nosotros."
        );
      }
    }
  };
  const handleCloseSnack = () => setOpenSnackbar(false);
  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);
  const handleClickShowPassword = () => setPasswordVisibility((show) => !show);
  const handleClickShowPassword2 = () =>
    setPasswordVisibility2((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  /* CSS */
  const ingreso_mitad = {
    width: littleSize ? "100vw" : "50vw",
    height: littleSize ? "fit-content" : "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: littleSize ? "center" : "flex-start",
    gap: littleSize ? "" : "6vh",
    alignItems: littleSize ? "center" : "stretch",
    padding: "3vh 5vw",
  };
  const logo_container = {
    display: "flex",
    justifyContent: littleSize ? "center" : "flex-start",
    alignItems: "center",
  };
  const formStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "0vh 3vw",
    gap: "33px",
  };
  const ingresar_plataforma_text = {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "140%",
    color: "#1876D1",
    paddingBottom: "10px",
  };
  const textFieldBox = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: "0px",
  };
  const textField = {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    background: "#FCFBFB",
    borderRadius: "6px",
    borderRadius: "10px",
    marginBottom: "5px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
    "input[type='password']::-ms-reveal": {
      display: "none",
    },
    "input[type='password']::-ms-clear": {
      display: "none",
    },
  };
  const ingresar_buttonBox = {
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    gap: "11px",
  };
  const ingresar_button = {
    display: "flex",
    flexDirection: "row",
    padding: "5px 20px",
    gap: "11px",
    color: "#1876D1",
    borderRadius: "10px",
    padding: "9px",
    color: "#1876D1",
    height: "40px",
  };
  const ingresar_button_text = {
    fontFamily: "Poppins",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    textAlign: "center",
    color: "#FCFBFB",
  };
  const progressStyle = {
    color: "#FCFBFB",
    padding: "1%",
  };
  const logoStyle = {
    width: "310px",
    height: "310px",
    justifyContent: "center",
    color: "#FCFBFB",
    transition: transition,
  };

  return (
    <Box sx={ingreso_mitad}>
      <Box sx={logo_container}>
        <Box component={Link} to={`/`}>
          <Box
            component="img"
            loading="lazy"
            sx={logoStyle}
            alt="imagen"
            src={logoName}
          />
        </Box>
      </Box>
      <Box
        component="form"
        id="ActualizarContraseña"
        onSubmit={handleSubmit}
        noValidate
        sx={formStyle}
      >
        <Typography sx={ingresar_plataforma_text}>
          Antes de continuar, actualiza tu contraseña
        </Typography>
        <Box sx={textFieldBox}>
          <TextField
            error={errorValuePsw}
            fullWidth
            required
            id="contrasena"
            label="Nueva contraseña"
            type={passwordVisibility ? "text" : "password"}
            sx={textField}
            InputLabelProps={{
              shrink: focusedPsw || currentValuePsw,
              style: { marginLeft: labelMarginPsw },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyOutlinedIcon
                    htmlColor={errorValuePsw ? "#DE5C5C" : "#1876D1"}
                    sx={{ transform: "rotate(90deg)" }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {currentValuePsw ? (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {passwordVisibility ? (
                        <VisibilityOffOutlinedIcon htmlColor={"#1876D1"} />
                      ) : (
                        <VisibilityOutlinedIcon htmlColor={"#1876D1"} />
                      )}
                    </IconButton>
                  ) : null}
                  <HelpOutlineOutlinedIcon
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    htmlColor={errorValuePsw ? "#DE5C5C" : "#1876D1"}
                  />
                </InputAdornment>
              ),
            }}
            value={currentValuePsw}
            onChange={(e) => handlePasswordStrong(e.target.value)}
            onFocus={() => handleFocusPsw(true)}
            onBlur={() => handleFocusPsw(false)}
          />
          <ContraseñaSegura isHover={isHover} />
          <StrongMeter value={securityValue} />
          <FormHelperText error>{errorValuePsw}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            error={errorValuePsw2}
            fullWidth
            required
            id="contrasena 2"
            label="Confirmar contraseña"
            type={passwordVisibility2 ? "text" : "password"}
            sx={textField}
            InputLabelProps={{
              shrink: focusedPsw2 || currentValuePsw2,
              style: { marginLeft: labelMarginPsw2 },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyOutlinedIcon
                    htmlColor={errorValuePsw2 ? "#DE5C5C" : "#1876D1"}
                    sx={{ transform: "rotate(90deg)" }}
                  />
                </InputAdornment>
              ),
              endAdornment: currentValuePsw2 ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {passwordVisibility2 ? (
                      <VisibilityOffOutlinedIcon htmlColor={"#1876D1"} />
                    ) : (
                      <VisibilityOutlinedIcon htmlColor={"#1876D1"} />
                    )}
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            value={currentValuePsw2}
            onChange={(e) => handlePasswordStrong2(e.target.value)}
            onFocus={() => handleFocusPsw2(true)}
            onBlur={() => handleFocusPsw2(false)}
          />
          <FormHelperText error>{errorValuePsw2}</FormHelperText>
        </Box>
        <Box sx={ingresar_buttonBox}>
          <Button
            disabled={isLoading}
            variant="contained"
            sx={ingresar_button}
            type="submit"
          >
            {isLoading ? (
              <CircularProgress sx={progressStyle} />
            ) : (
              <Typography sx={ingresar_button_text}>
                Actualizar contraseña
              </Typography>
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
