import React, { useState } from "react";
import isEmail from "validator/lib/isEmail";
import { useOutletContext, useNavigate, Link } from "react-router-dom";
import {
  TextField,
  FormHelperText,
  Button,
  Box,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@mui/material";

/* IMAGES */
import logoName from "../../images/logo/bluename_noBack.png";

/* ICONS */
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../controller/windowSize";
import { transition } from "../../controller/utils";
import { useResetPasswordEmailMutation } from "../../api/authApiSlice";

export default function Restablecer() {
  const [resetEmail, { isLoading }] = useResetPasswordEmailMutation();
  const navigate = useNavigate();
  const littleSize = littleSizeFunc();
  const form = document.querySelector("#EnviarCodigo");
  const [
    currentValueUsr,
    setCurrentValueUsr,
    openSnackbar,
    setOpenSnackbar,
    snackbarMessage,
    setSnackbarMessage,
    snackbarColor,
    setSnackbarColor,
  ] = useOutletContext();

  const [labelMarginUsr, setLabelMarginUsr] = useState(30);
  const [focusedUsr, setFocusedUsr] = useState(false);

  const [errorValue, setErrorValue] = useState(false);

  const handleFocus = (state, value) => {
    if (!state) {
      if (isEmail(currentValueUsr)) {
        setErrorValue("");
      } else {
        setErrorValue("Ingrese un correo válido");
      }
    }
    setFocusedUsr(state);
    if (currentValueUsr !== "") {
      setLabelMarginUsr(0);
    } else {
      setLabelMarginUsr(value);
    }
  };
  const handleError = () => {
    setOpenSnackbar(true);
    setSnackbarMessage(
      "Lo sentimos, no pudimos encontrar una cuenta asociada al email."
    );
    setSnackbarColor("#DE5C5C");
    setErrorValue(true);
  };
  const handleSentCode = () => {
    setOpenSnackbar(true);
    setSnackbarMessage(
      "¡Listo! Te hemos enviado un link a tu correo electrónico. Revisa tu bandeja de entrada."
    );
    setSnackbarColor("#1876D1");
    navigate("/auth/login");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!errorValue) {
      try {
        const response = await resetEmail({
          email: currentValueUsr,
        }).unwrap();
        handleSentCode();
      } catch (err) {
        handleError();
      }
    }
  };
  const handleCloseSnack = () => setOpenSnackbar(false);

  /* CSS */
  const ingreso_mitad = {
    width: littleSize ? "100vw" : "50vw",
    height: littleSize ? "fit-content" : "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: littleSize ? "center" : "flex-start",
    gap: littleSize ? "" : "6vh",
    alignItems: littleSize ? "center" : "stretch",
    padding: "3vh 5vw",
  };
  const logo_container = {
    display: "flex",
    justifyContent: littleSize ? "center" : "flex-start",
    alignItems: "center",
  };
  const formStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "0vh 3vw",
    gap: "33px",
  };
  const ingresar_plataforma_text = {
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "140%",
    color: "#1876D1",
    paddingBottom: "10px",
  };
  const textFieldBox = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: "0px",
    gap: "11px",
    borderRadius: "10px",
  };
  const textField = {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    background: "#FCFBFB",
    borderRadius: "10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
    "& .input[type='password']::-ms-reveal": {
      display: "none",
    },
    "& .input[type='password']::-ms-clear": {
      display: "none",
    },
  };
  const ingresar_button = {
    display: "flex",
    flexDirection: "row",
    padding: "5px 20px",
    gap: "11px",
    color: "#1876D1",
    borderRadius: "10px",
    padding: "9px",
    height: "40px",
  };
  const ingresar_button_text = {
    textTransform: "none",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    textAlign: "center",
    color: "#FCFBFB",
  };
  const snackbarStyle = {
    width: "100%",
    top: "0px !important",
    left: "0px",
    right: "0px",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    "& .MuiSnackbarContent-root": {
      justifyContent: "center",
      backgroundColor: snackbarColor,
      width: "inherit",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "150%",
      borderRadius: "inherit",
      top: "inherit",
    },
  };
  const progressStyle = {
    color: "#FCFBFB",
    padding: "1%",
  };
  const logoStyle = {
    width: "310px",
    height: "310px",
    justifyContent: "center",
    color: "#FCFBFB",
    transition: transition,
  };

  return (
    <Box sx={ingreso_mitad}>
      <Box sx={logo_container}>
        <Box component={Link} to={`/`}>
          <Box
            component="img"
            loading="lazy"
            sx={logoStyle}
            alt="imagen"
            src={logoName}
          />
        </Box>
      </Box>
      <Box
        component="form"
        id="EnviarCodigo"
        onSubmit={handleSubmit}
        noValidate
        sx={formStyle}
      >
        <Typography sx={ingresar_plataforma_text}>
          Restablecer contraseña
        </Typography>
        <Typography>
          Ingresa tu correo electrónico y te enviaremos un código para
          restablecer tu contraseña
        </Typography>
        <Box sx={textFieldBox}>
          <TextField
            error={errorValue}
            fullWidth
            required
            type="email"
            id="email"
            label="Correo electrónico"
            sx={textField}
            InputLabelProps={{
              shrink: focusedUsr || currentValueUsr ? true : false,
              style: { marginLeft: labelMarginUsr },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailIcon
                    htmlColor={errorValue ? "#DE5C5C" : "#1876D1"}
                  />
                </InputAdornment>
              ),
            }}
            value={currentValueUsr}
            onChange={(e) => setCurrentValueUsr(e.target.value)}
            onFocus={() => handleFocus(true, 0)}
            onBlur={() => handleFocus(false, 30)}
          />
          <FormHelperText error>{errorValue}</FormHelperText>
        </Box>
        <Button
          disabled={isLoading}
          variant="contained"
          sx={ingresar_button}
          type="submit"
        >
          {isLoading ? (
            <CircularProgress sx={progressStyle} />
          ) : (
            <Typography sx={ingresar_button_text}> Enviar código </Typography>
          )}
        </Button>
      </Box>
    </Box>
  );
}
