import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAccess, setUser } from "../../redux/authSlice";
import { useGetUserQuery } from "../../api/authApiSlice";
import LoadingRocket from "../Drawer Views/common/things/loadingRocket";

function RequireAuth() {
  const token = useSelector(selectCurrentAccess);
  const location = useLocation();
  const dispatch = useDispatch();

  const { data, isSuccess, isLoading } = useGetUserQuery();

  let content;

  if (token) {
    if (isSuccess) {
      dispatch(setUser(data));
      content = <Outlet />;
    } else if (isLoading) {
      content = <LoadingRocket isLoading={isLoading} />;
    }
  } else {
    // console.log("ELSE");
    // content = <Outlet />;
    content = <Navigate to="/home" state={{ from: location }} replace />;
  }

  return content;
}

export default RequireAuth;
