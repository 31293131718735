import React, { useState, useEffect, useRef } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  ListItem,
  IconButton,
  ListSubheader,
  Box,
  Typography,
  Toolbar,
  Fab,
  Breadcrumbs,
  CssBaseline,
  Modal,
  Badge
} from "@mui/material";

/* IMAGES */
import logoName from "../../images/logo/whitename_noBack.png";
import logoIcon from "../../images/logo/whiteicon_noBack.png";

/* ICONS */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LogoutIcon from "@mui/icons-material/Logout";

/* CUSTOM LIBRARIES */
import SupportPopUp from "../Drawer Views/common/components/supportCenter";
import ConfirmationModal from "./common//modals/confirmationModal";
import FinishModal from "./common/modals/finishModal";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../controller/windowSize";
import { POLLING_INTERVAL, transition } from "../../controller/utils";
import {
  drawerListValues_EmpresaA,
  drawerListValues_EmpresaB,
  drawerListValues_EmpresaC
} from "../../controller/listas";
import { logOut, selectCurrentType } from "../../redux/authSlice";
import { apiSlice } from "../../api/apiSlice";
import {
  setEmpresa,
  setFaena,
  setTrabajador
} from "../../redux/entidadesSlice";
import {
  useGetNotificacionesQuery,
  useNotificacionVistaMutation
} from "../../api/notificacionesApiSlice";

export default function Skeleton() {
  const dispatch = useDispatch();
  const type = useSelector(selectCurrentType);
  const navigate = useNavigate();
  const littleSize = littleSizeFunc();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const { currentData, isSuccess, isLoading, isFetching } =
    useGetNotificacionesQuery(null, {
      pollingInterval: POLLING_INTERVAL
    });

  const [verNotificacion, verNotificacionStatus] =
    useNotificacionVistaMutation();

  const drawerListType =
    type === "A"
      ? drawerListValues_EmpresaA
      : type === "B"
      ? drawerListValues_EmpresaB
      : type === "Supp" || type === "Admin"
      ? drawerListValues_EmpresaC
      : null;
  const path = pathname.replace(/%20/g, " ").split("/");
  path.shift();

  const [openLogOutModal, setOpenLogOutModal] = useState(false);
  const [open, setOpen] = useState(true);
  const [sectionClicked, setSectionClicked] = useState(path[0]);
  const [openSupp, setOpenSupp] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [selectedVerificacion, setSelectedVerificacion] = useState(null);
  const [titleValue, setTitleValue] = useState(path[0]);
  const [subtitleValue, setSubtitleValue] = useState(null);
  const [notificaciones, setNotificaciones] = useState([]);

  const [logo, setLogo] = useState(logoName);
  const logoRef = useRef(logo);
  logoRef.current = logo;

  useEffect(() => {
    if (isSuccess && !isFetching) {
      setNotificaciones([...currentData.data]);
      if (currentData.data.length > 0) {
        currentData.data.map((notificacion) => {
          if (!notificacion.visto) {
            enqueueSnackbar(<>{notificacion.mensaje} </>, {
              key: notificacion.id,
              variant: "notification",
              // persist: notificacion.persist,
              url: notificacion.notification_type.url,
              preventDuplicate: true,
              atClosing: (e) => handleVerNotificacion(notificacion.id),
              action: (e) => handleNotification(e, notificacion)
            });
          }
        });
      }
    }
  }, [currentData, isSuccess, isFetching]);

  useEffect(() => {
    const titlePath = path.slice(-1)[0];
    let title = titlePath;
    let subtitle = "";
    if (titlePath === "Clientes") {
    } else if (titlePath === "404") {
      title = "";
      subtitle = "";
      setSubtitleValue(subtitle);
    } else if (titlePath === "Listar documentacion") {
      title = "Listar documentación";
    }
    // else if (titlePath === "Dashboard") {
    //   subtitle = "Últimos 12 meses";
    //   setSubtitleValue(subtitle);
    // }
    setTitleValue(title);
  }, [path]);

  useEffect(() => {
    setSectionClicked(path[0]);
  }, [path]);

  useEffect(() => {
    changeImage();
  }, [open]);

  const changeImage = async () => {
    if (open) {
      setLogo(logoName);
    } else {
      setTimeout(() => {
        setLogo(logoIcon);
      }, 230);
      setLogo(logoName);
    }
  };

  const handleOpenSupp = () => setOpenSupp(true);
  const handleCloseSupp = () => setOpenSupp(false);
  const handleCloseLogOutModal = () => setOpenLogOutModal(false);
  const handleSwitchDrawer = () => setOpen(!open);

  const handleNotification = (e, notification) => {
    if (notification.notification_type.nombre == "Ticket de Ayuda") {
      setSelectedTicket(notification.object_id);
    }
    navigate(notification.notification_type.url);
  };

  const handleNotifications = () => {
    if (notificaciones.length > 0) {
      notificaciones.map((notificacion) => {
        enqueueSnackbar(<>{notificacion.mensaje} </>, {
          key: notificacion.id,
          preventDuplicate: true,
          url: notificacion.notification_type.url,
          variant: "notification",
          atClosing: (e) => handleVerNotificacion(notificacion.id),
          action: (e) => handleNotification(e, notificacion)
        });
      });
    }
  };

  const handleVerNotificacion = async (id) => {
    await verNotificacion(id);
  };

  const sectionClick = (event, section, subtitle) => {
    setSectionClicked(section);
    setSubtitleValue("");
    dispatch(setTrabajador({ id: "", nombre: "", documentos: [] }));
    dispatch(setEmpresa({ id: "", value: "", documentos: [] }));
    dispatch(setFaena({ id: "", value: "", documentos: [] }));
  };
  const setLittleSizeDrawer = () => {
    if (open) {
      setOpen(false);
    }
  };
  const handleLogOut = (event) => {
    dispatch(logOut());
    dispatch(apiSlice.util.resetApiState());
  };

  /* CSS */
  const inicio = {
    position: "relative",
    height: "100vh",
    width: "100vw",
    top: "0%",
    bottom: "0%",
    left: "0%",
    right: "0%",
    display: "grid",
    gridTemplateColumns: open ? "2fr 8fr" : "1fr 9fr",
    background: "#FCFBFB",
    transition: transition
  };
  const drawerContainer = {
    flex: "0 0 20%",
    zIndex: 1050,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    width: open ? "20vw" : "10vw",
    minWidth: "fit-content",
    transition: transition,
    gridColumn: open ? "1/span 2" : "1/span 1"
  };
  const drawer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#1876D1",
    height: "100%",
    width: "100%"
  };
  const drawerList = {
    width: "100%",
    height: "45%",
    display: "grid",
    gridTemplateColumns: open ? "1fr 1fr" : "1fr",
    gridTemplateRows: "3fr 1fr 1fr 1fr 1fr 1fr",
    transition: transition
  };
  const logoBox = {
    transition: transition,
    gridColumn: open ? "1/span 2" : "1/span 1",
    backgroundColor: "#1876D1",
    width: "100%",
    height: "100%",
    padding: "1vh 2vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };
  const logoStyle = {
    width: "100%",
    height: open ? "100%" : "60%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FCFBFB",
    transition: transition
  };
  const drawerListTile = {
    gridColumn: open ? "1 /span 2" : "1/span 1",
    width: "100%",
    height: "auto",
    padding: "0vh 0vw"
  };
  const drawerListTileButton = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "inherit",
    gap: open ? "0vh 1.5vw" : "0vh 0vw",
    padding: open ? "1vh 2vw" : "1vh 1vw",
    transition: transition,
    color: "#7EB0F2",
    "&:hover": {
      background: "#7EB0F2"
    },
    "&.Mui-selected": {
      background: "#7EB0F2",
      "&:hover": {
        background: "#7EB0F2"
      }
    }
  };
  const drawerListTileButtonIcon = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "auto",
    minWidth: "1vw",
    height: "fit-content",
    color: "#FCFBFB",
    transition: transition
  };
  const drawerListTileButtonText = {
    transition: transition, // smooth transition
    fontWeight: "500",
    fontSize: open ? "1vmax" : "0vw",
    maxWidth: open ? "100%" : "0%",
    height: open ? "100%" : "0%",
    color: "#FCFBFB"
  };
  const versionTile = {
    gridRow: 19,
    padding: "0"
  };
  const versionText = {
    fontWeight: "200",
    fontSize: "1vh",
    color: "#FCFBFB",
    display: "flex",
    justifyContent: "center"
  };
  const drawerSwitchBox = {
    height: "fit-content",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#1876D1",
    borderColor: "#1876D1",
    borderBottomRightRadius: "100%",
    borderBottomLeftRadius: "0%",
    borderTopRightRadius: "100%",
    borderTopLeftRadius: "0%"
  };
  const drawerSwitch = {
    borderBottomRightRadius: "100%",
    borderBottomLeftRadius: "0%",
    borderTopRightRadius: "100%",
    borderTopLeftRadius: "0%",
    height: "8vh",
    width: "1vw"
  };
  const iconDrawerButtonStyle = {
    transform: open ? "scaleX(-1)" : "",
    transition: transition, // smooth transition
    color: "#FCFBFB",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "8vh",
    width: "1vw"
  };
  const ladoDerecho = {
    maxWidth: "100%",
    maxHeight: "100vh",
    height: "100%",
    width: open ? "80vw" : "90vw",
    gridColumn: open ? "3/span 8" : "2/span 9",
    overflow: "hidden",
    padding: littleSize ? "1vh 0vw 0vh 1vw" : "1vh 0vw 0vh 0vw",
    transition: transition,
    background: "#FCFBFB"
  };
  const contenido = {
    width: "100%",
    maxHeight: "100%",
    padding: "60px 5.55vw 2vh 3vw",
    overflow: "auto",
    position: "relative",
    gap: "11px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  };
  const titleBox = {
    minHeight: "fit-content",
    height: "fit-content",
    width: "100%",
    padding: subtitleValue ? "5vh 0 0vh 0" : "5vh 0 3vh 0"
  };
  const titulo = {
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "120%",
    display: "flex",
    alignItems: "center",
    color: "#333840"
  };
  const subtitulo = {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#333840"
  };
  const outlet = {
    height: "fit-content",
    minHeight: "fit-content",
    width: "100%",
    margin: "auto"
  };
  const appBar = {
    zIndex: 1050,
    position: "absolute",
    gridColumn: open ? "3/span 8" : "2/span 9",
    left: littleSize ? "20px" : "0px",
    right: "20px",
    width: "auto",
    overflow: "hidden",
    transition: transition
  };
  const toolbar = {
    width: "100%",
    minHeight: "4vh",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FCFCFB"
  };
  const appBarButtonsBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "50%"
  };
  const appBarButton = {
    backgroundColor: "#FCFCFB"
  };
  const breadCrumbLink = {
    fontWeight: 600,
    color: "#818181",
    textDecoration: "none"
  };
  const breadCrumbLinkLast = {
    fontWeight: 600,
    color: "#1876D1"
  };
  const supportButton = {
    position: "absolute",
    right: "2vw",
    bottom: littleSize ? "11vh" : "5vh",
    backgroundColor: "#FCFCFB"
  };
  const supportButtonIcon = {
    color: "#1876D1"
  };
  const modal = {
    transition: transition,
    opacity: openSupp ? 1 : 0
  };
  const titleFinishModal = {
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "140%",
    textAlign: "center"
  };
  const textBoxFinishModal = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  };
  const textStyleFinishModal = {
    textAlign: "center"
  };

  const breadcrumbs =
    path.length > 1
      ? path.map((value, i, row) => {
          if (i + 1 === row.length) {
            return (
              <Typography key={value} sx={breadCrumbLinkLast}>
                {value === "Listar documentacion"
                  ? "Listar documentación"
                  : value}
              </Typography>
            );
          } else {
            return (
              <Typography
                key={value}
                component={Link}
                to={`${pathname.replace(/%20/g, " ").split(value)[0]}${value}`}
                sx={breadCrumbLink} /*onClick={handleClick}*/
              >
                {value === "Listar documentacion"
                  ? "Listar documentación"
                  : value}
              </Typography>
            );
          }
        })
      : null;

  const list = () => (
    <List
      sx={drawerList}
      subheader={
        <ListSubheader sx={logoBox}>
          <Box component={Link} to={`/`}>
            <Box
              component="img"
              loading="lazy"
              sx={logoStyle}
              alt="imagen"
              src={logoRef.current}
            />
          </Box>
        </ListSubheader>
      }
    >
      {drawerListType.map((value) => (
        <ListItem
          key={value.title}
          sx={drawerListTile}
          component={Link}
          to={`${value.title}`}
        >
          <ListItemButton
            sx={drawerListTileButton}
            selected={sectionClicked === value.title}
            onClick={(event) =>
              sectionClick(event, value.title, value.subtitle)
            }
          >
            <ListItemIcon sx={drawerListTileButtonIcon}>
              {value.icon}
            </ListItemIcon>
            <ListItemText
              primary={value.title}
              sx={drawerListTileButtonText}
              disableTypography
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box sx={inicio}>
      <CssBaseline />
      <Box sx={drawerContainer}>
        <Box sx={drawer}>
          {list()}
          <ListItem sx={drawerListTile}>
            <ListItemButton
              sx={drawerListTileButton}
              onClick={(event) => setOpenLogOutModal(true)}
            >
              <ListItemIcon sx={drawerListTileButtonIcon}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary="Cerrar sesión"
                sx={drawerListTileButtonText}
                disableTypography
              />
            </ListItemButton>
          </ListItem>
        </Box>
        {littleSize ? (
          setLittleSizeDrawer()
        ) : (
          <Box sx={drawerSwitchBox}>
            <IconButton sx={drawerSwitch} onClick={handleSwitchDrawer}>
              <ArrowForwardIosIcon style={iconDrawerButtonStyle} />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box sx={ladoDerecho}>
        <AppBar position="static" sx={appBar}>
          <Toolbar sx={toolbar}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              {breadcrumbs}
            </Breadcrumbs>
            <Box sx={appBarButtonsBox}>
              {type === "Supp" || type == "Admin" ? null : (
                <IconButton sx={appBarButton} onClick={handleOpenSupp}>
                  <HeadsetMicOutlinedIcon sx={supportButtonIcon} />
                </IconButton>
              )}
              <IconButton sx={appBarButton} onClick={handleNotifications}>
                <Badge
                  badgeContent={notificaciones?.length}
                  max={9}
                  color="error"
                >
                  <NotificationsNoneOutlinedIcon sx={supportButtonIcon} />
                </Badge>
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Box sx={contenido}>
          <Box sx={titleBox}>
            <Typography sx={titulo}>{titleValue}</Typography>
            <Typography sx={subtitulo}>{subtitleValue}</Typography>
          </Box>
          <Box sx={outlet}>
            <Outlet
              context={{
                selectedTicket,
                setSelectedTicket,
                selectedVerificacion,
                setSelectedVerificacion,
                titleValue,
                setTitleValue,
                subtitleValue,
                setSubtitleValue,
                setOpenSupp,
                handleCloseSupp
              }}
            />
          </Box>
        </Box>
      </Box>
      {type === "Supp" || type == "Admin" ? null : (
        <Fab sx={supportButton} onClick={handleOpenSupp}>
          <HeadsetMicOutlinedIcon sx={supportButtonIcon} />
        </Fab>
      )}
      <Modal open={openSupp} onClose={handleCloseSupp} sx={modal}>
        <SupportPopUp setOpenSupp={setOpenSupp} />
      </Modal>
      <FinishModal
        openModal={openLogOutModal}
        setOpenModal={setOpenLogOutModal}
        buttonText="Cerrar sesión"
        navigatePath="/"
        handleContinue={handleLogOut}
      >
        <Typography sx={titleFinishModal}>
          ¿Está seguro de cerrar sesión?
        </Typography>
      </FinishModal>
      <ConfirmationModal />
    </Box>
  );
}
