import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

/* ICONS */
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

/* CONTROLLER VARIABLES */
import { useMousePosition, transition } from "../../../../controller/utils";

export default function InfoHover(props) {
  const { hover, persist = false } = props;
  const [hoverP, setHoverP] = useState(false);
  const [position, setPosition] = useState({});
  const mousePosition = useMousePosition();

  useEffect(() => {
    if (hoverP) {
      setPosition({ x: mousePosition.x, y: mousePosition.y });
    }
  }, [hover, hoverP]);

  const handleMouseEnter = (event) => {
    event.preventDefault();
    setHoverP(true);
  };
  const handleMouseLeave = (event) => {
    event.preventDefault();
    setHoverP(false);
  };

  /* CSS */
  const hoverBox = {
    width: persist ? "fit-content" : "20.83vw",
    maxWidth: "350px",
    minWidth: "200px",
    transition: transition,
    position: "fixed",
    visibility: hover || hoverP ? "visible" : "hidden",
    opacity: hover || hoverP ? "1" : "0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10000000,
    boxSizing: "border-box",
    background: "#FFFFFF",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: "14px",
    gap: "14px",
    padding: "17px 14px",
    top: hoverP ? position.y : hover ? mousePosition.y : position.y,
    left: hoverP ? position.x : hover ? mousePosition.x : position.x,
  };
  const icon = {
    width: "30px",
    height: "auto",
    color: "#1876D1",
  };
  const textStyle = {
    textAlign: "start",
  };

  return (
    <Box
      sx={hoverBox}
      onPointerEnter={persist ? handleMouseEnter : null}
      onPointerLeave={persist ? handleMouseLeave : null}
    >
      <ErrorOutlineOutlinedIcon sx={icon} />
      <Typography sx={textStyle}>{props.children}</Typography>
    </Box>
  );
}
