import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Box, Typography, Button, Modal, IconButton } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";

/* CUSTOM LIBRARIES */
import CustomStepper from "../common/components/customStepper";
import DragDropFile from "../common/components/dragAndDrop";
import CustomTable from "../common/components/customTable";
import FinishModal from "../common/modals/finishModal";
import SubirDocumentoModal from "../common/modals/subirDocumentoModal";
import StateCell from "../common/things/stateCell";
import ActionButtonsCell from "../common/things/actionButttonsCell";
import PDFViewer from "../common/components/pdfViewer";
import LoadingRocket from "../common/things/loadingRocket";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { transition, MONTHSNUM } from "../../../controller/utils";
import {
  stepsCargaMasivaEmpresa,
  headCellsEmpresa
} from "../../../controller/listas";
import { useDispatch } from "react-redux";
import {
  setErrorText,
  setSuccessText,
  setOpenConfirmModal
} from "../../../redux/confirmModalSlice";
import { useAddDocumentosFaenasMasivoMutation } from "../../../api/docsApiSlice";

/* TEST DATA */
import { test_listaEmpresas } from "../../../controller/testData";

function FirstPage(props) {
  const { setContinueValue, archivos, setArchivos, isLoading } = props;
  const littleSize = littleSizeFunc();

  /* CSS */
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : ""
  };

  return isLoading ? (
    <LoadingRocket isLoading={isLoading} />
  ) : (
    <Box sx={selectDatosBoxBox}>
      <DragDropFile
        setContinueValue={setContinueValue}
        uploadedFiles={archivos}
        setUploadedFiles={setArchivos}
      />
    </Box>
  );
}

function SecondPage(props) {
  const [openSubirDocModal, setOpenSubirDocModal] = useState(false);
  const [openPDFViewModal, setOpenPDFViewModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [lineaDocumentoSeleccionada, setLineaDocumentoSeleccionada] =
    useState("");
  const [listaEmpresasFiltrada, setListaEmpresasFiltrada] = useState(
    test_listaEmpresas.list
      .map((empresa) => {
        empresa.documentos.forEach((doc) => {
          doc.nombre = empresa.nombre;
          doc.rut = empresa.rut;
          doc.mes = MONTHSNUM[doc.fecha.split("-")[1]];
        });
        return empresa.documentos;
      })
      .flat(1)
  );

  if (listaEmpresasFiltrada) {
    for (var i = 0; i < listaEmpresasFiltrada.length; i++) {
      let estado = listaEmpresasFiltrada[i]["estado"];
      if (typeof estado === "string") {
        listaEmpresasFiltrada[i]["estado"] = <StateCell state={`${estado}`} />;
      }
      listaEmpresasFiltrada[i]["acciones"] = (
        <ActionButtonsCell
          variant="documentos"
          linea={listaEmpresasFiltrada[i]}
          setLineaSeleccionada={setLineaDocumentoSeleccionada}
          setOpenSubirDocModal={setOpenSubirDocModal}
          setOpenPDFViewModal={setOpenPDFViewModal}
          setOpenErrorModal={setOpenErrorModal}
        />
      );
    }
  }

  const handleClosePDFModal = () => setOpenPDFViewModal(false);

  const contenido = {
    // minHeight: "40vh",
    width: "100%",
    height: "fit-content",
    maxHeight: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "1vh",
    transition: transition
  };
  const textStyleFinishModal = {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "20px",
    color: "#818181"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const modalPDF = {
    transition: transition,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    height: "fit-content",
    " .MuiBox-root.MuiBox-root": {
      borderRadius: "20px"
    }
  };

  return test_listaEmpresas.list.length > 0 ? (
    <Box sx={contenido}>
      <CustomTable
        search={false}
        headCells={headCellsEmpresa}
        rows={listaEmpresasFiltrada}
        setRows={setListaEmpresasFiltrada}
      />
      <Modal open={openPDFViewModal} onClose={handleClosePDFModal}>
        <Box sx={modalPDF}>
          <Box sx={closeButtonBox}>
            <IconButton onClick={handleClosePDFModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <PDFViewer
            openModal={openPDFViewModal}
            setOpenModal={setOpenPDFViewModal}
            subtitle={`${lineaDocumentoSeleccionada.documento} - ${lineaDocumentoSeleccionada.nombre}`}
          />
        </Box>
      </Modal>
      <SubirDocumentoModal
        openModal={openSubirDocModal}
        setOpenModal={setOpenSubirDocModal}
        subtitle={`${lineaDocumentoSeleccionada.nombre} - ${lineaDocumentoSeleccionada.documento}`}
        doneNavigation="/Empresa"
        maxCount={1}
      />
      <FinishModal openModal={openErrorModal} setOpenModal={setOpenErrorModal}>
        <Typography sx={textStyleFinishModal}>
          El documento adjunto no corresponde a un/a{" "}
          <b>{lineaDocumentoSeleccionada.documento}</b> vigente.
        </Typography>
      </FinishModal>
    </Box>
  ) : null;
}

export default function CargaMasivaEmpresas() {
  const { setSubtitleValue } = useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const littleSize = littleSizeFunc();

  const [uploadDocs, uploadResults] = useAddDocumentosFaenasMasivoMutation();

  const [stepCount, setStepCount] = useState(0);
  const [continueValue, setContinueValue] = useState(false);
  const [archivos, setArchivos] = useState([]);

  useEffect(() => {
    setSubtitleValue("");
  }, []);

  useEffect(() => {
    if (stepCount === 1) {
      setContinueValue(true);
    }
    if (stepCount === 2) {
      setContinueValue(false);
      dispatch(setOpenConfirmModal(true));
      dispatch(
        setSuccessText("¡Tus documentos han sido cargados de forma exitosa!")
      );
      navigate("/Empresa");
    }
  }, [stepCount]);

  const handleAsyncUpload = async () => {
    try {
      const response = await uploadDocs({
        documentos: archivos,
        tipo: "faenas"
      }).unwrap();
      setStepCount(stepCount + 1);
      setContinueValue(false);
    } catch (err) {
      console.error(err);
      dispatch(
        setErrorText(
          "¡Hubo un error al subir los documentos, intente nuevamente o conctace con nosotros!"
        )
      );
      dispatch(setOpenConfirmModal(true));
    }
  };

  const handleContinue = () => {
    if (stepCount == 0) {
      handleAsyncUpload();
    } else {
      setContinueValue(false);
      setStepCount(stepCount + 1);
    }
  };

  const handleBack = () => {
    setStepCount(stepCount - 1);
  };

  const datosButtonBox = {
    minHeight: "58vh",
    height: "100%",
    // maxHeight: "84vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    gap: stepCount === 1 ? "3vh" : "4vh",
    transition: transition
  };
  const datosBox = {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-around",
    gap: stepCount === 1 ? "1vh" : "3vh",
    alignItems: littleSize ? "center" : ""
  };
  const tituloDatos = {
    fontWeight: 900,
    fontSize: "24px",
    lineHeight: "100%",
    color: "#1876D1"
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px"
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    position: littleSize ? (stepCount > 0 ? "" : "absolute") : "",
    visibility: littleSize ? (stepCount > 0 ? "visible" : "hidden") : "",
    opacity: stepCount > 0 ? 1 : 0,
    ":hover": {
      border: "2px solid #1876D1"
    }
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1",
    minWidth: "110px"
  };

  return (
    <Box>
      <Box sx={datosButtonBox}>
        <CustomStepper stepCount={stepCount}>
          {stepsCargaMasivaEmpresa}
        </CustomStepper>
        {stepCount === 0 ? (
          <Box sx={datosBox}>
            <Typography sx={tituloDatos}>
              Paso 1: Suba los documentos solicitados
            </Typography>
            <Typography>
              A continuación, suba todos los documentos que necesite actualizar
              en uno o más archivos y haga click en “cargar” para poder avanzar.
            </Typography>
            <FirstPage
              setContinueValue={setContinueValue}
              archivos={archivos}
              setArchivos={setArchivos}
              isLoading={uploadResults.isLoading}
            />
          </Box>
        ) : (
          <Box sx={datosBox}>
            <Typography sx={tituloDatos}>Paso 2: Revisión final</Typography>
            <Typography>
              <p>
                Antes de finalizar, por favor confirme que los documentos
                requeridos se hayan cargado correctamente revisando su estado.
              </p>
              <p>
                De existir uno con estado “sin subir”, recuerde que puede
                cargarlo manualmente.
              </p>
            </Typography>
            <SecondPage />
          </Box>
        )}
        <Box sx={buttonBox}>
          <Button sx={buttonVolver} variant="outlined" onClick={handleBack}>
            Volver
          </Button>
          <Button
            sx={button}
            disableElevation
            variant="contained"
            type="submit"
            onClick={handleContinue}
            disabled={!continueValue}
          >
            {stepCount > 0 ? "Finalizar" : "Cargar"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
