import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  success: "",
  error: "",
  info: "",
};

export const confirmModalSlice = createSlice({
  name: "confirmModal",
  initialState: initialState,
  reducers: {
    setOpenConfirmModal: (state, action) => {
      state.open = action.payload;
    },
    setSuccessText: (state, action) => {
      state.success = action.payload;
      state.error = "";
      state.info = "";
    },
    setErrorText: (state, action) => {
      state.error = action.payload;
      state.success = "";
      state.info = "";
    },
    setInfoText: (state, action) => {
      state.error = "";
      state.success = "";
      state.info = action.payload;
    },
  },
});

export const {
  setOpenConfirmModal,
  setSuccessText,
  setErrorText,
  setInfoText,
} = confirmModalSlice.actions;
export default confirmModalSlice.reducer;

export const currentStateConfirmModal = (state) => state.confirmModal.open;
export const currentSuccesText = (state) => state.confirmModal.success;
export const currentErrorText = (state) => state.confirmModal.error;
export const currentInfoText = (state) => state.confirmModal.info;
