import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { IconButton, Button, Typography, Box, Modal } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";

/* CUSTOM LIBRARIES */
import DatosEmpresa from "../common/components/datosEmpresa";
import SubirPlantillasTiposTrabajadores from "./subirPlantillasTiposTrabajadores";
import SeleccionarTiposTrabajadores from "./seleccionarTiposTrabajadores";
import CustomStepper from "../common/components/customStepper";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { transition } from "../../../controller/utils";
import { stepsEditarEmpresa } from "../../../controller/listas";
import { useDispatch, useSelector } from "react-redux";
import { currentEmpresa, setEmpresa } from "../../../redux/entidadesSlice";
import LoadingRocket from "../common/things/loadingRocket";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../redux/confirmModalSlice";
import { useUpdateEmpresaMutation } from "../../../api/empresasApiSlice";
import { useLazyGetTiposDocumentosEmpresaQuery } from "../../../api/tipotrabajadoresApiSlice";

export default function EditarEmpresaModal(props) {
  const { openModal, setOpenModal, refetch } = props;
  const selectedEmpresa = useSelector(currentEmpresa);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const littleSize = littleSizeFunc();

  const [triggerGetData, { isLoading, isFetching }] =
    useLazyGetTiposDocumentosEmpresaQuery();

  const [updatePost, updateResult] = useUpdateEmpresaMutation();

  const [continueValue, setContinueValue] = useState(false);
  const [edicionTipo, setEdicionTipo] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [stepCount, setStepCount] = useState(0);
  const [stepTitle, setStepTitle] = useState("");
  const [stepSubtitle, setStepSubtitle] = useState("");
  const [stepContent, setStepContent] = useState();
  const [tipoTrabajadores, setTipoTrabajadores] = useState([]);
  const [tipoTrabajadoresTotal, setTipoTrabajadoresTotal] = useState([]);

  useEffect(() => {
    if (stepCount === 0) {
      setStepTitle("Indique los tipos de trabajadores");
      setStepSubtitle(
        "Elija los tipos de trabajadores que quiera editar y/o agregar:"
      );
      if (!isLoading) {
        setStepContent(
          <SeleccionarTiposTrabajadores
            setContinueValue={setContinueValue}
            tipoTrabajadoresTotal={tipoTrabajadoresTotal}
            tipoTrabajadores={tipoTrabajadores}
            setTipoTrabajadores={setTipoTrabajadores}
            refetch={handleGetTipoTrabajadoresData}
          />
        );
      } else {
        setStepContent(<LoadingRocket dialog={false} dark />);
      }
    }
    if (stepCount === 1) {
      setStepTitle("Subir/Editar plantillas");
      setStepSubtitle(
        "Elimine y suba las plantillas para actualizar estas para el tipo de trabajador indicado"
      );
      if (tipoTrabajadores.length > 0) {
        setStepContent(
          <SubirPlantillasTiposTrabajadores
            setContinueValue={setContinueValue}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            tipoTrabajadores={tipoTrabajadores}
            selectedEmpresa={selectedEmpresa}
            opened={stepCount === 1}
          />
        );
      } else {
        setStepContent(<LoadingRocket dialog={false} dark />);
      }
    }
    if (stepCount === 3) {
      dispatch(setSuccessText("¡Empresa creada con exito!"));
      dispatch(setOpenConfirmModal(true));
      handleCloseModal();
    }
  }, [stepCount, tipoTrabajadores]);

  useEffect(() => {
    if (openModal) {
      handleGetTipoTrabajadoresData();
    }
  }, [openModal]);

  const handleGetTipoTrabajadoresData = async () => {
    const { data, isSuccess } = await triggerGetData(selectedEmpresa.id, false);
    if (isSuccess) {
      setTipoTrabajadores(data.data.empresa);
      setTipoTrabajadoresTotal(data.data.todos);
    }
  };
  const handleContinue = () => {
    if (stepCount < 1) {
      setContinueValue(false);
      setStepCount(stepCount + 1);
    } else {
      handleGuardar();
    }
  };
  const handleBack = () => {
    setStepCount(stepCount - 1);
  };

  const handleCloseModal = () => {
    setContinueValue(false);
    setOpenModal(false);
    setEdicionTipo(false);
    setStepCount(0);
    refetch();
    navigate("/Empresas");
  };
  const handleGuardar = async () => {
    try {
      await updatePost(selectedEmpresa).unwrap();
      dispatch(
        setSuccessText("¡Tus cambios han sido guardados de forma exitosa!")
      );
      handleCloseModal();
    } catch (e) {
      console.error(e);
      dispatch(
        setErrorText(
          "¡Hubo un error, intente nuevamente o contáctese con nosotros!"
        )
      );
    }
    dispatch(setOpenConfirmModal(true));
  };
  const handleEditarTipos = () => setEdicionTipo(!edicionTipo);

  const setSelectedEmpresa = (value) => {
    dispatch(setEmpresa(value));
  };

  const modalBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxWidth: "1300px",
    height: "fit-content",
    maxHeight: "98vh",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: "5.882vh 3.472vw ",
    gap: "11px"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const content = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflowY: "auto"
  };
  const title = {
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "140%"
  };
  const subtitle = {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "150%",
    alignItems: littleSize ? "center" : "",
    textAlign: littleSize ? "center" : ""
  };
  const datosButtonBox = {
    transition: transition,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: littleSize ? "center" : "normal",
    justifyContent: "space-between",
    gap: "5vh",
    padding: "0vh 0px 6vh 0px"
  };
  const datosBox = {
    transition: transition,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: "3.882vh",
    alignItems: littleSize ? "center" : ""
  };
  const tituloDatos = {
    transition: transition,
    fontWeight: 900,
    fontSize: "26px",
    lineHeight: "140%",
    color: "#1876D1"
  };
  const buttonBox = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "17px",
    margin: "0 0 1px 0"
  };
  const button = {
    minWidth: "160px",
    textTransform: "none",
    borderRadius: "10px",
    fontWeight: 600
  };
  const buttonOut = {
    transition: transition,
    minWidth: "160px",
    textTransform: "none",
    borderRadius: "10px",
    fontWeight: 600,
    border: "2px solid #1876D1",
    ":hover": {
      border: "2px solid #1876D1",
      opacity: 0.8
    }
  };
  const buttonStepBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px"
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    position: littleSize ? (stepCount > 0 ? "" : "absolute") : "",
    visibility: littleSize ? (stepCount > 0 ? "visible" : "hidden") : "",
    opacity: stepCount > 0 ? 1 : 0,
    ":hover": {
      border: "2px solid #1876D1"
    }
  };

  return selectedEmpresa.id !== "" && !isLoading ? (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={modalBox}>
        <Box sx={closeButtonBox}>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography sx={title}>Editar</Typography>
        <Typography sx={subtitle}>Empresa: {selectedEmpresa.nombre}</Typography>
        <Box sx={content}>
          {edicionTipo ? (
            <Box sx={datosButtonBox}>
              <CustomStepper stepCount={stepCount}>
                {stepsEditarEmpresa}
              </CustomStepper>
              <Box sx={datosBox}>
                <Typography sx={tituloDatos}>
                  Paso {stepCount + 1}: {stepTitle}
                </Typography>
                <Typography>{stepSubtitle}</Typography>
                {stepContent}
              </Box>
            </Box>
          ) : (
            <DatosEmpresa
              dense
              setContinueValue={setContinueValue}
              datosEmpresa={selectedEmpresa}
              setDatosEmpresa={setSelectedEmpresa}
            />
          )}
        </Box>
        <Box sx={buttonBox}>
          {!isLoading && tipoTrabajadoresTotal.length > 0 ? (
            <Button
              sx={buttonOut}
              variant="outlined"
              onClick={handleEditarTipos}
              disableElevation
            >
              {edicionTipo
                ? "Editar datos de la empresa"
                : "Editar tipos de trabajador"}
            </Button>
          ) : null}
          {edicionTipo ? (
            <Box sx={buttonStepBox}>
              <Button sx={buttonVolver} variant="outlined" onClick={handleBack}>
                Volver
              </Button>
              <Button
                sx={button}
                disableElevation
                variant="contained"
                type="submit"
                onClick={handleContinue}
                disabled={!continueValue}
              >
                {stepCount < 1 ? "Continuar" : "Guardar cambios"}
              </Button>
            </Box>
          ) : (
            <Button
              sx={button}
              variant="contained"
              onClick={handleGuardar}
              disableElevation
              disabled={!continueValue}
            >
              Guardar cambios
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  ) : (
    <LoadingRocket />
  );
}
