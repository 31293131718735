import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useOutletContext, Link } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import {
  Box,
  Typography,
  Button,
  Avatar,
  SvgIcon,
  TextField,
  FormHelperText,
} from "@mui/material";
import MuiLink from "@mui/material/Link";

/* IMAGES */
import logoName from "../../images/logo/bluename_noBack_dense.png";

/* ICON */
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import { ReactComponent as AcuteIcon } from "../../images/svg/acute.svg";
import SuccessIcon from "../animations/Check Icon/successIcon";
import ErrorIcon from "../animations/Error Icon/errorIcon";
import LoadingRocket from "../Drawer Views/common/things/loadingRocket";
import { ReactComponent as EmpresaIcon1 } from "../../images/svg/empresa1.svg";
import { ReactComponent as EmpresaIcon2 } from "../../images/svg/empresa2.svg";
import { ReactComponent as EmpresaIcon3 } from "../../images/svg/empresa3.svg";
import { ReactComponent as EmpresaIconColor1 } from "../../images/svg/empresa1-color.svg";
import { ReactComponent as EmpresaIconColor2 } from "../../images/svg/empresa2-color.svg";
import { ReactComponent as EmpresaIconColor3 } from "../../images/svg/empresa3-color.svg";

/* CONTROLLER VARIABLES */
import { transition } from "../../controller/utils";
import { littleSizeFunc } from "../../controller/windowSize";
import { useSubmitDemoMutation } from "../../api/authApiSlice";

/* IMAGES */
import DashboardImage from "../../images/Landingpage/Dashboard.png";
import DocumentosContratistaImage from "../../images/Landingpage/DocumentosContratista.png";
import FacturacionImage from "../../images/Landingpage/Facturacion.png";
import PersonaImage from "../../images/Landingpage/persona.jpg";
import { display, margin, width } from "@mui/system";

export default function LandingPage() {
  const navigate = useNavigate();
  const littleSize = littleSizeFunc();

  const [demoFocused, setDemoFocused] = useState(false);
  const [submitDemo, { isLoading }] = useSubmitDemoMutation();

  const [nombreCompleto, setNombreCompleto] = useState("");
  const [correo, setCorreo] = useState("");
  const [empresa, setEmpresa] = useState("");

  const [approved, setApproved] = useState(null);

  const [errorNombreCompleto, setErrorNombreCompleto] = useState("");
  const [errorCorreo, setErrorCorreo] = useState("");
  const [errorEmpresa, setErrorEmpresa] = useState("");

  const [rocketLogo, setRocketLogo] = useState(logoName);
  const logoRef = useRef(rocketLogo);
  logoRef.current = rocketLogo;

  const handleInicio = () => {};

  const handleApprove = () => {
    setApproved(true);
    setTimeout(function () {
      setApproved(null);
    }, 5000);
  };
  const handleError = () => {
    setApproved(false);
    setTimeout(function () {
      setApproved(null);
    }, 5000);
  };

  const handleLogin = () => {
    navigate("/auth");
  };
  const handleDemo = async (event) => {
    event.preventDefault();
    // verificar si existe una cuenta con ese usuario y contraseña
    try {
      const userD = await submitDemo({
        nombre: nombreCompleto,
        mail: correo,
        empresa: empresa,
      }).unwrap();
      handleApprove();
    } catch (err) {
      handleError();
    }
  };

  const handleNotEmpty = (value, setValue, setError, error) => {
    setValue(value);
    if (isEmpty(value)) {
      setError(error);
    } else {
      setError("");
    }
  };
  const handleMailChange = (value) => {
    setCorreo(value);
    if (isEmail(value)) {
      setErrorCorreo("");
    } else {
      setErrorCorreo("Escriba un correo válido");
    }
  };
  const handleClickUpperDemo = () => {
    const demoElement = document.getElementById("nombre");
    setDemoFocused(true);
    demoElement.scrollIntoView({ behavior: "smooth" });
  };

  /* CSS */
  const contenido = {
    transition: transition,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FCFBFB",
    overflowX: "hidden",
    overflowY: "auto",
  };
  const header = {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const logoStyle = {
    width: "60%",
    height: "60%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FCFBFB",
    transition: transition,
    padding: littleSize ? "1vh 1vw" : "3.125vh 3.125vw",
  };
  const headerButtonBox = {
    transition: transition,
    height: "fit-content",
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    padding: "3.125vh 1.736vw",
    gap: "2.778vw",
  };
  const buttonHeader = {
    maxWidth: "240px",
    minWidth: "150px",
    fontSize: "14px",
    fontWeight: "800",
    textTransform: "none",
    borderRadius: "7.5px",
  };
  const primero = {
    display: "flex",
    flexDirection: "row",
    padding: "11.25vh 0",
    flexWrap: "wrap",
    // gap: "5.55556vw",
    justifyContent: littleSize ? "center" : "",
  };
  const titleBox = {
    maxWidth: "704px",
    minWidth: littleSize ? "fit-content" : "",
    width: "48.8888889vw",
    display: "flex",
    flexDirection: "column",
    padding: littleSize ? "0 5vw" : "0",
    justifyContent: littleSize ? "center" : "",
    alignItems: littleSize ? "center" : "",
    gap: "28px",
  };
  const titleStyle = {
    width: "fit-content",
    fontSize: littleSize ? "32px" : "48px",
    fontWeight: 700,
    lineHeight: "120%",
    color: "#1876D1",
    textAlign: littleSize ? "center" : "left",
  };
  const textStyle = {
    fontSize: littleSize ? "16px" : "20px",
    fontWeight: 400,
    lineHeight: "150%",
    color: "#818181",
    textAlign: littleSize ? "center" : "left",
  };
  const buttonText = {
    maxWidth: "240px",
    minWidth: "230px",
    width: "16.66667vw",
    fontSize: "16px",
    fontWeight: 800,
    textTransform: "none",
    borderRadius: "7.5px",
    padding: "15px 20px",
  };
  const imgBox = {
    maxWidth: "1000px",
    minWidth: "280px",
    width: "38.889vw",
  };
  const imgStyle = {
    display: littleSize ? "none" : "",
    maxWidth: "1120px",
    width: "47vw",
    minWidth: "280px",
    height: "auto",
    objectFit: "contain",
    borderRadius: "10px",
    boxShadow:
      "0.501177966594696px 2.004711866378784px 40.094234466552734px 0px rgba(0, 0, 0, 0.25)",
  };
  const segundoCita = {
    display: "flex",
    height: "fit-content",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px 83px",
    flexWrap: "wrap",
    gap: littleSize ? "0px" : "26px",
  };
  const avatarBox = {
    display: "flex",
    height: "90vw",
    maxHeight: "264px",
    maxWidth: "264px",
    width: "90vw",
    borderRadius: "100%",
    border: "3px solid #1876D1",
    alignItems: "center",
    justifyContent: "center",
    padding: "18px",
  };
  const avatar = {
    height: "auto",
    width: "100%",
  };
  const citaBox = {
    maxWidth: "940px",
    height: "fit-content",
    width: "90vw",
    textAlign: "center",
  };
  const citaStyle = {
    display: "flex",
    minHeight: "fit-content",
    height: "37.5vh",
    alignItems: "center",
    justifyContent: "center",
    fontSize: littleSize ? "20px" : "30px",
    fontStyle: "italic",
    fontWeight: 600,
    textAlign: "center",
    lineHeight: "120%",
    color: "#818181",
  };
  const comillas = {
    width: "fit-content",
    height: "100%",
    color: "#1876D1",
    textAlign: "center",
    fontSize: littleSize ? "150px" : "200px",
    fontFamily: "Port Lligat Sans",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "120%",
  };
  const nombreCitaStyle = {
    fontSize: littleSize ? "16px" : "20px",
    fontWeight: 500,
    lineHeight: "150%",
    color: "#1876D1",
  };
  const tercero = {
    display: "flex",
    padding: littleSize ? "0 0" : "11.25vh 80px",
    gap: littleSize ? "20px" : "60px",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  };
  const infoCard = {
    display: "flex",
    alignItems: "center",
    gap: littleSize ? "15px" : "35px",
  };
  const iconInfoCard = {
    height: littleSize ? "50px" : "75px",
    width: littleSize ? "50px" : "75px",
    color: "#7EB0F2",
  };
  const icon = {
    height: littleSize ? "50px" : "75px",
    width: littleSize ? "50px" : "75px",
    stroke: "#FCFBFB",
    strokeWidth: 0.5,
  };
  const textInfoCard = {
    display: "flex",
    alignItems: "center",
    minHeight: "fit-content",
    minWidth: "200px",
    height: "15.2vh",
    width: "19.01vw",
  };
  const textInfo = {
    fontSize: littleSize ? "20px" : "24px",
    fontWeight: 700,
    lineHeight: "130%",
    color: "#1876D1",
  };
  const textoImagenBox_left = {
    display: "flex",
    width: "100%",
    padding: littleSize ? "3.75vh 80px" : "9.75vh 80px",
    marginLeft: littleSize ? "20px" : "",
    marginRight: littleSize ? "10px" : "",
    gap: "5.42vw",
    justifyContent: littleSize ? "start" : "center",
    alignItems: "center",
    flexWrap: "wrap",
  };
  const textoImagenBox_right = {
    display: "flex",
    width: "100%",
    padding: "9.75vh 80px",
    marginLeft: littleSize ? "10px" : "",
    marginRight: littleSize ? "20px" : "",
    gap: "5.42vw",
    alignItems: "center",
    justifyContent: littleSize ? "end" : "center",
    flexWrap: "wrap-reverse",
  };
  const titleBox2 = {
    width: "668px",
    display: "flex",
    flexDirection: "column",
    gap: "28px",
    padding: littleSize ? "1vh 10px" : "",
  };
  const titleStyle_left = {
    fontSize: littleSize ? "24px" : "32px",
    textAlign: "left",
    fontWeight: 700,
    lineHeight: "120%",
    color: "#1876D1",
  };
  const titleStyle_right = {
    fontSize: littleSize ? "24px" : "32px",
    textAlign: littleSize ? "right" : "left",
    fontWeight: 700,
    lineHeight: "120%",
    color: "#1876D1",
  };
  const imgStyle2 = {
    maxWidth: "900px",
    width: littleSize ? "80vw" : "37.15vw",
    minWidth: "320px",
    height: "fit-content",
    objectFit: "contain",
    borderRadius: "10px",
    boxShadow:
      "0.501177966594696px 2.004711866378784px 40.094234466552734px 0px rgba(0, 0, 0, 0.25)",
  };
  const textStyle_left = {
    fontSize: littleSize ? "16px" : "20px",
    fontWeight: 400,
    lineHeight: "150%",
    color: "#818181",
    // textAlign: "right",
  };
  const textStyle_right = {
    fontSize: littleSize ? "16px" : "20px",
    fontWeight: 400,
    lineHeight: "150%",
    color: "#818181",
    textAlign: littleSize ? "right" : "left",
  };
  const blueBox = {
    width: "100%",
    minHeight: "410px",
    background: "#1876D1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "10vh 106px",
    gap: "8.5vh",
  };
  const textBlueStyle = {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "130%",
    color: "#FCFBFB",
    textAlign: "center",
  };
  const empresasBox = {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    gap: "6.25vh 120px",
  };
  const empresaIcon = {
    height: "42px",
    width: "220px",
  };
  const cartasEmpresasBox = {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexWrap: "wrap",
    gap: littleSize ? "30px" : "65px",
    padding: littleSize ? "40px 80px" : "14.25vh 80px",
  };
  const cartaEmpresa = {
    width: "383px",
    height: "fit-content",
    borderRadius: "12.172px",
    background: "#FFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "33px",
    padding: "42px 24px",
    boxShadow:
      "0px 16.22881317138672px 56.80084991455078px -4.05720329284668px rgba(173, 192, 203, 0.15), 0px 3.245762825012207px 16.22881317138672px 0px rgba(173, 192, 203, 0.10)",
  };
  const textCartaEmpresa = {
    color: "#818181",
    fontSize: littleSize ? "14px" : "16px",
    textAlign: littleSize ? "center" : "left",
    fontWeight: 400,
    lineHeight: "150%",
  };
  const avatarEmpresaBox = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  };
  const avatarEmpresaCircle = {
    display: "flex",
    height: "67px",
    minWidth: "67px",
    width: "67px",
    borderRadius: "100%",
    border: "0.67px solid #1876D1",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
  };
  const nameAvatarCard = {
    color: "#1876D1",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "150%",
  };
  const textAvatarCard = {
    color: "#818181",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
  };
  const lastBox = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "5.625vh 80px",
    gap: "9.625vh",
  };
  const lastTextBox = {
    maxWidth: "704px",
    width: "90vw",
    minWidth: "",
    display: "flex",
    flexDirection: "column",
    gap: "28px",
  };
  const lastTitle = {
    color: "#1876D1",
    textAlign: "center",
    fontSize: littleSize ? "32px" : "48px",
    fontWeight: 700,
    lineheight: "120%",
  };
  const lastText = {
    color: "#818181",
    textAlign: "center",
    fontSize: littleSize ? "16px" : "20px",
    fontWeight: 400,
    lineheight: "150%",
  };
  const signDemoBox = {
    width: "100%",
    // height: "57px",
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "44px",
  };
  const textFieldBox = {
    display: "flex",
    height: "57px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  const textField = {
    "& .MuiOutlinedInput-notchedOutline": {
      color: "#00000",
      background: "#FFFFF",
      borderRadius: "7.5px",
      border: "0.631px solid var(--grey, #D9D9D9)",
    },
  };
  const buttonBox = {
    display: "flex",
    maxWidth: "240px",
    minWidth: "230px",
    width: "16.66667vw",
    justifyContent: "center",
    alignItems: "center",
  };
  const loadingBox = {
    display: "flex",
    height: "57px",
    width: "114px",
    justifyContent: "center",
    alignItems: "center",
    ".sa": {
      height: "auto",
    },
    ".success-checkmark": {
      height: "auto",
    },
  };
  const buttonText2 = {
    maxWidth: "240px",
    minWidth: "230px",
    width: "16.66667vw",
    fontSize: "16px",
    fontWeight: 800,
    textTransform: "none",
    borderRadius: "7.5px",
    padding: "15px 20px",
  };
  const footerBox = {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "fit-content",
    alignItems: "center",
    justifyContent: "center",
    gap: "2.7778vw",
    padding: "105px 45px 43px 45px",
  };
  const copyRightBox = {
    display: "flex",
    gap: "1.11111vw",
    height: "fit-content",

    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  };
  const footerLogo = {
    padding: "0 45px",
    width: "30%",
    height: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FCFBFB",
    transition: transition,
  };
  const copyRight = {
    display: "flex",
    gap: "16px",
    color: "#818181",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 600,
    lineheight: "150%",
  };
  const footerLinkBox = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "2.7778vw",
    padding: "0 45px",
  };
  const footerLink = {
    display: "flex",
  };

  return (
    <Box sx={contenido}>
      <Box sx={header}>
        <Box component={Link} to={`/`}>
          <Box
            component="img"
            loading="lazy"
            sx={logoStyle}
            alt="imagen"
            src={logoRef.current}
          />
        </Box>
        <Box sx={headerButtonBox}>
          {/* <Button sx={buttonHeader} variant="text" onClick={handleInicio}>
            Inicio
          </Button> */}
          <Button sx={buttonHeader} variant="contained" onClick={handleLogin}>
            Acceso usuarios
          </Button>
        </Box>
      </Box>
      <Box sx={primero}>
        <Box sx={titleBox}>
          <Typography sx={titleStyle}>
            Gestiona tus contratistas de forma automática. En minutos.
          </Typography>
          <Typography sx={textStyle}>
            Maneja la relación con tus subconctratistas desde un solo lugar,
            validando la documentación de sus trabajadores de forma fácil,
            rápida y segura.
          </Typography>
          <Button
            sx={buttonText}
            variant="contained"
            onClick={handleClickUpperDemo}
          >
            Agenda tu demo gratis
          </Button>
        </Box>
        <Box sx={imgBox}>
          <Box
            component="img"
            src={DashboardImage}
            alt="Dashboard"
            sx={imgStyle}
          />
        </Box>
      </Box>
      {/* <Box sx={segundoCita}>
        <Box sx={avatarBox}>
          <Avatar sx={avatar} src={PersonaImage} alt="Persona" />
        </Box>
        <Box sx={citaBox}>
          <Typography sx={citaStyle}>
            <Typography sx={comillas}>“</Typography>
            Con VLK, fuimos capaces de reducir nuestro tiempo de registro de
            subcontratistas en un 80%
            <Typography sx={comillas}>„</Typography>
          </Typography>
          <Typography sx={nombreCitaStyle}>
            Rudolf Hartmann - CEO Contratistas soluciones SpA
          </Typography>
        </Box>
      </Box> */}
      <Box sx={tercero}>
        <Box sx={infoCard}>
          <Box sx={iconInfoCard}>
            <SvgIcon sx={icon}>
              <AcuteIcon />
            </SvgIcon>
          </Box>
          <Box sx={textInfoCard}>
            <Typography sx={textInfo}>
              Carga rápida de documentos de subcontratistas
            </Typography>
          </Box>
        </Box>
        <Box sx={infoCard}>
          <Box sx={iconInfoCard}>
            <HowToRegOutlinedIcon sx={icon} />
          </Box>
          <Box sx={textInfoCard}>
            <Typography sx={textInfo}>
              Validación automática de vencimientos
            </Typography>
          </Box>
        </Box>
        <Box sx={infoCard}>
          <Box sx={iconInfoCard}>
            <HistoryOutlinedIcon sx={icon} />
          </Box>
          <Box sx={textInfoCard}>
            <Typography sx={textInfo}>
              Actualización de información en tiempo real
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={textoImagenBox_left}>
        <Box
          component="img"
          src={DashboardImage}
          alt="Dashboard"
          sx={imgStyle2}
        />
        <Box sx={titleBox2}>
          <Typography sx={titleStyle_left}>
            Aumenta la eficiencia de tus proyectos, generando una disminución de
            los costos asociados a estos
          </Typography>
          <Typography sx={textStyle_left}>
            Valida la documentación de tus subcontratistas de forma más rápida y
            eficiente con nuestro sistema automático de validación de
            documentos, y comienza tus proyectos en plazos mucho más cortos,
            ayudando a reducir tus costos.
          </Typography>
        </Box>
      </Box>
      <Box sx={textoImagenBox_right}>
        <Box sx={titleBox2}>
          <Typography sx={titleStyle_right}>
            Mejora la seguridad de tus colaboradores en el lugar de trabajo
          </Typography>
          <Typography sx={textStyle_right}>
            Una validación más rápida de la documentación de tus
            subcontratistas, significa que puedes identificar y resolver
            posibles problemas de documentación caducada o incompleta de manera
            oportuna, mejorando la seguridad del proyecto en general.
          </Typography>
        </Box>
        <Box
          component="img"
          src={DocumentosContratistaImage}
          alt="Documentos Contratista"
          sx={imgStyle2}
        />
      </Box>
      <Box sx={textoImagenBox_left}>
        <Box
          component="img"
          src={FacturacionImage}
          alt="Facturacion"
          sx={imgStyle2}
        />
        <Box sx={titleBox2}>
          <Typography sx={titleStyle_left}>
            Reduce considerablemente tus tiempos de facturación con
            subcontratistas
          </Typography>
          <Typography sx={textStyle_left}>
            Al tener acceso directo a la documentación que cargan tus
            subcontratistas, estarás informado en tiempo real de cuando un
            subcontrato esté validado y listo para ser facturado.
          </Typography>
        </Box>
      </Box>
      {/* <Box sx={blueBox}>
        <Typography sx={textBlueStyle}>
          ¡Estas empresas ya confían en nosotros!
        </Typography>
        <Box sx={empresasBox}>
          <EmpresaIcon1 style={empresaIcon} />
          <EmpresaIcon2 style={empresaIcon} />
          <EmpresaIcon3 style={empresaIcon} />
          <EmpresaIcon2 style={empresaIcon} />
          <EmpresaIcon3 style={empresaIcon} />
          <EmpresaIcon1 style={empresaIcon} />
          <EmpresaIcon2 style={empresaIcon} />
        </Box>
      </Box> */}
      {/* <Box sx={cartasEmpresasBox}>
        <Box sx={cartaEmpresa}>
          <EmpresaIconColor1 />
          <Typography sx={textCartaEmpresa}>
            “Con VLK, logramos reducir nuestros tiempos de facturación en un
            50%, lo que ayudó a mejorar considerablemente la relación con
            nuestros subcontratistas”
          </Typography>
          <Box sx={avatarEmpresaBox}>
            <Box sx={avatarEmpresaCircle}>
              <Avatar sx={avatar} src={PersonaImage} alt="Persona" />
            </Box>
            <Box>
              <Typography sx={nameAvatarCard}>Rudolf Hartmann</Typography>
              <Typography sx={textAvatarCard}>
                CEO Contratistas soluciones SpA
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={cartaEmpresa}>
          <EmpresaIconColor2 />
          <Typography sx={textCartaEmpresa}>
            “Con VLK, logramos reducir nuestros tiempos de facturación en un
            50%, lo que ayudó a mejorar considerablemente la relación con
            nuestros subcontratistas”
          </Typography>
          <Box sx={avatarEmpresaBox}>
            <Box sx={avatarEmpresaCircle}>
              <Avatar sx={avatar} src={PersonaImage} alt="Persona" />
            </Box>
            <Box>
              <Typography sx={nameAvatarCard}>Rudolf Hartmann</Typography>
              <Typography sx={textAvatarCard}>
                CEO Contratistas soluciones SpA
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={cartaEmpresa}>
          <EmpresaIconColor3 />
          <Typography sx={textCartaEmpresa}>
            “Con VLK, logramos reducir nuestros tiempos de facturación en un
            50%, lo que ayudó a mejorar considerablemente la relación con
            nuestros subcontratistas”
          </Typography>
          <Box sx={avatarEmpresaBox}>
            <Box sx={avatarEmpresaCircle}>
              <Avatar sx={avatar} src={PersonaImage} alt="Persona" />
            </Box>
            <Box>
              <Typography sx={nameAvatarCard}>Rudolf Hartmann</Typography>
              <Typography sx={textAvatarCard}>
                CEO Contratistas soluciones SpA
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box> */}
      <Box sx={lastBox}>
        <Box sx={lastTextBox}>
          <Typography sx={lastTitle}>
            Comienza a administrar tus subcontratos de manera más eficiente
          </Typography>
          <Typography sx={lastText}>
            Optimiza tus procesos de facturación con nuestro sistema de
            validación automática de documentación de subcontratistas
          </Typography>
        </Box>
        <Box sx={signDemoBox}>
          <Box sx={textFieldBox}>
            <TextField
              sx={textField}
              fullWidth
              required
              id="nombre"
              label="Nombre completo"
              type="text"
              value={nombreCompleto}
              onChange={(e) =>
                handleNotEmpty(
                  e.target.value,
                  setNombreCompleto,
                  setErrorNombreCompleto,
                  "Escriba su nombre completo"
                )
              }
            />
            <FormHelperText error>{errorNombreCompleto}</FormHelperText>
          </Box>
          <Box sx={textFieldBox}>
            <TextField
              sx={textField}
              fullWidth
              required
              id="correo"
              label="Correo"
              type="text"
              value={correo}
              onChange={(e) => handleMailChange(e.target.value)}
            />
            <FormHelperText error>{errorCorreo}</FormHelperText>
          </Box>
          <Box sx={textFieldBox}>
            <TextField
              sx={textField}
              fullWidth
              required
              id="empresa"
              label="Empresa"
              type="text"
              value={empresa}
              onChange={(e) =>
                handleNotEmpty(
                  e.target.value,
                  setEmpresa,
                  setErrorEmpresa,
                  "Escriba el nombre de su empresa"
                )
              }
            />
            <FormHelperText error>{errorEmpresa}</FormHelperText>
          </Box>
          <Box sx={buttonBox}>
            {isLoading ? (
              <Box sx={loadingBox}>
                <LoadingRocket dialog={false} dark texto="" />
              </Box>
            ) : approved === true ? (
              <Box sx={loadingBox}>
                <SuccessIcon />
              </Box>
            ) : approved === false ? (
              <Box sx={loadingBox}>
                <ErrorIcon />
              </Box>
            ) : (
              <Button sx={buttonText2} variant="contained" onClick={handleDemo}>
                Quiero mi demo
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={footerBox}>
        <Box sx={copyRightBox}>
          <Box
            component="img"
            loading="lazy"
            sx={footerLogo}
            alt="imagen"
            src={logoRef.current}
          />
          <Typography sx={copyRight}>
            © <Typography sx={copyRight}>2024 Rocket Check</Typography>
          </Typography>
        </Box>
        {/* <Box sx={footerLinkBox}>
          <MuiLink sx={footerLink} underline="hover">
            Política de privacidad
          </MuiLink>
          <MuiLink sx={footerLink} underline="hover">
            Términos y Condiciones
          </MuiLink>
          <MuiLink sx={footerLink} underline="hover">
            Contáctanos
          </MuiLink>
        </Box> */}
      </Box>
    </Box>
  );
}
