import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  access: localStorage.getItem("access")
    ? localStorage.getItem("access")
    : null,
  refresh: localStorage.getItem("refresh")
    ? localStorage.getItem("refresh")
    : null,
  id: localStorage.getItem("id") ? localStorage.getItem("id") : null,
  user: localStorage.getItem("user") ? localStorage.getItem("user") : null,
  type: localStorage.getItem("type") ? localStorage.getItem("type") : null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      const { type, id, email } = action.payload;
      if (email === state.user) {
        state.type = type;
        state.id = id;
        localStorage.setItem("type", type);
        localStorage.setItem("id", id);
      }
    },
    setCredentials: (state, action) => {
      const { user, access, refresh } = action.payload;
      state.user = user;
      state.access = access;
      state.refresh = refresh;
      localStorage.setItem("access", access);
      localStorage.setItem("refresh", refresh);
      localStorage.setItem("user", user);
    },
    logOut: (state, action) => {
      state.user = null;
      state.id = null;
      state.type = null;
      state.access = null;
      state.refresh = null;
      localStorage.clear();
    },
  },
});

export const { setUser, setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentType = (state) => state.auth.type;
export const selectCurrentAccess = (state) => state.auth.access;
export const selectCurrentRefresh = (state) => state.auth.refresh;
