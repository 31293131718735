import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  ListItemButton,
  Card,
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  CircularProgress
} from "@mui/material";

/* ICONS */
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

/* CUSTOM LIBRARIES */
import DragDropFileList from "../common/components/dragAndDropList";

/* CONTROLLER VARIABLES */
import { emptyFileObj, transition } from "../../../controller/utils";
import {
  useLazyGetPlantillasEmpresaQuery,
  useLazyGetPlantillasTipoTrabajadorEmpresaQuery,
  useLazyGetPlantillasTipoTrabajadorQuery
} from "../../../api/plantillasApiSlice";

export default function SubirPlantillasTiposTrabajadores(props) {
  const {
    setContinueValue,
    uploadedFiles,
    setUploadedFiles,
    tipoTrabajadores,
    selectedEmpresa,
    opened
  } = props;

  const [triggerGetData, { isLoading, isFetching }] =
    useLazyGetPlantillasTipoTrabajadorEmpresaQuery();

  const [continueLocal, setContinueLocal] = useState(false);
  const [forward, setForward] = useState(true);
  const [back, setBack] = useState(false);
  const [index, setIndex] = useState(0);
  const [uploaded, setUploaded] = useState([]);
  const [uploadedFilesCopy, setUploadedFilesCopy] = useState(uploadedFiles);
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    if (opened) {
      handleGetPlantillasData();
    }
  }, [opened, index]);

  const handleGetPlantillasData = async () => {
    let id_empresa = selectedEmpresa.id;
    let id_tipo_trabajador = tipoTrabajadores[index].id;

    const { data, isSuccess } = await triggerGetData(
      { id_empresa, id_tipo_trabajador },
      false
    );
    if (isSuccess) {
      let newDocs = JSON.parse(JSON.stringify(data.data.tipo_documentos));
      // console.log("uploaded", data.data.plantillas);
      newDocs.map((tt) => (tt.acceptedFormats = ["application/pdf"]));
      setDocs(newDocs);
      setUploaded(data.data.plantillas);
      // Inicializa diccionario de documentos de tipos de trabajador
      let u_copy = [];
      for (let i = 0; i < tipoTrabajadores.length; i++) {
        // console.log(tipoTrabajadores[i].id);
        // console.log(!uploadedFilesCopy[`${tipoTrabajadores[i].id}`]);
        if (!uploadedFilesCopy[`${tipoTrabajadores[i].id}`]) {
          u_copy[`${tipoTrabajadores[i].id}`] = Array(docs.length).fill(
            emptyFileObj
          );
        } else {
          u_copy[`${tipoTrabajadores[i].id}`] =
            uploadedFilesCopy[`${tipoTrabajadores[i].id}`];
        }
      }
      // console.log("uploadedFilesCopy", u_copy);
      setUploadedFilesCopy(u_copy);
    }
  };

  // Guarda en el diccionario general los docs subidos para ese tipo de trabajador
  useEffect(() => {
    if (uploaded && uploaded.length > 0) {
      let u = uploadedFilesCopy;
      u[`${tipoTrabajadores[index].id}`] = uploaded;
      setUploadedFilesCopy(u);
      setContinueValue(
        continueLocal &&
          !Object.values(u).some((docsTipos) =>
            docsTipos.some((doc) => doc.name === null)
          )
      );
    }
  }, [uploaded, continueLocal]);

  useEffect(() => {
    if (Object.values(uploadedFilesCopy).length > 0) {
      setUploaded(uploadedFilesCopy[`${tipoTrabajadores[index].id}`]);
    }
  }, [index]);

  useEffect(() => {
    if (index === tipoTrabajadores.length - 1) {
      setForward(false);
    }
    if (index === 0) {
      setBack(false);
    }
    if (index > 0) {
      setBack(true);
    }
    if (index < tipoTrabajadores.length - 1) {
      setForward(true);
    }
  }, [index, tipoTrabajadores]);

  const onChangeView = (e, i) => {
    e.preventDefault();
    if (i >= 0 && i < tipoTrabajadores.length) {
      setIndex(i);
    }
  };

  const contenido = {
    transition: transition,
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    gap: "1vw"
  };
  const listaBox = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  };
  const titleStyle = {
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "120%"
    // color: "#1876D1",
  };
  const buttonStyle = {
    transition: transition,
    background: "#1876D1",
    color: "#FFFFFF",
    boxShadow: 5,
    ":hover": {
      background: "#1876D1",
      opacity: "0.95"
    },
    "&.Mui-disabled": {
      ":hover": {
        background: "#FFFFFF"
      }
    }
  };

  return tipoTrabajadores.length > 0 ? (
    <Box sx={contenido}>
      <Tooltip
        stepTitle="Trabajador anterior"
        placement="top-end"
        enterDelay={300}
        arrow
      >
        <IconButton
          disabled={!back}
          sx={buttonStyle}
          onClick={(e) => onChangeView(e, index - 1)}
        >
          <NavigateBeforeIcon />
        </IconButton>
      </Tooltip>
      <Box sx={listaBox}>
        <Typography sx={titleStyle}>Tipo de trabajador:</Typography>
        <Typography sx={titleStyle}>
          {tipoTrabajadores[index].nombre}
        </Typography>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <DragDropFileList
            list={docs}
            tipoTrabajador={tipoTrabajadores[index].id}
            empresa={selectedEmpresa.id}
            // setContinueValue={() => {}}
            setContinueValue={setContinueLocal}
            uploadedFiles={uploaded}
            setUploadedFiles={setUploaded}
            // multiple={false}
            maxCount={docs.length}
            memory
            type="plantillas"
          />
        )}
      </Box>
      <Tooltip
        stepTitle="Trabajador siguiente "
        placement="top-start"
        enterDelay={300}
        arrow
      >
        <IconButton
          disabled={!forward}
          sx={buttonStyle}
          onClick={(e) => onChangeView(e, index + 1)}
        >
          <NavigateNextIcon />
        </IconButton>
      </Tooltip>
    </Box>
  ) : null;
}
