import "./warningIcon.css";

export default function WarningIcon() {
  return (
    <div class="f-modal-alert">
      <div class="f-modal-icon f-modal-warning scaleWarning">
        <span class="f-modal-body pulseWarningIns"></span>
        <span class="f-modal-dot pulseWarningIns"></span>
      </div>
    </div>
  );
}
