import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { Button, Box } from "@mui/material";

/* ICONS */
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";

/* CUSTOM LIBRARIES */
import CustomTable from "../common/components/customTable";
import VerificacionModal from "./verificacionModal";
import CustomSelect from "../common/components/customSelect";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { transition } from "../../../controller/utils";
import {
  estadosVerificaciones,
  headCellsVerificaciones
} from "../../../controller/listas";
import { useSelector } from "react-redux";
import { selectCurrentType } from "../../../redux/authSlice";
import { useGetVerificacionesQuery } from "../../../api/verificacionesApiSlice";

/* TEST DATA */
import { listaMeses } from "../../../controller/testData";

export default function Verificaciones() {
  const { setSelectedVerificacion, selectedVerificacion } = useOutletContext();
  const userType = useSelector(selectCurrentType);
  const littleSize = littleSizeFunc();

  const { currentData, isSuccess, isLoading, isFetching } =
    useGetVerificacionesQuery(null, {
      keepUnusedDataFor: 3,
      pollingInterval: 3000,
      refetchOnMountOrArgChange: true
    });

  const [mes, setMes] = useState("");
  const [estado, setEstado] = useState("Abierto");
  const [empresa, setEmpresa] = useState("");
  const [filtroEmpresas, setFiltroEmpresas] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [listaVerificaciones, setListaVerificaciones] = useState([]);
  const [listaVerificacionesFiltrada, setListaVerificacionesFiltrada] =
    useState([]);
  const [lineaDocumentoSeleccionada, setLineaDocumentoSeleccionada] =
    useState("");

  // Set linea del boton clickeado
  useEffect(() => {
    if (selectedVerificacion && listaVerificacionesFiltrada.length > 0) {
      let linea = listaVerificacionesFiltrada.find(
        (element) => element.ticket_ayuda === selectedVerificacion
      );
      setLineaDocumentoSeleccionada(linea);
      setEstado("Ticket de ayuda");
      setOpenModal(true);
      setSelectedVerificacion("");
    }
  }, [listaVerificacionesFiltrada]);

  useEffect(() => {
    if (isSuccess && currentData) {
      setListaVerificaciones([...currentData?.data]);
      setListaVerificacionesFiltrada([...currentData?.data]);
      var lista_empresa = [];
      currentData.data.map((t, i) => {
        lista_empresa.push({ id: t.nombre_empresa, value: t.nombre_empresa });
      });
      let jsonObject = lista_empresa.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      lista_empresa = Array.from(uniqueSet).map(JSON.parse);
      setFiltroEmpresas(lista_empresa);
    }
  }, [currentData, isSuccess, isFetching]);

  // Filtros
  useEffect(() => {
    if (isSuccess && currentData) {
      if (
        listaVerificaciones.length > 0 ||
        listaVerificacionesFiltrada === currentData.data
      ) {
        let listaVer =
          listaVerificaciones.lenght > listaVerificacionesFiltrada.lenght
            ? [...listaVerificacionesFiltrada]
            : [...listaVerificaciones];
        if (empresa) {
          listaVer = listaVer
            .map((verificacionObj) => {
              if (verificacionObj.nombre_empresa === empresa) {
                return verificacionObj;
              }
              return null;
            })
            .filter((verificacionObj) => verificacionObj !== null);
          setListaVerificacionesFiltrada(listaVer);
        }
        if (mes) {
          listaVer = listaVer
            .map((verificacionObj) => {
              if (
                parseInt(verificacionObj.fecha_creacion.split("-")[1]) === mes
              ) {
                return verificacionObj;
              }
              return null;
            })
            .filter((verificacionObj) => verificacionObj !== null);
          setListaVerificacionesFiltrada(listaVer);
        }
        if (estado) {
          listaVer = listaVer
            .map((verificacionObj) => {
              let re = new RegExp(`${estado}`, "gi");
              if (re.test(verificacionObj.estado)) {
                return verificacionObj;
              }
              return null;
            })
            .filter((verificacionObj) => verificacionObj !== null);
          setListaVerificacionesFiltrada(listaVer);
        } else {
          setListaVerificacionesFiltrada(listaVer);
        }
      }
    }
  }, [mes, empresa, estado, currentData, isFetching, isSuccess]);

  const handleLimpiaFiltros = () => {
    setMes("");
    setEmpresa("");
    setEstado("");
  };

  const contenido = {
    minHeight: "58vh",
    height: "fit-content",
    maxHeight: "84vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "5.17vh",
    transition: transition
  };
  const barraFiltros = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap-reverse",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: littleSize ? "center" : "start",
    gap: "11px"
  };
  const formControl = {
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    width: "16vw",
    minWidth: "210px"
  };
  const filtrosBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap-reverse",
    alignItems: "center",
    gap: "11px"
  };
  const buttonLimparFiltros = {
    height: "fit-content",
    width: "fit-content",
    transition: transition,
    textTransform: "none ",
    borderRadius: "12px",
    background: "#1876D1",
    color: "#FCFBFB",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "12px",
    gap: "5px"
  };

  return (
    <Box sx={contenido}>
      <Box sx={barraFiltros}>
        {empresa || mes || estado ? (
          <Box sx={formControl}>
            <Button
              onClick={handleLimpiaFiltros}
              disableElevation={true}
              variant="contained"
              sx={buttonLimparFiltros}
            >
              <FilterListOffOutlinedIcon />
              Restablecer filtros
            </Button>
          </Box>
        ) : null}
        <Box sx={filtrosBox}>
          <Box sx={formControl}>
            <CustomSelect
              label="Filtrar por estado"
              value={estado}
              setValue={setEstado}
            >
              {estadosVerificaciones}
            </CustomSelect>
          </Box>
          <Box sx={formControl}>
            <CustomSelect label="Filtrar por mes" value={mes} setValue={setMes}>
              {listaMeses}
            </CustomSelect>
          </Box>
          <Box sx={formControl}>
            <CustomSelect
              label="Filtrar por empresa"
              value={empresa}
              setValue={setEmpresa}
            >
              {filtroEmpresas}
            </CustomSelect>
          </Box>
        </Box>
      </Box>
      <CustomTable
        headCells={headCellsVerificaciones}
        rows={listaVerificacionesFiltrada}
        setRows={setListaVerificacionesFiltrada}
        variant="verificaciones"
        setLineaSeleccionada={setLineaDocumentoSeleccionada}
        setOpenVerificacionModal={setOpenModal}
        isLoading={isLoading}
      />
      <VerificacionModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        lineaDocumentoSeleccionada={lineaDocumentoSeleccionada}
      />
    </Box>
  );
}
