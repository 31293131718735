import { useState, forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent } from "notistack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import MuiLink from "@mui/material/Link";

const NotificationSnack = forwardRef(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const card = {
    position: "relative",
    minWidth: "344px !important",
    width: "26.875vw",
    maxWidth: "387px",
    padding: "8px 32px 8px 8px",
    backgroundColor: "#FCFCFC"
  };
  const actionRoot = {
    display: "flex",
    justifyContent: "space-between",
    gap: "11px"
  };
  const typography = {
    fontFamily: "Poppins", // necesario
    color: "#818181",
    textAlign: "justify",
    fontSize: "16px",
    fontWeight: "400"
  };
  const notificationIcon = {
    height: "40px",
    width: "40px",
    color: "#1876D1"
  };
  const iconBox = {
    position: "absolute",
    top: "8px",
    right: "8px"
  };
  const closeButton = {
    color: "#818181"
  };
  const linkText = {
    fontFamily: "Poppins",
    color: "#1876D1",
    fontSize: "16px",
    fontWeight: "600",
    "&:hover": {
      cursor: "pointer"
    }
  };

  const handleDismiss = useCallback(() => {
    props.atClosing();
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const handleNotification = useCallback(() => {
    props.atClosing();
    props.action();
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent props={props} ref={ref}>
      <Card sx={card}>
        <CardActions sx={actionRoot}>
          <NotificationsNoneOutlinedIcon sx={notificationIcon} />
          <Typography variant="body2" sx={typography}>
            {props.message}
            {props.url ? (
              <MuiLink
                sx={linkText}
                component={MuiLink}
                underline="none"
                onClick={handleNotification}
              >
                Revísa aquí
              </MuiLink>
            ) : null}
          </Typography>
          <Box sx={iconBox}>
            <IconButton size="small" sx={closeButton} onClick={handleDismiss}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

export default NotificationSnack;
