import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { Button, Box } from "@mui/material";

/* ICONS */
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";

/* CUSTOM LIBRARIES */
import CustomTable from "../common/components/customTable";
import CustomSelect from "../common/components/customSelect";
import TicketModal from "./ticketModal";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { POLLING_INTERVAL, transition } from "../../../controller/utils";
import {
  headCellsTickets,
  listaMotivos,
  listaTiposContacto
} from "../../../controller/listas";
import { useSelector } from "react-redux";
import { selectCurrentType } from "../../../redux/authSlice";
import { useGetTicketsQuery } from "../../../api/ticketsApiSlice";

/* TEST DATA */
import { listaMeses } from "../../../controller/testData";

export default function TicketsAyuda() {
  const { setOpenSupp, selectedTicket } = useOutletContext();
  const userType = useSelector(selectCurrentType);
  const littleSize = littleSizeFunc();

  const { currentData, isSuccess, isLoading, isFetching } = useGetTicketsQuery(
    null,
    { pollingInterval: POLLING_INTERVAL }
  );

  const [mes, setMes] = useState("");
  const [motivo, setMotivo] = useState("");
  const [tipoContacto, setTipoContacto] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [listaTickets, setListaTickets] = useState([]);
  const [listaTicketsFiltrada, setListaTicketsFiltrada] = useState([]);
  const [lineaTicketSeleccionado, setLineaTicketSeleccionado] = useState("");

  // Set linea del boton clickeado
  useEffect(() => {
    if (selectedTicket) {
      setLineaTicketSeleccionado(
        listaTicketsFiltrada.find((element) => element.id === selectedTicket)
      );
      setOpenModal(true);
    }
  }, []);

  useEffect(() => {
    if (isSuccess && currentData) {
      setListaTickets([...currentData?.data]);
      setListaTicketsFiltrada([...currentData?.data]);
    }
  }, [currentData, isSuccess, isFetching]);

  // Filtros
  useEffect(() => {
    if (isSuccess && currentData) {
      if (
        listaTickets.length > 0 ||
        listaTicketsFiltrada === currentData.data
      ) {
        let listaTick =
          listaTickets.lenght > listaTicketsFiltrada.lenght
            ? [...listaTicketsFiltrada]
            : [...listaTickets];
        if (motivo) {
          listaTick = listaTick
            .map((ticketObj) => {
              if (ticketObj.motivo === motivo) {
                return ticketObj;
              }
              return null;
            })
            .filter((ticketObj) => ticketObj !== null);
          setListaTicketsFiltrada(listaTick);
        }
        if (mes) {
          listaTick = listaTick
            .map((ticketObj) => {
              if (parseInt(ticketObj.fecha_creacion.split("-")[1]) === mes) {
                return ticketObj;
              }
              return null;
            })
            .filter((ticketObj) => ticketObj !== null);
          setListaTicketsFiltrada(listaTick);
        }
        if (tipoContacto) {
          listaTick = listaTick
            .map((ticketObj) => {
              if (ticketObj.tipo_contacto === tipoContacto) {
                return ticketObj;
              }
              return null;
            })
            .filter((ticketObj) => ticketObj !== null);
          setListaTicketsFiltrada(listaTick);
        } else {
          setListaTicketsFiltrada(listaTick);
        }
      }
    }
  }, [mes, motivo, tipoContacto, currentData, isFetching, isSuccess]);

  const handleLimpiaFiltros = () => {
    setMes("");
    setMotivo("");
    setTipoContacto("");
  };

  const contenido = {
    minHeight: "58vh",
    height: "fit-content",
    maxHeight: "84vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "5.17vh",
    transition: transition
  };
  const barraFiltros = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap-reverse",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: littleSize ? "center" : "start",
    gap: "11px"
  };
  const formControl = {
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    width: "16vw",
    minWidth: "210px"
  };
  const filtrosBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap-reverse",
    alignItems: "center",
    gap: "11px"
  };
  const buttonNuevoTicket = {
    height: "40px",
    textTransform: "none",
    minHeight: "100%",
    fontWeight: 600,
    color: "#FCFBFB",
    backgroundColor: "#1876D1",
    borderRadius: "10px",
    padding: "5px 20px"
  };
  const buttonLimparFiltros = {
    height: "fit-content",
    width: "fit-content",
    transition: transition,
    textTransform: "none ",
    borderRadius: "12px",
    background: "#1876D1",
    color: "#FCFBFB",
    fontWeight: 800,
    fontSize: "12px",
    gap: "5px"
  };

  return (
    <Box sx={contenido}>
      <Box sx={barraFiltros}>
        {motivo || mes || tipoContacto ? (
          <Box sx={formControl}>
            <Button
              onClick={handleLimpiaFiltros}
              disableElevation={true}
              variant="contained"
              sx={buttonLimparFiltros}
            >
              <FilterListOffOutlinedIcon />
              Restablecer filtros
            </Button>
          </Box>
        ) : null}
        <Box sx={filtrosBox}>
          <Box sx={formControl}>
            <CustomSelect label="Filtrar por mes" value={mes} setValue={setMes}>
              {listaMeses}
            </CustomSelect>
          </Box>
          <Box sx={formControl}>
            <CustomSelect
              label="Filtrar por motivo"
              value={motivo}
              setValue={setMotivo}
            >
              {listaMotivos}
            </CustomSelect>
          </Box>
          <Box sx={formControl}>
            <CustomSelect
              label="Filtrar por tipo de contacto"
              value={tipoContacto}
              setValue={setTipoContacto}
            >
              {listaTiposContacto}
            </CustomSelect>
          </Box>
        </Box>
        {userType === "Supp" || userType === "Admin" ? null : (
          <Button
            variant="contained"
            sx={buttonNuevoTicket}
            onClick={(e) => setOpenSupp(true)}
          >
            Nuevo ticket de ayuda
          </Button>
        )}
      </Box>
      <CustomTable
        headCells={headCellsTickets}
        rows={listaTicketsFiltrada}
        setRows={setListaTicketsFiltrada}
        variant="tickets"
        setLineaSeleccionada={setLineaTicketSeleccionado}
        setOpenTicketModal={setOpenModal}
        isLoading={isLoading}
      />
      <TicketModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        lineaTicket={lineaTicketSeleccionado}
      />
    </Box>
  );
}
