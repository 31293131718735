import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import ForestOutlinedIcon from "@mui/icons-material/ForestOutlined";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import DirectionsBoatOutlinedIcon from "@mui/icons-material/DirectionsBoatOutlined";
import WavesIcon from "@mui/icons-material/Waves";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";

export const listaTiposTrabajador = [
  {
    id: 1,
    title: "Administrativos en faena",
    icon: <EngineeringOutlinedIcon />,
    documentos: [
      { title: "Contrato laboral", acceptedFormats: ["application/pdf"] },
      { title: "Obligacion de Informar", acceptedFormats: ["application/pdf"] },
      {
        title: "Equipos de Proteccion Personal",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Reglamento Interno de Orden, Higiene y Seguridad",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Anexo de contrato Teletrabajo",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Compromiso de confidencialidad",
        acceptedFormats: ["application/pdf"]
      }
    ]
  },
  {
    id: 2,
    title: "Operarios grúas y embarcaciones",
    icon: <DirectionsBoatOutlinedIcon />,
    documentos: [
      { title: "Contrato laboral", acceptedFormats: ["application/pdf"] },
      { title: "Obligacion de Informar", acceptedFormats: ["application/pdf"] },
      {
        title: "Equipos de Proteccion Personal",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Reglamento Interno de Orden, Higiene y Seguridad",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Licencia de conducir D",
        acceptedFormats: ["application/pdf", "image/png", "image/jpg"]
      }
    ]
  },
  {
    id: 3,
    title: "Piscicultura",
    icon: <WavesIcon />,
    documentos: [
      { title: "Contrato laboral", acceptedFormats: ["application/pdf"] },
      { title: "Obligacion de Informar", acceptedFormats: ["application/pdf"] },
      {
        title: "Equipos de Proteccion Personal",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Reglamento Interno de Orden, Higiene y Seguridad",
        acceptedFormats: ["application/pdf"]
      }
    ]
  },
  {
    id: 4,
    title: "Operarios grúas, camiones y otros",
    icon: <PrecisionManufacturingOutlinedIcon />,
    documentos: [
      { title: "Contrato laboral", acceptedFormats: ["application/pdf"] },
      { title: "Obligacion de Informar", acceptedFormats: ["application/pdf"] },
      {
        title: "Equipos de Proteccion Personal",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Reglamento Interno de Orden, Higiene y Seguridad",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Licencia de conducir D",
        acceptedFormats: ["application/pdf", "image/png", "image/jpg"]
      }
    ]
  },
  {
    id: 5,
    title: "Forestal",
    icon: <ForestOutlinedIcon />,
    documentos: [
      { title: "Contrato laboral", acceptedFormats: ["application/pdf"] },
      { title: "Obligacion de Informar", acceptedFormats: ["application/pdf"] },
      {
        title: "Equipos de Proteccion Personal",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Reglamento Interno de Orden, Higiene y Seguridad",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Compromiso de confidencialidad",
        acceptedFormats: ["application/pdf"]
      }
    ]
  },
  {
    id: 6,
    title: "Servicios bancarios",
    icon: <AccountBalanceOutlinedIcon />,
    documentos: [
      { title: "Contrato laboral", acceptedFormats: ["application/pdf"] },
      { title: "Obligacion de Informar", acceptedFormats: ["application/pdf"] },
      {
        title: "Equipos de Proteccion Personal",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Reglamento Interno de Orden, Higiene y Seguridad",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Compromiso de confidencialidad",
        acceptedFormats: ["application/pdf"]
      }
    ]
  },
  {
    id: 7,
    title: "Servicios mutualidad",
    icon: <BusinessCenterOutlinedIcon />,
    documentos: [
      { title: "Contrato laboral", acceptedFormats: ["application/pdf"] },
      { title: "Obligacion de Informar", acceptedFormats: ["application/pdf"] },
      {
        title: "Equipos de Proteccion Personal",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Reglamento Interno de Orden, Higiene y Seguridad",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Anexo de contrato Teletrabajo",
        acceptedFormats: ["application/pdf"]
      },
      {
        title: "Compromiso de confidencialidad",
        acceptedFormats: ["application/pdf"]
      }
    ]
  }
];

export const listaTiposDocumentos = [
  {
    title: "Contrato laboral",
    abreviacion: "Contrato"
  },
  {
    title: "Obligacion de Informar",
    abreviacion: "ODI"
  },
  {
    title: "Equipos de Proteccion Personal",
    abreviacion: "EPP"
  },
  {
    title: "Reglamento Interno de Orden, Higiene y Seguridad",
    abreviacion: "RIOHS"
  },
  {
    title: "Anexo de contrato Teletrabajo",
    abreviacion: "Teletrabajo"
  },
  {
    title: "Compromiso de confidencialidad",
    abreviacion: "Confidencialidad"
  },
  {
    title: "Protocolo de seguridad sanitaria laboral COVID-19",
    abreviacion: "COVID"
  },
  {
    title: "Licencia de conducir A1",
    abreviacion: "Licencia A1"
  },
  {
    title: "Licencia de conducir A2",
    abreviacion: "Licencia A2"
  },
  {
    title: "Licencia de conducir A3",
    abreviacion: "Licencia A3"
  },
  {
    title: "Licencia de conducir A4",
    abreviacion: "Licencia A4"
  },
  {
    title: "Licencia de conducir A5",
    abreviacion: "Licencia A5"
  },
  {
    title: "Licencia de conducir D",
    abreviacion: "Licencia D"
  },
  {
    title: "Licencia de conducir E",
    abreviacion: "Licencia E"
  },
  {
    title: "Licencia de conducir F",
    abreviacion: "Licencia F"
  }
];

export const tabla_lista_clientes = {
  headCells: [
    {
      id: "cliente",
      label: "Cliente"
    },
    {
      id: "faena",
      label: "Área/Faena"
    },
    {
      id: "sinSubir",
      label: "Sin subir"
    },
    {
      id: "porRevisar",
      label: "Por revisar"
    },
    {
      id: "aprobados",
      label: "Aprobados"
    },
    {
      id: "rechazados",
      label: "Rechazados"
    },
    {
      id: "anulados",
      label: "Anulados"
    }
  ],
  list: [
    {
      id: "72585486-K",
      value: "Falabella",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 100,
      inactivos: 500
    },
    {
      id: "79225289-1",
      value: "Ripley",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 1000,
      inactivos: 700
    },
    {
      id: "78575126-9",
      value: "BHP",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 800,
      inactivos: 100
    },
    {
      id: "71236485-2",
      value: "Minera escondida",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 500,
      inactivos: 20
    },
    {
      id: "70325141-0",
      value: "Cencosud",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 300,
      inactivos: 120
    },
    {
      id: "70525121-1",
      value: "PCFactory",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 200,
      inactivos: 150
    },
    {
      id: "72245131-2",
      value: "Samsung",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 440,
      inactivos: 420
    },
    {
      id: "70654142-1",
      value: "MSI",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 320,
      inactivos: 12
    },
    {
      id: "79322191-9",
      value: "Pernos Osorno",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 402,
      inactivos: 30
    },
    {
      id: "79329151-3",
      value: "Chilterra",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 335,
      inactivos: 121
    },
    {
      id: "70325541-5",
      value: "Colun",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 660,
      inactivos: 220
    },
    {
      id: "73323148-2",
      value: "ENAER",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 340,
      inactivos: 20
    },
    {
      id: "71825181-8",
      value: "Latam",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 2000,
      inactivos: 20
    }
  ]
};

export const tabla_lista_subcontratistas = {
  headCells: [
    {
      id: "value",
      label: "Subcontratista"
    },
    {
      id: "inicio",
      label: "Inicio contrato"
    },
    {
      id: "fin",
      label: "Fin contrato"
    },
    {
      id: "activos",
      label: "Trabajadores activos"
    },
    {
      id: "inactivos",
      label: "Trabajadores inactivos"
    },
    {
      id: "acciones",
      label: "Acciones"
    }
  ],
  list: [
    {
      id: "72585486-K",
      value: "Falabella",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 100,
      inactivos: 500,
      documentos: [
        {
          id: "Pago de mutualidades",
          documento: "Pago de mutualidades",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Procedimiento de trabajo seguro",
          documento: "Procedimiento de trabajo seguro",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Libro de asistencia",
          documento: "Libro de asistencia",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Contrato comercial",
          documento: "Contrato comercial",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Política de seguridad",
          documento: "Política de seguridad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Resolución F43",
          documento: "Resolución F43",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Plan de emergencia",
          documento: "Plan de emergencia",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Reglamento interno de orden, higiene y seguridad",
          documento: "Reglamento interno de orden, higiene y seguridad",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Matriz IPER",
          documento: "Matriz IPER",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Programas de prevención de riesgos",
          documento: "Programas de prevención de riesgos",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Cronograma mensual  de actividades",
          documento: "Cronograma mensual  de actividades",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Protocolo de seguridad laboral COVID 19",
          documento: "Protocolo de seguridad laboral COVID 19",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      id: "79225289-1",
      value: "Ripley",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 1000,
      inactivos: 700,
      documentos: [
        {
          id: "Pago de mutualidades",
          documento: "Pago de mutualidades",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Procedimiento de trabajo seguro",
          documento: "Procedimiento de trabajo seguro",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Libro de asistencia",
          documento: "Libro de asistencia",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Contrato comercial",
          documento: "Contrato comercial",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Política de seguridad",
          documento: "Política de seguridad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Resolución F43",
          documento: "Resolución F43",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Plan de emergencia",
          documento: "Plan de emergencia",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Reglamento interno de orden, higiene y seguridad",
          documento: "Reglamento interno de orden, higiene y seguridad",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Matriz IPER",
          documento: "Matriz IPER",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Programas de prevención de riesgos",
          documento: "Programas de prevención de riesgos",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Cronograma mensual  de actividades",
          documento: "Cronograma mensual  de actividades",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Protocolo de seguridad laboral COVID 19",
          documento: "Protocolo de seguridad laboral COVID 19",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      id: "78575126-9",
      value: "BHP",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 800,
      inactivos: 100,
      documentos: [
        {
          id: "Pago de mutualidades",
          documento: "Pago de mutualidades",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Procedimiento de trabajo seguro",
          documento: "Procedimiento de trabajo seguro",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Libro de asistencia",
          documento: "Libro de asistencia",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Contrato comercial",
          documento: "Contrato comercial",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Política de seguridad",
          documento: "Política de seguridad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Resolución F43",
          documento: "Resolución F43",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Plan de emergencia",
          documento: "Plan de emergencia",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Reglamento interno de orden, higiene y seguridad",
          documento: "Reglamento interno de orden, higiene y seguridad",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Matriz IPER",
          documento: "Matriz IPER",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Programas de prevención de riesgos",
          documento: "Programas de prevención de riesgos",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Cronograma mensual  de actividades",
          documento: "Cronograma mensual  de actividades",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Protocolo de seguridad laboral COVID 19",
          documento: "Protocolo de seguridad laboral COVID 19",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      id: "71236485-2",
      value: "Minera escondida",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 500,
      inactivos: 20,
      documentos: [
        {
          id: "Pago de mutualidades",
          documento: "Pago de mutualidades",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Procedimiento de trabajo seguro",
          documento: "Procedimiento de trabajo seguro",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Libro de asistencia",
          documento: "Libro de asistencia",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Contrato comercial",
          documento: "Contrato comercial",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Política de seguridad",
          documento: "Política de seguridad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Resolución F43",
          documento: "Resolución F43",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Plan de emergencia",
          documento: "Plan de emergencia",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Reglamento interno de orden, higiene y seguridad",
          documento: "Reglamento interno de orden, higiene y seguridad",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Matriz IPER",
          documento: "Matriz IPER",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Programas de prevención de riesgos",
          documento: "Programas de prevención de riesgos",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Cronograma mensual  de actividades",
          documento: "Cronograma mensual  de actividades",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Protocolo de seguridad laboral COVID 19",
          documento: "Protocolo de seguridad laboral COVID 19",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      id: "70325141-0",
      value: "Cencosud",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 300,
      inactivos: 120,
      documentos: [
        {
          id: "Pago de mutualidades",
          documento: "Pago de mutualidades",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Procedimiento de trabajo seguro",
          documento: "Procedimiento de trabajo seguro",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Libro de asistencia",
          documento: "Libro de asistencia",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Contrato comercial",
          documento: "Contrato comercial",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Política de seguridad",
          documento: "Política de seguridad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Resolución F43",
          documento: "Resolución F43",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Plan de emergencia",
          documento: "Plan de emergencia",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Reglamento interno de orden, higiene y seguridad",
          documento: "Reglamento interno de orden, higiene y seguridad",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Matriz IPER",
          documento: "Matriz IPER",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Programas de prevención de riesgos",
          documento: "Programas de prevención de riesgos",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Cronograma mensual  de actividades",
          documento: "Cronograma mensual  de actividades",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Protocolo de seguridad laboral COVID 19",
          documento: "Protocolo de seguridad laboral COVID 19",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      id: "70525121-1",
      value: "PCFactory",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 200,
      inactivos: 150
    },
    {
      id: "72245131-2",
      value: "Samsung",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 440,
      inactivos: 420,
      documentos: [
        {
          id: "Pago de mutualidades",
          documento: "Pago de mutualidades",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Procedimiento de trabajo seguro",
          documento: "Procedimiento de trabajo seguro",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Libro de asistencia",
          documento: "Libro de asistencia",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Contrato comercial",
          documento: "Contrato comercial",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Política de seguridad",
          documento: "Política de seguridad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Resolución F43",
          documento: "Resolución F43",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Plan de emergencia",
          documento: "Plan de emergencia",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Reglamento interno de orden, higiene y seguridad",
          documento: "Reglamento interno de orden, higiene y seguridad",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Matriz IPER",
          documento: "Matriz IPER",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Programas de prevención de riesgos",
          documento: "Programas de prevención de riesgos",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Cronograma mensual  de actividades",
          documento: "Cronograma mensual  de actividades",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Protocolo de seguridad laboral COVID 19",
          documento: "Protocolo de seguridad laboral COVID 19",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      id: "70654142-1",
      value: "MSI",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 320,
      inactivos: 12
    },
    {
      id: "79322191-9",
      value: "Pernos Osorno",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 402,
      inactivos: 30,
      documentos: [
        {
          id: "Pago de mutualidades",
          documento: "Pago de mutualidades",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Procedimiento de trabajo seguro",
          documento: "Procedimiento de trabajo seguro",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Libro de asistencia",
          documento: "Libro de asistencia",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Contrato comercial",
          documento: "Contrato comercial",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Política de seguridad",
          documento: "Política de seguridad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Resolución F43",
          documento: "Resolución F43",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Plan de emergencia",
          documento: "Plan de emergencia",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Reglamento interno de orden, higiene y seguridad",
          documento: "Reglamento interno de orden, higiene y seguridad",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Matriz IPER",
          documento: "Matriz IPER",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Programas de prevención de riesgos",
          documento: "Programas de prevención de riesgos",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Cronograma mensual  de actividades",
          documento: "Cronograma mensual  de actividades",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Protocolo de seguridad laboral COVID 19",
          documento: "Protocolo de seguridad laboral COVID 19",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      id: "79329151-3",
      value: "Chilterra",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 335,
      inactivos: 121,
      documentos: [
        {
          id: "Pago de mutualidades",
          documento: "Pago de mutualidades",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Procedimiento de trabajo seguro",
          documento: "Procedimiento de trabajo seguro",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Libro de asistencia",
          documento: "Libro de asistencia",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Contrato comercial",
          documento: "Contrato comercial",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Política de seguridad",
          documento: "Política de seguridad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Resolución F43",
          documento: "Resolución F43",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Plan de emergencia",
          documento: "Plan de emergencia",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Reglamento interno de orden, higiene y seguridad",
          documento: "Reglamento interno de orden, higiene y seguridad",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Matriz IPER",
          documento: "Matriz IPER",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Programas de prevención de riesgos",
          documento: "Programas de prevención de riesgos",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Cronograma mensual  de actividades",
          documento: "Cronograma mensual  de actividades",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Protocolo de seguridad laboral COVID 19",
          documento: "Protocolo de seguridad laboral COVID 19",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      id: "70325541-5",
      value: "Colun",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 660,
      inactivos: 220
    },
    {
      id: "73323148-2",
      value: "ENAER",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 340,
      inactivos: 20,
      documentos: [
        {
          id: "Pago de mutualidades",
          documento: "Pago de mutualidades",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Procedimiento de trabajo seguro",
          documento: "Procedimiento de trabajo seguro",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Libro de asistencia",
          documento: "Libro de asistencia",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Contrato comercial",
          documento: "Contrato comercial",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Política de seguridad",
          documento: "Política de seguridad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Resolución F43",
          documento: "Resolución F43",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Plan de emergencia",
          documento: "Plan de emergencia",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Reglamento interno de orden, higiene y seguridad",
          documento: "Reglamento interno de orden, higiene y seguridad",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Matriz IPER",
          documento: "Matriz IPER",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Programas de prevención de riesgos",
          documento: "Programas de prevención de riesgos",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Cronograma mensual  de actividades",
          documento: "Cronograma mensual  de actividades",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Protocolo de seguridad laboral COVID 19",
          documento: "Protocolo de seguridad laboral COVID 19",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      id: "71825181-8",
      value: "Latam",
      inicio: "2022-08-04",
      fin: "2024-09-01",
      activos: 2000,
      inactivos: 20,
      documentos: [
        {
          id: "Pago de mutualidades",
          documento: "Pago de mutualidades",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Procedimiento de trabajo seguro",
          documento: "Procedimiento de trabajo seguro",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Libro de asistencia",
          documento: "Libro de asistencia",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Contrato comercial",
          documento: "Contrato comercial",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Política de seguridad",
          documento: "Política de seguridad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Resolución F43",
          documento: "Resolución F43",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Plan de emergencia",
          documento: "Plan de emergencia",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Reglamento interno de orden, higiene y seguridad",
          documento: "Reglamento interno de orden, higiene y seguridad",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Matriz IPER",
          documento: "Matriz IPER",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Programas de prevención de riesgos",
          documento: "Programas de prevención de riesgos",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Cronograma mensual  de actividades",
          documento: "Cronograma mensual  de actividades",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Protocolo de seguridad laboral COVID 19",
          documento: "Protocolo de seguridad laboral COVID 19",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    }
  ]
};

export const tabla_lista_facturacion = {
  headCells: [
    {
      id: "faena",
      label: "Área/Faena"
    },
    {
      id: "value",
      label: "Subcontratista"
    },
    {
      id: "fecha",
      label: "Mes servicio"
    },
    {
      id: "sinSubir",
      label: "Sin subir"
    },
    {
      id: "aprobados",
      label: "Aprobados"
    },
    {
      id: "estado",
      label: "Estado"
    },
    {
      id: "acciones",
      label: "Acciones"
    }
  ],
  lista: [
    {
      id: "Falabella",
      value: "Falabella",
      faena: "Faena 1",
      fecha: "2023-03-06",
      sinSubir: 123,
      aprobados: 214,
      estado: "Facturar"
    },
    {
      id: "PCFactory",
      value: "PCFactory",
      faena: "Faena 3",
      fecha: "2023-02-06",
      sinSubir: 11,
      aprobados: 400,
      estado: "Rechazado"
    },
    {
      id: "BHP",
      value: "BHP",
      faena: "Faena 4",
      fecha: "2023-04-11",
      sinSubir: 40,
      aprobados: 145,
      estado: "Facturar"
    },
    {
      id: "Pernos Osorno",
      value: "Pernos Osorno",
      faena: "Faena 2",
      fecha: "2023-06-30",
      sinSubir: 99,
      aprobados: 599,
      estado: "En proceso"
    },
    {
      id: "Enaer",
      value: "Enaer",
      faena: "Faena 7",
      fecha: "2023-05-16",
      sinSubir: 22,
      aprobados: 234,
      estado: "Facturar"
    },
    {
      id: "Subcontratista X",
      value: "Subcontratista X",
      faena: "Faena 9",
      fecha: "2023-03-23",
      sinSubir: 80,
      aprobados: 321,
      estado: "Rechazado"
    },
    {
      id: "Subcontratista Y",
      value: "Subcontratista Y",
      faena: "Faena 1",
      fecha: "2023-03-06",
      sinSubir: 123,
      aprobados: 214,
      estado: "En proceso"
    },
    {
      id: "Ripley",
      value: "Ripley",
      faena: "Faena 1",
      fecha: "2023-03-06",
      sinSubir: 123,
      aprobados: 214,
      estado: "En proceso"
    },
    {
      id: "Paris",
      value: "Paris",
      faena: "Faena 4",
      fecha: "2023-02-06",
      sinSubir: 45,
      aprobados: 2222,
      estado: "Facturar"
    }
  ]
};

export const tabla_verificaciones = {
  headCells: [
    {
      id: "documento",
      label: "Documento a verificar"
    },
    {
      id: "empresa",
      label: "Empresa"
    },
    {
      id: "trabajador",
      label: "Trabajador"
    },
    {
      id: "fecha_creacion",
      label: "Fecha solicitud"
    },
    {
      id: "estado",
      label: "Estado"
    },
    {
      id: "acciones",
      label: "Acciones"
    }
  ],
  list: [
    {
      id: 123123,
      documentos: "Licencia tipo D",
      empresaID: "72585486-K",
      empresa: "Falabella",
      trabajador: "Juanito Perez",
      fecha: "2023-08-16",
      estado: "Ticket de ayuda"
    },
    {
      id: 1238,
      documentos: "Licencia tipo D",
      empresaID: "72585486-K",
      empresa: "Falabella",
      trabajador: "Juanito Perez",
      fecha: "2023-08-16",
      estado: "Resuelto"
    },
    {
      id: 123723,
      documentos: "Licencia tipo D",
      empresaID: "73323148-2",
      empresa: "ENAER",
      trabajador: "Juanito Perez",
      fecha: "2022-08-13",
      estado: "Abierto"
    },
    {
      id: 54323,
      documentos: "Licencia tipo D",
      empresaID: "78575126-9",
      empresa: "BHP",
      trabajador: "Juanito Perez",
      fecha: "2023-08-23",
      estado: "Resuelto"
    },
    {
      id: 23,
      documentos: "Contrato laboral",
      empresaID: "79225289-1",
      empresa: "Ripley",
      trabajador: "Pedro Perez",
      fecha: "2023-07-22",
      estado: "Resuelto"
    },
    {
      id: 3123,
      documentos: "Licencia tipo D",
      empresaID: "72585486-K",
      empresa: "Falabella",
      trabajador: "Juanito Perez",
      fecha: "2023-06-23",
      estado: "Abierto"
    },
    {
      id: 183123,
      documentos: "Licencia tipo D",
      empresaID: "72585486-K",
      empresa: "Falabella",
      trabajador: "Juanito Perez",
      fecha: "2023-08-23",
      estado: "Abierto"
    },
    {
      id: 173123,
      documentos: "ODI",
      empresaID: "79322191-9",
      empresa: "Pernos Osorno",
      trabajador: "Juanito Perez",
      fecha: "2023-08-23",
      estado: "Resuelto"
    },
    {
      id: 12123,
      documentos: "Licencia tipo D",
      empresaID: "72585486-K",
      empresa: "Falabella",
      trabajador: "Juanito Perez",
      fecha: "2023-07-23",
      estado: "Abierto"
    }
  ]
};

export const tabla_problemas = {
  headCells: [
    {
      id: "problema",
      label: "Problema"
    },
    {
      id: "nombre",
      label: "Nombre"
    },
    {
      id: "rut",
      label: "RUT"
    },
    {
      id: "documento",
      label: "Documento"
    },
    {
      id: "estado",
      label: "Estado"
    },
    {
      id: "acciones",
      label: "Acciones"
    }
  ],
  lista: [
    {
      problema: "Empresa",
      nombre: "Falabella",
      rut: "76.123.123-1",
      documento: "Formulario F30",
      estado: "Sin subir"
    },
    {
      problema: "Empresa",
      nombre: "Pernos Osorno",
      rut: "73.123.123-1",
      documento: "Formulario F30",
      estado: "Rechazado"
    },
    {
      problema: "Persona",
      nombre: "Josefina Cruz",
      rut: "17.123.123-1",
      documento: "Documento de identidad",
      estado: "Rechazado"
    },
    {
      problema: "Persona",
      nombre: "Jose Valenzuela",
      rut: "14.222.123-1",
      documento: "Documento de identidad",
      estado: "Rechazado"
    },
    {
      problema: "Persona",
      nombre: "Juan Valdez",
      rut: "19.223.123-1",
      documento: "Documento de identidad",
      estado: "Sin subir"
    },
    {
      problema: "Persona",
      nombre: "Matias Valdivia",
      rut: "19.423.123-1",
      documento: "Contrato plazo fijo",
      estado: "Sin subir"
    },
    {
      problema: "Persona",
      nombre: "Fernando Volke",
      rut: "17.223.123-1",
      documento: "Documento de identidad",
      estado: "Rechazado"
    },
    {
      problema: "Persona",
      nombre: "Claudia Fernández",
      rut: "14.133.123-1",
      documento: "Contrato plazo fijo",
      estado: "Sin subir"
    },
    {
      problema: "Persona",
      nombre: "Isabela Cruz",
      rut: "16.223.123-1",
      documento: "Contrato plazo fijo",
      estado: "Sin subir"
    },
    {
      problema: "Persona",
      nombre: "Nicolas Contreras",
      rut: "16.183.113-1",
      documento: "Contrato plazo fijo",
      estado: "Rechazado"
    },
    {
      problema: "Persona",
      nombre: "Thomas Prutzmann",
      rut: "16.123.993-1",
      documento: "Contrato plazo fijo",
      estado: "Sin subir"
    },
    {
      problema: "Persona",
      nombre: "Antonio Banderas",
      rut: "17.943.123-1",
      documento: "Contrato plazo fijo",
      estado: "Rechazado"
    },
    {
      problema: "Persona",
      nombre: "Ignacio Rios",
      rut: "12.923.123-1",
      documento: "Contrato plazo fijo",
      estado: "Sin subir"
    },
    {
      problema: "Persona",
      nombre: "Jürgen Zwanzger",
      rut: "16.193.193-1",
      documento: "Anexos",
      estado: "Sin subir"
    },
    {
      problema: "Persona",
      nombre: "Vicente Contreras",
      rut: "20.223.423-1",
      documento: "Anexos",
      estado: "Sin subir"
    },
    {
      problema: "Persona",
      nombre: "Alfonso Ibañez",
      rut: "19.133.173-9",
      documento: "Anexos",
      estado: "Rechazado"
    },
    {
      problema: "Persona",
      nombre: "Alberto Hurtado",
      rut: "13.923.129-K",
      documento: "Anexos",
      estado: "Sin subir"
    },
    {
      problema: "Persona",
      nombre: "Rudolf Hartmann",
      rut: "15.123.123-1",
      documento: "Contrato plazo fijo",
      estado: "Sin subir"
    },
    {
      problema: "Empresa",
      nombre: "Ripley",
      rut: "76.223.123-1",
      documento: "Formulario F30-1",
      estado: "Rechazado"
    },
    {
      problema: "Empresa",
      nombre: "BHP",
      rut: "76.124.123-1",
      documento: "Pago mutualidades",
      estado: "Sin subir"
    }
  ]
};

export const listaFaenas = [
  { id: "Faena 1", value: "Faena 1" },
  { id: "Faena 2", value: "Faena 2" },
  { id: "Faena 3", value: "Faena 3" },
  { id: "Faena 4", value: "Faena 4" },
  { id: "Faena 5", value: "Faena 5" }
];

export const listaAños = [
  { id: "2010", value: "2010" },
  { id: "2011", value: "2011" },
  { id: "2012", value: "2012" },
  { id: "2013", value: "2013" },
  { id: "2014", value: "2014" },
  { id: "2015", value: "2015" },
  { id: "2016", value: "2016" },
  { id: "2017", value: "2017" },
  { id: "2018", value: "2018" },
  { id: "2019", value: "2019" },
  { id: "2020", value: "2020" },
  { id: "2021", value: "2021" },
  { id: "2022", value: "2022" },
  { id: "2023", value: "2023" }
];

export const listaMeses = [
  { id: 1, value: "Enero", number: 1 },
  { id: 2, value: "Febrero", number: 2 },
  { id: 3, value: "Marzo", number: 3 },
  { id: 4, value: "Abril", number: 4 },
  { id: 5, value: "Mayo", number: 5 },
  { id: 6, value: "Junio", number: 6 },
  { id: 7, value: "Julio", number: 7 },
  { id: 8, value: "Agosto", number: 8 },
  { id: 9, value: "Septiembre", number: 9 },
  { id: 10, value: "Octubre", number: 10 },
  { id: 11, value: "Noviembre", number: 11 },
  { id: 12, value: "Diciembre", number: 12 }
];

export const listaTrabajadoresNuevos = [
  {
    nombre: "Rudolf",
    apellidoP: "Hartmann",
    apellidoM: "Valenzuela",
    numDoc: "19605564-9",
    nacionalidad: "Chile",
    nacimiento: "1997-01-13",
    sexo: "Masculino",
    correo: "rudolfhartmann@gmail.com",
    telefono: "987322978",
    direccion: "Quito 272",
    region: "V",
    comuna: "Viña del Mar",
    cargo: "Ing. Telemático",
    contrato: "2",
    jornada: "2",
    inicio: "2022-10-16",
    fin: "2022-12-13",
    ingreso: "",
    art22: false,
    teletrabajo: true
  },
  {
    nombre: "Alex",
    apellidoP: "Pohl",
    apellidoM: "France",
    numDoc: "19505264-9",
    nacionalidad: "Chile",
    nacimiento: "1997-01-13",
    sexo: "Masculino",
    correo: "alex@gmail.com",
    telefono: "987325972",
    direccion: "Quito 272",
    region: "V",
    comuna: "Viña del Mar",
    cargo: "Ing. Telemático",
    contrato: "2",
    jornada: "1",
    inicio: "2022-10-16",
    fin: "2022-12-13",
    ingreso: "",
    art22: false,
    teletrabajo: true
  },
  {
    nombre: "Florencia",
    apellidoP: "Castañeda",
    apellidoM: "Toledo",
    numDoc: "15602564-2",
    nacionalidad: "Chile",
    nacimiento: "1995-02-16",
    sexo: "Femenino",
    correo: "florencia@gmail.com",
    telefono: "987224978",
    direccion: "Quito 274",
    region: "VII",
    comuna: "",
    cargo: "",
    contrato: "0",
    jornada: "0",
    inicio: "2022-10-16",
    fin: "2022-12-13",
    ingreso: "",
    art22: true,
    teletrabajo: true
  }
];

export const listaDocumentos = [
  { id: "Liquidacion", value: "Liquidacion de sueldo" },
  {
    id: "EPP",
    value: "Declaración jurada uso de EPP"
  },
  { id: "Contrato", value: "Contrato laboral" },
  { id: "ODI", value: "Registro de entrega de ODI" },
  { id: "RIOHS", value: "Toma conocimiento RIOHS" },
  { id: "Teletrabajo", value: "Anexo Teletrabajo" },
  { id: "AFP", value: "Pago AFC y AFP" },
  { id: "Isapre/Fonasa", value: "Pago Isapre/Fonasa" },
  { id: "Licencia", value: "Licencia médica" },
  { id: "DI", value: "Documento de identidad" }
];

export const listaDocumentosRechazados = [
  { id: "Liquidacion de sueldo", value: "Liquidacion de sueldo" },
  {
    id: "Declaración jurada uso de EPP",
    value: "Declaración jurada uso de EPP"
  },
  { id: "Contrato laboral", value: "Contrato laboral" },
  { id: "Registro de entrega de ODI", value: "Registro de entrega de ODI" },
  { id: "Toma conocimiento RIOCH", value: "Toma conocimiento RIOCH" },
  { id: "Anexo Teletrabajo", value: "Anexo Teletrabajo" },
  { id: "Pago AFC y AFP", value: "Pago AFC y AFP" },
  { id: "Pago Isapre/Fonasa", value: "Pago Isapre/Fonasa" },
  { id: "Licencia médica", value: "Licencia médica" },
  { id: "Documento de identidad", value: "Documento de identidad" }
];

export const listaVencimientos = [
  {
    cliente: "Falabella",
    proyecto: "Proyecto A1",
    trabajador: "Isabel Cumplido",
    rut: "12345678-2",
    documento: "Contrato plazo fijo",
    vencimiento: "2023-03-15",
    estado: "Aprobado"
  },
  {
    cliente: "Ripley",
    proyecto: "Proyecto A2",
    trabajador: "Isabel Cumplido",
    rut: "21345678-2",
    documento: "Documento de identidad",
    vencimiento: "2023-02-11",
    estado: "Sin subir"
  },
  {
    cliente: "BHP",
    proyecto: "Proyecto B1",
    trabajador: "Isabel Cumplido",
    rut: "20335678-2",
    documento: "Contrato plazo fijo",
    vencimiento: "2022-12-12",
    estado: "Aprobado"
  },
  {
    cliente: "Cencosud",
    proyecto: "Proyecto A1",
    trabajador: "Isabel Cumplido",
    rut: "12345718-2",
    documento: "Contrato plazo fijo",
    vencimiento: "2023-03-11",
    estado: "Rechazado"
  },
  {
    cliente: "PCFactory",
    proyecto: "Proyecto B2",
    trabajador: "Isabel Cumplido",
    rut: "11345678-2",
    documento: "Documento de identidad",
    vencimiento: "2023-02-09",
    estado: "Analizando"
  },
  {
    cliente: "Falabella",
    proyecto: "Proyecto B2",
    trabajador: "Isabel Cumplido",
    rut: "14345678-2",
    documento: "Contrato plazo fijo",
    vencimiento: "2023-01-15",
    estado: "Sin subir"
  },
  {
    cliente: "Ripley",
    proyecto: "Proyecto A2",
    trabajador: "Isabel Cumplido",
    rut: "15345678-2",
    documento: "Documento de identidad",
    vencimiento: "2023-02-15",
    estado: "Sin subir"
  },
  {
    cliente: "Falabella",
    proyecto: "Proyecto A1",
    trabajador: "Isabel Cumplido",
    rut: "16345678-2",
    documento: "Documento de identidad",
    vencimiento: "2023-03-15",
    estado: "Analizando"
  },
  {
    cliente: "BHP",
    proyecto: "Proyecto A3",
    trabajador: "Isabel Cumplido",
    rut: "17345678-2",
    documento: "Contrato plazo fijo",
    vencimiento: "2023-02-21",
    estado: "Sin subir"
  },
  {
    cliente: "Cencosud",
    proyecto: "Proyecto B1",
    trabajador: "Isabel Cumplido",
    rut: "18245678-2",
    documento: "Documento de identidad",
    vencimiento: "2023-02-23",
    estado: "Analizando"
  },
  {
    cliente: "PCFactory",
    proyecto: "Proyecto B2",
    trabajador: "Isabel Cumplido",
    rut: "12385678-2",
    documento: "Contrato plazo fijo",
    vencimiento: "2023-01-15",
    estado: "Analizando"
  },
  {
    cliente: "Falabella",
    proyecto: "Proyecto A3",
    trabajador: "Isabel Cumplido",
    rut: "22345678-2",
    documento: "Contrato plazo fijo",
    vencimiento: "2023-02-15",
    estado: "Aprobado"
  },
  {
    cliente: "Ripley",
    proyecto: "Proyecto A1",
    trabajador: "Isabel Cumplido",
    rut: "19345678-2",
    documento: "Documento de identidad",
    vencimiento: "2023-03-15",
    estado: "Rechazado"
  },
  {
    cliente: "BHP",
    proyecto: "Proyecto A2",
    trabajador: "Isabel Cumplido",
    rut: "20345678-2",
    documento: "Contrato plazo fijo",
    vencimiento: "2023-01-15",
    estado: "Rechazado"
  }
];

export const test_listaEmpresas = {
  headCells: [
    {
      id: "rut",
      label: "RUT"
    },
    {
      id: "nombre",
      label: "Nombre"
    },
    {
      id: "estado",
      label: "Estado"
    },
    {
      id: "acciones",
      label: "Acciones"
    }
  ],
  list: [
    {
      rut: "76.155.433-5",
      nombre: "Empresa A SPA",
      estado: "Pendiente",
      documentos: [
        {
          documento: "Adhesión mutualidad",
          fecha: "2023-01-01",
          estado: "Sin subir"
        },
        {
          documento: "Certificado calidad del EPP",
          fecha: "2023-02-01",
          estado: "Sin subir"
        },
        {
          documento: "Formulario F30",
          fecha: "2023-06-05",
          estado: "Analizando"
        },
        {
          documento: "Formulario F30-1",
          fecha: "2023-06-01",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "75.155.433-5",
      nombre: "Empresa A 2.0 SPA",
      estado: "Pendiente",
      documentos: [
        {
          documento: "Adhesión mutualidad",
          fecha: "2023-06-01",
          estado: "Rechazado"
        },
        {
          documento: "Certificado calidad del EPP",
          fecha: "2023-06-01",
          estado: "Aprobado"
        },
        {
          documento: "Formulario F30",
          fecha: "2023-06-05",
          estado: "Sin subir"
        },
        {
          documento: "Formulario F30-1",
          fecha: "2023-06-06",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "71.153.243-2",
      nombre: "Empresa A 3.0 SPA",
      estado: "Validado",
      documentos: [
        {
          documento: "Adhesión mutualidad",
          fecha: "2023-06-05",
          estado: "Aprobado"
        },
        {
          documento: "Certificado calidad del EPP",
          fecha: "2023-06-05",
          estado: "Aprobado"
        },
        {
          documento: "Formulario F30",
          fecha: "2023-06-05",
          estado: "Aprobado"
        },
        {
          documento: "Formulario F30-1",
          fecha: "2023-06-05",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "70.135.244-1",
      nombre: "Empresa A 4.0 SPA",
      estado: "Pendiente",
      documentos: [
        {
          documento: "Adhesión mutualidad",
          fecha: "2023-03-01",
          estado: "Sin subir"
        },
        {
          documento: "Certificado calidad del EPP",
          fecha: "2023-03-01",
          estado: "Sin subir"
        },
        {
          documento: "Formulario F30",
          fecha: "2023-03-05",
          estado: "Sin subir"
        },
        {
          documento: "Formulario F30-1",
          fecha: "2023-06-01",
          estado: "Sin subir"
        }
      ]
    },
    {
      rut: "79.295.793-5",
      nombre: "Empresa A 5.0 SPA",
      estado: "Rechazado",
      documentos: [
        {
          documento: "Adhesión mutualidad",
          fecha: "2023-06-01",
          estado: "Rechazado"
        },
        {
          documento: "Certificado calidad del EPP",
          fecha: "2023-06-01",
          estado: "Rechazado"
        },
        {
          documento: "Formulario F30",
          fecha: "2023-06-01",
          estado: "Rechazado"
        },
        {
          documento: "Formulario F30-1",
          fecha: "2023-06-01",
          estado: "Rechazado"
        }
      ]
    }
  ]
};

export const listaTickets = [
  {
    id: 173124,
    fecha: "2022-06-15",
    motivo: "Documento rechazado",
    asunto: "Asunto 5",
    estado: "Necesita Verificación",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        rechazado: 123123,
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 123124,
    fecha: "2010-03-15",
    motivo: "Manejo de usuarios",
    asunto: "Asunto 1",
    estado: "Abierto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 2345112,
    fecha: "2012-04-15",
    motivo: "Manejo de documentos",
    asunto: "Asunto 2",
    estado: "Abierto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 123123,
    fecha: "2010-05-15",
    motivo: "Manejo de usuarios",
    asunto: "Asunto 11",
    estado: "Abierto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 123,
    fecha: "2010-11-15",
    motivo: "Manejo de fechas",
    asunto: "Asunto 123",
    estado: "Resuelto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 4567,
    fecha: "2018-12-15",
    motivo: "Manejo de clientes",
    asunto: "Asunto 12",
    estado: "Resuelto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 123124,
    fecha: "2011-05-15",
    motivo: "Manejo de documentos",
    asunto: "Asunto 5",
    estado: "Resuelto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 685,
    fecha: "2012-07-15",
    motivo: "Manejo de fechas",
    asunto: "Asunto 2",
    estado: "Resuelto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 34534,
    fecha: "2013-09-15",
    motivo: "Utilización del portal",
    asunto: "Asunto 3",
    estado: "Resuelto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 235523,
    fecha: "2018-02-15",
    motivo: "Manejo de documentos",
    asunto: "Asunto 2",
    estado: "Resuelto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 235235,
    fecha: "2014-11-15",
    motivo: "Manejo de documentos",
    asunto: "Asunto 8",
    estado: "Resuelto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 23523,
    fecha: "2015-03-15",
    motivo: "Utilización del portal",
    asunto: "Asunto 9",
    estado: "Resuelto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 235235,
    fecha: "2010-03-15",
    motivo: "Manejo de documentos",
    asunto: "Asunto 10",
    estado: "Resuelto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 85425,
    fecha: "2018-03-15",
    motivo: "Manejo de fechas",
    asunto: "Asunto 14",
    estado: "Resuelto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 5842,
    fecha: "2015-05-15",
    motivo: "Casos especiales",
    asunto: "Asunto 4",
    estado: "Resuelto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 2845,
    fecha: "2023-03-15",
    motivo: "Casos especiales",
    asunto: "Asunto 3",
    estado: "Resuelto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 5724,
    fecha: "2013-08-15",
    motivo: "Manejo de usuarios",
    asunto: "Asunto 6",
    estado: "Abierto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 5475274,
    fecha: "2015-09-15",
    motivo: "Manejo de documentos",
    asunto: "Asunto 1",
    estado: "Abierto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 245757,
    fecha: "2022-10-15",
    motivo: "Manejo de usuarios",
    asunto: "Asunto 1",
    estado: "Abierto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 24537,
    fecha: "2022-03-15",
    motivo: "Manejo de documentos",
    asunto: "Asunto 7",
    estado: "Abierto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 34453,
    fecha: "2020-04-15",
    motivo: "Manejo de documentos",
    asunto: "Asunto 96",
    estado: "Abierto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 4236453,
    fecha: "2019-03-15",
    motivo: "Manejo de fechas",
    asunto: "Asunto 45",
    estado: "Abierto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 345,
    fecha: "2016-04-15",
    motivo: "Manejo de documentos",
    asunto: "Asunto 32",
    estado: "Abierto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 634346,
    fecha: "2012-03-15",
    motivo: "Manejo de documentos",
    asunto: "Asunto 17",
    estado: "Abierto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 2432,
    fecha: "2010-06-15",
    motivo: "Manejo de documentos",
    asunto: "Asunto 21",
    estado: "Abierto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  },
  {
    id: 643543,
    fecha: "2021-03-15",
    motivo: "Manejo de fechas",
    asunto: "Asunto 25",
    estado: "Abierto",
    contenido: [
      {
        titulo: "Contacto - 15/01/2022 - 15:34 hrs",
        documentos: ["Contrato.pdf"],
        mensaje:
          "Hola, buenas tardes. \n\nMe comunico con ustedes puesto que el sistema no me está dejando subir los documentos correspondientes a la liquidación de sueldo de la trabajadora Isabel Cumplido. \n\nQuedo atenta a cómo proceder, muchas gracias. "
      },
      {
        titulo: "Respuesta ejecutivo 1 - 15/01/2022 - 16:34 hrs",
        mensaje:
          "Hola, buen día. \n\nPara poder ayudarte, ¿me podrías confirmar el mes de la liquidación que el sistema no te deja subir? Y, de ser posible, un screen shot de lo que pasa cuando intentas subirlo. \n\nQuedo atenta para proceder. "
      }
    ]
  }
];

export const listaSubcontratistasDashboard = {
  headCells: [
    {
      id: "value",
      label: "Subcontratista"
    },
    {
      id: "faena",
      label: "Área/Faena"
    },
    {
      id: "sinSubir",
      label: "Sin subir"
    },
    {
      id: "porRevisar",
      label: "Por revisar"
    },
    {
      id: "aprobados",
      label: "Aprobados"
    },
    {
      id: "rechazados",
      label: "Rechazados"
    },
    {
      id: "anulados",
      label: "Anulados"
    }
  ],
  list: [
    {
      value: "MSI",
      mes: 1,
      faena: "Faena 1",
      sinSubir: 1200,
      porRevisar: 0,
      aprobados: 3450,
      rechazados: 0,
      anulados: 54
    },
    {
      value: "Falabella",
      mes: 6,
      faena: "Faena 1",
      sinSubir: 1200,
      porRevisar: 0,
      aprobados: 3450,
      rechazados: 0,
      anulados: 54
    },
    {
      value: "Ripley",
      mes: 3,
      faena: "Faena 1",
      sinSubir: 1200,
      porRevisar: 0,
      aprobados: 3450,
      rechazados: 0,
      anulados: 54
    },
    {
      value: "ENAER",
      mes: 8,
      faena: "Faena 1",
      sinSubir: 112,
      porRevisar: 23,
      aprobados: 2323,
      rechazados: 23,
      anulados: 11
    },
    {
      value: "Cencosud",
      mes: 12,
      faena: "Faena 1",
      sinSubir: 1200,
      porRevisar: 0,
      aprobados: 3450,
      rechazados: 0,
      anulados: 54
    },
    {
      value: "Falabella",
      mes: 11,
      faena: "Faena 1",
      sinSubir: 1200,
      porRevisar: 0,
      aprobados: 3450,
      rechazados: 0,
      anulados: 54
    },
    {
      value: "ENAER",
      mes: 10,
      faena: "Faena 10",
      sinSubir: 123,
      porRevisar: 23,
      aprobados: 23,
      rechazados: 0,
      anulados: 54
    },
    {
      value: "Samsung",
      mes: 2,
      faena: "Faena 8",
      sinSubir: 2323,
      porRevisar: 232,
      aprobados: 223,
      rechazados: 123,
      anulados: 233
    }
  ]
};

export const listaClientesDashboard = {
  headCells: [
    {
      id: "value",
      label: "Cliente"
    },
    {
      id: "faena",
      label: "Área/Faena"
    },
    {
      id: "sinSubir",
      label: "Sin subir"
    },
    {
      id: "porRevisar",
      label: "Por revisar"
    },
    {
      id: "aprobados",
      label: "Aprobados"
    },
    {
      id: "rechazados",
      label: "Rechazados"
    },
    {
      id: "anulados",
      label: "Anulados"
    }
  ],
  list: [
    {
      value: "MSI",
      mes: 1,
      faena: "Faena 1",
      sinSubir: 1200,
      porRevisar: 0,
      aprobados: 3450,
      rechazados: 0,
      anulados: 54
    },
    {
      value: "Falabella",
      mes: 6,
      faena: "Faena 1",
      sinSubir: 1200,
      porRevisar: 0,
      aprobados: 3450,
      rechazados: 0,
      anulados: 54
    },
    {
      value: "Ripley",
      mes: 3,
      faena: "Faena 1",
      sinSubir: 1200,
      porRevisar: 0,
      aprobados: 3450,
      rechazados: 0,
      anulados: 54
    },
    {
      value: "ENAER",
      mes: 8,
      faena: "Faena 1",
      sinSubir: 112,
      porRevisar: 23,
      aprobados: 2323,
      rechazados: 23,
      anulados: 11
    },
    {
      value: "Cencosud",
      mes: 12,
      faena: "Faena 1",
      sinSubir: 1200,
      porRevisar: 0,
      aprobados: 3450,
      rechazados: 0,
      anulados: 54
    },
    {
      value: "Falabella",
      mes: 11,
      faena: "Faena 1",
      sinSubir: 1200,
      porRevisar: 0,
      aprobados: 3450,
      rechazados: 0,
      anulados: 54
    },
    {
      value: "ENAER",
      mes: 10,
      faena: "Faena 10",
      sinSubir: 123,
      porRevisar: 23,
      aprobados: 23,
      rechazados: 0,
      anulados: 54
    },
    {
      value: "Falabella",
      mes: 11,
      faena: "Faena 9",
      sinSubir: 123,
      porRevisar: 0,
      aprobados: 1234,
      rechazados: 11,
      anulados: 33
    },
    {
      value: "Samsung",
      mes: 2,
      faena: "Faena 8",
      sinSubir: 2323,
      porRevisar: 232,
      aprobados: 223,
      rechazados: 123,
      anulados: 233
    },
    {
      value: "Falabella",
      mes: 4,
      faena: "Faena 7",
      sinSubir: 1200,
      porRevisar: 0,
      aprobados: 3450,
      rechazados: 0,
      anulados: 54
    }
  ]
};

export const listaDocumentosTrabajadores = [
  {
    rut: "20.711.168-4",
    nombre: "Isabel Cumplido",

    ingreso: "2021-08-20",
    salida: ""
  }
];

export const tabla_lista_bajados = {
  headCells: [
    {
      id: "rut",
      label: "Rut"
    },
    {
      id: "nombre",
      label: "Nombre"
    },
    {
      id: "inicio",
      label: "Inicio contrato"
    },
    {
      id: "fin",
      label: "Fin contrato"
    },
    {
      id: "ingreso",
      label: "Ingreso faena"
    },
    {
      id: "salida",
      label: "Salida faena"
    },
    {
      id: "acciones",
      label: "Acciones"
    }
  ],
  list: [
    {
      rut: "20.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "78575126-9",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "19.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "17.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-07-31",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.422.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Alejandro Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-19",
      fin: "2/01/2023",
      ingreso: "2/09/2021",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2021-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "78575126-9",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",

      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Aprobado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        }
      ]
    }
  ]
};

export const tabla_lista_trabajadores = {
  headCells: [
    {
      id: "rut",
      label: "Rut"
    },
    {
      id: "nombre",
      label: "Nombre"
    },
    {
      id: "fecha_inicio_contrato",
      label: "Inicio contrato"
    },
    {
      id: "fecha_fin_contrato",
      label: "Fin contrato"
    },
    {
      id: "fecha_ingreso_faena",
      label: "Ingreso faena"
    },
    {
      id: "fecha_salida_faena",
      label: "Salida faena"
    },
    {
      id: "estado",
      label: "Estado"
    },
    {
      id: "acciones",
      label: "Acciones"
    }
  ],
  list: [
    {
      rut: "20.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "78575126-9",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Rechazado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "19.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Rechazado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "rechazado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "rechazado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "17.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "rechazado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "rechazado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "rechazado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-07-31",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.422.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Alejandro Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "pendiente",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-19",
      fin: "2/01/2023",
      ingreso: "2/09/2021",
      salida: "",
      estado: "pendiente",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2021-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "pendiente",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "pendiente",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "pendiente",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "pendiente",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325541-5",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "pendiente",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "validado",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72585486-K",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "79225289-1",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "78575126-9",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "78575126-9",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "78575126-9",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "78575126-9",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "78575126-9",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "71236485-2",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "71236485-2",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "71236485-2",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "71236485-2",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325141-0",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325141-0",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325141-0",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "70325141-0",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72245131-2",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72245131-2",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "72245131-2",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "19605564-9",
      nombre: "Rudolf Hartmann",
      cliente: "73323148-2",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    },
    {
      rut: "21.711.168-4",
      nombre: "Isabel Cumplido",
      cliente: "73323148-2",
      inicio: "2021-08-20",
      fin: "2023-10-06",
      ingreso: "2021-08-20",
      salida: "",
      estado: "Procesando",
      documentos: [
        {
          id: "Liquidacion de sueldo",
          documento: "Liquidacion de sueldo",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Declaración jurada uso de EPP",
          documento: "Declaración jurada uso de EPP",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Contrato laboral",
          documento: "Contrato laboral",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Sin subir"
        },
        {
          id: "Registro de entrega de ODI",
          documento: "Registro de entrega de ODI",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Toma conocimiento RIOCH",
          documento: "Toma conocimiento RIOCH",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Rechazado"
        },
        {
          id: "Anexo Teletrabajo",
          documento: "Anexo Teletrabajo",
          fecha: "2024-10-06",
          mes: "Septiembre",
          estado: "Sin subir"
        },
        {
          id: "Pago AFC y AFP",
          documento: "Pago AFC y AFP",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Aprobado"
        },
        {
          id: "Pago Isapre/Fonasa",
          documento: "Pago Isapre/Fonasa",
          fecha: "2022-10-06",
          mes: "Septiembre",
          estado: "Rechazado"
        },
        {
          id: "Licencia médica",
          documento: "Licencia médica",
          fecha: "2024-10-06",
          mes: "Agosto",
          estado: "Analizando"
        },
        {
          id: "Documento de identidad",
          documento: "Documento de identidad",
          fecha: "2022-10-06",
          mes: "Agosto",
          estado: "Analizando"
        }
      ]
    }
  ]
};
