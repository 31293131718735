import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { IconButton, Button, Typography, Box, Modal } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";
import InfoTrabajadorForm from "../common/components/infoTrabajadorForm";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../redux/confirmModalSlice";
import { useDispatch } from "react-redux";
import {
  useGetTrabajadorQuery,
  useLazyGetTrabajadorQuery,
  useUpdateTrabajadorMutation
} from "../../../api/trabajadoresApiSlice";
import LoadingRocket from "../common/things/loadingRocket";

export default function EditarTrabajadorModal(props) {
  const { openModal, setOpenModal, trabajador } = props;
  const navigate = useNavigate();
  const littleSize = littleSizeFunc();
  const dispatch = useDispatch();

  const [triggerGetData, { isFetching, isLoading }] =
    useLazyGetTrabajadorQuery();
  const [updatePost, updateResult] = useUpdateTrabajadorMutation();

  const [continueValue, setContinueValue] = useState(false);

  const [persona, setPersona] = useState({});
  const [personaEdit, setPersonaEdit] = useState({});

  const handleGetTrabajadorData = async () => {
    const { data, isSuccess } = await triggerGetData(trabajador.id, false);
    if (isSuccess) {
      setPersona({
        nombre: data.data.persona.nombre,
        apellido_paterno: data.data.persona.apellido_paterno,
        apellido_materno: data.data.persona.apellido_materno,
        rut: data.data.persona.rut,
        nacionalidad: data.data.persona.nacionalidad,
        fechaNac: data.data.persona.fechaNac,
        fechaVenc: data.data.persona.fechaVenc,
        sexo: data.data.persona.sexo,
        email: data.data.persona.email,
        telefono: data.data.persona.telefono,
        direccion: data.data.persona.direccion,
        region: data.data.persona.region,
        comuna: data.data.persona.comuna,
        cargo: data.data.cargo,
        tipo_contrato: data.data.tipo_contrato,
        jornada: data.data.jornada,
        fecha_inicio_contrato: data.data.fecha_inicio_contrato,
        fecha_fin_contrato: data.data.fecha_fin_contrato,
        fecha_ingreso_faena: data.data.fecha_ingreso_faena,
        art22: data.data.art22,
        teletrabajo: data.data.teletrabajo
      });
    }
  };

  useEffect(() => {
    if (openModal) {
      handleGetTrabajadorData();
    }
  }, [openModal]);

  const handleCloseModal = () => {
    setContinueValue(false);
    setOpenModal(false);
    setPersona({});
  };
  const handleGuardar = async () => {
    try {
      await updatePost({
        id: trabajador.id,
        updatedValues: personaEdit
      }).unwrap();
      dispatch(
        setSuccessText("¡Tus cambios han sido guardados de forma exitosa!")
      );
    } catch (e) {
      dispatch(
        setErrorText(
          "¡Hubo un error, intente nuevamente o contáctese con nosotros!"
        )
      );
    }
    setOpenModal(false);
    setContinueValue(false);
    dispatch(setOpenConfirmModal(true));
    navigate("/Trabajadores");
    setPersona({});
  };

  const modalBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxWidth: "1300px",
    height: "fit-content",
    maxHeight: "98vh",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: "5.882vh 3.472vw ",
    gap: "11px"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const content = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflowY: "auto"
  };
  const title = {
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "140%"
  };
  const subtitle = {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "150%",
    alignItems: littleSize ? "center" : "",
    textAlign: littleSize ? "center" : ""
  };
  const buttonBox = {
    display: "flex",
    alignItems: "center",
    justifyContent: littleSize ? "center" : "flex-end",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "17px",
    margin: "0 0 1px 0"
  };
  const button = {
    minWidth: "160px",
    textTransform: "none",
    borderRadius: "10px",
    fontWeight: 600
  };
  const loadingBox = {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    flexDirection: "column",
    overflowY: "auto"
  };

  // useEffect(() => {
  //   setContinueValue(true);
  // }, [
  //   persona
  // ]);

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={modalBox}>
        <Box sx={closeButtonBox}>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography sx={title}>Editar</Typography>
        <Typography sx={subtitle}>
          Trabajador/a: {persona.nombre} {persona.apellido_paterno}{" "}
          {persona.apellido_materno}
        </Typography>
        {Object.keys(persona).length === 0 || isLoading || isFetching ? (
          <Box sx={loadingBox}>
            <LoadingRocket dialog={false} dark />
          </Box>
        ) : (
          <Box sx={content}>
            <InfoTrabajadorForm
              editar={true}
              setContinueValue={setContinueValue}
              persona={persona}
              setPersona={setPersonaEdit}
            />
          </Box>
        )}
        <Box sx={buttonBox}>
          <Button
            sx={button}
            variant="contained"
            onClick={handleGuardar}
            disableElevation
            disabled={!continueValue}
          >
            Guardar cambios
          </Button>
        </Box>
      </Box>
      {/* <LoadingRocket isLoading={updateResult.isLoading} /> */}
    </Modal>
  );
}
