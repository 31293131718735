import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmpty from "validator/lib/isEmpty";
import {
  IconButton,
  TextField,
  Button,
  Box,
  Typography,
  Modal
} from "@mui/material";
import MuiLink from "@mui/material/Link";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";

/* CUSTOM LIBRARIES */
import CustomSelect from "../common/components/customSelect";
import CustomStepper from "../common/components/customStepper";
import DragDropFileList from "../common/components/dragAndDropList";
import PDFViewer from "../common/components/pdfViewer";
import FinishModal from "../common/modals/finishModal";
import LoadingRocket from "../common/things/loadingRocket";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { transition } from "../../../controller/utils";
import {
  stepsDarDeBajaModal,
  listaFilesDarDeBaja,
  listaMotivoDesvinculacion
} from "../../../controller/listas";
import { useDeleteTrabajadorMutation } from "../../../api/trabajadoresApiSlice";
import {
  setErrorText,
  setInfoText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../redux/confirmModalSlice";
import { useDispatch } from "react-redux";
import { useLazyGetDocumentosFaltantesTrabajadorQuery } from "../../../api/docsApiSlice";

function FirstPage(props) {
  const {
    continueValue,
    setContinueValue,
    uploadedFiles,
    setUploadedFiles,
    listaFilesDarDeBaja,
    setListaFilesDarDeBaja
  } = props;

  useEffect(() => {
    setContinueValue(
      uploadedFiles.filter((f) => f.titulo !== null).length ===
        listaFilesDarDeBaja.filter((f) => !f.hide).length
    );
  }, [uploadedFiles]);

  /* CSS */
  const datosBox = {
    width: "100%",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    gap: "3.882vh",
    alignItems: "center"
  };

  return (
    <Box sx={datosBox}>
      <Typography>
        Para poder dar de baja a este trabajador, por favor carga los siguientes
        documentos:
      </Typography>
      <DragDropFileList
        list={listaFilesDarDeBaja}
        setList={setListaFilesDarDeBaja}
        setContinueValue={() => {}}
        // setContinueValue={setContinueValue}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        multiple={false}
        memory={true}
        type="subcontratado"
      />
    </Box>
  );
}

function SecondPage(props) {
  const {
    setContinueValue,
    uploadedFiles,
    setUploadedFiles,
    // desvinculacion,
    // setDesvinculacion,
    motivo,
    setMotivo,
    fechaDesvinculacion,
    setFechaDesvinculacion,
    documento,
    setDocumento,
    openPDFModal,
    setOpenPDFModal,
    listaFilesDarDeBaja,
    setIdPDF
  } = props;
  const littleSize = littleSizeFunc();

  const uploaded = [];

  // useEffect(()=>{
  //   uploaded = [...uploadedFiles]
  // },[uploadedFiles])

  const popPDFViewer = (e, title, id) => {
    setDocumento(title);
    setIdPDF(id);
    setOpenPDFModal(true);
  };

  useEffect(() => {
    setContinueValue(motivo && fechaDesvinculacion);
  }, [motivo, fechaDesvinculacion]);

  /* CSS */
  const datosBox = {
    minWidth: "fit-content",
    maxWidth: "100%",
    width: littleSize ? "98vw" : "48vw",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "3.882vh",
    alignItems: "center",
    justifyContent: "center"
  };
  const selectBoxLine = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "4vh 3vw"
  };
  const formControl = {
    height: "30px",
    width: "16vw",
    minWidth: "210px"
  };
  const textField = {
    height: "inherit",
    background: "#FFFFFF",
    fontWeight: 400,
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400
    },
    ".Mui-disabled": {
      opacity: 0.8
    }
  };

  return (
    <Box sx={datosBox}>
      <Box sx={selectBoxLine}>
        <Box sx={formControl}>
          <CustomSelect
            label="Causal de desvinculación"
            fontWeightValue={400}
            value={motivo}
            setValue={setMotivo}
          >
            {listaMotivoDesvinculacion}
          </CustomSelect>
        </Box>
        <Box sx={formControl}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="correo"
            label="Fecha de desvinculación"
            value={fechaDesvinculacion}
            type="date"
            onChange={(e) => setFechaDesvinculacion(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Box>
      <Typography>
        * Antes de avanzar, por favor revisa que la información sea correcta.
      </Typography>
      {uploadedFiles.map((file, index) => (
        <Typography>
          {file.tipo_documento.abreviacion} -{" "}
          <MuiLink
            onClick={(e) =>
              popPDFViewer(e, file.tipo_documento.abreviacion, file.id)
            }
          >
            ver aquí
          </MuiLink>
        </Typography>
      ))}
    </Box>
  );
}

export default function DarBajaTrabajadorModal(props) {
  const { openModal, setOpenModal, trabajador } = props;
  const dispatch = useDispatch();
  const littleSize = littleSizeFunc();

  const [triggerGetData, { isFetching }] =
    useLazyGetDocumentosFaltantesTrabajadorQuery();
  const [deletePost, deleteResult] = useDeleteTrabajadorMutation();

  const [loading, setLoading] = useState(false);
  const [stepCount, setStepCount] = useState(0);
  const [continueValue, setContinueValue] = useState(false);
  const [listaFiles, setListaFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [openFinishModal, setOpenFinishModal] = useState(false);

  // const [desvinculacion, setDesvinculacion] = useState("");
  const [motivo, setMotivo] = useState("");
  const [fechaDesvinculacion, setFechaDesvinculacion] = useState("");
  const [documento, setDocumento] = useState("");
  const [openPDFModal, setOpenPDFModal] = useState(false);
  const [idPDF, setIdPDF] = useState();

  const handleDarDeBaja = async () => {
    try {
      const response = await deletePost({
        id: trabajador.id,
        deleteValues: {
          causal_termino: motivo,
          fecha_fin_contrato: fechaDesvinculacion
        }
      }).unwrap();
      if (response == "Trabajador Eliminado") {
        dispatch(
          setSuccessText("¡El trabajador ha sido dado de baja correctamente!")
        );
      } else if (response == "Documentos siendo analizados") {
        dispatch(
          setInfoText(
            "¡Algunos documentos aún están siendo analizados!\n¡Vuelva a intentarlo cuando estos ya sean aprobados!"
          )
        );
      } else {
        dispatch(
          setErrorText(
            "¡Algunos documentos aún no son subidos!\n¡Vuelva a intentarlo cuando estos ya sean subidos y aprobados!"
          )
        );
      }
    } catch (e) {
      console.error(e);
      dispatch(
        setErrorText(
          "¡Hubo un error, intente nuevamente o contáctese con nosotros!"
        )
      );
    }
    setStepCount(0);
    setOpenModal(false);
    setOpenFinishModal(false);
    setContinueValue(false);
    setDocumento("");
    setMotivo("");
    // setDesvinculacion("");
    setFechaDesvinculacion("");
    dispatch(setOpenConfirmModal(true));
  };

  const handleGetDocumentos = async () => {
    setLoading(true);
    const { data, isSuccess } = await triggerGetData(
      { id: trabajador.id },
      false
    );
    if (isSuccess) {
      let docs = data.data.documentos;
      let docs_subidos = data.data.documentos_subidos;
      let tipos_docs = data.data.tipos_documentos;
      let lista = [];
      if (docs.length > 0) {
        docs.map((file) => {
          lista.push({
            id: file.id,
            nombre: file.tipo_documento.nombre,
            acceptedFormats: ["application/pdf"]
          });
        });
      }
      let lista_tipos = [...listaFilesDarDeBaja];
      lista_tipos.forEach((file, index) => {
        file["id_tipo"] = tipos_docs[index].id;
        file["trabajador"] = trabajador.id;
      });
      setListaFiles([...lista, ...lista_tipos]);

      setUploadedFiles([...docs_subidos]);
    }
  };

  useEffect(() => {
    if (listaFiles.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [listaFiles]);

  useEffect(() => {
    if (stepCount === 2) {
      setOpenFinishModal(true);
    }
    if (stepCount > 2) {
      handleDarDeBaja();
    }
  }, [stepCount]);

  useEffect(() => {
    if (!openModal) {
      setStepCount(0);
      setOpenFinishModal(false);
      setContinueValue(false);
      setDocumento("");
      setMotivo("");
      setOpenPDFModal(false);
      // setDesvinculacion("");
      setListaFiles([]);
      setUploadedFiles([]);
      setFechaDesvinculacion("");
    } else {
      handleGetDocumentos();
    }
  }, [openModal]);

  const handleCloseModal = () => setOpenModal(false);
  const handleClosePDFModal = () => setOpenPDFModal(false);
  const handleContinue = () => {
    setContinueValue(false);
    setStepCount(stepCount + 1);
  };
  const handleBack = () => setStepCount(stepCount - 1);
  const handleSubmit = (e) => e.preventDefault();

  /* CSS */
  const todo = {
    transition: transition,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: littleSize ? "wrap" : "nowrap",
    // flexWrap: "wrap",
    justifyContent: "center",
    gap: "2vw",
    padding: "2vh 2vw",
    overflow: littleSize ? "auto" : "hidden"
  };
  const container = {
    position: "relative",
    transition: transition,
    minWidth: "40%",
    width: "fit-content",
    maxWidth: littleSize ? "99%" : "54%",
    height: "fit-content",
    maxHeight: "99vh",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: "6vh 2%",
    justifyContent: "space-around"
  };
  const pdfModalContainer = {
    // visibility: openPDFModal ? "visible" : "hidden",
    position: "relative",
    minHeight: "0px",
    // width: openPDFModal ? "fit-content" : "0px",
    width: "fit-content",
    // height: openPDFModal ? "fit-content" : "0px",
    height: "fit-content",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    transition: transition,
    " .MuiBox-root.MuiBox-root": {
      borderRadius: "20px"
    }
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const closeButtonBoxPDF = {
    width: openPDFModal ? "fit-content" : "0px",
    height: openPDFModal ? "fit-content" : "0px",
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const tituloBox = {
    padding: "0px 0px 5vh 0px"
  };
  const titulo = {
    fontWeight: 700,
    fontSize: "32px",
    textAlign: littleSize ? "center" : ""
    // lineHeight: '120%',
  };
  const subtitulo = {
    fontWeight: 400,
    fontSize: "20px",
    textAlign: littleSize ? "center" : ""
    // lineHeight: '120%',
  };
  const datosButtonBox = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflowY: "auto",
    gap: "5vh",
    padding: "5vh 0px 6vh 0px"
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px"
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    visibility: littleSize ? "visible" : "",
    ":hover": {
      border: "2px solid #1876D1"
    }
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1"
  };
  const textStyleFinishModal = {
    textAlign: "center"
  };

  return trabajador ? (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={todo}>
        <Box sx={container}>
          <Box sx={closeButtonBox}>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={tituloBox}>
            <Typography sx={titulo}>Dar de baja</Typography>
            <Typography sx={subtitulo}>
              Trabajador/a: {trabajador.nombre}
            </Typography>
          </Box>
          <Box>
            <CustomStepper stepCount={stepCount}>
              {stepsDarDeBajaModal}
            </CustomStepper>
          </Box>
          <Box sx={datosButtonBox}>
            {loading ? (
              <LoadingRocket dialog={false} dark />
            ) : stepCount === 0 ? (
              <FirstPage
                continueValue={continueValue}
                setContinueValue={setContinueValue}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                listaFilesDarDeBaja={listaFiles}
                setListaFilesDarDeBaja={setListaFiles}
              />
            ) : (
              <SecondPage
                setContinueValue={setContinueValue}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                listaFilesDarDeBaja={listaFiles}
                // desvinculacion={desvinculacion}
                // setDesvinculacion={setDesvinculacion}
                motivo={motivo}
                setMotivo={setMotivo}
                fechaDesvinculacion={fechaDesvinculacion}
                setFechaDesvinculacion={setFechaDesvinculacion}
                documento={documento}
                setDocumento={setDocumento}
                openPDFModal={openPDFModal}
                setOpenPDFModal={setOpenPDFModal}
                setIdPDF={setIdPDF}
              />
            )}
          </Box>
          <Box sx={buttonBox}>
            {stepCount > 0 ? (
              <Button variant="outlined" sx={buttonVolver} onClick={handleBack}>
                Volver
              </Button>
            ) : null}
            <Button
              variant="contained"
              sx={button}
              disabled={!continueValue}
              onClick={handleContinue}
            >
              Continuar
            </Button>
          </Box>
        </Box>
        {stepCount === 1 && openPDFModal ? (
          <Box sx={pdfModalContainer}>
            <Box sx={closeButtonBoxPDF}>
              <IconButton onClick={handleClosePDFModal}>
                <CloseIcon />
              </IconButton>
            </Box>
            <PDFViewer
              openModal={openPDFModal}
              setOpenModal={setOpenPDFModal}
              subtitle={documento}
              id={idPDF}
            />
          </Box>
        ) : null}
        <FinishModal
          openModal={openFinishModal}
          setOpenModal={setOpenFinishModal}
          handleContinue={handleContinue}
          handleBack={handleBack}
          // confirmationText="¡El trabajador ha sido dado de baja correctamente!"
          buttonText="Dar de baja"
          navigatePath="/Trabajadores"
        >
          <Typography sx={textStyleFinishModal}>
            Estás a punto de dar de baja a un trabajador ¿Estás seguro que
            quieres continuar?
          </Typography>
        </FinishModal>
      </Box>
    </Modal>
  ) : null;
}
