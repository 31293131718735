// import logo from './logo.svg';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import Poppins from "./fonts/Poppins-Regular.ttf";
import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { makeStyles } from "@mui/styles";
import { SnackbarProvider } from "notistack";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { selectCurrentType } from "./redux/authSlice";
import {
  currentEmpresa,
  currentTrabajador,
  currentFaena,
} from "./redux/entidadesSlice";

import Auth from "./views/Auth/auth";
import SignIn from "./views/Auth/signIn";
import Restablecer from "./views/Auth/restablecer";
import Skeleton from "./views/Drawer Views/skeleton";
import Dashboard from "./views/Drawer Views/Dashboard/dashboard";
import Trabajadores from "./views/Drawer Views/Trabajadores/trabajadores";
import NuevoTrabajador from "./views/Drawer Views/Trabajadores/nuevoTrabajador";
import Clientes from "./views/Drawer Views/Empresa/clientes";
import Empresa from "./views/Drawer Views/Empresa/empresa";
import MiPerfil from "./views/Drawer Views/Mi Perfil/miPerfil";
import MisDatosPersonales from "./views/Drawer Views/Mi Perfil/misDatosPersonales";
import ListaDocumentosTrabajador from "./views/Drawer Views/Trabajadores/listarDocumentosTrabajador";
import TicketsAyuda from "./views/Drawer Views/Mi Perfil/ticketsAyuda";
import Vencimientos from "./views/Drawer Views/Trabajadores/vencimientos";
import CargaMasivaVencimientos from "./views/Drawer Views/Trabajadores/cargaMasivaVencimientos";
import CargaMasivaEmpresas from "./views/Drawer Views/Empresa/cargaMasivaEmpresas";
import CargaMasivaTrabajadores from "./views/Drawer Views/Trabajadores/cargaMasivaTrabajadores";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import NotificationSnack from "./notificationSnack";
import NotFound from "./views/404/404";
import LandingPage from "./views/LandingPage/landingPage";
import Subcontratistas from "./views/Drawer Views/Subcontratistas/subcontratistas";
import NuevoSubcontratista from "./views/Drawer Views/Subcontratistas/nuevoSubcontratista";
import DatosEmpresa from "./views/Drawer Views/Empresa/datosEmpresa";
import ListaDocumentosSubcontratista from "./views/Drawer Views/Subcontratistas/listarDocumentosSubcontratista";
import EstadoSubcontratistas from "./views/Drawer Views/Estado Subcontratistas/estadoSubcontratistas";
import Bajados from "./views/Drawer Views/Trabajadores/bajados";
import ListarEmpresas from "./views/Drawer Views/Empresa/listarEmpresas";
import Verificaciones from "./views/Drawer Views/Verificaciones/verificaciones";
import DatosEmpresaPage from "./views/Drawer Views/Mi Perfil/datosEmpresaPage";
import NuevoTipoTrabajador from "./views/Drawer Views/Empresa/nuevoTipoTrabajador";
import RequireAuth from "./views/Auth/requireAuth";
import ResetPassword from "./views/Auth/resetPassword";
import NuevaEmpresaOutsourcing from "./views/Drawer Views/Empresa/nuevaEmpresaOutsourcing";
import NuevaEmpresaCliente from "./views/Drawer Views/Empresa/nuevaEmpresaCliente";
import ListaDocumentosDadosDeBaja from "./views/Drawer Views/Trabajadores/listarDocumentosDadosDeBaja";
import ListarFaenas from "./views/Drawer Views/Faenas/listarFaenas";
import ListaDocumentosFaena from "./views/Drawer Views/Faenas/listarDocumentosFaena";
import NuevaFaena from "./views/Drawer Views/Faenas/nuevaFaena";
import SubirPermisosInasistencia from "./views/Drawer Views/Faenas/subirPermisosInasistencia";

function App() {
  const type = useSelector(selectCurrentType);
  const trabajador = useSelector(currentTrabajador).id;
  const empresa = useSelector(currentEmpresa).id;
  const faena = useSelector(currentFaena).id;
  // const type = "Admin";

  // const useStyles = makeStyles({
  //   root: { top: 40 }
  // });

  const theme = createTheme({
    palette: {
      primary: {
        light: "#FCFBFB",
        main: "#1876D1",
        darker: "#053e85",
      },
      neutral: {
        light: "#D9D9D9",
        main: "#818181",
        // contrastText: '#fff',
      },
    },
    text: {
      primary: "#FCFBFB",
    },
    typography: {
      fontFamily: ["Poppins"].join(","),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Poppins';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Poppins'), local('Poppins-Regular'), url(${Poppins}) format('ttf');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
        `,
      },
    },
  });

  const routerA = (
    <Route path="" element={<Skeleton />}>
      <Route
        path=""
        index={true}
        element={<Navigate to="/Dashboard" replace />}
      />
      <Route path="Dashboard" element={<Dashboard />} />
      <Route path="Trabajadores" element={<Trabajadores />} />
      <Route
        path="Trabajadores/Listar documentacion"
        element={
          trabajador ? (
            <ListaDocumentosTrabajador />
          ) : (
            <Navigate replace to="/Trabajadores" />
          )
        }
      />
      <Route
        path="Trabajadores/Nuevos trabajadores"
        element={<NuevoTrabajador />}
      />
      <Route path="Trabajadores/Vencimientos" element={<Vencimientos />} />
      <Route
        path="Trabajadores/Carga masiva"
        element={<CargaMasivaTrabajadores />}
      />
      <Route path="Trabajadores/Dados de baja" element={<Bajados />} />
      <Route
        path="Trabajadores/Dados de baja/Listar documentacion"
        element={
          trabajador ? (
            <ListaDocumentosDadosDeBaja />
          ) : (
            <Navigate replace to="/Trabajadores/Dados de baja" />
          )
        }
      />
      <Route
        path="Trabajadores/Vencimientos/Carga masiva"
        element={<CargaMasivaVencimientos />}
      />
      <Route path="Clientes" element={<Clientes />} />
      <Route
        path="Clientes/Faenas"
        element={
          empresa ? <ListarFaenas /> : <Navigate replace to="/Clientes" />
        }
      />
      <Route
        path="Clientes/Faenas/Listar documentacion"
        element={
          empresa ? (
            faena ? (
              <ListaDocumentosFaena />
            ) : (
              <Navigate replace to="Clientes/Faenas" />
            )
          ) : (
            <Navigate replace to="/Clientes" />
          )
        }
      />
      <Route
        path="Clientes/Faenas/Crear faena"
        element={empresa ? <NuevaFaena /> : <Navigate replace to="/Clientes" />}
      />
      <Route
        path="Clientes/Faenas/Trabajadores"
        element={
          empresa ? (
            faena ? (
              <Trabajadores />
            ) : (
              <Navigate replace to="Clientes/Faenas" />
            )
          ) : (
            <Navigate replace to="/Clientes" />
          )
        }
      />
      <Route
        path="Clientes/Faenas/Trabajadores/Listar documentacion"
        element={
          empresa ? (
            trabajador ? (
              <ListaDocumentosTrabajador />
            ) : faena ? (
              <Trabajadores />
            ) : (
              <Navigate replace to="Clientes/Faenas" />
            )
          ) : (
            <Navigate replace to="/Clientes" />
          )
        }
      />
      <Route
        path="Clientes/Faenas/Carga masiva"
        element={
          empresa ? (
            <CargaMasivaEmpresas />
          ) : (
            <Navigate replace to="/Clientes" />
          )
        }
      />
      <Route path="Clientes/Trabajadores" element={<Trabajadores />} />
      <Route
        path="Clientes/Trabajadores/Listar documentacion"
        element={
          empresa ? (
            trabajador ? (
              <ListaDocumentosTrabajador />
            ) : (
              <Navigate replace to="/Clientes/Trabajadores" />
            )
          ) : (
            <Navigate replace to="/Clientes" />
          )
        }
      />
      <Route
        path="Clientes/Trabajadores/Nuevos trabajadores"
        element={
          empresa ? <NuevoTrabajador /> : <Navigate replace to="/Clientes" />
        }
      />
      <Route
        path="Clientes/Trabajadores/Vencimientos"
        element={
          empresa ? <Vencimientos /> : <Navigate replace to="/Clientes" />
        }
      />
      <Route
        path="Clientes/Trabajadores/Carga masiva"
        element={
          empresa ? (
            <CargaMasivaTrabajadores />
          ) : (
            <Navigate replace to="/Clientes" />
          )
        }
      />
      <Route
        path="Clientes/Trabajadores/Vencimientos/Carga masiva"
        element={
          empresa ? (
            <CargaMasivaVencimientos />
          ) : (
            <Navigate replace to="/Clientes" />
          )
        }
      />
      <Route
        path="Clientes/Datos cliente"
        element={
          empresa ? <DatosEmpresa /> : <Navigate replace to="/Clientes" />
        }
      />
      <Route
        path="Clientes/Trabajadores/Dados de baja"
        element={empresa ? <Bajados /> : <Navigate replace to="/Clientes" />}
      />
      <Route
        path="Clientes/Trabajadores/Dados de baja/Listar documentacion"
        element={
          empresa ? (
            trabajador ? (
              <ListaDocumentosDadosDeBaja />
            ) : (
              <Navigate replace to="/Clientes/Trabajadores/Dados de baja" />
            )
          ) : (
            <Navigate replace to="/Clientes" />
          )
        }
      />
      <Route path="Faenas" element={<ListarFaenas />} />
      <Route
        path="Faenas/Listar documentacion"
        element={
          faena ? <ListaDocumentosFaena /> : <Navigate replace to="/Faenas" />
        }
      />
      <Route
        path="Faenas/Listar documentacion/Subir permisos inasistencia"
        element={
          faena ? (
            <SubirPermisosInasistencia />
          ) : (
            <Navigate replace to="/Faenas" />
          )
        }
      />
      <Route path="Faenas/Crear faena" element={<NuevaFaena />} />
      <Route
        path="Faenas/Trabajadores"
        element={faena ? <Trabajadores /> : <Navigate replace to="/Faenas" />}
      />
      <Route
        path="Faenas/Trabajadores/Listar documentacion"
        element={
          trabajador ? (
            <ListaDocumentosTrabajador />
          ) : faena ? (
            <Trabajadores />
          ) : (
            <Navigate replace to="/Faenas" />
          )
        }
      />
      <Route path="Faenas/Carga masiva" element={<CargaMasivaEmpresas />} />
      <Route path="Mi perfil" element={<MiPerfil />} />
      <Route
        path="Mi perfil/Mis datos personales"
        element={<MisDatosPersonales />}
      />
      <Route path="Mi perfil/Datos empresa" element={<DatosEmpresaPage />} />
      <Route path="Mi perfil/Mis tickets de ayuda" element={<TicketsAyuda />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="404" element={<NotFound />} />
    </Route>
  );
  const routerB = (
    <Route path="" element={<Skeleton />}>
      <Route
        path=""
        index={true}
        element={<Navigate to="/Dashboard" replace />}
      />
      <Route path="Dashboard" element={<Dashboard />} />
      <Route path="Subcontratistas" element={<Subcontratistas />} />
      <Route
        path="Subcontratistas/Nuevo subcontratista"
        element={<NuevoSubcontratista />}
      />
      <Route
        path="Subcontratistas/Datos subcontratista"
        element={<DatosEmpresa />}
      />
      <Route
        path="Subcontratistas/Ver documentos de subcontratista"
        element={<ListaDocumentosSubcontratista />}
      />
      <Route
        path="Estado subcontratistas"
        element={<EstadoSubcontratistas />}
      />
      <Route
        path="Estado subcontratistas/Datos subcontratista"
        element={<DatosEmpresa />}
      />
      <Route path="Mi perfil" element={<MiPerfil />} />
      <Route
        path="Mi perfil/Mis datos personales"
        element={<MisDatosPersonales />}
      />
      <Route path="Mi perfil/Datos empresa" element={<DatosEmpresaPage />} />
      <Route path="Mi perfil/Mis tickets de ayuda" element={<TicketsAyuda />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="404" element={<NotFound />} />
    </Route>
  );
  const routerC_Admin = (
    <Route path="" element={<Skeleton />}>
      <Route
        path=""
        index={true}
        element={<Navigate to="/Dashboard" replace />}
      />
      <Route path="Dashboard" element={<Dashboard />} />
      <Route path="Tickets de ayuda" element={<TicketsAyuda />} />
      <Route path="Verificaciones pendientes" element={<Verificaciones />} />
      <Route path="Empresas" element={<ListarEmpresas />} />
      <Route
        path="Empresas/Faenas"
        element={
          empresa ? <ListarFaenas /> : <Navigate replace to="/Empresas" />
        }
      />
      <Route path="Empresas/Faenas/Crear faena" element={<NuevaFaena />} />
      <Route
        path="Empresas/Listar documentacion"
        element={empresa ? <Empresa /> : <Navigate replace to="/Empresas" />}
      />
      <Route
        path="Empresas/Crear empresa outsourcing"
        element={<NuevaEmpresaOutsourcing />}
      />
      <Route
        path="Empresas/Crear empresa mandante"
        element={<NuevaEmpresaCliente />}
      />
      <Route
        path="Empresas/Crear empresa/Crear tipo trabajador"
        element={<NuevoTipoTrabajador />}
      />
      <Route path="Faenas" element={<ListarFaenas />} />
      <Route
        path="Faenas/Listar documentacion"
        element={
          faena ? <ListaDocumentosFaena /> : <Navigate replace to="/Faenas" />
        }
      />
      <Route path="Faenas/Crear faena" element={<NuevaFaena />} />
      <Route
        path="Faenas/Trabajadores"
        element={faena ? <Trabajadores /> : <Navigate replace to="/Faenas" />}
      />
      <Route
        path="Faenas/Trabajadores/Listar documentacion"
        element={
          trabajador ? (
            <ListaDocumentosTrabajador />
          ) : faena ? (
            <Trabajadores />
          ) : (
            <Navigate replace to="/Faenas" />
          )
        }
      />
      <Route path="Trabajadores" element={<Trabajadores />} />
      <Route
        path="Trabajadores/Listar documentacion"
        element={
          trabajador ? (
            <ListaDocumentosTrabajador />
          ) : (
            <Navigate replace to="/Trabajadores" />
          )
        }
      />
      <Route path="Trabajadores/Dados de baja" element={<Bajados />} />
      <Route
        path="Trabajadores/Dados de baja/Listar documentacion"
        element={
          trabajador ? (
            <ListaDocumentosDadosDeBaja />
          ) : (
            <Navigate replace to="/Trabajadores/Dados de baja" />
          )
        }
      />
      <Route path="Documentos" element={<ListaDocumentosTrabajador />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="404" element={<NotFound />} />
    </Route>
  );
  const routerC_Supp = (
    <Route path="" element={<Skeleton />}>
      <Route
        path=""
        index={true}
        element={<Navigate to="/Tickets de ayuda" replace />}
      />
      <Route
        path="Dashboard"
        element={<Navigate to="/Tickets de ayuda" replace />}
      />
      <Route path="Tickets de ayuda" element={<TicketsAyuda />} />
      <Route path="Verificaciones pendientes" element={<Verificaciones />} />
      <Route path="Empresas" element={<ListarEmpresas />} />
      <Route
        path="Empresas/Listar documentacion"
        element={empresa ? <Empresa /> : <Navigate replace to="/Empresas" />}
      />
      <Route
        path="Empresas/Faenas"
        element={
          empresa ? <ListarFaenas /> : <Navigate replace to="/Empresas" />
        }
      />
      <Route path="Empresas/Faenas/Crear faena" element={<NuevaFaena />} />
      <Route path="Faenas" element={<ListarFaenas />} />
      <Route path="Faenas/Crear faena" element={<NuevaFaena />} />
      <Route
        path="Faenas/Listar documentacion"
        element={
          faena ? <ListaDocumentosFaena /> : <Navigate replace to="/Faenas" />
        }
      />
      <Route
        path="Faenas/Trabajadores"
        element={faena ? <Trabajadores /> : <Navigate replace to="/Faenas" />}
      />
      <Route path="Trabajadores" element={<Trabajadores />} />
      <Route
        path="Trabajadores/Listar documentacion"
        element={
          trabajador ? (
            <ListaDocumentosTrabajador />
          ) : (
            <Navigate replace to="/Trabajadores" />
          )
        }
      />
      <Route path="Trabajadores/Dados de baja" element={<Bajados />} />
      <Route
        path="Trabajadores/Dados de baja/Listar documentacion"
        element={
          trabajador ? (
            <ListaDocumentosDadosDeBaja />
          ) : (
            <Navigate replace to="/Trabajadores/Dados de baja/" />
          )
        }
      />
      <Route path="Documentos" element={<ListaDocumentosTrabajador />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="404" element={<NotFound />} />
    </Route>
  );

  const chooseRouter = (type) => {
    if (type === "A") {
      return routerA;
    } else if (type === "B") {
      return routerB;
    } else if (type === "Supp") {
      return routerC_Supp;
    } else if (type === "Admin") {
      return routerC_Admin;
    } else {
      return <Route path="" element={<Navigate to="/home" replace />} />;
    }
  };

  return (
    <SnackbarProvider
      dense
      preventDuplicate
      autoHideDuration={5000}
      persist={false}
      maxSnack={9}
      iconVariant={{
        info: <NotificationsNoneOutlinedIcon />,
      }}
      Components={{
        notification: NotificationSnack,
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/home" element={<LandingPage />} />
            <Route path="/auth" element={<Auth />}>
              <Route
                path=""
                index={true}
                element={<Navigate to="login" replace />}
              />
              <Route path="login" element={<SignIn />} />
              <Route path="reset" element={<Restablecer />} />
              {/* <Route path="restablecer/codigo" element={<Codigo />} /> */}
              <Route
                path="password/reset/:uid/:token"
                element={<ResetPassword />}
              />
            </Route>
            <Route path="*" element={<RequireAuth />}>
              {chooseRouter(type)}
            </Route>

            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
