import React, { useState, useEffect } from "react";
import {
  useOutletContext,
  useNavigate,
  useLocation,
  Link
} from "react-router-dom";
import { Typography, Box, Modal, IconButton, Button } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";

/* CUSTOM LIBRARIES */
import CustomTable from "../common/components/customTable";
import CustomSelect from "../common/components/customSelect";
import PDFViewer from "../common/components/pdfViewer";
import SubirDocumentoModal from "../common/modals/subirDocumentoModal";
import FinishModal from "../common/modals/finishModal";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import {
  MONTHSNUM,
  POLLING_INTERVAL,
  transition
} from "../../../controller/utils";
import {
  headCellsDocumentos,
  headCellsDocumentosOutsourcing,
  headCellsDocumentosTrabajador
} from "../../../controller/listas";
import { useSelector } from "react-redux";
import { selectCurrentType } from "../../../redux/authSlice";
import { currentFaena } from "../../../redux/entidadesSlice";
import { useGetDocumentosFaenaQuery } from "../../../api/docsApiSlice";

export default function ListaDocumentosFaena() {
  const { setTitleValue, setSubtitleValue } = useOutletContext();
  const userType = useSelector(selectCurrentType);
  const selectedFaena = useSelector(currentFaena);

  const { currentData, isSuccess, isLoading, isFetching } =
    useGetDocumentosFaenaQuery(
      { id: selectedFaena.id },
      { pollingInterval: POLLING_INTERVAL }
    );

  const navigate = useNavigate();
  const littleSize = littleSizeFunc();

  const [filtroTipoDocs, setFiltroTipoDocs] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [año, setAño] = useState("");
  const [listaAños, setListaAños] = useState([]);
  const [mes, setMes] = useState("");
  const [listaMeses, setListaMeses] = useState([]);
  const [lineaDocumentoSeleccionada, setLineaDocumentoSeleccionada] =
    useState("");
  const [openSubirDocModal, setOpenSubirDocModal] = useState(false);
  const [openPDFViewModal, setOpenPDFViewModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [listaDocumentosTrabajador, setListaDocumentosTrabajador] = useState(
    []
  );
  const [
    listaDocumentosTrabajadorFiltrada,
    setListaDocumentosTrabajadorFiltrada
  ] = useState([]);

  useEffect(() => {
    if (userType) {
      if (selectedFaena) {
        setSubtitleValue(`Faena: ${selectedFaena.nombre}`);
        // setListaDocumentosTrabajador(selectedFaena.documentos);
        // setListaDocumentosTrabajadorFiltrada(selectedFaena.documentos);
      } else {
        navigate("/Faenas");
      }
    }
  }, []);

  useEffect(() => {
    if (isSuccess && currentData) {
      var lista = [];
      let listaDocs = [];
      var lista_años = [];
      var lista_meses = [];
      currentData?.data.map((t) => {
        lista.push({
          id: t.nombre_tipo_documento,
          value: t.nombre_tipo_documento
        });
        lista_años.push({
          id: t.fecha_emitido.split("-")[0],
          value: t.fecha_emitido.split("-")[0]
        });
        lista_meses.push({
          id: t.fecha_emitido.split("-")[1],
          value: MONTHSNUM[t.fecha_emitido.split("-")[1]]
        });
        let clonedObject = { ...t };
        clonedObject = {
          ...clonedObject,
          titulo: t.nombre_tipo_documento
        };
        listaDocs.push(clonedObject);
      });
      let jsonObject = lista.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      lista = Array.from(uniqueSet).map(JSON.parse);
      setFiltroTipoDocs(lista);

      jsonObject = lista_años.map(JSON.stringify);
      uniqueSet = new Set(jsonObject);
      lista_años = Array.from(uniqueSet).map(JSON.parse);
      setListaAños(lista_años);

      jsonObject = lista_meses.map(JSON.stringify);
      uniqueSet = new Set(jsonObject);
      lista_meses = Array.from(uniqueSet).map(JSON.parse);
      setListaMeses(lista_meses);

      setListaDocumentosTrabajador([...listaDocs]);
      setListaDocumentosTrabajadorFiltrada([...listaDocs]);
    }
  }, [currentData, isSuccess, isFetching]);

  // Filtros
  useEffect(() => {
    if (isSuccess && currentData) {
      if (
        listaDocumentosTrabajador.length > 0 ||
        listaDocumentosTrabajador === currentData.data
      ) {
        let listaDocs =
          listaDocumentosTrabajador.lenght >
          listaDocumentosTrabajadorFiltrada.lenght
            ? [...listaDocumentosTrabajadorFiltrada]
            : [...listaDocumentosTrabajador];
        if (tipoDocumento) {
          listaDocs = listaDocs
            .map((docObj) => {
              if (docObj.nombre_tipo_documento === tipoDocumento) {
                return docObj;
              }
              return null;
            })
            .filter((docObj) => docObj !== null);
          setListaDocumentosTrabajadorFiltrada(listaDocs);
        }
        if (año) {
          listaDocs = listaDocs
            .map((docObj) => {
              if (docObj.fecha_emitido.split("-")[0] === año) {
                return docObj;
              }
              return null;
            })
            .filter((docObj) => docObj !== null);
          setListaDocumentosTrabajadorFiltrada(listaDocs);
        }
        if (mes) {
          listaDocs = listaDocs
            .map((docObj) => {
              if (docObj.fecha_emitido.split("-")[1] === mes) {
                return docObj;
              }
              return null;
            })
            .filter((docObj) => docObj !== null);
          setListaDocumentosTrabajadorFiltrada(listaDocs);
        } else {
          setListaDocumentosTrabajadorFiltrada(listaDocs);
        }
      }
    }
  }, [tipoDocumento, año, mes, currentData, isFetching, isSuccess]);

  const handleLimpiaFiltros = () => {
    setTipoDocumento("");
    setAño("");
    setMes("");
  };

  const handleClosePDFModal = () => setOpenPDFViewModal(false);

  /* CSS */
  const contenido = {
    minHeight: "58vh",
    height: "fit-content",
    maxHeight: "84vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "5.17vh",
    transition: transition
  };
  const barraFiltros = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap-reverse",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: littleSize ? "center" : "start",
    gap: "11px"
  };
  const filtrosBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "11px",
    alignItems: "center"
  };
  const formControl = {
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    height: "40px",
    width: "16vw",
    minWidth: "210px"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const modalPDF = {
    transition: transition,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    height: "fit-content",
    " .MuiBox-root.MuiBox-root": {
      borderRadius: "20px"
    }
  };
  const textStyleFinishModal = {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "20px",
    color: "#818181"
  };
  const buttonLimparFiltros = {
    height: "fit-content",
    width: "fit-content",
    transition: transition,
    textTransform: "none ",
    borderRadius: "12px",
    background: "#1876D1",
    color: "#FCFBFB",
    fontWeight: 800,
    fontSize: "12px",
    gap: "5px"
  };
  const buttonStyle = {
    height: "40px",
    textTransform: "none",
    minHeight: "100%",
    fontWeight: 600,
    color: "#FCFBFB",
    backgroundColor: "#1876D1",
    borderRadius: "10px",
    padding: "5px 20px",
    "&:hover": {
      background: "#1876D1"
    }
  };

  return (selectedFaena || userType === "Supp" || userType === "Admin") &&
    listaDocumentosTrabajadorFiltrada ? (
    <Box sx={contenido}>
      <Box sx={barraFiltros}>
        {tipoDocumento || mes || año ? (
          <Box sx={formControl}>
            <Button
              onClick={handleLimpiaFiltros}
              disableElevation={true}
              variant="contained"
              sx={buttonLimparFiltros}
            >
              <FilterListOffOutlinedIcon />
              Restablecer filtros
            </Button>
          </Box>
        ) : null}
        <Box sx={filtrosBox}>
          <Box sx={formControl}>
            <CustomSelect
              label="Filtrar por documento"
              value={tipoDocumento}
              setValue={setTipoDocumento}
            >
              {filtroTipoDocs}
            </CustomSelect>
          </Box>
          <Box sx={formControl}>
            <CustomSelect label="Filtrar por año" value={año} setValue={setAño}>
              {listaAños}
            </CustomSelect>
          </Box>
          <Box sx={formControl}>
            <CustomSelect label="Filtrar por mes" value={mes} setValue={setMes}>
              {listaMeses}
            </CustomSelect>
          </Box>
          <Button
            sx={buttonStyle}
            component={Link}
            to="Subir permisos inasistencia"
          >
            Subir justificación de inasitencia
          </Button>
        </Box>
      </Box>
      <CustomTable
        headCells={
          userType === "Supp" || userType === "Admin"
            ? headCellsDocumentos
            : headCellsDocumentosOutsourcing
        }
        rows={listaDocumentosTrabajadorFiltrada}
        setRows={setListaDocumentosTrabajadorFiltrada}
        variant="documentos"
        setLineaSeleccionada={setLineaDocumentoSeleccionada}
        setOpenSubirDocModal={setOpenSubirDocModal}
        setOpenPDFViewModal={setOpenPDFViewModal}
        setOpenErrorModal={setOpenErrorModal}
        isLoading={isLoading}
      />
      <Modal open={openPDFViewModal} onClose={handleClosePDFModal}>
        <Box sx={modalPDF}>
          <Box sx={closeButtonBox}>
            <IconButton onClick={handleClosePDFModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <PDFViewer
            id={lineaDocumentoSeleccionada.id}
            openModal={openPDFViewModal}
            setOpenModal={setOpenPDFViewModal}
            subtitle={
              userType === "Supp" || userType === "Admin"
                ? `${lineaDocumentoSeleccionada.titulo} - ${lineaDocumentoSeleccionada.nombre}`
                : `${lineaDocumentoSeleccionada.titulo} - ${selectedFaena.nombre}`
            }
          />
        </Box>
      </Modal>
      <SubirDocumentoModal
        id={lineaDocumentoSeleccionada.id}
        openModal={openSubirDocModal}
        setOpenModal={setOpenSubirDocModal}
        subtitle={
          userType === "Supp" || userType === "Admin"
            ? `${lineaDocumentoSeleccionada.titulo} - ${lineaDocumentoSeleccionada.nombre}`
            : `${lineaDocumentoSeleccionada.titulo} - ${selectedFaena.nombre}`
        }
        maxCount={1}
      />
      <FinishModal openModal={openErrorModal} setOpenModal={setOpenErrorModal}>
        <Typography sx={textStyleFinishModal}>
          <ul>
            {lineaDocumentoSeleccionada.razon_rechazo?.split("\n").length > 1
              ? lineaDocumentoSeleccionada.razon_rechazo
                  ?.split("\n")
                  .map((razon) => (razon ? <li>{razon}</li> : null))
              : lineaDocumentoSeleccionada.razon_rechazo}
          </ul>
        </Typography>
      </FinishModal>
    </Box>
  ) : null;
}
