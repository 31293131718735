import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Box, Button, TextField, Typography } from "@mui/material";

/* CUSTOM LIBRARIES */
import LoadingRocket from "../common/things/loadingRocket";
import CustomSelect from "../common/components/customSelect";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { emptyFileObj, transition } from "../../../controller/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateUserMutation,
  useLazyGetUserQuery
} from "../../../api/authApiSlice";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../redux/confirmModalSlice";
import { useGetEmpresasQuery } from "../../../api/empresasApiSlice";
import { selectCurrentType } from "../../../redux/authSlice";
import { useAddFaenaMutation } from "../../../api/faenasApiSlice";
import { currentEmpresa } from "../../../redux/entidadesSlice";

export default function NuevaFaena() {
  const { setTitleValue, setSubtitleValue } = useOutletContext();
  const userType = useSelector(selectCurrentType);
  const selectedEmpresa = useSelector(currentEmpresa);
  const littleSize = littleSizeFunc();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentData, isSuccess, isLoading, isFetching } =
    useGetEmpresasQuery("ambas");
  const [createFaena, creaFaenaResult] = useAddFaenaMutation();

  const [continueValue, setContinueValue] = useState(false);
  const [nombreFaena, setNombreFaena] = useState("");
  const [mandante, setMandante] = useState();
  const [outsourcing, setOutsourcing] = useState();
  const [listaMandantes, setListaMandantes] = useState([]);
  const [listaOutsourcing, setListaOutsourcing] = useState([]);

  useEffect(() => {
    setSubtitleValue("");
    if (userType === "A") {
      setOutsourcing("usuario");
    }
  }, []);

  useEffect(() => {
    if (selectedEmpresa.id) {
      setMandante(selectedEmpresa.id);
    }
  }, []);

  useEffect(() => {
    setContinueValue(mandante && outsourcing && nombreFaena);
  }, [mandante, outsourcing, nombreFaena]);

  useEffect(() => {
    if (isSuccess && currentData) {
      setListaMandantes([...currentData.data.mandante]);
      setListaOutsourcing([...currentData.data.outsourcing]);
    }
  }, [currentData, isSuccess, isLoading, isFetching]);

  const handleFinish = async () => {
    try {
      const faenaD = await createFaena({
        nombre: nombreFaena,
        empresa_outsourcing: outsourcing,
        empresa_mandante: mandante
      }).unwrap();
      dispatch(setSuccessText(`¡Faena creada con exito!\n`));
      setContinueValue(false);
      navigate("/Faenas");
    } catch (err) {
      dispatch(
        setErrorText(
          `Hubo un problema al momento de crear la faena\n\nIntente nuevamente más tarde o contactese con nosotros`
        )
      );
      console.error("ERROR");
      console.error(err);
    }
    dispatch(setOpenConfirmModal(true));
  };

  const handleSetMandante = (value) => {
    setMandante(value);
  };

  const handleSetOutsourcing = (value) => {
    setOutsourcing(value);
  };

  const handleContinue = () => {};

  /* CSS */
  const todo = {
    padding: "0 0 0 0",
    height: "100%",
    minHeight: "58vh",
    height: "fit-content",
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    transition: transition
  };
  const datosButtonBox = {
    transition: transition,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: littleSize ? "center" : "normal",
    justifyContent: "space-between",
    gap: "5vh",
    padding: "0vh 0px 6vh 0px"
  };
  const datosBox = {
    transition: transition,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: littleSize ? "center" : ""
  };
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : ""
  };
  const selectDatosBox = {
    gap: "0px",
    maxWidth: "15.6vw",
    width: "14.58vw",
    minWidth: "210px",
    padding: "2vh 0 3vh 0"
  };
  const textField = {
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400
    },
    ".Mui-disabled": {
      opacity: 0.7
    }
  };
  const tituloDatos = {
    transition: transition,
    fontWeight: 900,
    fontSize: "26px",
    lineHeight: "140%",
    color: "#1876D1"
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px",
    padding: "0vh 0px 6vh 0px"
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1"
  };

  return (
    <Box sx={todo}>
      <Box sx={datosButtonBox}>
        <Typography sx={tituloDatos}>Rellenar los siguientes datos:</Typography>
        <Box sx={datosBox}>
          Por favor asignele un nombre a la faena nueva:
          <Box sx={selectDatosBox}>
            <TextField
              sx={textField}
              req={true}
              size="small"
              fullWidth
              required
              id="nombre_faena"
              label="Nombre Faena"
              type="text"
              value={nombreFaena}
              onChange={(e) => setNombreFaena(e.target.value)}
            />
          </Box>
          Por favor seleccione las empresas respectivamente las cuales la faena
          estará relacionada:
          <Box sx={selectDatosBoxBox}>
            <Box sx={selectDatosBox}>
              <CustomSelect
                req={true}
                label="Seleccionar Mandante"
                value={mandante}
                setValue={handleSetMandante}
                valueName="nombre"
              >
                {listaMandantes}
              </CustomSelect>
            </Box>
            {userType === "A" ? null : (
              <Box sx={selectDatosBox}>
                <CustomSelect
                  req={true}
                  label="Seleccionar Outsourcing"
                  value={outsourcing}
                  setValue={handleSetOutsourcing}
                  valueName="nombre"
                >
                  {listaOutsourcing}
                </CustomSelect>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={buttonBox}>
        <Button
          sx={button}
          disableElevation
          variant="contained"
          type="submit"
          onClick={handleFinish}
          disabled={!continueValue}
        >
          Crear Faena
        </Button>
      </Box>
      <LoadingRocket isLoading={isLoading} />
    </Box>
  );
}
