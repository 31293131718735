import useWindowSize from "../../controller/windowSize";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

import Slide, { SlideProps } from "@mui/material/Slide";
import { littleSizeFunc } from "../../controller/windowSize";
import astronauta from "../../images/auth/autronauta.png";
// import './SignIn.css'

var littleSize = false;

export default function Auth() {
  const [currentValueUsr, setCurrentValueUsr] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState("");

  const littleSize = littleSizeFunc();

  const handleCloseSnack = () => {
    setOpenSnackbar(false);
  };

  /* CSS */
  const inicio = {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center",
    background: "#CEE0F3",
    overflowY: "auto",
    gap: "1vh",
    ".MuiSnackbar-root": {
      top: "0px"
    }
  };
  const image_mitad = {
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px",
    maxWidth: littleSize ? "90vw" : "45vw",
    padding: "5vh 5vw 5vh 0vw"
  };
  const image = {
    width: "100%",
    height: "auto",
    filter:
      "invert(31%) sepia(93%) saturate(1222%) hue-rotate(189deg) brightness(98%) contrast(90%)"
  };
  const snackbarStyle = {
    width: "100%",
    top: "0px !important",
    left: "0px",
    right: "0px",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    "& .MuiSnackbarContent-root": {
      justifyContent: "center",
      backgroundColor: snackbarColor,
      width: "inherit",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "150%",
      borderRadius: "inherit",
      top: "inherit"
    }
  };

  return (
    <Box style={inicio}>
      <CssBaseline />
      <Box>
        <Outlet
          context={[
            currentValueUsr,
            setCurrentValueUsr,
            openSnackbar,
            setOpenSnackbar,
            snackbarMessage,
            setSnackbarMessage,
            snackbarColor,
            setSnackbarColor
          ]}
        />
      </Box>
      <Box sx={image_mitad} maxWidth={false}>
        <Box
          component="img"
          loading="lazy"
          sx={image}
          alt="imagen"
          src={astronauta}
        />
      </Box>
      <Snackbar
        sx={snackbarStyle}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
        message={snackbarMessage}
        key={snackbarMessage}
        transitionDuration={500}
        TransitionComponent={Slide}
        disableWindowBlurListener
      />
    </Box>
  );
}
