import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut, setCredentials, setUser } from "../redux/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.access;
    headers.append("Origin", `${process.env.REACT_APP_BASE_URL}`);
    if (token) {
      headers.append("Content-Type", `application/json`);
      headers.set("Authorization", `JWT ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  const refreshToken = localStorage.getItem("refresh");
  if (result?.error?.status === 403 || result?.error?.status === 401) {
    const refreshResult = await baseQuery(
      {
        method: "POST",
        body: { refresh: refreshToken },
        url: "/auth/jwt/refresh/",
      },
      api,
      extraOptions
    );
    if (refreshResult?.data) {
      api.dispatch(
        setCredentials({
          user: localStorage.getItem("user"),
          access: refreshResult.data.access,
          refresh: refreshToken,
        })
      );
      result = await baseQuery(args, api, extraOptions);
      // socket = await socketConnected;
      // console.log("AFTER AWAIT");
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  // pollingInterval: process.env.PRODUCTION ? 3000 : 10000,
  refetchOnMountOrArgChange: process.env.DEBUG ? 3 : 90,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  // skipPollingIfUnfocused: process.env.PRODUCTION
});
