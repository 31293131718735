import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  NativeSelect,
  OutlinedInput
} from "@mui/material";

/* ICONS */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/* CONTROLLER VARIABLES */
import {
  transition,
  selectHeight,
  selectMaxHeight
} from "../../../../controller/utils";

export default function CustomSelect(props) {
  const {
    value,
    setValue,
    req,
    label,
    fontWeightValue,
    errorText = "",
    disabled = false,
    helperText = "",
    valueName = "value",
    object = false
  } = props;
  const [filtroSeleccionadoValue, setFiltroSeleccionadoValue] = useState(false);

  const handleValueChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === "") {
      setFiltroSeleccionadoValue(false);
    } else {
      setFiltroSeleccionadoValue(true);
    }
  };

  /* CSS */
  const form = {
    width: "100%"
  };
  const labelStyle = {
    fontWeight: fontWeightValue ? fontWeightValue : 600,
    width: "80%",
    ".Mui-disabled": {
      opacity: 0.7
    }
  };
  const filtroSeleccionado = {
    width: "100%",
    borderRadius: "10px",
    transition: transition,
    fontWeight: fontWeightValue ? fontWeightValue : 600,
    fontSize: "100%",
    lineHeight: "150%",
    alignItems: "center",
    color: "#1876D1",
    background: "#FFFFFF",
    height: selectHeight,
    maxHeight: selectMaxHeight,
    ".Mui-disabled": {
      opacity: 1
    }
  };
  const filtroVacioSiempre = {
    width: "100%",
    borderRadius: "10px",
    transition: transition,
    fontWeight: fontWeightValue ? fontWeightValue : 600,
    fontSize: "100%",
    display: "flex",
    alignItems: "center",
    lineHeight: "150%",
    // color: "#818181",
    background: "#FFFFFF",
    height: selectHeight,
    maxHeight: selectMaxHeight,
    ".Mui-disabled": {
      opacity: 1
    }
  };
  const filtroVacio = {
    width: "100%",
    borderRadius: "10px",
    transition: transition,
    fontWeight: fontWeightValue ? fontWeightValue : 600,
    fontSize: "100%",
    lineHeight: "150%",
    color: "#818181",
    background: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    height: selectHeight,
    maxHeight: selectMaxHeight,
    "&:hover": {
      color: "#1876D1"
    },
    ".Mui-disabled": {
      opacity: 1
    }
  };

  return (
    <FormControl fullwidth sx={form} size="small" required={req}>
      <InputLabel id={`${label}-id`} disabled={disabled} sx={labelStyle}>
        {label}
      </InputLabel>
      {props.children.length > 100 ? (
        <NativeSelect
          input={<OutlinedInput label={label} />}
          disabled={disabled}
          error={errorText}
          defaultValue={""}
          labelId={`${label}-id`}
          required={req}
          value={value || ""}
          label={label}
          onChange={handleValueChange}
          IconComponent={KeyboardArrowDownIcon}
          style={{ marginTop: 0, border: "1px #111111" }}
          sx={filtroSeleccionadoValue ? filtroSeleccionado : filtroVacioSiempre}
        >
          <option style={filtroVacioSiempre} aria-label="None" value="" />
          {props.children.map((val) => (
            <option
              style={
                object
                  ? val.id === value.id
                    ? filtroSeleccionado
                    : filtroVacio
                  : val.id === value
                  ? filtroSeleccionado
                  : filtroVacio
              }
              value={object ? JSON.stringify(val) : val.id}
              key={val.id}
            >
              {val[`${valueName}`]}
            </option>
          ))}
        </NativeSelect>
      ) : (
        <Select
          disabled={disabled}
          error={errorText}
          labelId={`${label}-id`}
          required={req}
          value={value || ""}
          onChange={handleValueChange}
          label={label}
          displayEmpty={req}
          IconComponent={KeyboardArrowDownIcon}
          sx={filtroSeleccionadoValue ? filtroSeleccionado : filtroVacioSiempre}
          // MenuProps= {keepMounted ? { keepMounted: keepMounted} : {}}
        >
          {req ? null : (
            <MenuItem sx={filtroVacioSiempre} value="">
              {label}
            </MenuItem>
          )}
          {props.children.length > 0
            ? props.children.map((val) => (
                <MenuItem
                  sx={
                    object
                      ? val.id === value.id
                        ? filtroSeleccionado
                        : filtroVacio
                      : val.id === value
                      ? filtroSeleccionado
                      : filtroVacio
                  }
                  value={object ? JSON.stringify(val) : val.id}
                  key={val.id}
                >
                  {val[`${valueName}`]}
                </MenuItem>
              ))
            : null}
        </Select>
      )}
      {helperText ? <FormHelperText error>{errorText}</FormHelperText> : null}
    </FormControl>
  );
}
