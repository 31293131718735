import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Box, Typography, Button, Modal, IconButton } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";

/* CUSTOM LIBRARIES */
import CustomStepper from "../common/components/customStepper";
import DragDropFile from "../common/components/dragAndDrop";
import CustomTable from "../common/components/customTable";
import FinishModal from "../common/modals/finishModal";
import SubirDocumentoModal from "../common/modals/subirDocumentoModal";
import CustomSelect from "../common/components/customSelect";
import PDFViewer from "../common/components/pdfViewer";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { transition } from "../../../controller/utils";
import {
  stepsCargaMasivaTrabajadores,
  headCellsCargaMasivaTrabajadores
} from "../../../controller/listas";
import { useDispatch, useSelector } from "react-redux";
import { currentEmpresa } from "../../../redux/entidadesSlice";
import { useGetFaenasQuery } from "../../../api/faenasApiSlice";
import { useAddDocumentosMasivosMutation } from "../../../api/docsApiSlice";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../redux/confirmModalSlice";

/* TEST DATA */
import {
  tabla_lista_clientes,
  tabla_lista_trabajadores
} from "../../../controller/testData";
import LoadingRocket from "../common/things/loadingRocket";

function FirstPage(props) {
  const {
    setContinueValue,
    cliente,
    setCliente,
    faena,
    setFaena,
    faenaNueva,
    setFaenaNueva,
    listaEmpresas,
    listaFaenas
  } = props;
  const selectedEmpresa = useSelector(currentEmpresa);
  const littleSize = littleSizeFunc();

  const [listaEmpresasCopy, setListaEmpresasCopy] = useState([]);
  const [listaFaenasCopy, setListaFaenasCopy] = useState([]);

  useEffect(() => {
    if (selectedEmpresa.id) {
      setCliente(selectedEmpresa.id);
    }
  }, []);

  useEffect(() => {
    setListaFaenasCopy([
      ...listaFaenas,
      { id: "otro", value: "Crear faena nueva" }
    ]);
    setListaEmpresasCopy(listaEmpresas);
  }, [listaFaenas, listaEmpresas]);

  useEffect(() => {
    setContinueValue(cliente && faena);
  }, [cliente, faena]);

  const handleSetCliente = (value) => {
    setCliente(value);
    let lista = [...listaFaenas].filter((faena) => faena.empresa === value);
    if (![...lista].includes(faena)) {
      setFaena("");
    }
    setListaFaenasCopy(lista);
  };

  const handleSetFaena = (value) => {
    setFaena(value);
    if (value != "otro") {
      let faena = listaFaenas.find((f) => f.id === value);
      let empresa = listaEmpresas.find((e) => faena.empresa === e.id);
      setCliente(empresa.id);
    }
  };

  /* CSS */
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : ""
  };
  const selectDatosBox = {
    gap: "0px",
    maxWidth: "15.6vw",
    width: "14.58vw",
    minWidth: "210px"
  };
  const textFieldBox = {
    // maxWidth: "300px",
    width: "14.58vw",
    minWidth: "210px"
  };
  const textField = {
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400
    },
    "& .Mui-disabled": {
      opacity: 1
    }
  };

  return (
    <Box sx={selectDatosBoxBox}>
      <Box sx={selectDatosBox}>
        <CustomSelect
          req={true}
          label="Seleccionar Cliente"
          value={cliente}
          setValue={handleSetCliente}
        >
          {listaEmpresasCopy}
        </CustomSelect>
      </Box>
      <Box sx={selectDatosBox}>
        <CustomSelect
          req={true}
          label="Seleccionar Faena"
          value={faena}
          setValue={handleSetFaena}
        >
          {listaFaenasCopy}
        </CustomSelect>
      </Box>
      {faena == "otro" ? (
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="faena"
            label="Nombre de la faena"
            type="text"
            value={faenaNueva}
            onChange={(e) => setFaenaNueva(e.target.value)}
          />
        </Box>
      ) : null}
    </Box>
  );
}

function SecondPage(props) {
  const { setContinueValue, archivos, setArchivos, isLoading } = props;
  const littleSize = littleSizeFunc();

  /* CSS */
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : ""
  };

  return isLoading ? (
    <LoadingRocket isLoading={isLoading} />
  ) : (
    <Box sx={selectDatosBoxBox}>
      <DragDropFile
        setContinueValue={setContinueValue}
        uploadedFiles={archivos}
        setUploadedFiles={setArchivos}
      />
    </Box>
  );
}

function ThirdPage(props) {
  const { cliente } = props;
  const [openSubirDocModal, setOpenSubirDocModal] = useState(false);
  const [openPDFViewModal, setOpenPDFViewModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [lineaDocumentoSeleccionada, setLineaDocumentoSeleccionada] =
    useState("");
  const [listaDocumentosTrabajador, setListaDocumentosTrabajador] = useState(
    []
  );

  useEffect(() => {
    const listaTrabTemp = tabla_lista_trabajadores.list
      .map((trabajador) => {
        return trabajador.documentos.map((doc) => {
          if (cliente === trabajador.cliente) {
            doc.cliente = tabla_lista_clientes.list.find(
              (c) => c.id === cliente
            ).value;
            doc.nombre = trabajador.nombre;
            doc.rut = trabajador.rut;
            return doc;
          }
          return null;
        });
      })
      .flat(1)
      .filter((trabajadorObj) => trabajadorObj !== null);

    setListaDocumentosTrabajador(listaTrabTemp);
  }, []);

  const handleClosePDFModal = (e) => setOpenPDFViewModal(false);

  /* CSS */
  const contenido = {
    width: "100%",
    height: "fit-content",
    maxHeight: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "1vh",
    transition: transition
  };
  const textStyleFinishModal = {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "20px",
    color: "#818181"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const modalPDF = {
    transition: transition,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    height: "fit-content",
    " .MuiBox-root.MuiBox-root": {
      borderRadius: "20px"
    }
  };

  return (
    <Box sx={contenido}>
      <CustomTable
        search={false}
        headCells={headCellsCargaMasivaTrabajadores}
        rows={listaDocumentosTrabajador}
        setRows={setListaDocumentosTrabajador}
        variant="documentos"
        setLineaSeleccionada={setLineaDocumentoSeleccionada}
        setOpenSubirDocModal={setOpenSubirDocModal}
        setOpenPDFViewModal={setOpenPDFViewModal}
        setOpenErrorModal={setOpenErrorModal}
      />
      <Modal open={openPDFViewModal} onClose={handleClosePDFModal}>
        <Box sx={modalPDF}>
          <Box sx={closeButtonBox}>
            <IconButton onClick={handleClosePDFModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <PDFViewer
            openModal={openPDFViewModal}
            setOpenModal={setOpenPDFViewModal}
            subtitle={`${lineaDocumentoSeleccionada.documento} - ${lineaDocumentoSeleccionada.nombre}`}
          />
        </Box>
      </Modal>
      <SubirDocumentoModal
        openModal={openSubirDocModal}
        setOpenModal={setOpenSubirDocModal}
        subtitle={`${lineaDocumentoSeleccionada.nombre} - ${lineaDocumentoSeleccionada.documento}`}
        doneNavigation="/Trabajadores/Carga masiva"
        maxCount={1}
      />
      <FinishModal openModal={openErrorModal} setOpenModal={setOpenErrorModal}>
        <Typography sx={textStyleFinishModal}>
          El documento adjunto no corresponde a un/a{" "}
          <b>{lineaDocumentoSeleccionada.documento}</b> vigente.
        </Typography>
      </FinishModal>
    </Box>
  );
}

export default function CargaMasivaTrabajadores() {
  const { setSubtitleValue } = useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const littleSize = littleSizeFunc();
  const selectedEmpresa = useSelector(currentEmpresa);

  const faenasState = useGetFaenasQuery();
  const [uploadDocs, uploadResults] = useAddDocumentosMasivosMutation();

  const [stepCount, setStepCount] = useState(0);
  const [continueValue, setContinueValue] = useState(false);
  const [openFinishModal, setOpenFinishModal] = useState(false);
  const [archivos, setArchivos] = useState([]);

  const [cliente, setCliente] = useState("");
  const [faena, setFaena] = useState("");
  const [faenaNueva, setFaenaNueva] = useState("");
  const [trabajador, setTrabajador] = useState("");
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [listaFaenas, setListaFaenas] = useState([]);

  useEffect(() => {
    setSubtitleValue("");
    if (selectedEmpresa.id) {
      setCliente(selectedEmpresa.id);
    }
  }, []);

  useEffect(() => {
    if (faenasState.isSuccess && faenasState.currentData) {
      let lista_e = [];
      let lista_f = [];
      faenasState.currentData?.data.map((t) => {
        if (!lista_e.find((e) => e.value === cliente)) {
          lista_e.push({
            id: t.empresa_mandante,
            value: t.nombre_empresa_mandante
          });
        }
        lista_f.push({
          id: t.id,
          value: t.nombre,
          empresa: t.empresa_mandante
        });
      });
      let jsonObject = lista_e.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      lista_e = Array.from(uniqueSet).map(JSON.parse);
      setListaEmpresas(lista_e);
      setListaFaenas(lista_f);
    }
  }, [faenasState.currentData, faenasState.isSuccess, faenasState.isLoading]);

  useEffect(() => {
    if (stepCount === 2) {
      setContinueValue(true);
    }
    if (stepCount === 3) {
      // if archivos fueron subidos de forma exitosa
      if (false) {
        setContinueValue(false);

        dispatch(
          setSuccessText("¡Tus documentos han sido cargados de forma exitosa!")
        );
        dispatch(setOpenConfirmModal(true));
        navigate("/Trabajadores/Vencimientos");
      } else {
        // if falta algun archivo que se suba de correctamente
        setOpenFinishModal(true);
        setContinueValue(false);
      }
    }
  }, [stepCount]);

  const handleAsyncUpload = async () => {
    try {
      const response = await uploadDocs({
        documentos: archivos,
        empresa_mandante: cliente,
        faena: faena,
        tipo: "trabajadores"
      }).unwrap();
      setStepCount(stepCount + 1);
      setContinueValue(false);
    } catch (err) {
      console.error(err);
      dispatch(
        setErrorText(
          "¡Hubo un error al subir los documentos, intente nuevamente o conctace con nosotros!"
        )
      );
      dispatch(setOpenConfirmModal(true));
    }
  };

  const handleContinue = () => {
    if (stepCount == 1) {
      handleAsyncUpload();
    } else {
      setContinueValue(false);
      setStepCount(stepCount + 1);
    }
  };
  const handleBack = () => {
    setStepCount(stepCount - 1);
  };

  /* CSS */
  const todo = {
    padding: "0 0 0 0",
    height: "100%",
    minHeight: "58vh",
    height: "fit-content",
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between"
  };
  const datosButtonBox = {
    minHeight: "58vh",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    gap: stepCount === 2 ? "3vh" : "4vh",
    transition: transition
  };
  const datosBox = {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-around",
    gap: stepCount === 2 ? "1vh" : "3vh",
    alignItems: littleSize ? "center" : ""
  };
  const tituloDatos = {
    fontWeight: 900,
    fontSize: "24px",
    lineHeight: "100%",
    color: "#1876D1"
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px"
    // padding: littleSize ? "0vh 0px 6vh 0px" : "0px",
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    position: littleSize ? (stepCount > 0 ? "" : "absolute") : "",
    visibility: littleSize ? (stepCount > 0 ? "visible" : "hidden") : "",
    opacity: stepCount > 0 ? 1 : 0,
    ":hover": {
      border: "2px solid #1876D1"
    }
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1",
    minWidth: "100px"
  };
  const titleFinishModal = {
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "140%",
    textAlign: "center"
  };
  const textBoxFinishModal = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  };
  const textStyleFinishModal = {
    textAlign: "center"
  };

  return (
    <Box sx={todo}>
      <Box sx={datosButtonBox}>
        <CustomStepper stepCount={stepCount}>
          {stepsCargaMasivaTrabajadores}
        </CustomStepper>
        {stepCount === 0 ? (
          <Box sx={datosBox}>
            <Typography sx={tituloDatos}>
              Paso 1: Suba los documentos solicitados
            </Typography>
            <Typography>
              Para poder cargar los documentos requeridos para este mes, por
              favor primero selecciona el cliente con el que quieres comenzar:
            </Typography>
            <FirstPage
              setContinueValue={setContinueValue}
              cliente={cliente}
              setCliente={setCliente}
              faena={faena}
              setFaena={setFaena}
              faenaNueva={faenaNueva}
              setFaenaNueva={setFaenaNueva}
              listaEmpresas={listaEmpresas}
              listaFaenas={listaFaenas}
            />
          </Box>
        ) : stepCount === 1 ? (
          <Box sx={datosBox}>
            <Typography sx={tituloDatos}>
              Paso 2: Suba los documentos solicitados
            </Typography>
            <Typography>
              A continuación, sube todos los documentos que necesites actualizar
              en uno o más archivos y haz click en “cargar” para poder avanzar.
            </Typography>
            <SecondPage
              setContinueValue={setContinueValue}
              archivos={archivos}
              setArchivos={setArchivos}
              isLoading={uploadResults.isLoading}
            />
          </Box>
        ) : (
          <Box sx={datosBox}>
            <Typography sx={tituloDatos}>Paso 3: Revisión final</Typography>
            <Typography>
              <p>
                Antes de finalizar, por favor confirme que los documentos
                requeridos se hayan cargado correctamente revisando su estado.
              </p>
              <p>
                De existir uno con estado “sin subir”, recuerde que puede
                cargarlo manualmente.
              </p>
            </Typography>
            <ThirdPage cliente={cliente} />
          </Box>
        )}
        <Box sx={buttonBox}>
          <Button sx={buttonVolver} variant="outlined" onClick={handleBack}>
            Volver
          </Button>
          <Button
            sx={button}
            disableElevation
            variant="contained"
            type="submit"
            onClick={handleContinue}
            disabled={!continueValue}
          >
            {stepCount == 1
              ? "Cargar"
              : stepCount == 2
              ? "Finalizar"
              : "Continuar"}
          </Button>
        </Box>
      </Box>
      <FinishModal
        openModal={openFinishModal}
        setOpenModal={setOpenFinishModal}
        handleContinue={handleContinue}
        handleBack={handleBack}
        confirmationText="¡Tus documentos han sido cargados de forma exitosa!"
        buttonText="Cargar documentos"
        navigatePath="/Trabajadores"
      >
        <Typography sx={titleFinishModal}>
          ¿Estás seguro/a que quieres continuar?
        </Typography>
        <Box sx={textBoxFinishModal}>
          <Typography sx={textStyleFinishModal}>
            <p>
              Se ha detectado que dos de los archivos no han sido
              subidos/reconocidos por el sistema.{" "}
            </p>
            <p>
              Intente cumplir con los deadlines y sube los documentos faltantes
              lo antes posible.
            </p>
          </Typography>
        </Box>
      </FinishModal>
    </Box>
  );
}
