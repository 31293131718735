import React, { useState, useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  TextField,
  ListItemButton,
  Select
} from "@mui/material";

/* ICONS */
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/* CUSTOM LIBRARIES */
import CustomTable from "../common/components/customTable";
import CustomSelect from "../common/components/customSelect";
import FinishModal from "../common/modals/finishModal";
import EditarEmpresaModal from "./editarEmpresaModal";
import NuevoTipoTrabajadorModal from "./nuevoTipoTrabajadorModal";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import {
  estadosEmpresas,
  headCellsListarEmpresas
} from "../../../controller/listas";
import {
  transition,
  selectHeight,
  selectMaxHeight,
  POLLING_INTERVAL
} from "../../../controller/utils";
import { selectCurrentType } from "../../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { currentEmpresa, setEmpresa } from "../../../redux/entidadesSlice";
import {
  useDeleteEmpresaMutation,
  useGetEmpresasQuery
} from "../../../api/empresasApiSlice";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../redux/confirmModalSlice";

export default function ListarEmpresas() {
  const { setSubtitleValue } = useOutletContext();
  const userType = useSelector(selectCurrentType);
  const selectedEmpresa = useSelector(currentEmpresa);
  const littleSize = littleSizeFunc();
  const dispatch = useDispatch();

  const [deleteEmpresa, deleteResult] = useDeleteEmpresaMutation();

  const { currentData, isSuccess, isLoading, isFetching, refetch } =
    useGetEmpresasQuery(null, {
      pollingInterval: POLLING_INTERVAL
    });

  const [estado, setEstado] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCrearTipoTrabajador, setOpenCrearTipoTrabajador] = useState(false);
  const [openBajaModal, setOpenBajaModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [listaEmpresasFiltrada, setListaEmpresasFiltrada] = useState([]);
  const [confirmationText, setConfirmationText] = useState("");
  const [accion, setAccion] = useState("");

  useEffect(() => {
    if (isSuccess && currentData) {
      setListaEmpresas([...currentData.data]);
      setListaEmpresasFiltrada([...currentData.data]);
    }
  }, [currentData, isSuccess, isLoading, isFetching]);

  useEffect(() => {
    setSubtitleValue("");
  }, []);

  useEffect(() => {
    if (!openBajaModal) {
      setConfirmationText("");
    }
  }, [openBajaModal]);

  useEffect(() => {
    if (isSuccess) {
      if (listaEmpresas.length > 0 || listaEmpresas === currentData.data) {
        let listaEmp =
          listaEmpresas.lenght > listaEmpresasFiltrada.lenght
            ? [...listaEmpresasFiltrada]
            : [...listaEmpresas];
        if (estado) {
          listaEmp = listaEmp
            .map((empresaObj) => {
              let re = new RegExp(`${estado}`, "gi");
              if (re.test(empresaObj.estado)) {
                return empresaObj;
              }
              return null;
            })
            .filter((empresaObj) => empresaObj !== null);
          setListaEmpresasFiltrada(listaEmp);
        } else {
          setListaEmpresasFiltrada(listaEmp);
        }
      }
    }
  }, [estado, currentData, isFetching, isLoading, isSuccess]);

  const handleLimpiaFiltros = () => {
    setEstado("");
  };

  const setSelectedEmpresa = (value) => {
    dispatch(setEmpresa({ ...value }));
  };

  const handleDelete = async () => {
    try {
      await deleteEmpresa(selectedEmpresa.id).unwrap();
      dispatch(
        setSuccessText("¡El trabajador ha sido dado de baja correctamente!")
      );
      refetch();
    } catch (e) {
      dispatch(
        setErrorText(
          "¡Hubo un error, intente nuevamente o contáctese con nosotros!"
        )
      );
      dispatch(setOpenConfirmModal(true));
    }
  };

  /* CSS */
  const contenido = {
    minHeight: "58vh",
    height: "fit-content",
    maxHeight: "84vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "5.17vh",
    transition: transition
  };
  const barraFiltros = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap-reverse",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: littleSize ? "center" : "start",
    gap: "11px"
  };
  const filtrosBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap-reverse",
    alignItems: "center",
    gap: "11px"
  };
  const formControl = {
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    width: "16vw",
    minWidth: "210px"
  };
  const textStyleFinishModal = {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "20px",
    color: "#818181"
  };
  const buttonNuevoTipo = {
    height: "40px",
    textTransform: "none",
    minHeight: "100%",
    fontWeight: 600,
    borderRadius: "10px",
    padding: "5px 20px",
    border: "2px solid #1876D1",
    ":hover": {
      border: "2px solid #1876D1"
    }
  };
  const confirmationBox = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px"
  };
  const textField = {
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      // border: "2px solid #DE5C5C",
      fontWeight: 400
    }
  };
  const buttonLimparFiltros = {
    height: "fit-content",
    width: "fit-content",
    transition: transition,
    textTransform: "none ",
    borderRadius: "12px",
    background: "#1876D1",
    color: "#FCFBFB",
    fontWeight: 800,
    fontSize: "12px",
    gap: "5px"
  };
  const seleccionAcciones = {
    borderRadius: "10px",
    transition: transition,
    fontWeight: 600,
    // fontSize: "100%",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    height: selectHeight,
    maxHeight: selectMaxHeight,
    color: "#FCFBFB",
    background: "#1876D1",
    textTransform: "none",
    ".MuiSelect-icon": {
      color: "#FCFBFB"
    }
  };
  const filtroVacio = {
    width: "100%",
    borderRadius: "10px",
    transition: transition,
    fontWeight: 600,
    fontSize: "100%",
    lineHeight: "150%",
    color: "#818181",
    display: "flex",
    alignItems: "center",
    height: selectHeight,
    maxHeight: selectMaxHeight,
    "&:hover": {
      color: "#1876D1"
    }
  };
  const seleccionAccionesDefaultValue = {
    width: "100%",
    fontWeight: 600,
    fontSize: "100%",
    lineHeight: "150%",
    color: "#FCFBFB"
  };

  return (
    <Box sx={contenido}>
      <Box sx={barraFiltros}>
        {estado ? (
          <Box sx={formControl}>
            <Button
              onClick={handleLimpiaFiltros}
              disableElevation={true}
              variant="contained"
              sx={buttonLimparFiltros}
            >
              <FilterListOffOutlinedIcon />
              Restablecer filtros
            </Button>
          </Box>
        ) : null}
        <Box sx={filtrosBox}>
          <Box sx={formControl}>
            <CustomSelect
              label="Filtrar por estado"
              value={estado}
              setValue={setEstado}
            >
              {estadosEmpresas}
            </CustomSelect>
          </Box>
          {userType === "Admin" ? (
            <Button
              variant="outlined"
              sx={buttonNuevoTipo}
              onClick={(e) => setOpenCrearTipoTrabajador(true)}
            >
              Crear tipo trabajador
            </Button>
          ) : null}
          {userType === "Admin" ? (
            <Select
              value={accion}
              IconComponent={KeyboardArrowDownIcon}
              displayEmpty
              onChange={(event) => setAccion(event.target.value)}
              sx={seleccionAcciones}
              renderValue={
                accion !== ""
                  ? undefined
                  : () => (
                      <Typography sx={seleccionAccionesDefaultValue}>
                        Crear empresa
                      </Typography>
                    )
              }
            >
              <ListItemButton
                sx={filtroVacio}
                component={Link}
                to="Crear empresa outsourcing"
              >
                Empresa Outsourcing
              </ListItemButton>
              <ListItemButton
                sx={filtroVacio}
                component={Link}
                to="Crear empresa mandante"
              >
                Empresa Mandante
              </ListItemButton>
            </Select>
          ) : null}
        </Box>
      </Box>
      <CustomTable
        headCells={headCellsListarEmpresas}
        rows={listaEmpresasFiltrada}
        setRows={setListaEmpresasFiltrada}
        variant="empresas"
        setLineaSeleccionada={setSelectedEmpresa}
        setOpenEditModal={setOpenEditModal}
        setOpenBajaModal={setOpenBajaModal}
        setOpenErrorModal={setOpenErrorModal}
        isLoading={isLoading}
      />
      <EditarEmpresaModal
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        refetch={refetch}
      />
      <FinishModal
        openModal={openBajaModal}
        setOpenModal={setOpenBajaModal}
        handleContinue={handleDelete}
        buttonText="Eliminar"
        navigatePath="./"
        confirmationValue={
          confirmationText === `ELIMINAR ${selectedEmpresa.nombre}`
        }
      >
        <Typography sx={textStyleFinishModal}>
          Estás a punto de eliminar la empresa "{selectedEmpresa.nombre}" ¿Estás
          seguro que quieres continuar?
        </Typography>
        <Box sx={confirmationBox}>
          <Typography sx={textStyleFinishModal}>
            Escriba "ELIMINAR" seguido por el nombre de la empresa, para
            confirmar la eliminación de esta empresa.
          </Typography>
          <TextField
            sx={textField}
            size="small"
            error
            required
            id="confirmation"
            type="text"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
          />
        </Box>
      </FinishModal>
      <FinishModal openModal={openErrorModal} setOpenModal={setOpenErrorModal}>
        <Typography sx={textStyleFinishModal}>
          {selectedEmpresa.razon_rechazo}
        </Typography>
      </FinishModal>
      <NuevoTipoTrabajadorModal
        openModal={openCrearTipoTrabajador}
        setOpenModal={setOpenCrearTipoTrabajador}
      />
    </Box>
  );
}
