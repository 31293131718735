import React, { useState, useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Button, Typography, Box, Modal, IconButton } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";

/* CUSTOM LIBRARIES */
import CustomTable from "../common/components/customTable";
import SubirDocumentoModal from "../common/modals/subirDocumentoModal";
import PDFViewer from "../common/components/pdfViewer";
import FinishModal from "../common/modals/finishModal";
import CustomSelect from "../common/components/customSelect";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import {
  transition,
  MONTHSNUM,
  POLLING_INTERVAL
} from "../../../controller/utils";
import {
  headCellsEmpresa,
  headCellsEmpresas
} from "../../../controller/listas";
import { selectCurrentType } from "../../../redux/authSlice";
import { useSelector } from "react-redux";
import { currentEmpresa } from "../../../redux/entidadesSlice";
import { useGetDocumentosEmpresaQuery } from "../../../api/docsApiSlice";

export default function Empresa(props) {
  const { setSubtitleValue } = useOutletContext();
  const littleSize = littleSizeFunc();
  const userType = useSelector(selectCurrentType);
  const selectedEmpresa = useSelector(currentEmpresa);

  const { currentData, isSuccess, isLoading, isFetching } = selectedEmpresa.id
    ? useGetDocumentosEmpresaQuery(
        { id: selectedEmpresa.id },
        {
          pollingInterval: POLLING_INTERVAL
        }
      )
    : { currentData: { data: [] } };

  const [estado, setEstado] = useState("");
  const [estadosFiltro, setEstadoFiltro] = useState([]);
  const [openSubirDocModal, setOpenSubirDocModal] = useState(false);
  const [openPDFViewModal, setOpenPDFViewModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [lineaDocumentoSeleccionada, setLineaDocumentoSeleccionada] =
    useState("");

  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [listaEmpresasFiltrada, setListaEmpresasFiltrada] = useState([]);

  useEffect(() => {
    if (selectedEmpresa.nombre) {
      setSubtitleValue(`Empresa: ${selectedEmpresa.nombre}`);
    } else {
      setSubtitleValue("");
    }
  }, []);

  useEffect(() => {
    if (isSuccess && currentData) {
      var lista_estado = [];
      let listaDocs = [];
      currentData?.data.map((t) => {
        lista_estado.push({ id: t.estado, value: t.estado });
        let clonedObject = { ...t };
        clonedObject = {
          ...clonedObject,
          titulo: t.nombre_tipo_documento
        };
        listaDocs.push(clonedObject);
      });
      setEstadoFiltro(lista_estado);
      setListaEmpresas([...listaDocs]);
      setListaEmpresasFiltrada([...listaDocs]);
    }
  }, [currentData, isSuccess, isFetching]);

  useEffect(() => {
    if (isSuccess && currentData) {
      if (listaEmpresas.length > 0 || listaEmpresas === currentData.data) {
        let listaEmp =
          listaEmpresas.lenght > listaEmpresasFiltrada.lenght
            ? [...listaEmpresasFiltrada]
            : [...listaEmpresas];
        if (estado) {
          listaEmp = listaEmp
            .map((empObj) => {
              let re = new RegExp(`${estado}`, "gi");
              if (re.test(empObj.estado)) {
                return empObj;
              }
              return null;
            })
            .filter((empObj) => empObj !== null);
          setListaEmpresasFiltrada(listaEmp);
        } else {
          setListaEmpresasFiltrada(listaEmp);
        }
      }
    }
  }, [estado, currentData, isFetching, isSuccess]);

  const handleLimpiaFiltros = () => {
    setEstado("");
  };
  const handleClosePDFModal = () => setOpenPDFViewModal(false);

  const contenido = {
    minHeight: "58vh",
    height: "fit-content",
    maxHeight: "84vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "5.17vh",
    transition: transition
  };
  const barraFiltros = {
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: littleSize ? "center" : "start",
    gap: "11px"
  };
  const filtrosBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap-reverse",
    alignItems: "center",
    gap: "11px"
  };
  const buttonNuevoTicket = {
    height: "40px",
    textTransform: "none",
    minHeight: "100%",
    fontWeight: 600,
    color: "#FCFBFB",
    backgroundColor: "#1876D1",
    borderRadius: "10px",
    padding: "5px 20px"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const modalPDF = {
    transition: transition,
    position: "absolute",
    overflow: littleSize ? "auto" : "hidden",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    height: "fit-content",
    " .MuiBox-root.MuiBox-root": {
      borderRadius: "20px"
    }
  };
  const textStyleFinishModal = {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "20px",
    color: "#818181"
  };
  const formControl = {
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    width: "16vw",
    minWidth: "210px"
  };
  const buttonLimparFiltros = {
    height: "fit-content",
    width: "fit-content",
    transition: transition,
    textTransform: "none ",
    borderRadius: "12px",
    background: "#1876D1",
    color: "#FCFBFB",
    fontWeight: 800,
    fontSize: "12px",
    gap: "5px"
  };

  return listaEmpresas ? (
    <Box sx={contenido}>
      <Box sx={barraFiltros}>
        {estado ? (
          <Box sx={formControl}>
            <Button
              onClick={handleLimpiaFiltros}
              disableElevation={true}
              variant="contained"
              sx={buttonLimparFiltros}
            >
              <FilterListOffOutlinedIcon />
              Restablecer filtros
            </Button>
          </Box>
        ) : null}

        {userType === "Supp" || userType === "Admin" ? (
          <Box sx={filtrosBox}>
            <Box sx={formControl}>
              <CustomSelect
                label="Filtrar por estado"
                value={estado}
                setValue={setEstado}
              >
                {estadosFiltro}
              </CustomSelect>
            </Box>
            {userType === "Admin" ? (
              <Button
                variant="contained"
                sx={buttonNuevoTicket}
                component={Link}
                to="/Empresas/Crear empresa outsourcing"
              >
                Crear empresa
              </Button>
            ) : null}
          </Box>
        ) : (
          <Box sx={filtrosBox}>
            <Button
              variant="contained"
              sx={buttonNuevoTicket}
              component={Link}
              to="Carga masiva"
              // onClick={(e) => setOpenSupp(true)}
            >
              Carga masiva
            </Button>
          </Box>
        )}
      </Box>
      <CustomTable
        headCells={
          userType === "Supp" || userType === "Admin"
            ? headCellsEmpresa
            : headCellsEmpresas
        }
        rows={listaEmpresasFiltrada}
        setRows={setListaEmpresasFiltrada}
        variant="documentos"
        setLineaSeleccionada={setLineaDocumentoSeleccionada}
        setOpenSubirDocModal={setOpenSubirDocModal}
        setOpenPDFViewModal={setOpenPDFViewModal}
        setOpenErrorModal={setOpenErrorModal}
        isLoading={isLoading}
      />
      <Modal open={openPDFViewModal} onClose={handleClosePDFModal}>
        <Box sx={modalPDF}>
          <Box sx={closeButtonBox}>
            <IconButton onClick={handleClosePDFModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <PDFViewer
            id={lineaDocumentoSeleccionada.id}
            openModal={openPDFViewModal}
            setOpenModal={setOpenPDFViewModal}
            subtitle={
              selectedEmpresa.nombre
                ? `${selectedEmpresa.nombre} - ${lineaDocumentoSeleccionada.nombre}`
                : `${lineaDocumentoSeleccionada.nombre} - ${lineaDocumentoSeleccionada.documento}`
            }
          />
        </Box>
      </Modal>
      <SubirDocumentoModal
        id={lineaDocumentoSeleccionada.id}
        openModal={openSubirDocModal}
        setOpenModal={setOpenSubirDocModal}
        subtitle={
          selectedEmpresa.nombre
            ? `${selectedEmpresa.nombre} - ${lineaDocumentoSeleccionada.nombre}`
            : `${lineaDocumentoSeleccionada.nombre} - ${lineaDocumentoSeleccionada.documento}`
        }
        maxCount={1}
      />
      <FinishModal openModal={openErrorModal} setOpenModal={setOpenErrorModal}>
        <Typography sx={textStyleFinishModal}>
          {lineaDocumentoSeleccionada.razon_rechazo}
        </Typography>
      </FinishModal>
    </Box>
  ) : null;
}
