import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ListItemButton, Card, Box, Typography } from "@mui/material";

/* ICONS */
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";

/* CONTROLLER VARIABLES */
import { transition } from "../../../controller/utils";

export default function MiPerfil() {
  const navigate = useNavigate();
  const [option, setOption] = useState(0);

  useEffect(() => {
    if (option == 1) {
      // setTitleValue("Mis datos personales")
      navigate("Mis datos personales");
    } else if (option == 2) {
      // setTitleValue("Mis tickets de ayuda");
      navigate("Mis tickets de ayuda");
    } else if (option == 3) {
      // setTitleValue("Datos de empresa");
      navigate("Datos empresa");
    }
    setOption(0);
  }, [option]);

  const handleClick = (event, id) => {
    setOption(id);
  };

  /* CSS */
  const contenido = {
    minHeight: "58vh",
    height: "fit-content",
    // maxHeight: "84vh",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "75px",
    justifyContent: "center",
    alignItems: "center",
  };
  const card = {
    transition: transition,
    height: "220px",
    width: "230px",
    maxWidth: "230px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#1876D1",
    padding: "43px 23px",
    gap: "22px",
    borderRadius: "15px",
    background: "#FFFFFF",
    boxShadow: 3,
    "&:hover": {
      boxShadow: 1,
    },
    ".css-i4bv87-MuiSvgIcon-root": {
      width: "65px",
      height: "65px",
    },
    "&:active": {
      boxShadow: 0,
      background: "#1876D1",
      color: "#FFFFFF",
    },
  };
  const textCard = {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "150%",
    textAlign: "center",
  };

  return (
    <Box sx={contenido}>
      <ListItemButton
        sx={card}
        component={Card}
        onClick={(event) => handleClick(event, 1)}
      >
        <PersonOutlineOutlinedIcon />
        <Typography sx={textCard}>Mis datos personales</Typography>
      </ListItemButton>
      <ListItemButton
        sx={card}
        component={Card}
        onClick={(event) => handleClick(event, 2)}
      >
        <SupportAgentOutlinedIcon />
        <Typography sx={textCard}>Mis tickets de ayuda</Typography>
      </ListItemButton>
      <ListItemButton
        sx={card}
        component={Card}
        onClick={(event) => handleClick(event, 3)}
      >
        <DomainOutlinedIcon />
        <Typography sx={textCard}>Datos de empresa</Typography>
      </ListItemButton>
    </Box>
  );
}
