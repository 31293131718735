import React, { useState, useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Button, Typography, Box, Modal, IconButton } from "@mui/material";

/* ICONS */
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";

/* CUSTOM LIBRARIES */
import CustomTable from "../common/components/customTable";
import CustomSelect from "../common/components/customSelect";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import {
  MONTHSNUM,
  POLLING_INTERVAL,
  transition
} from "../../../controller/utils";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentType } from "../../../redux/authSlice";
import { currentEmpresa, setTrabajador } from "../../../redux/entidadesSlice";
import { useGetDadosDeBajaQuery } from "../../../api/trabajadoresApiSlice";
import { headCellsDadosDeBaja } from "../../../controller/listas";

export default function Bajados(props) {
  const { setSubtitleValue } = useOutletContext();
  const littleSize = littleSizeFunc();
  const dispatch = useDispatch();
  const userType = useSelector(selectCurrentType);
  const selectedEmpresa = useSelector(currentEmpresa);

  const { currentData, isSuccess, isLoading, isFetching } =
    useGetDadosDeBajaQuery(null, {
      pollingInterval: POLLING_INTERVAL
    });

  const [empresaOutsourcing, setEmpresaOutsourcing] = useState("");
  const [empresaMandante, setEmpresaMandante] = useState("");
  const [año, setAño] = useState("");
  const [listaAños, setListaAños] = useState([]);
  const [mes, setMes] = useState("");
  const [listaMeses, setListaMeses] = useState([]);
  const [filtroEmpresasOutsourcing, setFiltroEmpresasOutsourcing] = useState(
    []
  );
  const [filtroEmpresasMandantes, setFiltroEmpresasMandantes] = useState([]);
  const [listaTrabajadores, setListaTrabajadores] = useState([]);
  const [listaTrabajadoresFiltrada, setListaTrabajadoresFiltrada] = useState(
    []
  );

  useEffect(() => {
    if (isSuccess && !isFetching) {
      setListaTrabajadores([...currentData.data]);
      setListaTrabajadoresFiltrada([...currentData.data]);
      var lista_empresa_o = [];
      var lista_empresa_m = [];
      var lista_años = [];
      var lista_meses = [];
      currentData.data.map((t, i) => {
        lista_empresa_o.push({
          id: t.empresa_outsourcing,
          value: t.empresa_outsourcing
        });
        lista_empresa_m.push({
          id: t.empresa_mandante,
          value: t.empresa_mandante
        });
        lista_años.push({
          id: t.fecha_fin_contrato.split("-")[0],
          value: t.fecha_fin_contrato.split("-")[0]
        });
        lista_meses.push({
          id: t.fecha_fin_contrato.split("-")[1],
          value: MONTHSNUM[t.fecha_fin_contrato.split("-")[1]]
        });
      });

      let jsonObject = lista_empresa_o.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      lista_empresa_o = Array.from(uniqueSet).map(JSON.parse);
      setFiltroEmpresasOutsourcing(lista_empresa_o);

      jsonObject = lista_empresa_m.map(JSON.stringify);
      uniqueSet = new Set(jsonObject);
      lista_empresa_m = Array.from(uniqueSet).map(JSON.parse);
      setFiltroEmpresasMandantes(lista_empresa_m);

      jsonObject = lista_años.map(JSON.stringify);
      uniqueSet = new Set(jsonObject);
      lista_años = Array.from(uniqueSet).map(JSON.parse);
      setListaAños(lista_años);

      jsonObject = lista_meses.map(JSON.stringify);
      uniqueSet = new Set(jsonObject);
      lista_meses = Array.from(uniqueSet).map(JSON.parse);
      setListaMeses(lista_meses);
    }
  }, [currentData, isSuccess, isFetching]);

  useEffect(() => {
    if (selectedEmpresa.id) {
      setSubtitleValue(`Cliente: ${selectedEmpresa.value}`);
    } else {
      setSubtitleValue("");
    }
  }, []);

  // Filtros
  useEffect(() => {
    if (isSuccess) {
      if (
        listaTrabajadores.length > 0 ||
        listaTrabajadores === currentData.data
      ) {
        let listaTrab =
          listaTrabajadores.length > listaTrabajadoresFiltrada.lenght
            ? [...listaTrabajadoresFiltrada]
            : [...listaTrabajadores];
        if (empresaOutsourcing) {
          listaTrab = listaTrab
            .map((trabajadorObj) => {
              if (trabajadorObj.empresa_outsourcing === empresaOutsourcing) {
                return trabajadorObj;
              }
              return null;
            })
            .filter((trabajadorObj) => trabajadorObj !== null);
          setListaTrabajadoresFiltrada(listaTrab);
        }
        if (empresaMandante) {
          if (!selectedEmpresa.id) {
            listaTrab = listaTrab
              .map((trabajadorObj) => {
                if (trabajadorObj.empresa_mandante === empresaMandante) {
                  return trabajadorObj;
                }
                return null;
              })
              .filter((trabajadorObj) => trabajadorObj !== null);
            setListaTrabajadoresFiltrada(listaTrab);
          }
        }
        if (año) {
          listaTrab = listaTrab
            .map((trabajadorObj) => {
              if (trabajadorObj.fecha_fin_contrato.split("-")[0] === año) {
                return trabajadorObj;
              }
              return null;
            })
            .filter((trabajadorObj) => trabajadorObj !== null);
          setListaTrabajadoresFiltrada(listaTrab);
        }
        if (mes) {
          listaTrab = listaTrab
            .map((trabajadorObj) => {
              if (trabajadorObj.fecha_fin_contrato.split("-")[1] === mes) {
                return trabajadorObj;
              }
              return null;
            })
            .filter((trabajadorObj) => trabajadorObj !== null);
          setListaTrabajadoresFiltrada(listaTrab);
        } else {
          setListaTrabajadoresFiltrada(listaTrab);
        }
      }
    }
  }, [
    empresaMandante,
    empresaOutsourcing,
    año,
    mes,
    currentData,
    isFetching,
    isSuccess
  ]);

  const handleLimpiaFiltros = () => {
    setEmpresaOutsourcing("");
    setEmpresaMandante("");
    setAño("");
    setMes("");
  };

  const setSelectedTrabajador = (value) => {
    dispatch(setTrabajador(value));
  };

  /* CSS */
  const contenido = {
    minHeight: "58vh",
    height: "fit-content",
    maxHeight: "84vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "5.17vh",
    transition: transition
  };
  const barraFiltros = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap-reverse",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: littleSize ? "center" : "start",
    gap: "11px"
  };
  const filtrosBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap-reverse",
    alignItems: "center",
    gap: "11px"
  };
  const formControl = {
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    width: "16vw",
    minWidth: "210px"
  };
  const buttonLimparFiltros = {
    height: "fit-content",
    width: "fit-content",
    transition: transition,
    textTransform: "none ",
    borderRadius: "12px",
    background: "#1876D1",
    color: "#FCFBFB",
    fontWeight: 800,
    fontSize: "12px",
    gap: "5px"
  };

  return (
    <Box sx={contenido}>
      <Box sx={barraFiltros}>
        {(empresaMandante && !selectedEmpresa.id) ||
        empresaOutsourcing ||
        mes ||
        año ? (
          <Box sx={formControl}>
            <Button
              onClick={handleLimpiaFiltros}
              disableElevation={true}
              variant="contained"
              sx={buttonLimparFiltros}
            >
              <FilterListOffOutlinedIcon />
              Restablecer filtros
            </Button>
          </Box>
        ) : null}
        <Box sx={filtrosBox}>
          {selectedEmpresa.id ? null : (
            <Box sx={formControl}>
              <CustomSelect
                label={
                  userType === "Supp" || userType === "Admin"
                    ? "Filtrar por empresa mandante"
                    : "Filtrar por cliente"
                }
                value={empresaMandante}
                setValue={setEmpresaMandante}
              >
                {filtroEmpresasMandantes}
              </CustomSelect>
            </Box>
          )}
          {userType == "A" ? null : (
            <Box sx={formControl}>
              <CustomSelect
                label={"Filtrar por empresa outsourcing"}
                value={empresaOutsourcing}
                setValue={setEmpresaOutsourcing}
              >
                {filtroEmpresasOutsourcing}
              </CustomSelect>
            </Box>
          )}
          <Box sx={formControl}>
            <CustomSelect
              label="Filtrar por año de baja"
              value={año}
              setValue={setAño}
            >
              {listaAños}
            </CustomSelect>
          </Box>
          <Box sx={formControl}>
            <CustomSelect
              label="Filtrar por mes de baja"
              value={mes}
              setValue={setMes}
            >
              {listaMeses}
            </CustomSelect>
          </Box>
        </Box>
      </Box>
      <CustomTable
        headCells={headCellsDadosDeBaja}
        rows={listaTrabajadoresFiltrada}
        setRows={setListaTrabajadoresFiltrada}
        variant="bajados"
        setLineaSeleccionada={setSelectedTrabajador}
        isLoading={isLoading}
      />
    </Box>
  );
}
