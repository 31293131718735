import React from "react";
import { Stepper, Step, StepLabel, StepConnector } from "@mui/material";
import { stepConnectorClasses } from "@mui/material/StepConnector";

export default function CustomStepper(props) {
  const { stepCount } = props;

  /* CSS */
  const stepperStyle = {
    justifyContents: "center",
  };
  const stepConnector = {
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#1876D1",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#1876D1",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: "10px",
      backgroundColor: "#D9D9D9",
      borderRadius: 1,
    },
  };
  const stepLabel = {
    ".MuiStepLabel-alternativeLabel": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      "&.Mui-completed": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        color: "#1876D1",
      },
      "&.Mui-active": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        color: "#1876D1",
      },
      "&.Mui-disabled": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        color: "#D9D9D9",
        ".css-hf8c47-MuiSvgIcon-root-MuiStepIcon-root": {
          color: "#D9D9D9",
          ".css-2t64ky-MuiStepIcon-text": {
            fill: "#333840",
          },
        },
      },
    },
  };

  return (
    <Stepper
      sx={stepperStyle}
      activeStep={stepCount}
      alternativeLabel
      connector={<StepConnector sx={stepConnector} />}
    >
      {props.children.map((label) => (
        <Step key={label}>
          <StepLabel sx={stepLabel}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
