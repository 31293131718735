import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editModal: false,
  extenderModal: false,
  bajaModal: false,
  subirDocModal: false,
  subirMultipleDocsModal: false,
  pdfViewModal: false,
  errorModal: false,
  ticketModal: false,
  verificacionModal: false,
};

export const modalsSlice = createSlice({
  name: "modals",
  initialState: initialState,
  reducers: {
    setEditModal: (state, action) => {
      state.editModal = action.payload;
    },
    setExtenderModal: (state, action) => {
      state.extenderModal = action.payload;
    },
    setBajaModal: (state, action) => {
      state.bajaModal = action.payload;
    },
    setSubirDocModal: (state, action) => {
      state.subirDocModal = action.payload;
    },
    setSubirMultipleDocsModal: (state, action) => {
      state.subirMultipleDocsModal = action.payload;
    },
    setPDFViewModal: (state, action) => {
      state.pdfViewModal = action.payload;
    },
    setErrorModal: (state, action) => {
      state.errorModal = action.payload;
    },
    setTicketModal: (state, action) => {
      state.ticketModal = action.payload;
    },
    setVerificacionModal: (state, action) => {
      state.verificacionModal = action.payload;
    },
  },
});

export const {
  setEditModal,
  setExtenderModal,
  setBajaModal,
  setSubirDocModal,
  setSubirMultipleDocsModal,
  setPDFViewModal,
  setErrorModal,
  setTicketModal,
  setVerificacionModal,
} = modalsSlice.actions;
export default modalsSlice.reducer;

export const currentStateEditModal = (state) => state.modals.editModal;
export const currentStateExtenderModal = (state) => state.modals.extenderModal;
export const currentStateBajaModal = (state) => state.modals.bajaModal;
export const currentStateSubirDocModal = (state) => state.modals.subirDocModal;
export const currentStateSubirMultipleDocsModal = (state) => state.modals.subirMultipleDocsModal;
export const currentStatePdfViewModal = (state) => state.modals.pdfViewModal;
export const currentStateTrrorModal = (state) => state.modals.errorModal;
export const currentStateTicketModal = (state) => state.modals.ticketModal;
export const currentStateVerificacionModal = (state) => state.modals.verificacionModal;
