import React, { useState, useEffect } from "react";
import { ReactComponent as PDFIcon } from "../images/svg/pdf.svg";
import { ReactComponent as RARIcon } from "../images/svg/rar.svg";
import { ReactComponent as ImgIcon } from "../images/svg/img.svg";
import * as MaterialIcons from "@mui/icons-material";

export const MAX_UPLOAD_FILES = 200;
export const POLLING_INTERVAL = process.env.PRODUCTION ? 90000 : 3000;
export const transition = "500ms";
export const actionButtonWidthHeight = "15px";
export const selectHeight = "40px";
export const selectMaxHeight = "40px";
export const emptyFileObj = { titulo: null, tipo_documento: null, name: null };

export const GenerateIcon = (variation, props = {}) => {
  const IconName = MaterialIcons[variation];
  if (IconName === undefined) {
    return null;
  } else {
    return <IconName {...props} />;
  }
};

export const romanTable = {
  I: 1,
  II: 2,
  III: 3,
  IV: 4,
  V: 5,
  VI: 6,
  VII: 7,
  VIII: 8,
  IX: 9,
  X: 10,
  XI: 11,
  XII: 12,
  XIII: 13,
  XIV: 14,
  XV: 15,
  XVI: 16,
  XVII: 17,
  XVIII: 18,
  XIX: 19,
  XX: 20
};

export const STATES = {
  rechazado: 1,
  pendiente: 2,
  "en proceso": 2,
  "sin subir": 2,
  abierto: 2,
  procesando: 3,
  analizando: 3,
  "necesita verificación": 3,
  validado: 4,
  aprobado: 4,
  resuelto: 4,
  facturar: 4
};

export const MONTHSNUM = {
  "01": "Enero",
  1: "Enero",
  "02": "Febrero",
  2: "Febrero",
  "03": "Marzo",
  3: "Marzo",
  "04": "Abril",
  4: "Abril",
  "05": "Mayo",
  5: "Mayo",
  "06": "Junio",
  6: "Junio",
  "07": "Julio",
  7: "Julio",
  "08": "Agosto",
  8: "Agosto",
  "09": "Septiembre",
  9: "Septiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre"
};

export const NUMMONTH = {
  enero: 1,
  febrero: 2,
  marzo: 3,
  abril: 4,
  mayo: 5,
  junio: 6,
  julio: 7,
  agosto: 8,
  septiembre: 9,
  octubre: 10,
  noviembre: 11,
  diciembre: 12
};

export const FileIcon = (file) => {
  if (file.name === undefined && file.titulo === undefined) {
    if (/pdf/i.test(file.split(".").slice(-1)[0])) {
      return <PDFIcon />;
    } else if (/rar/i.test(file.split(".").slice(-1)[0])) {
      return <RARIcon />;
    } else if (
      /jpg/i.test(file.split(".").slice(-1)[0]) ||
      /png/i.test(file.split(".").slice(-1)[0]) ||
      /jpeg/i.test(file.split(".").slice(-1)[0])
    ) {
      return <ImgIcon />;
    }
  } else if (file.name !== undefined) {
    if (/pdf/i.test(file.name.split(".").slice(-1)[0])) {
      return <PDFIcon />;
    } else if (/rar/i.test(file.name.split(".").slice(-1)[0])) {
      return <RARIcon />;
    } else if (
      /jpg/i.test(file.name.split(".").slice(-1)[0]) ||
      /png/i.test(file.name.split(".").slice(-1)[0]) ||
      /jpeg/i.test(file.name.split(".").slice(-1)[0])
    ) {
      return <ImgIcon />;
    }
  } else if (file.titulo !== undefined) {
    if (/pdf/i.test(file.titulo.split(".").slice(-1)[0])) {
      return <PDFIcon />;
    } else if (/rar/i.test(file.titulo.split(".").slice(-1)[0])) {
      return <RARIcon />;
    } else if (
      /jpg/i.test(file.titulo.split(".").slice(-1)[0]) ||
      /png/i.test(file.titulo.split(".").slice(-1)[0]) ||
      /jpeg/i.test(file.titulo.split(".").slice(-1)[0])
    ) {
      return <ImgIcon />;
    }
  } else if (file.type !== undefined) {
    if (
      file.type === "application/pdf" ||
      /pdf/i.test(file.name.split(".").slice(-1)[0])
    ) {
      return <PDFIcon />;
    } else if (
      file.type === "application/x-zip-compressed" ||
      /rar/i.test(file.name.split(".").slice(-1)[0])
    ) {
      return <RARIcon />;
    } else if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      /jpg/i.test(file.name.split(".").slice(-1)[0]) ||
      /png/i.test(file.name.split(".").slice(-1)[0]) ||
      /jpeg/i.test(file.name.split(".").slice(-1)[0])
    ) {
      return <ImgIcon />;
    }
  } else if (file.path !== undefined) {
    if (/pdf/i.test(file.path.split(".").slice(-1)[0])) {
      return <PDFIcon />;
    } else if (/rar/i.test(file.path.split(".").slice(-1)[0])) {
      return <RARIcon />;
    } else if (
      /jpg/i.test(file.path.split(".").slice(-1)[0]) ||
      /png/i.test(file.path.split(".").slice(-1)[0]) ||
      /jpeg/i.test(file.path.split(".").slice(-1)[0])
    ) {
      return <ImgIcon />;
    }
  }
};

export var Fn = {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  validaRut: function (rutCompleto) {
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
    var tmp = rutCompleto.split("-");
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == "K") digv = "k";
    return Fn.dv(rut) == digv;
  },
  dv: function (T) {
    var M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? S - 1 : "k";
  }
};

export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  useEffect(() => {
    const updateMousePosition = (ev) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };

    window.addEventListener("mousemove", updateMousePosition);

    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  return mousePosition;
};

export function quoted_printable_decode(str) {
  //       discuss at: http://phpjs.org/functions/quoted_printable_decode/
  //      original by: Ole Vrijenhoek
  //      bugfixed by: Brett Zamir (http://brett-zamir.me)
  //      bugfixed by: Theriault
  // reimplemented by: Theriault
  //      improved by: Brett Zamir (http://brett-zamir.me)
  //        example 1: quoted_printable_decode('a=3Db=3Dc');
  //        returns 1: 'a=b=c'
  //        example 2: quoted_printable_decode('abc  =20\r\n123  =20\r\n');
  //        returns 2: 'abc   \r\n123   \r\n'
  //        example 3: quoted_printable_decode('012345678901234567890123456789012345678901234567890123456789012345678901234=\r\n56789');
  //        returns 3: '01234567890123456789012345678901234567890123456789012345678901234567890123456789'
  //        example 4: quoted_printable_decode("Lorem ipsum dolor sit amet=23, consectetur adipisicing elit");
  //        returns 4: 'Lorem ipsum dolor sit amet#, consectetur adipisicing elit'

  var RFC2045Decode1 = /=\r\n/gm,
    // Decodes all equal signs followed by two hex digits
    RFC2045Decode2IN = /=([0-9A-F]{2})/gim,
    // the RFC states against decoding lower case encodings, but following apparent PHP behavior
    // RFC2045Decode2IN = /=([0-9A-F]{2})/gm,
    RFC2045Decode2OUT = function (sMatch, sHex) {
      return String.fromCharCode(parseInt(sHex, 16));
    };
  return str
    .replace(RFC2045Decode1, "")
    .replace(RFC2045Decode2IN, RFC2045Decode2OUT);
}

export function utf8_decode(str_data) {
  //  discuss at: http://phpjs.org/functions/utf8_decode/
  // original by: Webtoolkit.info (http://www.webtoolkit.info/)
  //    input by: Aman Gupta
  //    input by: Brett Zamir (http://brett-zamir.me)
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // improved by: Norman "zEh" Fuchs
  // bugfixed by: hitwork
  // bugfixed by: Onno Marsman
  // bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // bugfixed by: kirilloid
  //   example 1: utf8_decode('Kevin van Zonneveld');
  //   returns 1: 'Kevin van Zonneveld'

  var tmp_arr = [],
    i = 0,
    ac = 0,
    c1 = 0,
    c2 = 0,
    c3 = 0,
    c4 = 0;

  str_data += "";

  while (i < str_data.length) {
    c1 = str_data.charCodeAt(i);
    if (c1 <= 191) {
      tmp_arr[ac++] = String.fromCharCode(c1);
      i++;
    } else if (c1 <= 223) {
      c2 = str_data.charCodeAt(i + 1);
      tmp_arr[ac++] = String.fromCharCode(((c1 & 31) << 6) | (c2 & 63));
      i += 2;
    } else if (c1 <= 239) {
      // http://en.wikipedia.org/wiki/UTF-8#Codepage_layout
      c2 = str_data.charCodeAt(i + 1);
      c3 = str_data.charCodeAt(i + 2);
      tmp_arr[ac++] = String.fromCharCode(
        ((c1 & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
      );
      i += 3;
    } else {
      c2 = str_data.charCodeAt(i + 1);
      c3 = str_data.charCodeAt(i + 2);
      c4 = str_data.charCodeAt(i + 3);
      c1 = ((c1 & 7) << 18) | ((c2 & 63) << 12) | ((c3 & 63) << 6) | (c4 & 63);
      c1 -= 0x10000;
      tmp_arr[ac++] = String.fromCharCode(0xd800 | ((c1 >> 10) & 0x3ff));
      tmp_arr[ac++] = String.fromCharCode(0xdc00 | (c1 & 0x3ff));
      i += 4;
    }
  }

  return tmp_arr.join("");
}
