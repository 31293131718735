import React, { useEffect, useState } from "react";
import {
  IconButton,
  Button,
  Typography,
  Box,
  Modal,
  TextField,
  FormGroup,
  FormHelperText
} from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";

/* CUSTOM LIBRARIES */
import CustomSelect from "../common/components/customSelect";
import FinishModal from "../common/modals/finishModal";
import DragDropFileList from "../common/components/dragAndDropList";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import {
  listaFilesExtenderContrato,
  listaTipoContrato
} from "../../../controller/listas";
import { useDispatch } from "react-redux";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../redux/confirmModalSlice";
import { useUpdateTrabajadorMutation } from "../../../api/trabajadoresApiSlice";
import { useNavigate } from "react-router-dom";
import LoadingRocket from "../common/things/loadingRocket";
import { useLazyValidarDocumentoQuery } from "../../../api/docsApiSlice";

export default function ExtenderContratoModal(props) {
  const { openModal, setOpenModal, trabajador } = props;
  const [updatePost, updateResult] = useUpdateTrabajadorMutation();
  const [validarDoc, getResultValidar] = useLazyValidarDocumentoQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [continueValue, setContinueValue] = useState(false);
  const [openFinishModal, setOpenFinishModal] = useState(false);
  const [stepCount, setStepCount] = useState(0);
  const [listaFiles, setListaFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [tipoDoc, setTipoDoc] = useState({});

  const [valueNombre, setValueNombre] = useState("");
  const [valueTipoContrato, setValueTipoContrato] = useState("");
  const [valueFechaFin, setValueFechaFin] = useState("");
  const [valueSueldo, setValueSueldo] = useState(0);

  const [errorTipoContrato, setErrorTipoContrato] = useState("");
  const [errorFechaFin, setErrorFechaFin] = useState("");

  const littleSize = littleSizeFunc();

  const handleGuardar = async () => {
    try {
      await updatePost({
        id: trabajador.id,
        updatedValues: {
          fecha_fin_contrato: valueFechaFin,
          tipo_contrato: valueTipoContrato,
          renta: valueSueldo
        }
      }).unwrap();
      await validarDoc(uploadedFiles[0].id).unwrap();
      dispatch(
        setSuccessText("¡Tus cambios han sido guardados de forma exitosa!")
      );
    } catch (e) {
      dispatch(
        setErrorText(
          "¡Hubo un error, intente nuevamente o contáctese con nosotros!"
        )
      );
    }
    setOpenModal(false);
    dispatch(setOpenConfirmModal(true));
    setOpenFinishModal(false);
    setStepCount(0);
    setValueFechaFin("");
    setValueTipoContrato("");
    setValueSueldo(0);
    setContinueValue(false);
  };

  useEffect(() => {
    if (trabajador && openModal) {
      setValueNombre(trabajador.nombre);
      setValueTipoContrato(trabajador.tipo_contrato);
      setValueFechaFin(trabajador.fecha_fin_contrato);
      setValueSueldo(trabajador.renta);
      let lista_tipos = [...listaFilesExtenderContrato];
      lista_tipos.forEach((file, index) => {
        file["id_tipo"] = 0;
        file["trabajador"] = trabajador.id;
      });
      setListaFiles([...lista_tipos]);
    }
  }, [trabajador, openModal]);

  useEffect(() => {
    if (stepCount === 0) {
      setOpenFinishModal(false);
    } else if (stepCount === 1) {
      setOpenFinishModal(true);
    } else if (stepCount === 2) {
      handleGuardar();
    }
  }, [stepCount]);

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      if (valueTipoContrato === "Indefinido") {
        setContinueValue(
          valueTipoContrato && valueSueldo && uploadedFiles[0].titulo
        );
      } else {
        setContinueValue(
          valueTipoContrato &&
            valueFechaFin &&
            valueSueldo &&
            uploadedFiles[0].titulo
        );
      }
    }
  }, [
    valueTipoContrato,
    valueFechaFin,
    valueSueldo,
    uploadedFiles,
    continueValue
  ]);

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      if (uploadedFiles[0].titulo) {
        setValueTipoContrato(uploadedFiles[0].tipo_contrato);
        setValueFechaFin(uploadedFiles[0].fecha_fin_contrato);
        setValueSueldo(uploadedFiles[0].renta);
      }
    }
  }, [uploadedFiles]);

  const handleCambioFecha = (value) => {
    if (value !== valueFechaFin) {
      setValueFechaFin(value);
    }
  };

  const handleCambioSueldo = (value) => {
    if (value !== valueSueldo) {
      setValueSueldo(value);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setStepCount(0);
    setValueFechaFin("");
    setValueTipoContrato("");
  };

  const handleContinue = () => {
    setContinueValue(false);
    setStepCount(stepCount + 1);
  };

  const handleBack = () => {
    setContinueValue(true);
    setStepCount(stepCount - 1);
  };

  const modalBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxWidth: "1300px",
    height: "fit-content",
    maxHeight: "98vh",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: "5vh 3.472vw 3vh 3.472vw ",
    gap: "3vh"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const content = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "20px",
    gap: "20px",
    overflowY: "auto"
  };
  const titleBox = {
    display: "flex",
    flexDirection: "column",
    gap: "11px"
  };
  const title = {
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "140%"
  };
  const subtitle = {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "150%",
    alignItems: littleSize ? "center" : "",
    textAlign: littleSize ? "center" : ""
  };
  const selectDatosBox = {
    flexDirection: "row",
    gap: "0px",
    maxWidth: "300px",
    width: "14.58vw",
    minWidth: "210px"
  };
  const textField = {
    height: "inherit",
    background: "#FFFFFF",
    fontWeight: 400,
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400
    }
  };
  const buttonBox = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    gap: "17px",
    margin: "0 0 1px 0"
  };
  const button = {
    minWidth: "160px",
    textTransform: "none",
    borderRadius: "10px",
    fontWeight: 600
  };
  const textStyleFinishModal = {
    textAlign: "center"
  };
  const fileBox = {
    width: "600px"
  };

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={modalBox}>
        <Box sx={closeButtonBox}>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={titleBox}>
          <Typography sx={title}>Extender Contrato</Typography>
          <Typography sx={subtitle}>Trabajador/a: {valueNombre}</Typography>
        </Box>
        {tipoDoc ? (
          <Box sx={content}>
            <Box sx={selectDatosBox} component={FormGroup}>
              <CustomSelect
                req={true}
                label="Tipo de contrato"
                fontWeightValue={400}
                value={valueTipoContrato}
                setValue={setValueTipoContrato}
                errorText={valueTipoContrato ? "" : errorTipoContrato}
              >
                {listaTipoContrato}
              </CustomSelect>
            </Box>
            {valueTipoContrato != "Indefinido" ? (
              <Box sx={selectDatosBox} component={FormGroup}>
                <TextField
                  sx={textField}
                  size="small"
                  fullWidth
                  required
                  id="fechaFinContrato"
                  label="Fecha fin de contrato"
                  type="date"
                  value={valueFechaFin}
                  onChange={(e) => handleCambioFecha(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  // onBlur={(e) => handleMailChange(e.target.value)}
                />
                <FormHelperText error>{errorFechaFin}</FormHelperText>
              </Box>
            ) : null}
            <Box sx={selectDatosBox} component={FormGroup}>
              <TextField
                sx={textField}
                size="small"
                fullWidth
                required
                id="fechaFinContrato"
                label="Sueldo mensual bruto"
                type="number"
                value={valueSueldo}
                onChange={(e) => handleCambioSueldo(e.target.value)}
                InputLabelProps={{ shrink: true }}
                // onBlur={(e) => handleMailChange(e.target.value)}
              />
              <FormHelperText error>{errorFechaFin}</FormHelperText>
            </Box>
            <Box sx={fileBox}>
              <DragDropFileList
                list={listaFiles}
                setContinueValue={setContinueValue}
                multiple={false}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                type="anexo"
              />
            </Box>
          </Box>
        ) : (
          <LoadingRocket dark dialog={false} tiny />
        )}
        <Box sx={buttonBox}>
          <Button
            sx={button}
            variant="contained"
            onClick={handleContinue}
            disableElevation
            disabled={!continueValue}
          >
            Extender contrato
          </Button>
        </Box>
        <FinishModal
          openModal={openFinishModal}
          setOpenModal={setOpenFinishModal}
          handleContinue={handleContinue}
          handleBack={handleBack}
          buttonText="Extender"
          navigatePath="/Trabajadores"
        >
          <Typography sx={textStyleFinishModal}>
            ¿Estás seguro que quieres extender la fecha del contrato?
          </Typography>
        </FinishModal>
      </Box>
    </Modal>
  );
}
