import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Box, Typography, Button, Modal, IconButton } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";

/* CUSTOM LIBRARIES */
import CustomStepper from "../common/components/customStepper";
import DragDropFile from "../common/components/dragAndDrop";
import CustomTable from "../common/components/customTable";
import FinishModal from "../common/modals/finishModal";
import SubirDocumentoModal from "../common/modals/subirDocumentoModal";
import PDFViewer from "../common/components/pdfViewer";
import LoadingRocket from "../common/things/loadingRocket";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { transition } from "../../../controller/utils";
import {
  stepsCargaMasivaVencimientos,
  headCellsVencimientos,
  headCellsTipoDocumentos
} from "../../../controller/listas";
import {
  setErrorText,
  setSuccessText,
  setOpenConfirmModal
} from "../../../redux/confirmModalSlice";
import { useDispatch, useSelector } from "react-redux";

/* TEST DATA */
import { listaVencimientos } from "../../../controller/testData";
import { useAddDocumentosMasivosMutation } from "../../../api/docsApiSlice";
import { currentFaena } from "../../../redux/entidadesSlice";

function FirstPage(props) {
  const { setContinueValue, archivos, setArchivos, isLoading } = props;
  const littleSize = littleSizeFunc();

  /* CSS */
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : ""
  };

  return isLoading ? (
    <LoadingRocket isLoading={isLoading} />
  ) : (
    <Box sx={selectDatosBoxBox}>
      <DragDropFile
        setContinueValue={setContinueValue}
        uploadedFiles={archivos}
        setUploadedFiles={setArchivos}
      />
    </Box>
  );
}

function SecondPage(props) {
  const { listaTipoDocs } = props;
  const [listaTipoDocsFiltrada, setListaTipoDocsFiltrada] =
    useState(listaTipoDocs);

  /* CSS */
  const contenido = {
    // minHeight: "40vh",
    width: "100%",
    height: "fit-content",
    maxHeight: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "1vh",
    transition: transition
  };

  return (
    <Box sx={contenido}>
      <CustomTable
        search={false}
        headCells={headCellsTipoDocumentos}
        rows={listaTipoDocsFiltrada}
        setRows={setListaTipoDocsFiltrada}
        variant="tipo_documentos"
        isLoading={listaTipoDocs.length < 1}
      />
    </Box>
  );
}

export default function SubirPermisosInasistencia() {
  const { setSubtitleValue } = useOutletContext();
  const selectedFaena = useSelector(currentFaena);
  const littleSize = littleSizeFunc();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [uploadDocs, uploadResults] = useAddDocumentosMasivosMutation();

  const [stepCount, setStepCount] = useState(0);
  const [continueValue, setContinueValue] = useState(false);
  const [archivos, setArchivos] = useState([]);
  const [listaTipoDocs, setListaTipoDocs] = useState([]);

  useEffect(() => {
    setSubtitleValue("");
  }, []);

  useEffect(() => {
    if (stepCount === 1) {
      setContinueValue(true);
    }
    if (stepCount === 2) {
      setContinueValue(false);
      dispatch(
        setSuccessText("¡Tus documentos han sido cargados de forma exitosa!")
      );
      dispatch(setOpenConfirmModal(true));
      navigate("/Faenas/Listar documentacion");
    }
  }, [stepCount]);

  const handleAsyncUpload = async () => {
    try {
      const response = await uploadDocs({
        documentos: archivos,
        tipo: "permisos",
        faena: selectedFaena.id
      }).unwrap();
      setStepCount(stepCount + 1);
      setContinueValue(false);
      setListaTipoDocs(response);
    } catch (err) {
      console.error(err);
      dispatch(
        setErrorText(
          "¡Hubo un error al subir los documentos, intente nuevamente o conctace con nosotros!"
        )
      );
      dispatch(setOpenConfirmModal(true));
    }
  };

  const handleContinue = () => {
    if (stepCount == 0) {
      handleAsyncUpload();
    } else {
      setContinueValue(false);
      setStepCount(stepCount + 1);
    }
  };
  const handleBack = () => {
    setStepCount(stepCount - 1);
  };

  /* CSS */
  const datosButtonBox = {
    minHeight: "58vh",
    height: "100%",
    // maxHeight: "84vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    gap: stepCount === 1 ? "3vh" : "4vh",
    transition: transition
  };
  const datosBox = {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-around",
    gap: stepCount === 1 ? "1vh" : "3vh",
    alignItems: littleSize ? "center" : ""
  };
  const tituloDatos = {
    fontWeight: 900,
    fontSize: "24px",
    lineHeight: "100%",
    color: "#1876D1"
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px"
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    position: littleSize ? (stepCount > 0 ? "" : "absolute") : "",
    visibility: littleSize ? (stepCount > 0 ? "visible" : "hidden") : "",
    opacity: stepCount > 0 ? 1 : 0,
    ":hover": {
      border: "2px solid #1876D1"
    }
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1",
    minWidth: "110px"
  };

  return (
    <Box>
      <Box sx={datosButtonBox}>
        <CustomStepper stepCount={stepCount}>
          {stepsCargaMasivaVencimientos}
        </CustomStepper>
        {stepCount === 0 ? (
          <Box sx={datosBox}>
            <Typography sx={tituloDatos}>
              Paso 1: Suba los permisos de inasistencia
            </Typography>
            <Typography>
              A continuación, suba todos los documentos que se traten de
              permisos de inasistencia no remuneradas.
            </Typography>
            <FirstPage
              setContinueValue={setContinueValue}
              archivos={archivos}
              setArchivos={setArchivos}
              isLoading={uploadResults.isLoading}
            />
          </Box>
        ) : (
          <Box sx={datosBox}>
            <Typography sx={tituloDatos}>Paso 2: Revisión final</Typography>
            <Typography>
              <p>
                Antes de finalizar, por favor confirme que los documentos
                requeridos esten en la siguiente lista.
              </p>
            </Typography>
            <SecondPage listaTipoDocs={listaTipoDocs} />
          </Box>
        )}
        <Box sx={buttonBox}>
          <Button sx={buttonVolver} variant="outlined" onClick={handleBack}>
            Volver
          </Button>
          <Button
            sx={button}
            disableElevation
            variant="contained"
            type="submit"
            onClick={handleContinue}
            disabled={!continueValue}
          >
            {stepCount > 0 ? "Finalizar" : "Cargar"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
