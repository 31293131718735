import React from "react";
import { Box, Typography } from "@mui/material";

/* CONTROLLER VARIABLES */
import { transition } from "../../../../controller/utils";

export default function BarraCarga(props) {
  const { disabled, archivo, value } = props;

  let percentage = value;

  /* CSS */
  const meter = {
    transition: transition,
    opacity: value && !disabled && value < 100 ? 1 : 0,
    width: "100%",
    height: value && !disabled && value < 100 ? "fit-content" : "0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "2px",
    borderRadius: "8px",
    margin: "16px 0 0 0"
  };
  const lineBox = {
    transition: transition,
    opacity: value && !disabled && value < 100 ? 1 : 0,
    width: "100%",
    height: value && !disabled && value < 100 ? "5px" : "0px",
    display: "flex",
    borderRadius: "8px",
  };
  const line = {
    position: "relative",
    height: "inherit",
    minHeight: "5px",
    borderRadius: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    background: "#D9D9D9",
  };
  const blueLine = {
    position: "absolute",
    width: `${percentage}%`,
    height: "inherit",
    background: "#1876D1",
    borderRadius: "10px",
  };
  const textStyle = {
    fontWeight: 400,
    fontSize: "10px",
    width: "100%",
    minWidth: "80px",
    color: "#818181",
    textAlign: "right",
  };

  return (
    <Box sx={meter}>
      <Box sx={lineBox}>
        <Box sx={line}>
          <Box sx={blueLine} />
        </Box>
      </Box>
      <Typography sx={textStyle}>{`${archivo} (${percentage === 0.1 ? 0 : percentage}%)`}</Typography>
    </Box>
  );
}
