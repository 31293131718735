import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import {
  IconButton,
  Button,
  Typography,
  Box,
  Modal,
  TextField,
  ListItemButton,
  ListItemIcon
} from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";

/* CUSTOM LIBRARIES */
import DragDropFileList from "../common/components/dragAndDropList";
import PDFViewer from "../common/components/pdfViewer";
import StateCell from "../common/things/stateCell";
import InfoHover from "../common/things/infoHover";
import LoadingRocket from "../common/things/loadingRocket";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { listaFilesTicket } from "../../../controller/listas";
import {
  FileIcon,
  quoted_printable_decode,
  transition,
  utf8_decode
} from "../../../controller/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../redux/confirmModalSlice";
import { selectCurrentType } from "../../../redux/authSlice";
import {
  useAddRespuestaMutation,
  useLazyGetTicketQuery
} from "../../../api/ticketsApiSlice";

export default function TicketModal(props) {
  const { openModal, setOpenModal, lineaTicket } = props;
  const { setSelectedTicket, setSelectedVerificacion } = useOutletContext();
  const userType = useSelector(selectCurrentType);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const littleSize = littleSizeFunc();

  const [triggerGetData, { isLoading }] = useLazyGetTicketQuery();
  const [updateTicket, updateResult] = useAddRespuestaMutation();

  const [continueValue, setContinueValue] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [openPDFModal, setOpenPDFModal] = useState(false);
  const [openPDFRechazadoModal, setOpenPDFRechazadoModal] = useState(false);
  const [documento, setDocumento] = useState("");
  const [ticketDocId, setTicketDocId] = useState("");
  const [hover, setHover] = useState([false]);

  const [valueParrafo, setValueParrafo] = useState("");
  const [respuestas, setRespuestas] = useState([]);

  useEffect(() => {
    valueParrafo ? setContinueValue(true) : setContinueValue(false);
  }, [valueParrafo]);

  useEffect(() => {
    if (openModal) {
      handleGetTicketData();
    }
  }, [openModal]);

  useEffect(() => {
    let array = new Array(respuestas.length + 1).fill(false);
    setHover(array);
  }, [respuestas]);

  const handleGetTicketData = async () => {
    const { data, isSuccess } = await triggerGetData(lineaTicket.id, false);
    if (isSuccess) {
      setRespuestas(data.data);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTicket(null);
    handleClosePDFModal();
    setContinueValue(false);
    setValueParrafo("");
  };

  const handleContinue = async () => {
    try {
      await updateTicket({
        id: lineaTicket.id,
        titulo: uploadedFiles[0].name ? uploadedFiles[0].name : null,
        texto: valueParrafo,
        cerrar: false,
        blob: uploadedFiles[0].blob ? uploadedFiles[0].blob : null
      }).unwrap();
      dispatch(setSuccessText("¡Tu mensaje ha sido enviado de forma exitosa!"));
      handleCloseModal();
    } catch (e) {
      console.error(e);
      dispatch(setErrorText("¡Hubo un error, intente nuevamente más tarde!"));
    }
    dispatch(setOpenConfirmModal(true));
  };

  const handleContinueAndClose = async () => {
    try {
      await updateTicket({
        id: lineaTicket.id,
        titulo: uploadedFiles[0].name ? uploadedFiles[0].name : null,
        texto: valueParrafo,
        cerrar: true,
        blob: uploadedFiles[0].blob ? uploadedFiles[0].blob : null
      }).unwrap();
      dispatch(setSuccessText("¡Tu mensaje ha sido enviado de forma exitosa!"));
      handleCloseModal();
    } catch (e) {
      console.error(e);
      dispatch(setErrorText("¡Hubo un error, intente nuevamente más tarde!"));
    }
    dispatch(setOpenConfirmModal(true));
  };

  const handleClickDoc = (e, id, document, index) => {
    setTicketDocId(`${id}/${index}`);
    setDocumento(document);
    setOpenPDFModal(true);
    setOpenPDFRechazadoModal(false);
  };

  const handleClickDocRechazado = (e, documento) => {
    setTicketDocId(documento.id);
    setDocumento(documento.path);
    setOpenPDFModal(false);
    setOpenPDFRechazadoModal(true);
  };

  const handleClickVerificacion = (e, docId) => {
    setSelectedVerificacion(docId);
    navigate("/Verificaciones pendientes");
  };

  const handleParrafoValue = (value) => {
    if (valueParrafo.length <= 255 && value.length <= 255) {
      setValueParrafo(value);
    }
  };

  const handleClosePDFModal = () => {
    setOpenPDFRechazadoModal(false);
    setOpenPDFModal(false);
  };

  const handleMouseEnter = (event) => {
    event.preventDefault();
    setHover(hover.with(event.target.id, true));
  };
  const handleMouseLeave = (event) => {
    event.preventDefault();
    setHover(hover.with(event.target.id, false));
  };

  const todo = {
    transition: transition,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: littleSize ? "wrap" : "nowrap",
    // flexWrap: "wrap",
    justifyContent: "center",
    gap: "2vw",
    padding: "2vh 2vw",
    overflow: littleSize ? "auto" : "hidden"
  };
  const pdfModalContainer = {
    position: "relative",
    minHeight: "0px",
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    transition: transition,
    " .MuiBox-root.MuiBox-root": {
      borderRadius: "20px"
    }
  };
  const closeButtonBoxPDF = {
    width: openPDFModal || openPDFRechazadoModal ? "fit-content" : "0px",
    height: openPDFModal || openPDFRechazadoModal ? "fit-content" : "0px",
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const modalBox = {
    position: "relative",
    transition: transition,
    minWidth: "40%",
    width: "fit-content",
    maxWidth: littleSize ? "99%" : "54%",
    height: "fit-content",
    maxHeight: "98vh",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FFFFFF",
    borderRadius: "20px",
    padding: "5vh 3.472vw 3vh 3.472vw ",
    gap: "3vh"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const content = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "20px",
    gap: "20px",
    overflowY: "auto"
  };
  const textBox = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "fit-content",
    background: "#FCFBFB",
    padding: "20px 20px 40px 20px",
    borderRadius: "20px",
    border: "1px solid #D9D9D9",
    gap: "44px"
  };
  const textBoxTitle = {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "140%",
    width: "fit-content",
    color: "#1876D1"
  };
  const textBoxText = {
    fontSize: "16px",
    whiteSpace: "pre-line"
  };
  const titleBox = {
    display: "flex",
    flexDirection: "column",
    gap: "11px"
  };
  const stateBox = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px"
  };
  const title = {
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "140%",
    width: "fit-content"
  };
  const subtitleBox = {
    display: "flex",
    flexDirection: "column"
    // gap: "11px",
  };
  const subtitle = {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "150%",
    alignItems: littleSize ? "center" : "",
    textAlign: littleSize ? "center" : ""
  };
  const subsubtitle = {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "100%",
    alignItems: littleSize ? "center" : "",
    textAlign: littleSize ? "center" : ""
  };
  const datos = {
    display: "flex",
    flexDirection: "column",
    alignItems: littleSize ? "center" : "",
    width: "100%",
    gap: "11px"
  };
  const tituloDatos = {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "140%"
  };
  const selectDatosBoxBox = {
    height: "fit-content",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : ""
  };
  const textField = {
    width: "100%",
    height: "fit-content",
    background: "#FFFFFF",
    fontWeight: 600,
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400
    }
  };
  const buttonBox = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    gap: "17px",
    margin: "0 0 1px 0"
  };
  const buttonCerrar = {
    minWidth: "160px",
    textTransform: "none",
    borderRadius: "10px",
    fontWeight: 600
  };
  const button = {
    minWidth: "160px",
    textTransform: "none",
    borderRadius: "10px",
    fontWeight: 600
  };
  const documentList = {
    display: "flex",
    gap: "11px",
    flexDirection: "column"
  };
  const documentoButton = {
    display: "flex",
    justifyContent: "space-around",
    minHeight: "50px",
    width: "fit-content",
    maxWidth: "100%",
    gap: "10px",
    background: "#FCFCFC",
    fontWeight: 600,
    borderRadius: "10px",
    boxShadow: 1,
    border: "1px solid #1876D1",
    overflowWrap: "normal",
    "&:hover": {
      opacity: 0.9
    }
  };
  const listIcon = {
    height: "40px",
    width: "fit-content"
  };
  const buttonText = {
    maxWidth: "90%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "keep-all"
  };
  const verificacionButton = {
    height: "40px",
    width: "fit-content",
    gap: "20px",
    background: "#FCFCFC",
    fontWeight: 600,
    borderRadius: "10px",
    boxShadow: 1,
    border: "1px solid #1876D1",
    color: "#1876D1",
    "&:hover": {
      opacity: 0.9
    }
  };

  return lineaTicket ? (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={todo}>
        <Box sx={modalBox}>
          <Box sx={closeButtonBox}>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={titleBox}>
            <Box sx={stateBox}>
              <Typography sx={title}>Ticket de ayuda</Typography>
              <StateCell table={false} state={lineaTicket.estado} />
            </Box>
            <Box sx={subtitleBox}>
              <Typography sx={subsubtitle}>
                N° ticket: {lineaTicket.id}
              </Typography>
              <Typography sx={subtitle}>{lineaTicket.motivo}</Typography>
              <Box sx={documentList}>
                {lineaTicket.documento_rechazado ? (
                  <ListItemButton
                    sx={documentoButton}
                    key={lineaTicket.documento_rechazado.id}
                    onClick={(event) =>
                      handleClickDocRechazado(
                        event,
                        lineaTicket.documento_rechazado
                      )
                    }
                  >
                    <ListItemIcon sx={listIcon}>
                      {FileIcon(lineaTicket.documento_rechazado.path)}
                    </ListItemIcon>
                    <Typography sx={buttonText}>
                      {utf8_decode(
                        quoted_printable_decode(
                          lineaTicket.documento_rechazado.path
                            .split("/")
                            .slice(-1)[0]
                            .toString()
                            .replaceAll("%", "=")
                        )
                      )}
                    </Typography>
                  </ListItemButton>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box sx={content}>
            <Box sx={textBox}>
              <Typography sx={textBoxTitle}>
                <Box
                  id={0}
                  onPointerEnter={handleMouseEnter}
                  onPointerLeave={handleMouseLeave}
                >
                  {lineaTicket.usuario?.name}{" "}
                  {lineaTicket.usuario?.firstLastname}:
                </Box>
                <InfoHover persist hover={hover[0]}>
                  <p>
                    <b>Email:</b>
                    <br />
                    {lineaTicket.usuario?.email}
                  </p>
                  <p>
                    <b>Teléfono:</b>
                    <br /> {lineaTicket.usuario?.phone}
                  </p>
                </InfoHover>
              </Typography>
              <Typography sx={textBoxText}>{lineaTicket.detalles}</Typography>
              <Box sx={documentList}>
                {[
                  lineaTicket.documento_1,
                  lineaTicket.documento_2,
                  lineaTicket.documento_3
                ].map((doc, index) =>
                  doc ? (
                    <ListItemButton
                      sx={documentoButton}
                      key={index}
                      onClick={(event) =>
                        handleClickDoc(event, lineaTicket.id, doc, index + 1)
                      }
                    >
                      <ListItemIcon sx={listIcon}>{FileIcon(doc)}</ListItemIcon>
                      <Typography sx={buttonText}>
                        {utf8_decode(
                          quoted_printable_decode(
                            doc
                              .split("/")
                              .slice(-1)[0]
                              .toString()
                              .replaceAll("%", "=")
                          )
                        )}
                      </Typography>
                    </ListItemButton>
                  ) : null
                )}
              </Box>
              {/necesita verificaci[oó]n/i.test(lineaTicket.estado) &&
              lineaTicket.documento_rechazado ? (
                <ListItemButton
                  sx={verificacionButton}
                  key={lineaTicket.id}
                  onClick={(event) =>
                    handleClickVerificacion(event, lineaTicket.id)
                  }
                >
                  <BackHandOutlinedIcon />
                  Ir a verificar el documento
                </ListItemButton>
              ) : null}
            </Box>
            {isLoading ? (
              <Box sx={textBox}>
                <LoadingRocket dialog={false} dark />
              </Box>
            ) : (
              respuestas.map((respuesta, index) => (
                <Box sx={textBox}>
                  <Typography sx={textBoxTitle}>
                    <Box
                      id={index + 1}
                      onPointerEnter={handleMouseEnter}
                      onPointerLeave={handleMouseLeave}
                    >
                      {respuesta.usuario?.name}{" "}
                      {respuesta.usuario?.firstLastname}:
                    </Box>
                    <InfoHover persist hover={hover[index + 1]}>
                      <p>
                        <b>Email:</b>
                        <br />
                        {respuesta.usuario?.email}
                      </p>
                      <p>
                        <b>Teléfono:</b>
                        <br /> {respuesta.usuario?.phone}
                      </p>
                    </InfoHover>
                  </Typography>
                  <Typography sx={textBoxText}>
                    {respuesta.respuesta}
                  </Typography>
                  <Box sx={documentList}>
                    {respuesta.documento ? (
                      <ListItemButton
                        sx={documentoButton}
                        key={respuesta.documento}
                        onClick={(event) =>
                          handleClickDoc(
                            event,
                            respuesta.id,
                            respuesta.documento,
                            index + 4
                          )
                        }
                      >
                        <ListItemIcon sx={listIcon}>
                          {FileIcon(respuesta.documento)}
                        </ListItemIcon>
                        <Typography sx={buttonText}>
                          {utf8_decode(
                            quoted_printable_decode(
                              respuesta.documento
                                .split("/")
                                .slice(-1)[0]
                                .toString()
                                .replaceAll("%", "=")
                            )
                          )}
                        </Typography>
                      </ListItemButton>
                    ) : null}
                  </Box>
                </Box>
              ))
            )}
            {/abierto/i.test(lineaTicket.estado) ||
            /necesita verificaci[oó]n/i.test(lineaTicket.estado) ? (
              <Box sx={datos}>
                <Typography sx={tituloDatos}>Responder mensaje</Typography>
                <Box sx={selectDatosBoxBox}>
                  <TextField
                    sx={textField}
                    size="medium"
                    required
                    multiline
                    rows={5}
                    id="parrafo"
                    label="Respuesta"
                    type="text"
                    value={valueParrafo}
                    onChange={(e) => handleParrafoValue(e.target.value)}
                    helperText={
                      <Typography variant="body1" style={{ color: "#818181" }}>
                        {valueParrafo.length} / 255
                      </Typography>
                    }
                  />
                </Box>
              </Box>
            ) : null}
            {/abierto/i.test(lineaTicket.estado) ||
            /necesita verificaci[oó]n/i.test(lineaTicket.estado) ? (
              <Box sx={datos}>
                <Typography sx={tituloDatos}>
                  Adjuntar documentos de apoyo
                </Typography>
                <DragDropFileList
                  list={listaFilesTicket}
                  setContinueValue={() => {}} // De esta manera no es necesario subir documento para enviar el ticket
                  multiple={false}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                />
              </Box>
            ) : null}
          </Box>
          {/abierto/i.test(lineaTicket.estado) ||
          /necesita verificaci[oó]n/i.test(lineaTicket.estado) ? (
            <Box sx={buttonBox}>
              {userType === "Admin" || userType === "Supp" ? (
                <Button
                  sx={buttonCerrar}
                  variant="contained"
                  onClick={handleContinueAndClose}
                  disableElevation
                  disabled={!continueValue}
                >
                  Enviar y cerrar ticket
                </Button>
              ) : null}
              <Button
                sx={button}
                variant="contained"
                onClick={handleContinue}
                disableElevation
                disabled={!continueValue}
              >
                Enviar
              </Button>
            </Box>
          ) : null}
        </Box>
        {openPDFModal ? (
          <Box sx={pdfModalContainer}>
            <Box sx={closeButtonBoxPDF}>
              <IconButton onClick={handleClosePDFModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <PDFViewer
              openModal={openPDFModal}
              setOpenModal={setOpenPDFModal}
              type="ticket"
              id={ticketDocId}
              subtitle={documento.split("/").slice(-1)[0]}
            />
          </Box>
        ) : openPDFRechazadoModal ? (
          <Box sx={pdfModalContainer}>
            <Box sx={closeButtonBoxPDF}>
              <IconButton onClick={handleClosePDFModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <PDFViewer
              openModal={openPDFRechazadoModal}
              setOpenModal={setOpenPDFRechazadoModal}
              id={ticketDocId}
              subtitle={documento.split("/").slice(-1)[0]}
            />
          </Box>
        ) : null}
      </Box>
    </Modal>
  ) : null;
}
