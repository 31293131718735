import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Box, Typography, Button, Modal, IconButton } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";

/* CUSTOM LIBRARIES */
import CustomStepper from "../common/components/customStepper";
import DragDropFile from "../common/components/dragAndDrop";
import CustomTable from "../common/components/customTable";
import FinishModal from "../common/modals/finishModal";
import SubirDocumentoModal from "../common/modals/subirDocumentoModal";
import PDFViewer from "../common/components/pdfViewer";
import LoadingRocket from "../common/things/loadingRocket";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { transition } from "../../../controller/utils";
import {
  stepsCargaMasivaVencimientos,
  headCellsVencimientos
} from "../../../controller/listas";
import {
  setErrorText,
  setSuccessText,
  setOpenConfirmModal
} from "../../../redux/confirmModalSlice";
import { useDispatch } from "react-redux";

/* TEST DATA */
import { listaVencimientos } from "../../../controller/testData";
import { useAddDocumentosMasivosMutation } from "../../../api/docsApiSlice";

function FirstPage(props) {
  const { setContinueValue, archivos, setArchivos, isLoading } = props;
  const littleSize = littleSizeFunc();

  /* CSS */
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : ""
  };

  return isLoading ? (
    <LoadingRocket isLoading={isLoading} />
  ) : (
    <Box sx={selectDatosBoxBox}>
      <DragDropFile
        setContinueValue={setContinueValue}
        uploadedFiles={archivos}
        setUploadedFiles={setArchivos}
      />
    </Box>
  );
}

function SecondPage(props) {
  const [openModal, setOpenModal] = useState(false);
  const [openUploadSingleModal, setOpenUploadSingleModal] = useState(false);
  const [openUploadMultipleModal, setOpenUploadMultipleModal] = useState(false);
  const [openPDFModal, setOpenPDFModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [listaVencimientosFiltrada, setListaVencimientosFiltrada] =
    useState(listaVencimientos);
  const [lineaSeleccionada, setLineaSeleccionada] = useState("");

  const handleClosePDFModal = (e) => setOpenPDFModal(false);

  /* CSS */
  const contenido = {
    // minHeight: "40vh",
    width: "100%",
    height: "fit-content",
    maxHeight: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "1vh",
    transition: transition
  };
  const textStyleFinishModal = {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "20px",
    color: "#818181"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const modalPDF = {
    transition: transition,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    height: "fit-content",
    " .MuiBox-root.MuiBox-root": {
      borderRadius: "20px"
    }
  };

  return listaVencimientos.length > 0 ? (
    <Box sx={contenido}>
      <CustomTable
        search={false}
        headCells={headCellsVencimientos}
        rows={listaVencimientosFiltrada}
        setRows={setListaVencimientosFiltrada}
        variant="documentos"
        setLineaSeleccionada={setLineaSeleccionada}
        setOpenUploadMultipleModal={setOpenUploadMultipleModal}
        setOpenUploadSingleModal={setOpenUploadSingleModal}
        setOpenPDFModal={setOpenPDFModal}
        setOpenErrorModal={setOpenErrorModal}
      />
      <SubirDocumentoModal
        openModal={openUploadSingleModal}
        setOpenModal={setOpenUploadSingleModal}
        title="Subir documento a vencer"
        subtitle={`${lineaSeleccionada.trabajador} - ${lineaSeleccionada.documento}`}
        doneNavigation="/Trabajadores/Vencimientos"
        maxCount={1}
        text={
          <p>
            <b>
              Hay varios documentos prontos a vencer asociados a este
              trabajador:
            </b>
          </p>
        }
      />
      <SubirDocumentoModal
        openModal={openUploadMultipleModal}
        setOpenModal={setOpenUploadMultipleModal}
        title="Subir documentos a vencer"
        subtitle={`Trabajador/a: ${lineaSeleccionada.trabajador}`}
        doneNavigation="/Trabajadores/Vencimientos"
        maxCount={1}
      >
        <p>
          <b>
            Hay varios documentos prontos a vencer asociados a este trabajador:
          </b>
        </p>
        <li>Documento de identidad</li>
        <li>Contrato fijo/indefinido</li>
      </SubirDocumentoModal>
      <FinishModal openModal={openErrorModal} setOpenModal={setOpenErrorModal}>
        <Typography sx={textStyleFinishModal}>
          El documento adjunto no corresponde a un/a{" "}
          <b>{lineaSeleccionada.documento}</b> vigente.
        </Typography>
      </FinishModal>
      <Modal open={openPDFModal} onClose={handleClosePDFModal}>
        <Box sx={modalPDF}>
          <Box sx={closeButtonBox}>
            <IconButton onClick={handleClosePDFModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <PDFViewer
            openModal={openPDFModal}
            setOpenModal={setOpenPDFModal}
            subtitle={`${lineaSeleccionada.trabajador} - ${lineaSeleccionada.documento}`}
          />
        </Box>
      </Modal>
    </Box>
  ) : null;
}

export default function CargaMasivaVencimientos() {
  const { setSubtitleValue } = useOutletContext();
  const littleSize = littleSizeFunc();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [uploadDocs, uploadResults] = useAddDocumentosMasivosMutation();

  const [stepCount, setStepCount] = useState(0);
  const [continueValue, setContinueValue] = useState(false);
  const [archivos, setArchivos] = useState([]);

  useEffect(() => {
    setSubtitleValue("");
  }, []);

  useEffect(() => {
    if (stepCount === 1) {
      setContinueValue(true);
    }
    if (stepCount === 2) {
      setContinueValue(false);
      dispatch(
        setSuccessText("¡Tus documentos han sido cargados de forma exitosa!")
      );
      dispatch(setOpenConfirmModal(true));
      navigate("/Trabajadores/Vencimientos");
    }
  }, [stepCount]);

  const handleAsyncUpload = async () => {
    try {
      const response = await uploadDocs({
        documentos: archivos,
        tipo: "vencimientos"
      }).unwrap();
      setStepCount(stepCount + 1);
      setContinueValue(false);
    } catch (err) {
      console.error(err);
      dispatch(
        setErrorText(
          "¡Hubo un error al subir los documentos, intente nuevamente o conctace con nosotros!"
        )
      );
      dispatch(setOpenConfirmModal(true));
    }
  };

  const handleContinue = () => {
    if (stepCount == 0) {
      handleAsyncUpload();
    } else {
      setContinueValue(false);
      setStepCount(stepCount + 1);
    }
  };
  const handleBack = () => {
    setStepCount(stepCount - 1);
  };

  /* CSS */
  const datosButtonBox = {
    minHeight: "58vh",
    height: "100%",
    // maxHeight: "84vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    gap: stepCount === 1 ? "3vh" : "4vh",
    transition: transition
  };
  const datosBox = {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-around",
    gap: stepCount === 1 ? "1vh" : "3vh",
    alignItems: littleSize ? "center" : ""
  };
  const tituloDatos = {
    fontWeight: 900,
    fontSize: "24px",
    lineHeight: "100%",
    color: "#1876D1"
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px"
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    position: littleSize ? (stepCount > 0 ? "" : "absolute") : "",
    visibility: littleSize ? (stepCount > 0 ? "visible" : "hidden") : "",
    opacity: stepCount > 0 ? 1 : 0,
    ":hover": {
      border: "2px solid #1876D1"
    }
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1",
    minWidth: "110px"
  };

  return (
    <Box>
      <Box sx={datosButtonBox}>
        <CustomStepper stepCount={stepCount}>
          {stepsCargaMasivaVencimientos}
        </CustomStepper>
        {stepCount === 0 ? (
          <Box sx={datosBox}>
            <Typography sx={tituloDatos}>
              Paso 1: Suba los documentos a vencer
            </Typography>
            <Typography>
              A continuación, suba todos los documentos que necesite actualizar
              en uno o más archivos y haga click en “cargar” para poder avanzar.
            </Typography>
            <FirstPage
              setContinueValue={setContinueValue}
              archivos={archivos}
              setArchivos={setArchivos}
              isLoading={uploadResults.isLoading}
            />
          </Box>
        ) : (
          <Box sx={datosBox}>
            <Typography sx={tituloDatos}>Paso 2: Revisión final</Typography>
            <Typography>
              <p>
                Antes de finalizar, por favor confirme que los documentos
                requeridos se hayan cargado correctamente revisando su estado.
              </p>
              <p>
                De existir uno con estado “sin subir”, recuerde que puede
                cargarlo manualmente.
              </p>
            </Typography>
            <SecondPage />
          </Box>
        )}
        <Box sx={buttonBox}>
          <Button sx={buttonVolver} variant="outlined" onClick={handleBack}>
            Volver
          </Button>
          <Button
            sx={button}
            disableElevation
            variant="contained"
            type="submit"
            onClick={handleContinue}
            disabled={!continueValue}
          >
            {stepCount > 0 ? "Finalizar" : "Cargar"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
