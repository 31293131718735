import React from "react";
import { IconButton, Typography, Box, Modal } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../../controller/windowSize";

/* IMAGES */
import adversoCorrecto from "../../../../images/carnet/adverso_correcto.jpg";
import adversoIncorrecto from "../../../../images/carnet/adverso_incorrecto.jpg";
import reversoCorrecto from "../../../../images/carnet/reverso_correcto.jpg";
import reversoIncorrecto from "../../../../images/carnet/reverso_incorrecto.jpg";

export default function CarnetModal(props) {
  const { openModal, setOpenModal } = props;
  const littleSize = littleSizeFunc();

  const handleCloseModal = () => setOpenModal(false);

  /* CSS */
  const modalBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "74.5139vw",
    height: "fit-content",
    maxHeight: "98vh",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: "6vh 3.542vw ",
    overflowY: "auto",
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin",
  };
  const contentWithText = {
    gap: "50px",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    overflowY: "auto",
  };
  const title = {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "140%",
    textAlign: littleSize ? "center" : "",
  };
  const content = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "4.4444vw",
    alignItems: "center",
    justifyContent: littleSize ? "center" : "",
  };
  const imgs = {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    gap: "2.353vh",
  };
  const imgsLine = {
    width: "fit-content",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "18px",
  };
  const icon = {
    width: "45px",
    height: "auto",
  };
  const imgStyleBox = {
    width: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: littleSize ? "center" : "",
    flexWrap: "wrap",
    gap: "2.292vw",
  };
  const imgStyle = {
    width: "16.66667vw",
    minWidth: "200px",
    maxWidth: "400px",
    height: "auto",
    objectFit: "cover",
    borderRadius: "10px",
  };

  const text = {};

  return (
    <Modal open={openModal} onClose={handleCloseModal} keepMounted>
      <Box sx={modalBox}>
        <Box sx={closeButtonBox}>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={contentWithText}>
          <Typography sx={title}>
            ¿Cómo subir la Cédula de identidad?
          </Typography>
          <Typography>
            <p>Para registrar a un nuevo trabajador, requerimos dos imágenes de su
            cédula de identidad: una del frente y otra del reverso. </p>
            <p>
            Es fundamental que ambas imágenes muestren la información de forma
            clara, sin brillos ni sombras que dificulten la lectura.
            Preferiblemente, las imágenes deben estar escaneadas para obtener la
            mejor calidad posible.
            </p>
          </Typography>
          <Box sx={content}>
            <Box sx={imgs}>
              <Box sx={imgsLine}>
                <CheckCircleOutlinedIcon sx={icon} htmlColor="#6DCDAA" />
                <Box sx={imgStyleBox}>
                  <Box
                    component="img"
                    src={adversoCorrecto}
                    alt="Adverso Correcto"
                    sx={imgStyle}
                  />
                  <Box
                    component="img"
                    src={reversoCorrecto}
                    alt="Reverso Correcto"
                    sx={imgStyle}
                  />
                </Box>
              </Box>
              <Box sx={imgsLine}>
                <CancelOutlinedIcon sx={icon} htmlColor="#DE5C5C" />
                <Box sx={imgStyleBox}>
                  <Box
                    component="img"
                    src={adversoIncorrecto}
                    alt="Adverso Incorrecto"
                    sx={imgStyle}
                  />
                  <Box
                    component="img"
                    src={reversoIncorrecto}
                    alt="Reverso Incorrecto"
                    sx={imgStyle}
                  />
                </Box>
              </Box>
            </Box>
            <Typography sx={text}>
              Problemas a evitar:
              <p></p>
              <li>Fotografías borrosas.</li>
              <li>Mala iluminación.</li>
              <li>Destellos sobre la imagen.</li>
              <li>Ángulos extremos.</li>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
