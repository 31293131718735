import React, { useState, useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Button, Typography, Box, Modal, IconButton } from "@mui/material";

/* ICONS */
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import CloseIcon from "@mui/icons-material/Close";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";

/* CUSTOM LIBRARIES */
import CustomTable from "../common/components/customTable";
import CustomSelect from "../common/components/customSelect";
import SubirDocumentoModal from "../common/modals/subirDocumentoModal";
import FinishModal from "../common/modals/finishModal";
import PDFViewer from "../common/components/pdfViewer";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { POLLING_INTERVAL, transition } from "../../../controller/utils";
import { headCellsVencimientos } from "../../../controller/listas";
import { currentEmpresa } from "../../../redux/entidadesSlice";
import { useSelector } from "react-redux";

/* TEST DATA */
import { tabla_lista_clientes } from "../../../controller/testData";
import { useGetVencimientosQuery } from "../../../api/docsApiSlice";
import { current } from "@reduxjs/toolkit";

export default function Vencimientos() {
  const littleSize = littleSizeFunc();
  const selectedEmpresa = useSelector(currentEmpresa);
  const { setSubtitleValue } = useOutletContext();

  const { currentData, isSuccess, isLoading, isFetching } =
    useGetVencimientosQuery(null, {
      pollingInterval: POLLING_INTERVAL
    });

  const [cliente, setCliente] = useState("");
  const [openUploadSingleModal, setOpenUploadSingleModal] = useState(false);
  const [openUploadMultipleModal, setOpenUploadMultipleModal] = useState(false);
  const [openPDFModal, setOpenPDFModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [listaVencimientos, setListaVencimientos] = useState([]);
  const [listaVencimientosFiltrada, setListaVencimientosFiltrada] = useState(
    []
  );
  const [listaFiltroEmpresa, setListaFiltroEmpresa] = useState([]);
  const [lineaSeleccionada, setLineaSeleccionada] = useState("");
  const [documentosAsociados, setDocumentosAsociados] = useState([]);

  useEffect(() => {
    if (selectedEmpresa.id) {
      setSubtitleValue(`Cliente: ${selectedEmpresa.value}`);
      setCliente(selectedEmpresa.id);
    } else {
      setSubtitleValue("");
    }
  }, []);

  useEffect(() => {
    if (isSuccess && currentData) {
      setListaVencimientos([...currentData.data]);
      setListaVencimientosFiltrada([...currentData.data]);
      var lista = [];
      currentData?.data.map((t) => {
        lista.push({
          id: t.nombre_empresa_mandante,
          value: t.nombre_empresa_mandante
        });
      });
      let jsonObject = lista.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      lista = Array.from(uniqueSet).map(JSON.parse);
      setListaFiltroEmpresa(lista);
    }
  }, [currentData, isSuccess, isFetching]);

  useEffect(() => {
    if (isSuccess) {
      if (
        listaVencimientos.length > 0 ||
        listaVencimientos === currentData.data
      ) {
        let listaVenc =
          listaVencimientos.lenght > listaVencimientosFiltrada.lenght
            ? [...listaVencimientosFiltrada]
            : [...listaVencimientos];

        if (cliente) {
          listaVenc = listaVenc
            .map((docObj) => {
              if (docObj.empresa_outsourcing === cliente) {
                return docObj;
              }
              return null;
            })
            .filter((docObj) => docObj !== null);
          setListaVencimientosFiltrada(listaVenc);
        } else {
          setListaVencimientosFiltrada(listaVenc);
        }
      }
    }
  }, [cliente, isFetching, isLoading, isSuccess, currentData]);

  useEffect(() => {
    if (openUploadMultipleModal || openUploadSingleModal) {
      let lista = [];
      if (lineaSeleccionada.nombre) {
        lista = listaVencimientos.filter(
          (venc) => lineaSeleccionada.nombre === venc.nombre
        );
        setDocumentosAsociados(lista);
      } else {
        lista = listaVencimientos.filter(
          (venc) => lineaSeleccionada.faena === venc.faena && !venc.nombre
        );
        setDocumentosAsociados(lista);
      }
    } else {
      setDocumentosAsociados([]);
    }
  }, [openUploadMultipleModal, openUploadSingleModal]);

  const handleClosePDFModal = (e) => setOpenPDFModal(false);
  const handleLimpiaFiltros = () => setCliente("");

  /* CSS */
  const contenido = {
    minHeight: "58vh",
    height: "fit-content",
    maxHeight: "84vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "5.17vh",
    transition: transition
  };
  const barraFiltros = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    flexWrap: "wrap-reverse",
    alignItems: "start",
    height: "fit-content",
    gap: "15px"
  };
  const formControl = {
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    height: "40px",
    width: "16vw",
    minWidth: "210px"
  };
  const buttonLimparFiltros = {
    height: "fit-content",
    width: "fit-content",
    transition: transition,
    textTransform: "none ",
    borderRadius: "12px",
    background: "#1876D1",
    color: "#FCFBFB",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "12px",
    gap: "5px"
  };
  const buttonNuevoTicket = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "40px",
    textTransform: "none",
    minHeight: "100%",
    fontWeight: 600,
    color: "#FCFBFB",
    backgroundColor: "#1876D1",
    borderRadius: "10px",
    padding: "5px 20px"
  };
  const emptyListBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#818181",
    fontWeight: 500,
    fontSize: "18px"
  };
  const emptyListIcon = {
    height: "6.25vmax",
    width: "auto"
  };
  const textStyleFinishModal = {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "20px",
    color: "#818181"
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const modalPDF = {
    transition: transition,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    height: "fit-content",
    " .MuiBox-root.MuiBox-root": {
      borderRadius: "20px"
    }
  };

  return listaVencimientos.length > 0 && !isLoading ? (
    <Box sx={contenido}>
      <Box sx={barraFiltros}>
        {cliente && !selectedEmpresa.id ? (
          <Box sx={formControl}>
            <Button
              onClick={handleLimpiaFiltros}
              disableElevation={true}
              variant="contained"
              sx={buttonLimparFiltros}
            >
              <FilterListOffOutlinedIcon />
              Restablecer filtros
            </Button>
          </Box>
        ) : null}
        {selectedEmpresa.id ? null : (
          <Box sx={formControl}>
            <CustomSelect
              label="Filtrar por cliente"
              value={cliente}
              setValue={setCliente}
            >
              {listaFiltroEmpresa}
            </CustomSelect>
          </Box>
        )}
        <Button
          variant="contained"
          sx={buttonNuevoTicket}
          component={Link}
          to="Carga masiva"
        >
          Carga masiva
        </Button>
      </Box>
      <CustomTable
        headCells={headCellsVencimientos}
        rows={listaVencimientosFiltrada}
        setRows={setListaVencimientosFiltrada}
        variant="documentos"
        setLineaSeleccionada={setLineaSeleccionada}
        setOpenSubirDocModal={setOpenUploadSingleModal}
        setOpenSubirMultipleDocsModal={setOpenUploadMultipleModal}
        setOpenPDFViewModal={setOpenPDFModal}
        setOpenErrorModal={setOpenErrorModal}
        isLoading={isLoading}
      />
      <SubirDocumentoModal
        id={lineaSeleccionada.id}
        openModal={openUploadSingleModal}
        setOpenModal={setOpenUploadSingleModal}
        title="Subir documento a vencer"
        subtitle={
          lineaSeleccionada.nombre
            ? `Trabajador/a: ${lineaSeleccionada.nombre}`
            : `Faena: ${lineaSeleccionada.faena} - ${lineaSeleccionada.nombre_empresa_mandante}`
        }
        doneNavigation="/Trabajadores/Vencimientos"
        maxCount={documentosAsociados.length}
      >
        {documentosAsociados.length > 1 ? (
          <div>
            <p>
              <b>
                Hay varios documentos prontos a vencer asociados a
                {lineaSeleccionada.nombre ? " este trabajador" : " esta faena"}:
              </b>
            </p>
            {documentosAsociados.map((doc) => (
              <li>{doc.nombre_tipo_documento}</li>
            ))}
          </div>
        ) : null}{" "}
      </SubirDocumentoModal>
      <SubirDocumentoModal
        openModal={openUploadMultipleModal}
        setOpenModal={setOpenUploadMultipleModal}
        title="Subir documentos a vencer"
        subtitle={
          lineaSeleccionada.nombre
            ? `Trabajador/a: ${lineaSeleccionada.nombre}`
            : `Faena: ${lineaSeleccionada.faena} - ${lineaSeleccionada.nombre_empresa_mandante}`
        }
        doneNavigation="/Trabajadores/Vencimientos"
        maxCount={documentosAsociados.length}
      >
        {documentosAsociados.length > 1 ? (
          <div>
            <p>
              <b>
                Hay varios documentos prontos a vencer asociados a
                {lineaSeleccionada.nombre ? " este trabajador" : " esta faena"}:
              </b>
            </p>
            {documentosAsociados.map((doc) => (
              <li>{doc.nombre_tipo_documento}</li>
            ))}
          </div>
        ) : null}
      </SubirDocumentoModal>
      <FinishModal openModal={openErrorModal} setOpenModal={setOpenErrorModal}>
        <Typography sx={textStyleFinishModal}>
          {lineaSeleccionada.razon_rechazo}
        </Typography>
      </FinishModal>
      <Modal open={openPDFModal} onClose={handleClosePDFModal}>
        <Box sx={modalPDF}>
          <Box sx={closeButtonBox}>
            <IconButton onClick={handleClosePDFModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <PDFViewer
            id={lineaSeleccionada.id}
            openModal={openPDFModal}
            setOpenModal={setOpenPDFModal}
            subtitle={`${
              lineaSeleccionada.nombre ||
              lineaSeleccionada.nombre_empresa_mandante
            } - ${lineaSeleccionada.nombre_tipo_documento}`}
          />
        </Box>
      </Modal>
    </Box>
  ) : (
    <Box sx={contenido}>
      <Box sx={emptyListBox}>
        <EventAvailableOutlinedIcon sx={emptyListIcon} />
        <Typography>No hay documentos prontos a vencer</Typography>
      </Box>
    </Box>
  );
}
