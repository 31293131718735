import React from "react";
import { IconButton, Typography, Box, Modal } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../../controller/windowSize";

/* IMAGES */
import OneFile from "../../../../images/contrato/OneFileContrato.png";
import MultipleFiles from "../../../../images/contrato/MultipleFilesContrato.png";

export default function ContratoModal(props) {
  const { openModal, setOpenModal } = props;
  const littleSize = littleSizeFunc();

  const handleCloseModal = () => setOpenModal(false);

  /* CSS */
  const modalBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "74.5139vw",
    height: "fit-content",
    maxHeight: "98vh",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: "6vh 3.542vw ",
    overflowY: "auto",
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin",
  };
  const contentWithText = {
    gap: "40px",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    overflowY: "auto",
  };
  const title = {
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "140%",
    textAlign: littleSize ? "center" : "",
  };
  const content = {
    display: "flex",
    flexWrap: "wrap",
    gap: "22px 4.4444vw",
    alignItems: "start",
    justifyContent: littleSize ? "center" : "",
  };
  const imgColumn = {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "18px",
  };
  const titleImgs = {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "140%",
    textAlign: littleSize ? "center" : "",
  };
  const imgStyleBox = {
    width: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: littleSize ? "center" : "",
    flexWrap: "wrap",
    gap: "2.292vw",
  };
  const imgStyle = {
    width: "16.66667vw",
    minWidth: "200px",
    maxWidth: "400px",
    height: "auto",
    objectFit: "cover",
    borderRadius: "10px",
  };
  const optionsBox = {
    width: "fit-content",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "5vw",
  }

  return (
    <Modal open={openModal} onClose={handleCloseModal} keepMounted>
      <Box sx={modalBox}>
        <Box sx={closeButtonBox}>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={contentWithText}>
          <Typography sx={title}>
            ¿Cómo subir el contrato y sus anexos?
          </Typography>
          <Typography>
            <p>
              Con el fin de asegurar una óptima legibilidad de cada documento,
              es preferible que los archivos se encuentren en{" "}
              <b>formato PDF de texto en lugar de imagenes llevadas a PDF</b>.
            </p>
            <p>
              En casos donde no sea viable el formato PDF, le recomendamos
              escanear los documentos o tomar fotografías de alta calidad,
              asegurándose de que el documento sea perfectamente legible, sin
              borrones, brillos, sombras o manchas que puedan dificultar la
              lectura.
            </p>
            <p>
              Siguiendo estas prácticas le garantizará un proceso más rápido y
              eficiente.
            </p>
          </Typography>

          <Box sx={content}>
            <Typography sx={titleImgs}>
              Tiene dos opciones para subir los documentos requeridos:
            </Typography>
            <Box sx={optionsBox}>
              <Box sx={imgColumn}>
                <Typography>
                  1. Contrato laboral con todos sus anexos en un solo archivo
                  PDF:
                </Typography>
                <Box sx={imgStyleBox}>
                  <Box
                    component="img"
                    src={OneFile}
                    alt="Un archivo"
                    sx={imgStyle}
                  />
                </Box>
              </Box>
              <Box sx={imgColumn}>
                <Typography>2. Cada archivo por separado:</Typography>
                <Box sx={imgStyleBox}>
                  <Box
                    component="img"
                    src={MultipleFiles}
                    alt="Multiples archivos"
                    sx={imgStyle}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
