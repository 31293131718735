import React, { useState, useEffect, useRef } from "react";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import isMobilePhone from "validator/lib/isMobilePhone";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormHelperText,
} from "@mui/material";

/* CUSTOM LIBRARIES */
import DragDropFileList from "./dragAndDropList";
import CustomSelect from "./customSelect";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../../controller/windowSize";
import { Fn, transition } from "../../../../controller/utils";
import {
  listaPlantillaLiquidacion,
  listaFilesPlantillasEmpresa,
  listaRegiones,
} from "../../../../controller/listas";
import { useSelector } from "react-redux";
import { selectCurrentType } from "../../../../redux/authSlice";
import { useLazyGetPlantillasGeneralesQuery } from "../../../../api/empresasApiSlice";

export default function DatosEmpresa(props) {
  const {
    dense = false,
    continueValue,
    setContinueValue,
    create = false,
    datosEmpresa = {},
    setDatosEmpresa,
  } = props;
  const userType = useSelector(selectCurrentType);
  const littleSize = littleSizeFunc();

  const [triggerGetData, { isLoading, isFetching }] =
    useLazyGetPlantillasGeneralesQuery();

  const valueID = datosEmpresa.id || "";
  const tipo_empresa = datosEmpresa.tipo_empresa || "";
  const tipo = datosEmpresa.tipo || "";

  // informacion empresa
  const [valueRut, setValueRut] = useState(datosEmpresa.rut || "");
  const [valueRazon, setValueRazon] = useState(datosEmpresa.razon_social || "");
  const [valueNombreComercial, setValueNombreComercial] = useState(
    datosEmpresa.nombre || ""
  );
  const [valueRegion, setValueRegion] = useState(datosEmpresa.region || "");
  const [valueTelefono, setValueTelefono] = useState(
    datosEmpresa.telefono || ""
  );
  const [valueDireccion, setValueDireccion] = useState(
    datosEmpresa.direccion_legal || ""
  );

  console.log(valueRegion);

  const [listaFiles, setListaFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [errorRut, setErrorRut] = useState("");
  const [errorValueRazon, setErrorRazon] = useState("");
  const [errorNombreComercial, setErrorNombreComercial] = useState("");
  const [errorRegion, setErrorRegion] = useState("");
  const [errorTelefono, setErrorTelefono] = useState("");
  const [errorDireccion, setErrorDireccion] = useState("");

  // informacion contacto
  const [valueNombreContacto, setValueNombreContacto] = useState(
    datosEmpresa.nombre_contacto || ""
  );
  const [valueCorreoContacto, setValueCorreo] = useState(
    datosEmpresa.correo_contacto || ""
  );
  const [valueTelefonoContacto, setValueTelefonoContacto] = useState(
    datosEmpresa.telefono_contacto || ""
  );
  const [valueNombreRepresentante, setValueNombreRespresentante] = useState(
    datosEmpresa.nombre_representante || ""
  );
  const [valueRutRepresentante, setValueRutRepresentante] = useState(
    datosEmpresa.rut_representate || ""
  );

  const [errorNombreContacto, setErrorNombreContacto] = useState("");
  const [errorCorreoContacto, setErrorCorreo] = useState("");
  const [errorTelefonoContacto, setErrorTelefonoContacto] = useState("");
  const [errorNombreRepresentante, setErrorNombreRespresentante] = useState("");
  const [errorRutRepresentante, setErrorRutRepresentante] = useState("");

  useEffect(() => {
    if (create) {
      if (
        !errorRut &&
        !errorValueRazon &&
        !errorNombreComercial &&
        !errorRegion &&
        !errorTelefono &&
        !errorDireccion &&
        !errorNombreContacto &&
        !errorCorreoContacto &&
        !errorTelefonoContacto &&
        !errorNombreRepresentante &&
        !errorRutRepresentante &&
        valueRut &&
        valueRazon &&
        valueNombreComercial &&
        valueRegion &&
        valueTelefono &&
        valueDireccion &&
        valueNombreContacto &&
        valueCorreoContacto &&
        valueTelefonoContacto &&
        valueNombreRepresentante &&
        valueRutRepresentante
      ) {
        if (tipo !== "mandante") {
          setContinueValue(uploadedFiles[0]?.titulo !== null);
        } else {
          setContinueValue(true);
        }
      }
    } else {
      if (
        !errorDireccion &&
        !errorRegion &&
        !errorTelefono &&
        !errorNombreContacto &&
        !errorCorreoContacto &&
        !errorTelefonoContacto &&
        !errorNombreRepresentante &&
        !errorRutRepresentante &&
        valueRut &&
        valueRazon &&
        valueNombreComercial &&
        valueRegion &&
        valueTelefono &&
        valueDireccion &&
        valueNombreContacto &&
        valueCorreoContacto &&
        valueTelefonoContacto &&
        valueNombreRepresentante &&
        valueRutRepresentante &&
        (datosEmpresa.region !== valueRegion ||
          datosEmpresa.telefono !== valueTelefono ||
          datosEmpresa.razon_social !== valueRazon ||
          datosEmpresa.rut !== valueRut ||
          datosEmpresa.nombre !== valueNombreComercial ||
          datosEmpresa.direccion_legal !== valueDireccion ||
          datosEmpresa.nombre_contacto !== valueNombreContacto ||
          datosEmpresa.correo_contacto !== valueCorreoContacto ||
          datosEmpresa.telefono_contacto !== valueTelefonoContacto ||
          datosEmpresa.nombre_representante !== valueNombreRepresentante ||
          datosEmpresa.rut_representate !== valueRutRepresentante)
      ) {
        if (tipo !== "mandante") {
          setContinueValue(uploadedFiles[0]?.titulo !== null);
        } else {
          setContinueValue(true);
        }
      }
    }
  }, [
    valueRut,
    valueRazon,
    valueNombreComercial,
    valueRegion,
    valueTelefono,
    valueDireccion,
    valueNombreContacto,
    valueCorreoContacto,
    valueTelefonoContacto,
    valueNombreRepresentante,
    valueRutRepresentante,
    uploadedFiles,
  ]);

  useEffect(() => {
    let dE = {
      id: valueID,
      rut: valueRut,
      razon_social: valueRazon,
      nombre: valueNombreComercial,
      region: valueRegion,
      correo_contacto: valueCorreoContacto,
      telefono: valueTelefono,
      direccion_legal: valueDireccion,
      nombre_contacto: valueNombreContacto,
      telefono_contacto: valueTelefonoContacto,
      nombre_representante: valueNombreRepresentante,
      rut_representate: valueRutRepresentante,
      tipo_empresa: tipo_empresa,
    };
    if (
      (!tipo_empresa || tipo_empresa === "Outsourcing") &&
      tipo !== "mandante"
    ) {
      dE["plantilla_liquidacion"] = uploadedFiles[0];
      dE["plantilla_sin_goce"] = uploadedFiles[1];
    }
    setDatosEmpresa(dE);
  }, [
    valueRut,
    valueRazon,
    valueNombreComercial,
    valueRegion,
    valueCorreoContacto,
    valueTelefono,
    valueDireccion,
    valueNombreContacto,
    valueTelefonoContacto,
    valueNombreRepresentante,
    valueRutRepresentante,
    uploadedFiles,
  ]);

  useEffect(() => {
    if (!create) {
      handleGetPlantillaLiquidacion();
    }
  }, [, uploadedFiles]);

  const handleNotEmpty = (value, setValue, setError, error) => {
    setValue(value);
    if (isEmpty(value)) {
      setError(error);
    } else {
      setError("");
    }
  };
  const formatValueNumDocumento = (value) => {
    let rut = value;
    rut = rut.replace(/\.|-/g, "");
    if (rut.length > 2) {
      if (rut.length < 5) {
        rut = rut.slice(0, 1) + "." + rut.slice(1);
      } else if (rut.length < 9) {
        rut = rut.slice(0, 1) + "." + rut.slice(1, 4) + "." + rut.slice(4);
      } else if (rut.length === 9) {
        rut = rut.slice(0, 2) + "." + rut.slice(2, 5) + "." + rut.slice(5);
      } else if (rut.length > 9) {
        rut = rut.slice(0, 2) + "." + rut.slice(2, 5) + "." + rut.slice(5, 9);
      }
      if (rut.replace(".", "").length > 7) {
        rut = rut.slice(0, -1) + "-" + rut.slice(-1);
      }
    }
    return rut;
  };
  const handleRUTChange = (value, setValue, setError) => {
    let rut = formatValueNumDocumento(value);
    rut = value.replace(/\.|-/g, "");
    rut = rut.slice(0, -1) + "-" + rut.slice(-1);
    setValue(rut);
    if (!Fn.validaRut(rut)) {
      setError("RUT inválido");
    } else {
      setError("");
    }
  };
  const handleTelChange = (value, setValue, setError) => {
    let tel = value.includes("+56") ? value : `+56${value}`;
    setValue(tel);
    if (isMobilePhone(tel, "es-CL")) {
      setError("");
    } else {
      setError("Escriba un número de teléfono válido");
    }
  };
  const handleMailChange = (value) => {
    setValueCorreo(value);
    if (isEmail(value)) {
      setErrorCorreo("");
    } else {
      setErrorCorreo("Escriba un correo_contacto válido");
    }
  };
  const handleGetPlantillaLiquidacion = async () => {
    const { data, isSuccess } = await triggerGetData(datosEmpresa.id, false);
    if (isSuccess) {
      let newDocs = JSON.parse(JSON.stringify(data.data.tipo_documentos));
      newDocs.forEach((file, index) => {
        file.acceptedFormats =
          listaFilesPlantillasEmpresa[index]["acceptedFormats"];
        file.nombre = `Plantilla ${file.abreviacion}`;
      });
      setListaFiles(newDocs);
      setUploadedFiles(data.data.plantillas);
    }
  };

  const todo = {
    padding: "0 0 0 0",
    minHeight: dense ? "100%" : "58vh",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: dense ? "flex-start" : "space-around",
    gap: dense ? "2vh 0" : "4vh 0",
  };
  const contenido = {
    display: "flex",
    flexWrap: "wrap",
    gap: "3.47vh 40px",
    justifyContent: littleSize ? "center" : "flex-start",
    alignItems: "center",
    height: "100%",
    minHeight: "fit-content",
    overflow: "hidden",
    padding: "11px 0",
    width: "100%",
  };
  const tituloDatos = {
    width: "100%",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "140%",
    color: "#1876D1",
    textAlign: littleSize ? "center" : "left",
    // padding: "0 0 0 0",
  };
  const textFieldBox = {
    // maxWidth: "300px",
    width: "14.58vw",
    minWidth: "210px",
  };
  const textField = {
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400,
    },
    "& .Mui-disabled": {
      opacity: 1,
    },
  };
  const textFieldTelLeft = {
    width: "58px",
    height: "inherit",
    borderRadius: "4.34415px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      borderBottomRightRadius: "0%",
      borderTopRightRadius: "0%",
      "& .MuiOutlinedInput-input": {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
  };
  const textFieldTelRight = {
    width: "calc(100% - 58px)",
    height: "inherit",
    borderRadius: "4.34415px",
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      borderBottomLeftRadius: "0%",
      borderTopLeftRadius: "0%",
    },
  };
  const fileBox = {
    width: "600px",
  };

  return (
    <Box sx={todo}>
      <Typography sx={tituloDatos}>Información de empresa</Typography>
      <Box sx={contenido}>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="rutEmpresa"
            label="Rut de empresa"
            type="text"
            value={formatValueNumDocumento(valueRut)}
            disabled={userType !== "Admin"}
            onChange={(e) =>
              handleRUTChange(e.target.value, setValueRut, setErrorRut)
            }
          />
          <FormHelperText error>{errorRut}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="razonSocial"
            label="Razón social"
            type="text"
            value={valueRazon}
            disabled={userType !== "Admin"}
            onChange={(e) =>
              handleNotEmpty(
                e.target.value,
                setValueRazon,
                setErrorRazon,
                "Escriba la razón social"
              )
            }
          />
          <FormHelperText error>{errorValueRazon}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="nombre"
            label="Nombre comercial"
            type="text"
            value={valueNombreComercial}
            disabled={userType !== "Admin"}
            onChange={(e) =>
              handleNotEmpty(
                e.target.value,
                setValueNombreComercial,
                setErrorNombreComercial,
                "Escriba el nombre comercial"
              )
            }
          />
          <FormHelperText error>{errorNombreComercial}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="direccion_legal"
            label="Dirección legal"
            type="text"
            value={valueDireccion}
            onChange={(e) =>
              handleNotEmpty(
                e.target.value,
                setValueDireccion,
                setErrorDireccion,
                "Escriba la dirección"
              )
            }
          />
          <FormHelperText error>{errorDireccion}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <CustomSelect
            label="Región"
            fontWeightValue={400}
            value={valueRegion}
            setValue={setValueRegion}
            errorText={valueRegion ? "" : errorRegion}
          >
            {listaRegiones}
          </CustomSelect>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            sx={textFieldTelLeft}
            size={"small"}
            fullWidth
            disabled
            label="+56"
          />
          <TextField
            sx={textFieldTelRight}
            size="small"
            fullWidth
            required
            id="telefono"
            label="Telefono"
            type="tel"
            value={valueTelefono.replace("+56", "")}
            onChange={(e) =>
              handleTelChange(
                e.target.value,
                setValueTelefono,
                setErrorTelefono
              )
            }
          />
          <FormHelperText error>{errorTelefono}</FormHelperText>
        </Box>
      </Box>

      <Typography sx={tituloDatos}>Información de contacto</Typography>
      <Box sx={contenido}>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="nombre_contacto"
            label="Nombre de contacto"
            type="text"
            value={valueNombreContacto}
            onChange={(e) =>
              handleNotEmpty(
                e.target.value,
                setValueNombreContacto,
                setErrorNombreContacto,
                "Escriba el nombre de contacto"
              )
            }
          />
          <FormHelperText error>{errorNombreContacto}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="correo_contacto"
            label="Correo de contacto"
            type="text"
            value={valueCorreoContacto}
            onChange={(e) => handleMailChange(e.target.value)}
          />
          <FormHelperText error>{errorCorreoContacto}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            sx={textFieldTelLeft}
            size={"small"}
            fullWidth
            disabled
            label="+56"
          />
          <TextField
            sx={textFieldTelRight}
            size="small"
            fullWidth
            required
            id="telefono"
            label="Telefono"
            type="tel"
            value={valueTelefonoContacto.replace("+56", "")}
            onChange={(e) =>
              handleTelChange(
                e.target.value,
                setValueTelefonoContacto,
                setErrorTelefonoContacto
              )
            }
          />
          <FormHelperText error>{errorTelefonoContacto}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="nombre_representante"
            label="Nombre representate legal"
            type="text"
            value={valueNombreRepresentante}
            onChange={(e) =>
              handleNotEmpty(
                e.target.value,
                setValueNombreRespresentante,
                setErrorNombreRespresentante,
                "Escriba el nombre del representante"
              )
            }
          />
          <FormHelperText error>{errorNombreRepresentante}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="rut_representate"
            label="RUT representante legal"
            type="text"
            value={formatValueNumDocumento(valueRutRepresentante)}
            onChange={(e) =>
              handleRUTChange(
                e.target.value,
                setValueRutRepresentante,
                setErrorRutRepresentante
              )
            }
          />
          <FormHelperText error>{errorRutRepresentante}</FormHelperText>
        </Box>
      </Box>
      {(!tipo_empresa || tipo_empresa === "Outsourcing") &&
      tipo !== "mandante" ? (
        <Box sx={fileBox}>
          <DragDropFileList
            list={
              listaFiles.length > 0 ? listaFiles : listaPlantillaLiquidacion
            }
            setContinueValue={() => {}}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            notUpload
          />
        </Box>
      ) : null}
    </Box>
  );
}
