import React, { useState, useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Box, Button, Typography, TextField } from "@mui/material";

/* CUSTOM LIBRARIES */
import CustomTable from "../common/components/customTable";
import FinishModal from "../common/modals/finishModal";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { headCellsSubcontratistas } from "../../../controller/listas";
import { useDispatch, useSelector } from "react-redux";
import { POLLING_INTERVAL, transition } from "../../../controller/utils";
import {
  useDeleteSubcontratistaMutation,
  useGetSubcontratistasQuery
} from "../../../api/empresasApiSlice";
import { currentEmpresa, setEmpresa } from "../../../redux/entidadesSlice";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../redux/confirmModalSlice";

export default function Subcontratistas() {
  const { setSubtitleValue } = useOutletContext();
  const littleSize = littleSizeFunc();
  const selectedEmpresa = useSelector(currentEmpresa);
  const dispatch = useDispatch();

  const { currentData, isSuccess, isLoading, isFetching, refetch } =
    useGetSubcontratistasQuery(null, {
      pollingInterval: POLLING_INTERVAL
    });

  const [deleteSubcontratista, deleteResult] =
    useDeleteSubcontratistaMutation();

  const [listaSubcontratistas, setListaSubcontratistas] = useState([]);
  const [listaSubcontratistasFiltrada, setListaSubcontratistasFiltrada] =
    useState([]);
  const [openDarDeBajaSubcontratista, setOpenDarDeBajaSubcontratista] =
    useState(false);
  const [confirmationText, setConfirmationText] = useState("");

  useEffect(() => {
    if (isSuccess && currentData) {
      setListaSubcontratistas([...currentData.data]);
      setListaSubcontratistasFiltrada([...currentData.data]);
    }
  }, [currentData, isSuccess, isLoading, isFetching]);

  useEffect(() => {
    setSubtitleValue("");
  }, []);

  useEffect(() => {
    if (!openDarDeBajaSubcontratista) {
      setConfirmationText("");
    }
  }, [openDarDeBajaSubcontratista]);

  const setSelectedEmpresa = (value) => {
    dispatch(setEmpresa({ ...value }));
  };

  const handleDelete = async () => {
    try {
      await deleteSubcontratista(selectedEmpresa.id).unwrap();
      dispatch(
        setSuccessText(
          "¡La empresa subcontratada ha sido dada de baja correctamente!"
        )
      );
      refetch();
    } catch (e) {
      dispatch(
        setErrorText(
          "¡Hubo un error, intente nuevamente o contáctese con nosotros!"
        )
      );
      dispatch(setOpenConfirmModal(true));
    }
  };
  /* CSS */
  const contenido = {
    minHeight: "58vh",
    height: "fit-content",
    maxHeight: "84vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "5.17vh",
    transition: transition
  };
  const barraFiltros = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
    height: "fit-content",
    gap: "15px"
  };
  const buttonNuevo = {
    height: "40px",
    textTransform: "none",
    minHeight: "100%",
    fontWeight: 600,
    color: "#FCFBFB",
    backgroundColor: "#1876D1",
    borderRadius: "10px",
    padding: "5px 20px"
  };
  const confirmationBox = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px"
  };
  const textStyleFinishModal = {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "20px",
    color: "#818181"
  };
  const textField = {
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      // border: "2px solid #DE5C5C",
      fontWeight: 400
    }
  };

  return (
    <Box sx={contenido}>
      <Box sx={barraFiltros}>
        <Button
          variant="contained"
          sx={buttonNuevo}
          component={Link}
          to="Nuevo subcontratista"
        >
          Nuevo subcontratista
        </Button>
      </Box>
      <CustomTable
        wide
        headCells={headCellsSubcontratistas}
        rows={listaSubcontratistasFiltrada}
        setRows={setListaSubcontratistasFiltrada}
        variant="subcontratista"
        setLineaSeleccionada={setSelectedEmpresa}
        setOpenBajaModal={setOpenDarDeBajaSubcontratista}
        isLoading={isLoading}
      />
      <FinishModal
        openModal={openDarDeBajaSubcontratista}
        setOpenModal={setOpenDarDeBajaSubcontratista}
        navigatePath="/Subcontratistas"
        buttonText="Dar de baja"
        handleContinue={handleDelete}
        confirmationValue={
          confirmationText === `ELIMINAR ${selectedEmpresa.nombre}`
        }
      >
        <Typography sx={textStyleFinishModal}>
          Estás a punto de eliminar la empresa subcontratada "
          {selectedEmpresa.nombre}". Al hacer esto, se cobrará los documentos
          analizados hasta ahora. ¿Estás seguro que quieres continuar?
        </Typography>
        <Box sx={confirmationBox}>
          <Typography sx={textStyleFinishModal}>
            Escriba "ELIMINAR" seguido por el nombre de la empresa, para
            confirmar la eliminación de esta empresa.
          </Typography>
          <TextField
            sx={textField}
            size="small"
            error
            required
            id="confirmation"
            type="text"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
          />
        </Box>
      </FinishModal>
    </Box>
  );
}
