import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  ListItemButton,
  Card,
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Dialog,
} from "@mui/material";

/* CUSTOM LIBRARIES */
import CustomStepper from "../common/components/customStepper";
import DatosEmpresa from "../common/components/datosEmpresa";
import MisDatosPersonales from "../Mi Perfil/misDatosPersonales";
import LoadingRocket from "../common/things/loadingRocket";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { stepsNuevaEmpresaCliente } from "../../../controller/listas";
import { emptyFileObj, transition } from "../../../controller/utils";
import { useDispatch } from "react-redux";
import {
  useCreateUserMutation,
  useLazyGetUserQuery,
} from "../../../api/authApiSlice";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText,
} from "../../../redux/confirmModalSlice";
import { useAddEmpresaMutation } from "../../../api/empresasApiSlice";

function FirstPage(props) {
  const { continueValue, setContinueValue, datos, setDatos } = props;
  const littleSize = littleSizeFunc();

  /* CSS */
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : "",
  };

  return (
    <Box sx={selectDatosBoxBox}>
      <MisDatosPersonales
        dense
        create
        continueValue={continueValue}
        setContinueValue={setContinueValue}
        datosUsuario={datos}
        setDatosUsuario={setDatos}
      />
    </Box>
  );
}

function SecondPage(props) {
  const { continueValue, setContinueValue, datos, setDatos } = props;
  const littleSize = littleSizeFunc();

  /* CSS */
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : "",
  };

  return (
    <Box sx={selectDatosBoxBox}>
      <DatosEmpresa
        dense
        create
        continueValue={continueValue}
        setContinueValue={setContinueValue}
        datosEmpresa={datos}
        setDatosEmpresa={setDatos}
      />
    </Box>
  );
}

export default function NuevaEmpresaCliente() {
  const littleSize = littleSizeFunc();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setTitleValue, setSubtitleValue } = useOutletContext();

  const [createUser, userCreationState] = useCreateUserMutation();
  const [createEmpresa, { isLoading }] = useAddEmpresaMutation();

  const [stepCount, setStepCount] = useState(0);
  const [continueValue, setContinueValue] = useState(false);

  const [stepTitle, setStepTitle] = useState("");
  const [stepSubtitle, setStepSubtitle] = useState("");
  const [stepContent, setStepContent] = useState();

  const [datosUsuario, setDatosUsuario] = useState({});
  const [datosEmpresa, setDatosEmpresa] = useState({
    tipo: "mandante",
  });

  useEffect(() => {
    setSubtitleValue("");
  }, []);

  useEffect(() => {
    if (stepCount === 0) {
      setStepTitle("Datos del usuario");
      setStepSubtitle(
        "Por favor ingresar, los datos del usuario que usará el portal."
      );
      setStepContent(
        <FirstPage
          datos={datosUsuario}
          setDatos={setDatosUsuario}
          continueValue={continueValue}
          setContinueValue={setContinueValue}
        />
      );
    }
    if (stepCount === 1) {
      setStepTitle("Datos de la empresa");
      setStepSubtitle(
        "Para poder crear una nueva empresa, por favor primero ingrese los datos de la empresa y el contacto."
      );
      setStepContent(
        <SecondPage
          datos={datosEmpresa}
          setDatos={setDatosEmpresa}
          continueValue={continueValue}
          setContinueValue={setContinueValue}
        />
      );
    }
    if (stepCount === 2) {
      handleFinish();
    }
  }, [stepCount]);

  const handleFinish = async () => {
    try {
      const userD = await createUser({ ...datosUsuario, type: "B" }).unwrap();
      if (userD.name) {
        try {
          const empresaD = await createEmpresa({
            ...datosEmpresa,
            tipo: "mandante",
            email_usuario: datosUsuario.email,
            region: datosEmpresa.region.id,
          }).unwrap();
          dispatch(
            setSuccessText(
              `¡Empresa y usuario creados con exito!\n\nRevise el email ${datosUsuario.email}`
            )
          );
          navigate("/Empresas");
        } catch (err) {
          setStepCount(1);
          dispatch(
            setErrorText(
              `Hubo un problema al momento de crear la empresa \n\nIntente nuevamente más tarde o contactese con nosotros`
            )
          );
        }
      } else {
        setStepCount(0);
        dispatch(
          setErrorText(
            `El email ${datosUsuario.email} ya está asociado a una empresa.\n\nCambielo y vuelva a intentar.`
          )
        );
      }
    } catch (err) {
      setStepCount(1);
      dispatch(
        setErrorText(
          `Hubo un problema al momento de crear el usuario\n\nIntente nuevamente más tarde o contactese con nosotros`
        )
      );
      console.error("ERROR");
      console.error(err);
    }
    dispatch(setOpenConfirmModal(true));
  };

  const handleContinue = () => {
    setContinueValue(false);
    setStepCount(stepCount + 1);
  };
  const handleBack = () => {
    setContinueValue(true);
    setStepCount(stepCount - 1);
  };

  /* CSS */
  const todo = {
    padding: "0 0 0 0",
    height: "100%",
    minHeight: "58vh",
    height: "fit-content",
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    transition: transition,
  };
  const datosButtonBox = {
    transition: transition,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: littleSize ? "center" : "normal",
    justifyContent: "space-between",
    gap: "5vh",
    padding: "0vh 0px 6vh 0px",
  };
  const datosBox = {
    transition: transition,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: "3.882vh",
    alignItems: littleSize ? "center" : "",
  };
  const tituloDatos = {
    transition: transition,
    fontWeight: 900,
    fontSize: "26px",
    lineHeight: "140%",
    color: "#1876D1",
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px",
    padding: "0vh 0px 6vh 0px",
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    position: littleSize ? (stepCount > 0 ? "" : "absolute") : "",
    visibility: littleSize ? (stepCount > 0 ? "visible" : "hidden") : "",
    opacity: stepCount > 0 ? 1 : 0,
    ":hover": {
      border: "2px solid #1876D1",
    },
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1",
  };

  return (
    <Box sx={todo}>
      <Box sx={datosButtonBox}>
        <CustomStepper stepCount={stepCount}>
          {stepsNuevaEmpresaCliente}
        </CustomStepper>
        <Box sx={datosBox}>
          <Typography sx={tituloDatos}>
            Paso {stepCount + 1}: {stepTitle}
          </Typography>
          <Typography>{stepSubtitle}</Typography>
          {stepContent}
        </Box>
      </Box>
      <Box sx={buttonBox}>
        <Button sx={buttonVolver} variant="outlined" onClick={handleBack}>
          Volver
        </Button>
        <Button
          sx={button}
          disableElevation
          variant="contained"
          type="submit"
          onClick={handleContinue}
          disabled={!continueValue}
        >
          {stepCount < stepsNuevaEmpresaCliente.length - 1
            ? "Continuar"
            : "Crear Empresa"}
        </Button>
      </Box>
      <LoadingRocket isLoading={isLoading} />
    </Box>
  );
}
