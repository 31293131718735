import React, { useState, useMemo, useEffect } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  InputAdornment,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Box,
  Typography,
  Button,
  TableRow,
  TableSortLabel,
  TextField,
  Card,
  SvgIcon,
} from "@mui/material";

/* ICONS */
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as EmptyFileIcon } from "../../../../images/svg/emptyFile.svg";

/* CUSTOM LIBRARIES */
import StateCell from "../things/stateCell";
import ActionButtonsCell from "../things/actionButttonsCell";
import TipoTrabajadoresIcons from "../things/tipoTrabajadoresIcons";
import LoadingRocket from "../things/loadingRocket";

/* CONTROLLER VARIABLES */
import { transition, STATES, NUMMONTH } from "../../../../controller/utils";
import { useSelector } from "react-redux";
import { selectCurrentType } from "../../../../redux/authSlice";

function descendingComparator(a, b, orderBy) {
  if (orderBy === "estado") {
    const b_estado = STATES[b.estado.toLowerCase()];
    const a_estado = STATES[a.estado.toLowerCase()];
    if (b_estado < a_estado) {
      return -1;
    }
    if (b_estado > a_estado) {
      return 1;
    }
    return 0;
  } else if (orderBy === "año-mes") {
    const b_estado =
      b["año-mes"].split("-")[0].trim() +
      NUMMONTH[b["año-mes"].split("-")[1].trim().toLowerCase()];
    const a_estado =
      a["año-mes"].split("-")[0].trim() +
      NUMMONTH[a["año-mes"].split("-")[1].trim().toLowerCase()];
    if (b_estado < a_estado) {
      return -1;
    }
    if (b_estado > a_estado) {
      return 1;
    }
    return 0;
  } else if (orderBy === "mes") {
    const b_estado = NUMMONTH[b.mes.toLowerCase()];
    const a_estado = NUMMONTH[a.mes.toLowerCase()];
    if (b_estado < a_estado) {
      return -1;
    }
    if (b_estado > a_estado) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomTable(props) {
  const {
    headCells,
    rows,
    setRows,
    search = true,
    wide = false,
    variant,
    setLineaSeleccionada,
    setOpenEditModal,
    setOpenExtenderModal,
    setOpenBajaModal,
    setOpenFacturarModal,
    setOpenSubirDocModal,
    setOpenSubirMultipleDocsModal,
    setOpenPDFViewModal,
    setOpenErrorModal,
    setOpenTicketModal,
    setOpenVerificacionModal,
    isLoading = true,
  } = props;

  const userType = useSelector(selectCurrentType);

  const [searchColor, setSearchColor] = useState("#D9D9D9");
  const [labelMarginSearch, setLabelMarginSearch] = useState(30);
  const [focusedSearch, setFocusedSearch] = useState(false);
  const [currentValueSearch, setCurrentValueSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState(rows);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const visibleRows = useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredRows]
  );

  const initialRows = useMemo(() => rows, [rows]);

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  /* SEARCH FILTER */
  useEffect(() => {
    if (currentValueSearch) {
      let fr = initialRows.filter((obj) => {
        for (let i = 0; i < headCells.length; i++) {
          var exists = false;
          if (headCells[i].id === "estado") {
            exists = obj[headCells[i].id]
              .toString()
              .toLowerCase()
              .includes(currentValueSearch.toLowerCase());
          } else if (headCells[i].id !== "acciones") {
            exists = obj[headCells[i].id]
              .toString()
              .toLowerCase()
              .includes(currentValueSearch.toLowerCase());
          }
          if (exists) {
            return exists;
          }
        }
        return false;
      });
      setFilteredRows(fr);
    } else {
      setFilteredRows(initialRows);
    }
  }, [currentValueSearch, rows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleFocus = (state) => {
    setFocusedSearch(state);
    if (currentValueSearch !== "" || state) {
      setLabelMarginSearch(0);
      setSearchColor("");
    } else {
      setLabelMarginSearch(30);
      setSearchColor("#D9D9D9");
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const formatValueNumDocumento = (value) => {
    if (value) {
      let rut = value;
      rut = rut.replace(/\.|-/g, "");
      if (rut.length > 2) {
        if (rut.length < 5) {
          rut = rut.slice(0, 1) + "." + rut.slice(1);
        } else if (rut.length < 9) {
          rut = rut.slice(0, 1) + "." + rut.slice(1, 4) + "." + rut.slice(4);
        } else if (rut.length === 9) {
          rut = rut.slice(0, 2) + "." + rut.slice(2, 5) + "." + rut.slice(5);
        } else if (rut.length > 9) {
          rut = rut.slice(0, 2) + "." + rut.slice(2, 5) + "." + rut.slice(5, 9);
        }
        if (rut.replace(".", "").length > 7) {
          rut = rut.slice(0, -1) + "-" + rut.slice(-1);
        }
      }
      return rut;
    }
  };

  /* CSS */
  const contenido = {
    display: "flex",
    flexDirection: "column",
    gap: "3vh",
    transition: transition,
  };
  const searchFieldBox = {
    display: "flex",
    flexDirection: "column",
    padding: "0px",
  };
  const searchField = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    height: "4.94vh",
    width: "100%",
    ".MuiOutlinedInput-root": {
      display: "flex",
      borderRadius: "10px",
      background: "#FFFFFF",
      boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
    },
  };
  const searchLabel = {
    transition: transition,
    marginLeft: labelMarginSearch,
    fontWeight: 700,
    lineHeight: "150%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    color: searchColor,
  };
  const tableBox = {
    height: "fit-content",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "2vh",
    transition: transition,
  };
  const tableCard = {
    height: "fit-content",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "11px",
    transition: transition,
    overflow: "hidden",
    borderRadius: "10px",
  };
  const table = {
    transition: transition,
  };
  const titleRow = {
    background: "#1876D1",
    color: "#FCFBFB",
    height: "fit-content",
  };
  const titleCell = {
    textAlign: "center",
    color: "#FCFBFB",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "150%",
    width: "auto",
    minWidth: "fit-content",
    height: "2.82vh",
    padding: "0.94vh 0.6944vw",
  };
  const titleCellSortIcon = {
    height: "fit-content",
    color: "#FCFBFB",
    "&.MuiTableSortLabel-root": {
      color: "#FCFBFB",
      ".MuiTableSortLabel-icon": {
        color: "#FCFBFB",
        transition: transition,
      },
    },
  };
  const cell = {
    height: "fit-content",
    textAlign: "justify",
    width: "auto",
    minWidth: "fit-content",
    padding: wide ? "28px 28px" : "6px 6px",
  };
  const cellText = {
    height: "fit-content",
    textAlign: "center",
    fontSize: "14px",
    width: "auto",
    minWidth: "fit-content",
    whiteSpace: wide ? "normal" : "nowrap",
  };
  const tableFooterBox = {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  };
  const tableFooterButtonsBox = {
    width: "80%",
    height: "fit-content",
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    margin: "14px 0",
  };
  const tableFooterButton = {
    fontWeight: 600,
    textTransform: "none",
    border: "1px solid #1876D1",
    borderRadius: "10px",
    color: "#1876D1",
    background: "#FFFFFF",
  };
  const tableFooter = {
    width: "100%",
    ".MuiTablePagination-toolbar": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-end",
      "&.MuiTablePagination-select": {
        marginLeft: "8px",
        marginRight: "40px",
      },
    },
  };
  const emptyRow = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content",
    textAlign: "center",
    fontSize: "20px",
    color: "#818181",
    padding: "11px",
  };
  const emptyIcon = {
    height: "150px",
    width: "auto",
  };

  return (
    <Box sx={contenido}>
      {search ? (
        <Box sx={searchFieldBox}>
          <TextField
            fullWidth
            size="small"
            sx={searchField}
            label="Buscar"
            onFocus={() => handleFocus(true)}
            onBlur={() => handleFocus(false)}
            value={currentValueSearch}
            onChange={(e) => setCurrentValueSearch(e.target.value)}
            InputProps={{
              type: "search",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: focusedSearch || currentValueSearch ? true : false,
              style: searchLabel,
            }}
          />
        </Box>
      ) : null}
      <Box sx={tableBox}>
        <Card sx={tableCard}>
          <TableContainer sx={table}>
            <ScrollContainer>
              <Table size="small">
                <TableHead>
                  <TableRow sx={titleRow}>
                    {headCells.map((title) => (
                      <TableCell
                        sx={titleCell}
                        sortDirection={orderBy === title.id ? order : false}
                      >
                        <TableSortLabel
                          sx={titleCellSortIcon}
                          active={
                            title.id === "acciones"
                              ? false
                              : orderBy === title.id
                          }
                          direction={orderBy === title.id ? order : "asc"}
                          onClick={createSortHandler(title.id)}
                          hideSortIcon={true}
                        >
                          {title.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={headCells.length}>
                        <Box sx={emptyRow}>
                          <LoadingRocket dark dialog={false} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : visibleRows.length > 0 ? (
                    visibleRows.map((r) => (
                      <TableRow>
                        {headCells.map((title) => (
                          <TableCell sx={cell}>
                            {typeof r[`${title.id}`] === "string" ||
                            "numeric" ? (
                              <Typography sx={cellText}>
                                {/rut/.test(title.id) ? (
                                  formatValueNumDocumento(r[`${title.id}`]) ||
                                  "-"
                                ) : /tipos_trabajadores/.test(title.id) ? (
                                  <TipoTrabajadoresIcons>
                                    {r[`${title.id}`]}
                                  </TipoTrabajadoresIcons>
                                ) : /estado/.test(title.id) ? (
                                  <StateCell state={`${r[`${title.id}`]}`} />
                                ) : /acciones/.test(title.id) ? (
                                  <ActionButtonsCell
                                    variant={variant}
                                    linea={r}
                                    setLineaSeleccionada={setLineaSeleccionada}
                                    setOpenSubirDocModal={setOpenSubirDocModal}
                                    setOpenSubirMultipleDocsModal={
                                      setOpenSubirMultipleDocsModal
                                    }
                                    setOpenPDFViewModal={setOpenPDFViewModal}
                                    setOpenErrorModal={setOpenErrorModal}
                                    setOpenEditModal={setOpenEditModal}
                                    setOpenExtenderModal={setOpenExtenderModal}
                                    setOpenBajaModal={setOpenBajaModal}
                                    setOpenFacturarModal={setOpenFacturarModal}
                                    setOpenTicketModal={setOpenTicketModal}
                                    setOpenVerificacionModal={
                                      setOpenVerificacionModal
                                    }
                                  />
                                ) : r[`${title.id}`] != null ? (
                                  r[`${title.id}`]
                                ) : (
                                  "-"
                                )}
                              </Typography>
                            ) : r[`${title.id}`] !== null ? (
                              r[`${title.id}`]
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={headCells.length}>
                        <Box sx={emptyRow}>
                          <SvgIcon sx={emptyIcon}>
                            <EmptyFileIcon />
                          </SvgIcon>
                          No hay datos
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </ScrollContainer>
          </TableContainer>
        </Card>
        <Box sx={tableFooterBox}>
          {/* {userType === "Supp" ? null : (
            <Box sx={tableFooterButtonsBox}>
              <Button sx={tableFooterButton} size="small" variant="outlined">
                Excel
              </Button>
              <Button sx={tableFooterButton} size="small" variant="outlined">
                CSV
              </Button>
              <Button sx={tableFooterButton} size="small" variant="outlined">
                PDF
              </Button>
            </Box>
          )} */}
          <TablePagination
            sx={tableFooter}
            labelRowsPerPage="Registros por página:"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Box>
  );
}
