import React from "react";
import { Box, Typography, Card } from "@mui/material";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../../controller/windowSize";
import { transition } from "../../../../controller/utils";

export default function ContraseñaSegura(props) {
  const { isHover } = props;
  const littleSize = littleSizeFunc();

  /* CSS */
  const box = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    background: "#FFFFFF",
    borderRadius: "14px",
    width: "22.91667vw",
    minWidth: "200px",
    maxWidth: "330px",
    height: "fit-content",
    padding: "42px 25px",
    right: littleSize ? "0%" : "",
    left: littleSize ? "" : "102%",
    top: littleSize ? "100%" : "0%",
    position: "absolute",
    zIndex: 2000,
    transition: transition,
    visibility: isHover ? "visible" : "hidden",
    opacity: isHover ? "1" : "0",
    gap: "11px",
  };
  const titulo = {};
  const text = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "first baseline",
    gap: "11px",
    color: "#818181",
  };
  const punto = {
    background: "#1876D1",
    minWidth: "7px",
    minHeight: "7px",
    borderRadius: "100%",
  };

  return (
    <Card sx={box}>
      <Typography sx={titulo}>
        <b>Debe tener al menos 8 caracteres</b>
      </Typography>
      <Typography sx={text}>Es mejor si:</Typography>

      <Typography sx={text}>
        <Box sx={punto} /> Contiene mayúsculas y minúsculas.
      </Typography>
      <Typography sx={text}>
        <Box sx={punto} /> Contiene mínimo un símbolo (#$&).
      </Typography>
      <Typography sx={text}>
        <Box sx={punto} /> Es una contraseña larga.
      </Typography>
    </Card>
  );
}
