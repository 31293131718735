import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import ScrollContainerProps from "react-indiana-drag-scroll";
import {
  Button,
  Typography,
  Box,
  IconButton,
  Tooltip,
  CircularProgress
} from "@mui/material";

/* ICONS */
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import errorImg from "../../../../images/logo/no_doc.png";

/* Custom Libraries */
import LoadingRocket from "../things/loadingRocket";

/* CSS */
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../../controller/windowSize";
import { useSelector } from "react-redux";
import { selectCurrentType } from "../../../../redux/authSlice";
import {
  useDownDocumentoBajadoMutation,
  useDownDocumentoMutation,
  useGetDocumentoByIdQuery,
  useGetDocumentosContratoQuery
} from "../../../../api/docsApiSlice";

import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import {
  useDownDocumentoTicketMutation,
  useGetDocumentoTicketByIdQuery,
  useGetTicketByIdQueryx
} from "../../../../api/ticketsApiSlice";
import {
  useDownDocumentoPlantillaMutation,
  useGetDocumentoPlantillaByIdQuery
} from "../../../../api/plantillasApiSlice";
import {
  quoted_printable_decode,
  utf8_decode
} from "../../../../controller/utils";
import { useGetCedulaByIdQuery } from "../../../../api/personasApiSlice";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

export default function PDFViewer(props) {
  const {
    modal = true,
    download = true,
    type = "documento",
    title = "Previsualización",
    subtitle = "",
    id = 0
    // margin = "true",
  } = props;
  const { data, isSuccess, isLoading, isError, error } =
    type === "documento"
      ? useGetDocumentoByIdQuery(id)
      : type === "ticket"
      ? useGetDocumentoTicketByIdQuery(id)
      : type === "plantilla"
      ? useGetDocumentoPlantillaByIdQuery(id)
      : type === "cedula"
      ? useGetCedulaByIdQuery(id)
      : type === "contrato"
      ? useGetDocumentosContratoQuery(id)
      : null;
  const [start_download, downloadData] =
    type === "documento"
      ? useDownDocumentoMutation()
      : type === "ticket"
      ? useDownDocumentoTicketMutation()
      : type === "plantilla"
      ? useDownDocumentoPlantillaMutation()
      : useDownDocumentoBajadoMutation();
  const littleSize = littleSizeFunc();
  const userType = useSelector(selectCurrentType);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [pdfString, setPdfString] = useState("");

  useEffect(() => {
    if (isSuccess) {
      let base64String;
      let reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        base64String = reader.result;
        setPdfString(base64String.substr(base64String.indexOf(",") + 1));
      };
    }
    if (isError) {
      console.error(error);
    }
    if (type == "cedula") {
      setZoom(0.1);
    }
  }, [data, isSuccess]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }
  function previousPage() {
    changePage(-1);
  }
  function nextPage() {
    changePage(1);
  }
  const handleZoomIn = (event) => {
    setZoom((prevZoom) => prevZoom + 0.1);
  };
  const handleZoomOut = (event) => {
    setZoom((prevZoom) => prevZoom - 0.1);
  };
  const handleDownload = async (event) => {
    event.preventDefault();
    try {
      await start_download({ id, subtitle }).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  const container = {
    width: "fit-content",
    minWidth: "0px",
    maxWidth: "99vw",
    minHeight: "0px",
    height: "98vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    background: "#FCFBFB",
    padding: modal
      ? littleSize
        ? "6vh 5vw"
        : "6vh 3.44vw"
      : littleSize
      ? "2vh 1vw"
      : "6vh 1vw",
    overflowY: "hidden",
    overflowX: "hidden",
    gap: "22px"
  };
  const titleBox = {
    width: "100%",
    gap: "11px",
    display: "flex",
    alignItems: littleSize ? "center" : "start",
    justifyContent: "flex-start",
    flexDirection: "column"
  };
  const titleStyle = {
    display: "flex",
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "140%",
    textAlign: littleSize ? "center" : "",
    alignItems: "center",
    gap: "12px"
  };
  const blueButton = {
    width: "fit-content",
    height: "fit-content",
    minWidth: "30px",
    minHeight: "30px",
    padding: "0px",
    borderRadius: "5px",
    border: "1.5px solid #1876D1",
    color: "#1876D1"
  };
  const pdfBoxBox = {
    height: "705px",
    maxHeight: "72vh",
    minHeight: "70vh",
    width: "550px",
    maxWidth: littleSize ? "80vw" : "550px",
    minWidth: littleSize ? "80vw" : "500px",
    boxShadow: 3,
    borderRadius: "10px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };
  const scrollBox = {
    overflow: "hidden"
  };
  const pdfBox = {
    maxHeight: "70vh",
    maxWidth: "100%",
    ".react-pdf__Page": {
      maxHeight: "inherit",
      maxWidth: "inherit",
      ".react-pdf__Page__canvas": {
        maxHeight: "inherit",
        maxWidth: "inherit"
      }
    }
  };
  const footerBox = {
    width: littleSize ? "100%" : "350px",
    display: "flex",
    alignItems: "center",
    justifyContent: littleSize ? "space-around" : "space-between",
    flexDirection: "row",
    gap: "20px",
    flexWrap: littleSize ? "wrap" : "nowrap"
  };
  const pageChangerWithText = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    gap: "5px",
    flexWrap: "wrap"
  };
  const pageChanger = {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "5px"
  };
  const pageChangerText = {
    whiteSpace: "nowrap"
  };
  const buttonStyle = {
    width: "1vw",
    height: "26px",
    color: "#1876D1",
    border: "1px solid #1876D1"
  };
  const zoomBox = {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "5px"
  };
  const iconButtonStyle = {
    width: "26px",
    height: "26px",
    color: "#1876D1",
    border: "1px solid #1876D1"
  };
  const loadingBox = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  };
  const errorImgStyle = {
    width: "30%",
    height: "30%",
    filter:
      "invert(62%) sepia(48%) saturate(4691%) hue-rotate(322deg) brightness(89%) contrast(93%)"
  };

  return (
    <Box sx={container}>
      <Box sx={titleBox}>
        <Typography sx={titleStyle}>
          <b>{title}</b>
          {userType !== "Supp" && download ? (
            downloadData.isLoading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="outlined"
                sx={blueButton}
                onClick={handleDownload}
              >
                <FileDownloadOutlinedIcon />
              </Button>
            )
          ) : null}
        </Typography>
        <Typography>
          {utf8_decode(quoted_printable_decode(subtitle?.replaceAll("%", "=")))}
        </Typography>
      </Box>
      <Box sx={pdfBoxBox}>
        <ScrollContainerProps sx={scrollBox} vertical={true}>
          <Box sx={pdfBox}>
            {!isLoading && pdfString.length > 0 ? (
              <Document
                file={`data:application/pdf;base64,${pdfString}`}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={isLoading}
              >
                <Page
                  pageNumber={pageNumber}
                  renderTextLayer={false}
                  scale={zoom}
                />
              </Document>
            ) : isError ? (
              <Box sx={loadingBox}>
                <Box
                  component="img"
                  loading="lazy"
                  sx={errorImgStyle}
                  src={errorImg}
                  alt="Documento no encontrado"
                />
                Documento no encontrado
              </Box>
            ) : (
              <LoadingRocket dialog={false} dark />
            )}
          </Box>
          {/* </Box> */}
        </ScrollContainerProps>
      </Box>
      <Box sx={footerBox}>
        <Box sx={zoomBox}>
          <IconButton
            sx={iconButtonStyle}
            variant="outlined"
            disabled={zoom < 0.2}
            onClick={handleZoomOut}
          >
            <RemoveOutlinedIcon />
          </IconButton>
          <Typography>{Math.round(zoom * 100)}%</Typography>
          <IconButton
            sx={iconButtonStyle}
            variant="outlined"
            disabled={zoom > 9}
            onClick={handleZoomIn}
          >
            <AddOutlinedIcon />
          </IconButton>
        </Box>
        <Box sx={pageChangerWithText}>
          <Typography sx={pageChangerText}>
            {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}
          </Typography>
          <Box sx={pageChanger}>
            <Tooltip
              title="Página anterior"
              placement="bottom-end"
              enterDelay={300}
              arrow
            >
              <Button
                sx={buttonStyle}
                variant="outlined"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                <ArrowBackOutlinedIcon />
              </Button>
            </Tooltip>
            <Tooltip
              title="Página siguiente"
              placement="bottom-start"
              enterDelay={300}
              arrow
            >
              <Button
                sx={buttonStyle}
                variant="outlined"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                <ArrowForwardOutlinedIcon />
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
