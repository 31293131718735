import React, { useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import isMobilePhone from "validator/lib/isMobilePhone";
import {
  Box,
  Typography,
  TextField,
  FormHelperText,
  FormGroup,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material";

/* ICONS */

/* CUSTOM LIBRARIES */
import CustomSelect from "./customSelect";
import InfoHover from "../things/infoHover";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../../controller/windowSize";
import { Fn } from "../../../../controller/utils";
import {
  listaTrabajos,
  listaNacionalidad,
  listaRegiones,
  listaComunas,
  listaSexo,
  listaTipoContrato,
  listaJornada
} from "../../../../controller/listas";

export default function InfoTrabajadorForm(props) {
  const { view = "", editar, setContinueValue, persona, setPersona } = props;

  const littleSize = littleSizeFunc();
  const [hover, setHover] = useState(false);

  //// States informacion personal
  const [valueNombre, setValueNombre] = useState("");
  const [valueApellidoPaterno, setValueApellidoPaterno] = useState("");
  const [valueApellidoMaterno, setValueApellidoMaterno] = useState("");
  const [valueNacionalidad, setValueNacionalidad] = useState("");
  const [valueNumDocumento, setValueNumDocumento] = useState("");
  const [valueFechaNacimiento, setValueFechaNacimiento] = useState("");
  const [valueFechaVencimiento, setValueFechaVencimiento] = useState("");
  const [valueSexo, setValueSexo] = useState();
  const [valueCorreo, setValueCorreo] = useState();
  const [valueTelefono, setValueTelefono] = useState();
  const [valueDireccion, setValueDireccion] = useState();
  const [valueRegion, setValueRegion] = useState();
  const [valueComuna, setValueComuna] = useState();
  //// Errores informacion personal
  const [errorNombre, setErrorNombre] = useState("");
  const [errorApellidoPaterno, setErrorApellidoPaterno] = useState("");
  const [errorApellidoMaterno, setErrorApellidoMaterno] = useState("");
  const [errorNacionalidad, setErrorNacionalidad] = useState("");
  const [errorNumDocumento, setErrorNumDocumento] = useState("");
  const [errorFechaNacimiento, setErrorFechaNacimiento] = useState("");
  const [errorFechaVencimiento, setErrorFechaVencimiento] = useState("");
  const [errorSexo, setErrorSexo] = useState("");
  const [errorCorreo, setErrorCorreo] = useState("");
  const [errorTelefono, setErrorTelefono] = useState("");
  const [errorDireccion, setErrorDireccion] = useState("");
  const [errorRegion, setErrorRegion] = useState("");
  const [errorComuna, setErrorComuna] = useState("");

  //// States contrato laboral
  const [valueCargo, setValueCargo] = useState();
  const [valueTipoContrato, setValueTipoContrato] = useState();
  const [valueJornada, setValueJornada] = useState();
  const [valueFechaInicio, setValueFechaInicio] = useState();
  const [valueFechaFin, setValueFechaFin] = useState();
  const [valueIngresoFaena, setValueIngresoFaena] = useState();
  const [valueArt22, setValueArt22] = useState("");
  const [valueTeletrabajo, setValueTeletrabajo] = useState("");
  //// Errores contrato laboral
  const [errorCargo, setErrorCargo] = useState("");
  const [errorTipoContrato, setErrorTipoContrato] = useState("");
  const [errorJornada, setErrorJornada] = useState("");
  const [errorFechaInicio, setErrorFechaInicio] = useState("");
  const [errorFechaFin, setErrorFechaFin] = useState("");
  const [errorIngresoFaena, setErrorIngresoFaena] = useState("");
  const [errorArt22, setErrorArt22] = useState("");
  const [errorTeletrabajo, setErrorTeletrabajo] = useState("");

  useEffect(() => {
    if (
      valueNombre !== persona.nombre ||
      valueApellidoPaterno !== persona.apellido_paterno ||
      valueApellidoMaterno !== persona.apellido_materno ||
      valueNacionalidad !== persona.nacionalidad ||
      valueNumDocumento !== persona.rut ||
      valueFechaNacimiento !== persona.fechaNac ||
      valueFechaVencimiento !== persona.fechaVenc ||
      valueSexo !== persona.sexo ||
      valueCorreo !== persona.email ||
      valueTelefono !== persona.telefono ||
      valueDireccion !== persona.direccion ||
      valueRegion !== persona.region ||
      valueComuna !== persona.comuna ||
      valueTipoContrato !== persona.tipo_contrato ||
      valueJornada !== persona.jornada ||
      valueFechaInicio !== persona.fecha_inicio_contrato ||
      valueFechaFin !== persona.fecha_fin_contrato ||
      valueIngresoFaena !== persona.fecha_ingreso_faena ||
      valueArt22 !== persona.art22 ||
      valueTeletrabajo !== persona.teletrabajo
    ) {
      setValueNombre(persona.nombre);
      setValueApellidoPaterno(persona.apellido_paterno);
      setValueApellidoMaterno(persona.apellido_materno);
      setValueNacionalidad(persona.nacionalidad);
      setValueNumDocumento(persona.rut);
      setValueFechaNacimiento(persona.fechaNac);
      setValueFechaVencimiento(persona.fechaVenc);
      setValueSexo(persona.sexo);
      setValueCorreo(persona.email);
      setValueTelefono(persona.telefono);
      setValueDireccion(persona.direccion);
      setValueRegion(persona.region);
      setValueComuna(persona.comuna);
      setValueCargo(persona.cargo);
      setValueTipoContrato(persona.tipo_contrato);
      setValueJornada(persona.jornada);
      setValueFechaInicio(persona.fecha_inicio_contrato);
      setValueFechaFin(persona.fecha_fin_contrato);
      setValueIngresoFaena(persona.fecha_ingreso_faena);
      setValueArt22(persona.art22);
      setValueTeletrabajo(persona.teletrabajo);
    }
  }, [, persona.id]);

  useEffect(() => {
    if (view === "cedula") {
      if (editar) {
        setContinueValue(
          !errorNombre &&
            !errorApellidoPaterno &&
            !errorApellidoMaterno &&
            !errorNacionalidad &&
            !errorNumDocumento &&
            !errorFechaNacimiento &&
            !errorSexo &&
            (valueNombre !== persona.nombre ||
              valueApellidoPaterno !== persona.apellido_paterno ||
              valueApellidoMaterno !== persona.apellido_materno ||
              valueNacionalidad !== persona.nacionalidad ||
              valueNumDocumento !== persona.rut ||
              valueFechaNacimiento !== persona.fechaNac ||
              valueFechaVencimiento !== persona.fechaVenc ||
              valueSexo !== persona.sexo)
        );
      } else {
        setContinueValue(
          !errorNombre &&
            !errorApellidoPaterno &&
            !errorApellidoMaterno &&
            !errorNacionalidad &&
            !errorNumDocumento &&
            !errorFechaNacimiento &&
            !errorFechaVencimiento &&
            !errorSexo &&
            valueNombre &&
            valueApellidoPaterno &&
            valueApellidoMaterno &&
            valueNacionalidad &&
            valueNumDocumento &&
            valueFechaNacimiento &&
            valueFechaVencimiento &&
            valueSexo
        );
      }
    } else if (view === "contrato") {
      if (editar) {
        setContinueValue(
          !errorCorreo &&
            !errorTelefono &&
            !errorDireccion &&
            !errorRegion &&
            !errorComuna &&
            !errorTipoContrato &&
            !errorJornada &&
            !errorFechaInicio &&
            !errorFechaFin &&
            !errorIngresoFaena &&
            !errorArt22 &&
            !errorTeletrabajo
          // (valueCorreo !== persona.email ||
          //   valueTelefono !== persona.telefono ||
          //   valueDireccion !== persona.direccion ||
          //   valueRegion !== persona.region ||
          //   valueComuna !== persona.comuna ||
          //   valueTipoContrato !== persona.tipo_contrato ||
          //   valueJornada !== persona.jornada ||
          //   valueFechaInicio !== persona.fecha_inicio_contrato ||
          //   valueFechaFin !== persona.fecha_fin_contrato ||
          //   valueIngresoFaena !== persona.fecha_ingreso_faena ||
          //   valueArt22 !== persona.art22 ||
          //   valueTeletrabajo !== persona.teletrabajo)
        );
      } else {
        setContinueValue(
          !errorCorreo &&
            !errorTelefono &&
            !errorDireccion &&
            !errorRegion &&
            !errorComuna &&
            !errorTipoContrato &&
            !errorJornada &&
            !errorFechaInicio &&
            !errorFechaFin &&
            !errorIngresoFaena &&
            !errorArt22 &&
            !errorTeletrabajo
          // valueDireccion &&
          // valueRegion &&
          // valueComuna &&
          // valueTipoContrato &&
          // valueJornada &&
          // valueFechaInicio &&
          // valueFechaFin &&
          // valueIngresoFaena &&
          // valueArt22 &&
          // valueTeletrabajo
        );
      }
    } else {
      setContinueValue(
        !errorNombre &&
          !errorApellidoPaterno &&
          !errorApellidoMaterno &&
          !errorNacionalidad &&
          !errorNumDocumento &&
          !errorFechaNacimiento &&
          !errorFechaVencimiento &&
          !errorSexo &&
          !errorCorreo &&
          !errorTelefono &&
          !errorDireccion &&
          !errorRegion &&
          !errorComuna &&
          !errorTipoContrato &&
          !errorJornada &&
          !errorFechaInicio &&
          !errorFechaFin &&
          !errorIngresoFaena &&
          !errorArt22 &&
          !errorTeletrabajo &&
          (valueNombre !== persona.nombre ||
            valueApellidoPaterno !== persona.apellido_paterno ||
            valueApellidoMaterno !== persona.apellido_materno ||
            valueNacionalidad !== persona.nacionalidad ||
            valueNumDocumento !== persona.rut ||
            valueFechaNacimiento !== persona.fechaNac ||
            valueFechaVencimiento !== persona.fechaVenc ||
            valueSexo !== persona.sexo ||
            valueCorreo !== persona.email ||
            valueTelefono !== persona.telefono ||
            valueDireccion !== persona.direccion ||
            valueRegion !== persona.region ||
            valueComuna !== persona.comuna ||
            valueCargo !== persona.cargo ||
            valueTipoContrato !== persona.tipo_contrato ||
            valueJornada !== persona.jornada ||
            valueFechaInicio !== persona.fecha_inicio_contrato ||
            valueFechaFin !== persona.fecha_fin_contrato ||
            valueIngresoFaena !== persona.fecha_ingreso_faena ||
            valueArt22 !== persona.art22 ||
            valueTeletrabajo !== persona.teletrabajo)
      );
    }
  }, [
    valueNombre,
    valueApellidoPaterno,
    valueApellidoMaterno,
    valueNacionalidad,
    valueNumDocumento,
    valueFechaNacimiento,
    valueFechaVencimiento,
    valueSexo,
    valueCorreo,
    valueTelefono,
    valueDireccion,
    valueRegion,
    valueComuna,
    valueCargo,
    valueTipoContrato,
    valueJornada,
    valueFechaInicio,
    valueFechaFin,
    valueIngresoFaena,
    valueArt22,
    valueTeletrabajo,
    errorNombre,
    errorApellidoPaterno,
    errorApellidoMaterno,
    errorNacionalidad,
    errorNumDocumento,
    errorFechaNacimiento,
    errorFechaVencimiento,
    errorSexo,
    errorCorreo,
    errorTelefono,
    errorDireccion,
    errorRegion,
    errorComuna,
    errorTipoContrato,
    errorJornada,
    errorFechaInicio,
    errorFechaFin,
    errorIngresoFaena,
    errorArt22,
    errorTeletrabajo
  ]);

  useEffect(() => {
    if (
      valueNombre ||
      valueApellidoPaterno ||
      valueApellidoMaterno ||
      valueNacionalidad ||
      valueNumDocumento ||
      valueFechaNacimiento ||
      valueFechaVencimiento ||
      valueSexo ||
      valueCorreo ||
      valueTelefono ||
      valueDireccion ||
      valueRegion ||
      valueComuna ||
      valueTipoContrato ||
      valueJornada ||
      valueFechaInicio ||
      valueFechaFin ||
      valueIngresoFaena ||
      valueArt22 ||
      valueTeletrabajo
    ) {
      setPersona({
        id: persona.id,
        nombre: valueNombre,
        apellido_paterno: valueApellidoPaterno,
        apellido_materno: valueApellidoMaterno,
        rut: valueNumDocumento,
        nacionalidad: valueNacionalidad,
        fechaNac: valueFechaNacimiento,
        fechaVenc: valueFechaVencimiento,
        sexo: valueSexo,
        email: valueCorreo,
        telefono: valueTelefono,
        direccion: valueDireccion,
        region: valueRegion,
        comuna: valueComuna,
        cargo: valueCargo,
        tipo_contrato: valueTipoContrato,
        jornada: valueJornada,
        fecha_inicio_contrato: valueFechaInicio,
        fecha_fin_contrato: valueFechaFin,
        fecha_ingreso_faena: valueIngresoFaena,
        art22: valueArt22,
        teletrabajo: valueTeletrabajo
      });
    }
  }, [
    valueNombre,
    valueApellidoPaterno,
    valueApellidoMaterno,
    valueNacionalidad,
    valueNumDocumento,
    valueFechaNacimiento,
    valueFechaVencimiento,
    valueSexo,
    valueCorreo,
    valueTelefono,
    valueDireccion,
    valueRegion,
    valueComuna,
    valueCargo,
    valueTipoContrato,
    valueJornada,
    valueFechaInicio,
    valueFechaFin,
    valueIngresoFaena,
    valueArt22,
    valueTeletrabajo
  ]);

  const handleNotEmpty = (value, setValue, setError, error) => {
    setValue(value);
    if (isEmpty(value)) {
      setError(error);
    } else {
      setError("");
    }
  };
  const formatValueNumDocumento = (value) => {
    let rut = value;
    rut = rut.replace(/\.|-/g, "");
    if (rut.length > 2) {
      if (rut.length < 5) {
        rut = rut.slice(0, 1) + "." + rut.slice(1);
      } else if (rut.length < 9) {
        rut = rut.slice(0, 1) + "." + rut.slice(1, 4) + "." + rut.slice(4);
      } else if (rut.length === 9) {
        rut = rut.slice(0, 2) + "." + rut.slice(2, 5) + "." + rut.slice(5);
      } else if (rut.length > 9) {
        rut = rut.slice(0, 2) + "." + rut.slice(2, 5) + "." + rut.slice(5, 9);
      }
      if (rut.replace(".", "").length > 7) {
        rut = rut.slice(0, -1) + "-" + rut.slice(-1);
      }
    }
    return rut;
  };
  const handleRUTChange = (value) => {
    let rut = value.replace(/\.|-/g, "");
    rut = rut.slice(0, -1) + "-" + rut.slice(-1);
    setValueNumDocumento(rut);
    if (!Fn.validaRut(rut)) {
      setErrorNumDocumento("RUT inválido");
    } else {
      setErrorNumDocumento("");
    }
  };
  const handleTelChange = (value) => {
    let tel = value.includes("+56") ? value : `+56${value}`;
    setValueTelefono(tel);
    if (isMobilePhone(tel, "es-CL")) {
      setErrorTelefono("");
    } else {
      setErrorTelefono("Escriba un número de teléfono válido");
    }
  };
  const handleMailChange = (value) => {
    setValueCorreo(value);
    if (isEmail(value)) {
      setErrorCorreo("");
    } else {
      setErrorCorreo("Escriba un email válido");
    }
  };

  const handleMouseEnter = (event) => {
    event.preventDefault();
    editar ? setHover(true) : null;
  };
  const handleMouseLeave = (event) => {
    event.preventDefault();
    editar ? setHover(false) : null;
  };

  /* CSS */
  const todo = {
    width: "fit-content",
    height: "fit-content",
    overflowY: editar ? "auto" : "hidden",
    overflowX: "hidden"
  };
  const informacionPersonalBox = {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    gap: "4vh",
    justifyContent: "flex-start",
    alignItems: littleSize ? "center" : "start",
    padding: "3.5vh 0px",
    textAlign: littleSize ? "center" : "start"
  };
  const tituloSeccion = {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "22px",
    lineHeight: "140%",
    color: "#1876D1"
  };
  const informacionPersonal = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "3vh 3vw",
    justifyContent: littleSize ? "center" : "flex-start",
    alignItems: "center"
  };
  const selectDatosBox = {
    flexDirection: "column",
    gap: "0px",
    maxWidth: "300px",
    width: "14.58vw",
    minWidth: "210px"
  };
  const checkBox = {
    flexDirection: "column",
    width: "10vw",
    minWidth: "200px",
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    padding: "15px 11px",
    gap: "10px"
  };
  const radioGroup = {
    justifyContent: littleSize ? "center" : "flex-start",
    alignItems: "center"
  };
  const textField = {
    height: "inherit",
    background: "#FFFFFF",
    fontWeight: 400,
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400
    },
    ".Mui-disabled": {
      opacity: 0.7
    }
  };
  const textFieldBoxTel = {
    display: "flex",
    flexDirection: "row"
  };
  const textFieldTelLeft = {
    width: "58px",
    height: "inherit",
    borderRadius: "4.34415px",
    // ".css-183emvd-MuiInputBase-root-MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      borderBottomRightRadius: "0%",
      borderTopRightRadius: "0%",
      // ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
      "& .MuiOutlinedInput-input": {
        paddingLeft: "0px",
        paddingRight: "0px"
      }
    }
  };
  const textFieldTelRight = {
    width: "calc(100% - 58px)",
    height: "inherit",
    borderRadius: "4.34415px",
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      // ".css-183emvd-MuiInputBase-root-MuiOutlinedInput-root": {
      borderRadius: "10px",
      borderBottomLeftRadius: "0%",
      borderTopLeftRadius: "0%"
    }
  };

  return persona ? (
    <Box sx={todo}>
      <Box sx={informacionPersonalBox}>
        <Typography sx={tituloSeccion}>Información personal</Typography>
        <Box sx={informacionPersonal}>
          <Box sx={selectDatosBox} component={FormGroup}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              id="nombre"
              label="Nombre"
              type="text"
              disabled={view === "contrato" ? true : false}
              value={valueNombre}
              onChange={(e) =>
                handleNotEmpty(
                  e.target.value,
                  setValueNombre,
                  setErrorNombre,
                  "Escriba el nombre"
                )
              }
            />
            <FormHelperText error>{errorNombre}</FormHelperText>
          </Box>
          <Box sx={selectDatosBox} component={FormGroup}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              required
              id="apellidoPaterno"
              label="Apellido paterno"
              type="text"
              disabled={view === "contrato" ? true : false}
              value={valueApellidoPaterno}
              onChange={(e) =>
                handleNotEmpty(
                  e.target.value,
                  setValueApellidoPaterno,
                  setErrorApellidoPaterno,
                  "Escriba el apellido paterno"
                )
              }
            />
            <FormHelperText error>{errorApellidoPaterno}</FormHelperText>
          </Box>
          <Box sx={selectDatosBox} component={FormGroup}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              required
              id="apellidoMaterno"
              label="Apellido materno"
              type="text"
              disabled={view === "contrato" ? true : false}
              value={valueApellidoMaterno}
              onChange={(e) =>
                handleNotEmpty(
                  e.target.value,
                  setValueApellidoMaterno,
                  setErrorApellidoMaterno,
                  "Escriba apellido materno"
                )
              }
            />
            <FormHelperText error>{errorApellidoMaterno}</FormHelperText>
          </Box>
          <Box sx={selectDatosBox} component={FormGroup}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              required
              id="rut"
              label="RUT"
              type="text"
              disabled={view === "contrato" ? true : false}
              value={formatValueNumDocumento(valueNumDocumento)}
              onChange={(e) => handleRUTChange(e.target.value)}
            />
            <FormHelperText error>{errorNumDocumento}</FormHelperText>
          </Box>
          <Box sx={selectDatosBox} component={FormGroup}>
            <TextField
              sx={textField}
              size="small"
              // autoFocus
              fullWidth
              required
              id="fechaNac"
              label="Fecha de nacimiento"
              type="date"
              disabled={view === "contrato" ? true : false}
              value={valueFechaNacimiento}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                handleNotEmpty(
                  e.target.value,
                  setValueFechaNacimiento,
                  setErrorFechaNacimiento,
                  "Seleccione la fecha de nacimiento"
                )
              }
            />
            <FormHelperText error>{errorFechaNacimiento}</FormHelperText>
          </Box>
          <Box sx={selectDatosBox} component={FormGroup}>
            <TextField
              sx={textField}
              size="small"
              // autoFocus
              fullWidth
              required
              id="fechaVenc"
              label="Fecha de vencimiento de la cédula"
              type="date"
              disabled={view === "contrato" ? true : false}
              value={valueFechaVencimiento}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                handleNotEmpty(
                  e.target.value,
                  setValueFechaVencimiento,
                  setErrorFechaVencimiento,
                  "Seleccione la fecha de vencimiento"
                )
              }
            />
            <FormHelperText error>{errorFechaVencimiento}</FormHelperText>
          </Box>
          <Box sx={selectDatosBox} component={FormGroup}>
            <CustomSelect
              req={true}
              label="Nacionalidad"
              fontWeightValue={400}
              disabled={view === "contrato" ? true : false}
              value={valueNacionalidad}
              setValue={setValueNacionalidad}
              errorText={valueNacionalidad ? "" : errorNacionalidad}
            >
              {listaNacionalidad}
            </CustomSelect>
          </Box>
          <Box sx={selectDatosBox} component={FormGroup}>
            <CustomSelect
              req={true}
              label="Sexo"
              fontWeightValue={400}
              disabled={view === "contrato" ? true : false}
              value={valueSexo}
              setValue={setValueSexo}
              errorText={valueSexo ? "" : errorSexo}
            >
              {listaSexo}
            </CustomSelect>
          </Box>
          {view === "cedula" ? null : (
            <Box sx={selectDatosBox} component={FormGroup}>
              <TextField
                sx={textField}
                size="small"
                fullWidth
                required
                id="email"
                label="Correo electrónico"
                type="email"
                value={valueCorreo}
                onChange={(e) => handleMailChange(e.target.value)}
              />
              <FormHelperText error>{errorCorreo}</FormHelperText>
            </Box>
          )}
          {view === "cedula" ? null : (
            <Box sx={selectDatosBox} component={FormGroup}>
              <Box sx={textFieldBoxTel}>
                <TextField
                  sx={textFieldTelLeft}
                  size={"small"}
                  fullWidth
                  disabled
                  label="+56"
                />
                <TextField
                  sx={textFieldTelRight}
                  size="small"
                  fullWidth
                  required
                  id="telefono"
                  label="Telefono"
                  type="tel"
                  value={valueTelefono?.replace("+56", "")}
                  onChange={(e) => handleTelChange(e.target.value)}
                />
              </Box>
              <FormHelperText error>{errorTelefono}</FormHelperText>
            </Box>
          )}
          {view === "cedula" ? null : (
            <Box sx={selectDatosBox} component={FormGroup}>
              <TextField
                sx={textField}
                size="small"
                fullWidth
                required
                id="direccion"
                label="Dirección"
                type="text"
                value={valueDireccion}
                onChange={(e) =>
                  handleNotEmpty(
                    e.target.value,
                    setValueDireccion,
                    setErrorDireccion,
                    "Escriba la dirección"
                  )
                }
              />
              <FormHelperText error>{errorDireccion}</FormHelperText>
            </Box>
          )}
          {view === "cedula" ? null : (
            <Box sx={selectDatosBox} component={FormGroup}>
              <CustomSelect
                req={true}
                label="Región"
                fontWeightValue={400}
                value={valueRegion}
                setValue={setValueRegion}
                errorText={valueRegion ? "" : errorRegion}
              >
                {listaRegiones}
              </CustomSelect>
            </Box>
          )}
          {view === "cedula" ? null : (
            <Box sx={selectDatosBox} component={FormGroup}>
              <CustomSelect
                disabled={valueRegion === ""}
                req={true}
                label="Comuna"
                fontWeightValue={400}
                value={valueComuna}
                setValue={setValueComuna}
                errorText={valueComuna ? "" : errorComuna}
              >
                {listaComunas.find((obj) => obj.region === valueRegion)
                  ?.comunas || listaComunas}
              </CustomSelect>
            </Box>
          )}
        </Box>
      </Box>

      {view === "cedula" ? null : (
        <Box sx={informacionPersonalBox}>
          <Typography sx={tituloSeccion}>
            Información del contrato laboral
          </Typography>
          <Box sx={informacionPersonal}>
            <Box sx={selectDatosBox} component={FormGroup}>
              <CustomSelect
                keepMounted={true}
                req={true}
                label="Cargo del trabajador"
                fontWeightValue={400}
                value={valueCargo}
                setValue={setValueCargo}
                errorText={valueCargo ? "" : errorCargo}
              >
                {listaTrabajos}
              </CustomSelect>
            </Box>
            <Box sx={selectDatosBox} component={FormGroup}>
              <CustomSelect
                req={true}
                label="Tipo de contrato"
                fontWeightValue={400}
                value={valueTipoContrato}
                setValue={setValueTipoContrato}
                errorText={valueTipoContrato ? "" : errorTipoContrato}
              >
                {listaTipoContrato}
              </CustomSelect>
            </Box>
            <Box sx={selectDatosBox} component={FormGroup}>
              <CustomSelect
                req={true}
                label="Jornada laboral"
                fontWeightValue={400}
                value={valueJornada}
                setValue={setValueJornada}
                errorText={valueJornada ? "" : errorJornada}
              >
                {listaJornada}
              </CustomSelect>
            </Box>
            <Box
              sx={selectDatosBox}
              component={FormGroup}
              onPointerEnter={handleMouseEnter}
              onPointerLeave={handleMouseLeave}
            >
              <TextField
                sx={textField}
                size="small"
                disabled={editar}
                fullWidth
                required
                id="fechaInicioContrato"
                label="Fecha inicio de contrato"
                type="date"
                value={valueFechaInicio}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleNotEmpty(
                    e.target.value,
                    setValueFechaInicio,
                    setErrorFechaInicio,
                    "Seleccione la fecha de inicio del contrato"
                  )
                }
              />
              <FormHelperText error>{errorFechaInicio}</FormHelperText>
            </Box>
            <Box
              sx={selectDatosBox}
              component={FormGroup}
              onPointerEnter={handleMouseEnter}
              onPointerLeave={handleMouseLeave}
            >
              <TextField
                sx={textField}
                size="small"
                disabled={editar}
                fullWidth
                required
                id="fechaFinContrato"
                label="Fecha fin de contrato"
                type="date"
                value={valueFechaFin}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleNotEmpty(
                    e.target.value,
                    setValueFechaFin,
                    setErrorFechaFin,
                    "Seleccione la fecha de fin del contrato"
                  )
                }
              />
              <FormHelperText error>{errorFechaFin}</FormHelperText>
            </Box>
            <Box
              sx={selectDatosBox}
              component={FormGroup}
              onPointerEnter={handleMouseEnter}
              onPointerLeave={handleMouseLeave}
            >
              <TextField
                sx={textField}
                size="small"
                disabled={editar}
                fullWidth
                required
                id="fechaIngresoFaena"
                label="Fecha ingreso a faena"
                type="date"
                value={valueIngresoFaena}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleNotEmpty(
                    e.target.value,
                    setValueIngresoFaena,
                    setErrorIngresoFaena,
                    "Seleccione la fecha de ingresa a la faena"
                  )
                }
              />
              <FormHelperText error>{errorIngresoFaena}</FormHelperText>
            </Box>
            <Box sx={checkBox} component={FormGroup}>
              <Typography>
                <b>Art. 22</b>
              </Typography>
              <RadioGroup
                row
                sx={radioGroup}
                value={valueArt22}
                name="art22"
                onChange={(e) => setValueArt22(e.target.value)}
              >
                <FormControlLabel value={true} control={<Radio />} label="Si" />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
              <FormHelperText error>{errorArt22}</FormHelperText>
            </Box>
            <Box sx={checkBox} component={FormGroup}>
              <Typography>
                <b>Teletrabajo</b>
              </Typography>
              <RadioGroup
                row
                sx={radioGroup}
                name="teletrabajo"
                value={valueTeletrabajo}
                onChange={(e) => setValueTeletrabajo(e.target.value)}
              >
                <FormControlLabel value={true} control={<Radio />} label="Si" />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
              <FormHelperText error>{errorTeletrabajo}</FormHelperText>
            </Box>
            <InfoHover hover={hover}>
              Comunícate con soporte para generar un ticket de ayuda que te
              permita editar este campo.
            </InfoHover>
          </Box>
        </Box>
      )}
    </Box>
  ) : null;
}
