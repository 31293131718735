import React, { useState, useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Button, ListItemButton, Select, Typography, Box } from "@mui/material";

/* ICONS */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";

/* CUSTOM LIBRARIES */
import CustomTable from "../common/components/customTable";
import CustomSelect from "../common/components/customSelect";
import EditarTrabajadorModal from "./editarTrabajadorModal";
import ExtenderContratoModal from "./extenderContratoModal";
import DarBajaTrabajadorModal from "./darBajaTrabajadorModal";
import FinishModal from "../common/modals/finishModal";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import {
  estadosTrabajadores,
  headCellsTrabajadores
} from "../../../controller/listas";
import {
  transition,
  selectHeight,
  selectMaxHeight,
  POLLING_INTERVAL
} from "../../../controller/utils";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentType } from "../../../redux/authSlice";
import {
  currentEmpresa,
  currentFaena,
  currentTrabajador,
  setTrabajador
} from "../../../redux/entidadesSlice";
import {
  useGetTrabajadoresFaenaQuery,
  useGetTrabajadoresQuery
} from "../../../api/trabajadoresApiSlice";

export default function Trabajadores() {
  const { setSubtitleValue } = useOutletContext();
  const dispatch = useDispatch();
  const littleSize = littleSizeFunc();
  const userType = useSelector(selectCurrentType);
  const selectedTrabajador = useSelector(currentTrabajador);
  const selectedEmpresa = useSelector(currentEmpresa);
  const selectedFaena = useSelector(currentFaena);

  const { currentData, isSuccess, isLoading, isFetching } =
    selectedFaena.id !== ""
      ? useGetTrabajadoresFaenaQuery(selectedFaena.id, {
          pollingInterval: POLLING_INTERVAL
        })
      : selectedEmpresa.id !== ""
      ? useGetTrabajadoresQuery(selectedEmpresa.id, {
          pollingInterval: POLLING_INTERVAL
        })
      : useGetTrabajadoresQuery(null, {
          pollingInterval: POLLING_INTERVAL
        });

  const [empresaOutsourcing, setEmpresaOutsourcing] = useState(""); // ID del empresa
  const [empresaMandante, setEmpresaMandante] = useState(""); // ID del empresa
  const [estado, setEstado] = useState("");
  const [accion, setAccion] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openExtenderModal, setOpenExtenderModal] = useState(false);
  const [openBajaModal, setOpenBajaModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [listaTrabajadores, setListaTrabajadores] = useState([]);
  const [filtroEmpresasMandantes, setFiltroEmpresasMandantes] = useState([]);
  const [filtroEmpresasOutsourcing, setFiltroEmpresasOutsourcing] = useState(
    []
  );
  const [listaTrabajadoresFiltrada, setListaTrabajadoresFiltrada] = useState(
    []
  );

  useEffect(() => {
    if (selectedEmpresa.id) {
      setSubtitleValue(`Cliente: ${selectedEmpresa.nombre}`);
    } else {
      setSubtitleValue("");
    }
  }, []);

  useEffect(() => {
    if (isSuccess && currentData) {
      setListaTrabajadores([...currentData.data]);
      setListaTrabajadoresFiltrada([...currentData.data]);
      var lista_empresa_o = [];
      var lista_empresa_m = [];
      currentData.data.map((t, i) => {
        lista_empresa_o.push({
          id: t.empresa_outsourcing,
          value: t.empresa_outsourcing
        });
        lista_empresa_m.push({
          id: t.empresa_mandante,
          value: t.empresa_mandante
        });
      });
      let jsonObject = lista_empresa_o.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      lista_empresa_o = Array.from(uniqueSet).map(JSON.parse);
      setFiltroEmpresasOutsourcing(lista_empresa_o);

      jsonObject = lista_empresa_m.map(JSON.stringify);
      uniqueSet = new Set(jsonObject);
      lista_empresa_m = Array.from(uniqueSet).map(JSON.parse);
      setFiltroEmpresasMandantes(lista_empresa_m);
    }
  }, [currentData, isSuccess, isFetching]);

  useEffect(() => {
    if (isSuccess) {
      if (
        listaTrabajadores.length > 0 ||
        listaTrabajadores === currentData.data
      ) {
        let listaTrab =
          listaTrabajadores.lenght > listaTrabajadoresFiltrada.lenght
            ? [...listaTrabajadoresFiltrada]
            : [...listaTrabajadores];
        if (empresaOutsourcing) {
          listaTrab = listaTrab
            .map((trabajadorObj) => {
              if (trabajadorObj.empresa_outsourcing === empresaOutsourcing) {
                return trabajadorObj;
              }
              return null;
            })
            .filter((trabajadorObj) => trabajadorObj !== null);
          setListaTrabajadoresFiltrada(listaTrab);
        }
        if (empresaMandante) {
          if (!selectedEmpresa.id) {
            listaTrab = listaTrab
              .map((trabajadorObj) => {
                if (trabajadorObj.empresa_mandante === empresaMandante) {
                  return trabajadorObj;
                }
                return null;
              })
              .filter((trabajadorObj) => trabajadorObj !== null);
            setListaTrabajadoresFiltrada(listaTrab);
          }
        }
        if (estado) {
          listaTrab = listaTrab
            .map((trabajadorObj) => {
              let re = new RegExp(`${estado}`, "gi");
              if (re.test(trabajadorObj.estado)) {
                return trabajadorObj;
              }
              return null;
            })
            .filter((trabajadorObj) => trabajadorObj !== null);
          setListaTrabajadoresFiltrada(listaTrab);
        } else {
          setListaTrabajadoresFiltrada(listaTrab);
        }
      }
    }
  }, [
    empresaMandante,
    empresaOutsourcing,
    estado,
    currentData,
    isFetching,
    isSuccess
  ]);

  const handleLimpiaFiltros = () => {
    setEmpresaMandante("");
    setEmpresaOutsourcing("");
    setEstado("");
  };

  const setSelectedTrabajador = (value) => {
    dispatch(setTrabajador(value));
  };

  /* CSS */
  const contenido = {
    minHeight: "58vh",
    height: "fit-content",
    maxHeight: "84vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "5.17vh",
    transition: transition
  };
  const barraFiltros = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap-reverse",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: littleSize ? "center" : "start",
    gap: "11px"
  };
  const filtrosBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap-reverse",
    alignItems: "center",
    gap: "11px"
  };
  const formControl = {
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    width: "16vw",
    minWidth: "210px"
  };
  const buttonLimparFiltros = {
    height: "fit-content",
    width: "fit-content",
    transition: transition,
    textTransform: "none ",
    borderRadius: "12px",
    background: "#1876D1",
    color: "#FCFBFB",
    fontWeight: 800,
    fontSize: "12px",
    gap: "5px"
  };
  const filtroVacio = {
    width: "100%",
    borderRadius: "10px",
    transition: transition,
    fontWeight: 600,
    fontSize: "100%",
    lineHeight: "150%",
    color: "#818181",
    display: "flex",
    alignItems: "center",
    height: selectHeight,
    maxHeight: selectMaxHeight,
    "&:hover": {
      color: "#1876D1"
    }
  };
  const seleccionAccionesDefaultValue = {
    width: "100%",
    // borderRadius: "10px",
    fontWeight: 600,
    fontSize: "100%",
    lineHeight: "150%",
    color: "#FCFBFB"
  };
  const seleccionAcciones = {
    width: "100%",
    borderRadius: "10px",
    transition: transition,
    fontWeight: 600,
    fontSize: "100%",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    height: selectHeight,
    maxHeight: selectMaxHeight,
    color: "#FCFBFB",
    background: "#1876D1",
    textTransform: "none",
    ".MuiSelect-icon": {
      color: "#FCFBFB"
    }
  };
  const textStyleFinishModal = {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "20px",
    color: "#818181"
  };
  const buttonStyle = {
    height: "40px",
    textTransform: "none",
    minHeight: "100%",
    fontWeight: 600,
    color: "#FCFBFB",
    backgroundColor: "#1876D1",
    borderRadius: "10px",
    padding: "5px 20px",
    "&:hover": {
      background: "#1876D1"
    }
  };

  return (
    <Box sx={contenido}>
      <Box sx={barraFiltros}>
        {(empresaMandante && !selectedEmpresa.id) ||
        empresaOutsourcing ||
        estado ? (
          <Box sx={formControl}>
            <Button
              onClick={handleLimpiaFiltros}
              disableElevation={true}
              variant="contained"
              sx={buttonLimparFiltros}
            >
              <FilterListOffOutlinedIcon />
              Restablecer filtros
            </Button>
          </Box>
        ) : null}
        <Box sx={filtrosBox}>
          {selectedEmpresa.id ? null : (
            <Box sx={formControl}>
              <CustomSelect
                label={
                  userType === "Supp" || userType === "Admin"
                    ? "Filtrar por empresa mandante"
                    : "Filtrar por cliente"
                }
                value={empresaMandante}
                setValue={setEmpresaMandante}
              >
                {filtroEmpresasMandantes}
              </CustomSelect>
            </Box>
          )}
          {userType == "A" ? null : (
            <Box sx={formControl}>
              <CustomSelect
                label={"Filtrar por empresa outsourcing"}
                value={empresaOutsourcing}
                setValue={setEmpresaOutsourcing}
              >
                {filtroEmpresasOutsourcing}
              </CustomSelect>
            </Box>
          )}
          <Box sx={formControl}>
            <CustomSelect
              label={"Filtrar por estado"}
              value={estado}
              setValue={setEstado}
            >
              {estadosTrabajadores}
            </CustomSelect>
          </Box>
          {selectedFaena.id ? null : userType === "Supp" ||
            userType === "Admin" ? (
            <Button sx={buttonStyle} component={Link} to="Dados de baja">
              Ver dados de baja
            </Button>
          ) : (
            <Box sx={formControl}>
              <Select
                value={accion}
                IconComponent={KeyboardArrowDownIcon}
                displayEmpty
                onChange={(event) => setAccion(event.target.value)}
                sx={seleccionAcciones}
                renderValue={
                  accion !== ""
                    ? undefined
                    : () => (
                        <Typography sx={seleccionAccionesDefaultValue}>
                          Más acciones
                        </Typography>
                      )
                }
              >
                <ListItemButton
                  sx={filtroVacio}
                  component={Link}
                  to="Nuevos trabajadores"
                >
                  Crear nuevos trabajadores
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="Carga masiva"
                  sx={filtroVacio}
                >
                  Carga masiva
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="Vencimientos"
                  sx={filtroVacio}
                >
                  Ver vencimientos
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="Dados de baja"
                  sx={filtroVacio}
                >
                  Ver dados de baja
                </ListItemButton>
              </Select>
            </Box>
          )}
        </Box>
      </Box>
      <CustomTable
        headCells={headCellsTrabajadores}
        rows={listaTrabajadoresFiltrada}
        setRows={setListaTrabajadoresFiltrada}
        variant="trabajadores"
        setLineaSeleccionada={setSelectedTrabajador}
        setOpenEditModal={setOpenEditModal}
        setOpenExtenderModal={setOpenExtenderModal}
        setOpenBajaModal={setOpenBajaModal}
        setOpenErrorModal={setOpenErrorModal}
        isLoading={isLoading}
      />
      <EditarTrabajadorModal
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        trabajador={selectedTrabajador}
      />
      <ExtenderContratoModal
        openModal={openExtenderModal}
        setOpenModal={setOpenExtenderModal}
        trabajador={selectedTrabajador}
      />
      <DarBajaTrabajadorModal
        openModal={openBajaModal}
        setOpenModal={setOpenBajaModal}
        trabajador={selectedTrabajador}
      />
      <FinishModal openModal={openErrorModal} setOpenModal={setOpenErrorModal}>
        <Typography sx={textStyleFinishModal}>
          {selectedTrabajador.razon_rechazo}
        </Typography>
      </FinishModal>
    </Box>
  );
}
