import React, { useState, useRef, useEffect } from "react";
import {
  Input,
  FormControl,
  Typography,
  Button,
  SvgIcon,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  IconButton,
  backdropClasses,
} from "@mui/material";

/* ICONS */
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

/* CUSTOM LIBRARIES */
import BarraCarga from "../things/barraCarga";
import CarnetModal from "../modals/carnetModal";
import InfoHover from "../things/infoHover";
import CustomAlert from "../modals/customAlert";
import ContratoModal from "../modals/contratoModal";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../../controller/windowSize";
import {
  FileIcon,
  MAX_UPLOAD_FILES,
  emptyFileObj,
} from "../../../../controller/utils";
import { useSelector } from "react-redux";
import { selectCurrentType } from "../../../../redux/authSlice";
import {
  useAddPlantillaMutation,
  useDeletePlantillaMutation,
} from "../../../../api/plantillasApiSlice";
import {
  useAddAnexoMutation,
  useAddDocumentoMutation,
  useDeleteDocumentoMutation,
} from "../../../../api/docsApiSlice";

export default function DragDropFileList(props) {
  const {
    list,
    setList = () => {},
    setContinueValue,
    multipleSingular = false,
    maxCount = list.length ? list.length : MAX_UPLOAD_FILES,
    multiple = true,
    uploadedFiles,
    setUploadedFiles,
    memory = multipleSingular,
    type = null,
    tipoTrabajador = null,
    empresa = null,
    isLoading = false,
    notUpload = false,
  } = props;
  const littleSize = littleSizeFunc();
  const userType = useSelector(selectCurrentType);

  const [addDocumento, uploadState] =
    type === "plantillas"
      ? useAddPlantillaMutation()
      : type === "subcontratado"
      ? useAddDocumentoMutation()
      : type === "anexo"
      ? useAddAnexoMutation()
      : useAddDocumentoMutation();

  const [deleteDocumento, deleteState] =
    type === "plantillas"
      ? useDeletePlantillaMutation()
      : type === "subcontratado"
      ? useDeleteDocumentoMutation()
      : useDeleteDocumentoMutation();

  const countUploadedFiles = uploadedFiles.reduce((n, val) => {
    return n + (val.titulo !== null);
  }, 0);

  const acceptedFormatsList = list.map((file) =>
    file.acceptedFormats
      ? file.acceptedFormats
          .map((format) =>
            format.split(".").slice(-1)[0].split("/").slice(-1)[0].toUpperCase()
          )
          .toString()
          .replace(",", ", ")
      : "PDF"
  );

  const [cargaValue, setCargaValue] = useState(0);
  const [hover, setHover] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [openCarnetModal, setOpenCarnetModal] = useState(false);
  const [openContratoModal, setOpenContratoModal] = useState(false);
  const [waitingUploadFiles, setWaitingUploadFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [currentLoadingDocument, setCurrentLoadingDocument] = useState("");
  const [indexValue, setIndexValue] = useState(0);
  const [alertText, setAlertText] = useState("");
  const [errorState, setErrorState] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (multipleSingular) {
      setContinueValue(uploadedFiles.length > 0);
    } else {
      setContinueValue(
        waitingUploadFiles.length !== uploadedFiles.length &&
          countUploadedFiles === list.length &&
          !cargaValue
      );
    }
  }, [waitingUploadFiles, uploadedFiles, countUploadedFiles, list, cargaValue]);

  // controlador de carga
  useEffect(() => {
    let cargaId;
    if (cargaValue) {
      if (multipleSingular) {
        if (isLoading && cargaValue < 96) {
          cargaId = setInterval(() => {
            setCargaValue((cargaValue) => cargaValue + 5);
          }, 100);
        } else if (!isLoading) {
          cargaId = setInterval(() => {
            setCargaValue((cargaValue) => cargaValue + 99);
          }, 100);
          if (cargaValue > 99) {
            clearInterval(cargaId);
            setCargaValue(100);
          }
        }
      } else if (type != null) {
        if (cargaValue < 96) {
          cargaId = setInterval(() => {
            setCargaValue((cargaValue) => cargaValue + 5);
          }, 500);
        }
      } else if (cargaValue < 100) {
        cargaId = setInterval(() => {
          setCargaValue((cargaValue) => cargaValue + 5);
        }, 100);
      } else {
        clearInterval(cargaId);
        setCargaValue(100);
      }
    }
    return () => clearInterval(cargaId);
  }, [cargaValue, isLoading]);

  // cuando la carga llega al 100
  useEffect(() => {
    if (notUpload && cargaValue > 0) {
      setCargaValue(100);
    }
    if (cargaValue === 100) {
      const count = uploadedFiles.reduce((n, val) => {
        return n + (val.titulo !== null);
      }, 0);
      setCurrentLoadingDocument("");
      setCargaValue(0);
      // setIndexValue(count);
    }
  }, [cargaValue]);

  useEffect(() => {
    let i = uploadedFiles.findIndex((file) => file.titulo === null);
    if (cargaValue === 0) {
      if (i > -1) {
        setIndexValue(i);
      } else {
        setIndexValue(uploadedFiles.length);
      }
    }
  }, [uploadedFiles, cargaValue]);

  // Si se borra un archivo se activa el boton para subir
  useEffect(() => {
    const count = uploadedFiles.reduce((n, val) => {
      return n + (val.titulo !== null);
    }, 0);
    if (count < maxCount) {
      setFileLimit(false);
    }
  }, [uploadedFiles]);

  // se borra la referencia para si no hay archivo cargandose
  useEffect(() => {
    if (currentLoadingDocument.name === "") inputRef.current.value = "";
  }, [currentLoadingDocument]);

  // Cargar siguiente archivo en la lista de espera
  useEffect(() => {
    if (waitingUploadFiles.length > 0 && currentLoadingDocument === "") {
      const files = [...waitingUploadFiles];
      setCurrentLoadingDocument(files.splice(0, 1)[0]);
      setWaitingUploadFiles(files);
      setCargaValue(1);
    }
  }, [waitingUploadFiles, currentLoadingDocument]);

  // Llamado al principio, hace rellena el array de nulos con la cantidad maxima de archivos a subir.
  useEffect(() => {
    if (uploadedFiles) {
      let sorted = [];
      if (multipleSingular && uploadedFiles.length < maxCount * list.length) {
        sorted = uploadedFiles.concat(
          Array(maxCount * list.length - uploadedFiles.length).fill(
            emptyFileObj
          )
        );
        setUploadedFiles(sorted);
      } else if (uploadedFiles.length < maxCount) {
        let toUpload = uploadedFiles.concat(
          Array(maxCount - uploadedFiles.length).fill(emptyFileObj)
        );
        list.forEach((item) =>
          sorted.push(
            toUpload.find((doc) =>
              doc.tipo_documento
                ? typeof doc.tipo_documento == "number"
                  ? doc.tipo_documento == item.id ||
                    doc.tipo_documento == item.id_tipo
                  : doc.tipo_documento.id == item.id_tipo ||
                    doc.tipo_documento.id == item.id_tipo
                : emptyFileObj
            )
          )
        );
        setUploadedFiles(sorted);
      }
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (currentLoadingDocument !== "" && type) {
      setCargaValue(1);
      if (!notUpload) {
        handleAsyncUpload();
      }
    }
  }, [currentLoadingDocument.blob]);

  useEffect(() => {
    if (!openAlertModal) {
      setErrorState(false);
    }
  }, [openAlertModal]);

  const handleAsyncUpload = async () => {
    try {
      if (type === "cedula") {
        const response = await addDocumento({
          titulo: currentLoadingDocument.name,
          blob: currentLoadingDocument.blob,
          tipo_documento: "cedula",
        }).unwrap();
        setCargaValue(100);
        let uploaded = [...uploadedFiles];
        let i = uploaded.findIndex((file) => {
          if (file.name) {
            return file.name === response.titulo;
          }
        });
        uploaded[i] = response;
        setUploadedFiles(uploaded);
      } else {
        const response = await addDocumento({
          id: list[indexValue].id,
          titulo: currentLoadingDocument.name,
          blob: currentLoadingDocument.blob,
          trabajador: list[indexValue].trabajador,
          tipo_trabajador: tipoTrabajador ? tipoTrabajador : null,
          empresa: empresa,
          tipo_documento:
            type === "plantillas"
              ? list[indexValue].id
              : type === "subcontratado"
              ? list[indexValue].id_tipo
              : null,
        }).unwrap();
        if (response.estado === "Rechazado") {
          handleCancel(indexValue);
          setErrorState(true);
          handleAlert(
            `Documento rechazado por las siguientes razones: ${response.razon_rechazo
              ?.split("\n")
              .map((razon) => (razon ? "\n• " + razon : ""))}`
          );
        }
        if (response.estado === "Analizando") {
          setCargaValue(100);

          handleAlert(
            `Su documento fue enviado para una verificación manual, tendrá su respuesta dentro de 24 horas`
          );

          let uploaded = [...uploadedFiles];
          let i = uploaded.findIndex((file) => {
            if (file.name) {
              return file.name === response.titulo;
            }
          });
          uploaded[i] = response;
          setUploadedFiles(uploaded);
        }
        if (response.razon_rechazo === "Colilla") {
          let l = [...list];
          let i = l.findIndex((file) => {
            if (file.nombre == "Colilla Carta Certificada") {
              return true;
            }
          });
          l[i].hide = false;
          setList(l);
          handleAlert(
            `Firma del trabajador no detectada, suba la colilla del envio de la carta certificada`
          );
          let uploaded = [...uploadedFiles];
          let j = uploaded.findIndex((file) => {
            if (file.name) {
              return file.name === response.titulo;
            }
          });
          uploaded[j] = response;
          setUploadedFiles(uploaded);
        } else {
          setCargaValue(100);
          if (type === "plantillas") {
            setUploadedFiles(response);
          } else if (type === "anexo") {
            setUploadedFiles([response]);
          } else {
            let uploaded = [...uploadedFiles];
            let i = uploaded.findIndex((file) => {
              if (file.name) {
                return file.name === response.titulo;
              }
            });
            uploaded[i] = response;
            setUploadedFiles(uploaded);
          }
        }
      }
    } catch (err) {
      console.error(err);
      handleCancel(indexValue);
      setErrorState(true);
      handleAlert(
        "Hubo un error en subir el documento, intente nuevamente más tarde."
      );
    }
  };

  const handleAsyncDelete = async (index) => {
    try {
      const uploaded = [...uploadedFiles];
      console.log(uploaded);
      console.log(index);
      const response = await deleteDocumento(uploaded[index].id).unwrap();
      setCargaValue(0);
      // setIndexValue(index);
      if (!memory) {
        for (let i = 0; i < uploaded.length; i++) {
          if (i >= index) {
            uploaded[i] = emptyFileObj;
          }
        }
      } else {
        uploaded[index] = emptyFileObj;
      }
      setUploadedFiles(uploaded);
    } catch (err) {
      console.error(err);
      setErrorState(true);
      handleAlert(
        "Hubo un error en eliminar el documento, intente nuevamente más tarde."
      );
    }
  };

  const handleAlert = (text) => {
    setAlertText(text);
    setOpenAlertModal(true);
  };
  const handleMouseEnter = (e, disabled) => {
    e.preventDefault();
    disabled ? setHover(true) : setHover(false);
  };
  const handleMouseLeave = (e, disabled) => {
    e.preventDefault();
    disabled ? setHover(false) : null;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    let indexCount = indexValue;
    let count = 0;
    let startIndex = Math.floor(indexCount / maxCount) * maxCount;
    let endIndex = (Math.floor(indexCount / maxCount) + 1) * maxCount;
    files.some((file) => {
      if (file.name.length > 100) {
        limitExceeded = true;
        setCargaValue(0);
        setCurrentLoadingDocument("");
        handleAlert(
          "Asegúrese de que el nombre del archivo no tenga más de 100 caracteres."
        );
      }
      if (
        list
          .map((f) => f.acceptedFormats.find((type) => type === file.type))
          .filter((type) => type !== undefined).length > 0
      ) {
        if (
          uploaded.findIndex(
            (f) => f.titulo === file.name || f.name === file.name
          ) === -1
        ) {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.addEventListener(
            "load",
            () => {
              file.blob = reader.result;
            },
            false
          );
          if (multipleSingular) {
            var sectionUploaded = uploaded
              .map((x, i) => {
                if (startIndex <= i && i < endIndex) {
                  return x;
                }
              })
              .filter((x) => x !== undefined);
            let i = sectionUploaded.findIndex((f) => f.name === null);
            uploaded[startIndex + i] = file;
            sectionUploaded[i] = file;
            if (i >= 0) {
              indexCount = startIndex + i;
              count = sectionUploaded.filter((f) => f.name !== null).length;
            } else {
              indexCount = endIndex;
              count = sectionUploaded.filter((f) => f.name !== null).length + 1;
            }
          } else {
            uploaded[indexCount] = file;
            count = uploaded.reduce((n, val, i) => {
              return n + (val.name !== null);
            }, 0);
          }
          indexCount = uploaded.findIndex((f) => f.name === null) - 1;

          if (count === maxCount) {
            setCargaValue(1);
            setFileLimit(true);
          } else if (count > maxCount) {
            handleAlert(
              `Solo puedes subir un máximo de ${maxCount} archivo${
                maxCount > 1 ? "s" : ""
              }`
            );
            setFileLimit(false);
            setCurrentLoadingDocument("");
            setCargaValue(0);
            limitExceeded = true;
            return true;
          } else {
            setCargaValue(1);
          }
        } else {
          limitExceeded = true;
          setCargaValue(0);
          setCurrentLoadingDocument("");
          handleAlert(`Este archivo ya se encuentra subido`);
        }
      } else {
        setCargaValue(0);
        handleAlert(
          `Solo se admiten formatos ${acceptedFormatsList[indexValue]}`
        );
      }
    });
    if (!limitExceeded) {
      // let i = uploaded.findIndex((f) => f.titulo === null);
      // setIndexValue(i < 0 ? uploaded.length + 1 : i);
      setUploadedFiles(uploaded);
      setWaitingUploadFiles(files);
    }
  };
  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const chosenFiles = Array.prototype.slice.call(e.dataTransfer.files);
      if (chosenFiles.length > 1 && !(multiple || multipleSingular)) {
        handleAlert(`Solo puedes subir un máximo de 1 archivo`);
      } else {
        handleUploadFiles(chosenFiles);
      }
    }
  };
  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const chosenFiles = Array.prototype.slice.call(e.target.files);
      handleUploadFiles(chosenFiles);
    }
  };
  // triggers the input when the button is clicked
  const onUploadClick = (e, index) => {
    e.preventDefault();
    inputRef.current.value = null;
    inputRef.current.click();
    if (multipleSingular) {
      setIndexValue(index * maxCount);
    } else {
      setIndexValue(index);
    }
  };
  const onDeleteClick = async (e, index) => {
    e.preventDefault();
    if (!cargaValue) {
      const uploaded = [...uploadedFiles];
      inputRef.current.value = "";
      if (type && !notUpload) {
        await handleAsyncDelete(index);
      } else {
        setCargaValue(0);
        // setIndexValue(index);
        if (!memory) {
          for (let i = 0; i < uploaded.length; i++) {
            if (i >= index) {
              uploaded[i] = emptyFileObj;
            }
          }
        } else {
          uploaded[index] = emptyFileObj;
        }
        setUploadedFiles(uploaded);
      }
    }
  };
  const onDeleteAllClick = (e, index) => {
    e.preventDefault();
    if (!cargaValue) {
      inputRef.current.value = "";
      setCargaValue(0);
      // setIndexValue(index * maxCount);
      const uploaded = [...uploadedFiles];
      for (let i = index * maxCount; i < (index + 1) * maxCount; i++) {
        uploaded[i] = emptyFileObj;
      }
      setUploadedFiles(uploaded);
    }
  };
  const onCancelClick = (e, index) => {
    e.preventDefault();
    inputRef.current.value = "";
    handleCancel(index);
  };
  const handleCancel = (index) => {
    const uploaded = [...uploadedFiles];
    const waiting = [...waitingUploadFiles];

    if (uploaded[index].name === currentLoadingDocument.name) {
      setCurrentLoadingDocument("");
    } else {
      waiting.splice(
        waiting.findIndex(
          (waitingFile) => waitingFile.name === uploaded[index].name
        ),
        1
      );
      setWaitingUploadFiles(waiting);
    }

    uploaded[index] = emptyFileObj;
    setUploadedFiles(uploaded);

    // multipleSingular ? setIndexValue(index * maxCount) : setIndexValue(index);

    if (
      uploaded.reduce((n, val) => {
        return n + (val.name !== null);
      }, 0) < 1
    ) {
      setCargaValue(0);
    } else {
      setCargaValue(1);
    }
  };

  /* CSS */
  const formFileUpload = {
    // minHeight: "30vh",
    height: "fit-content",
    width: "100%",
    maxWidth: "90vw",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: dragActive ? "center" : "flex-start",
    background: dragActive ? "rgba(24, 118, 209, 0.2)" : "",
    borderRadius: "20px",
  };
  const inputFileUpload = {
    height: "100%",
    width: "100%",
    display: "none",
  };
  const addIcon = {
    position: "absolute",
    // color: "#818181",
    width: "auto",
    opacity: dragActive ? "1" : "0",
    visibility: dragActive ? "visible" : "hidden",
    color: "#1876D1",
    height: "65px",
    width: "65px",
  };
  const insideBoxFiles = {
    height: "fit-content",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // padding: "22px",
    gap: "11px",
    opacity: dragActive ? "0.1" : "1",
  };
  const listItem = {
    minHeight: "fit-content",
    height: "fit-content",
    padding: "11px 14px",
    gap: "10px",
    background: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: dragActive ? 0 : 1,
  };
  const listItemSubTitleText = {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "150%",
    margin: "8px 0",
    width: littleSize ? "60%" : "fit-content",
    overflowWrap: "break-word",
  };
  const listItemSubTitle = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "2px",
  };
  const listItemIcon = {
    color: "#1876D1",
    height: "100%",
    width: "auto",
  };
  const listIcon = {
    height: "100%",
    width: "auto",
  };
  const listItemText = {
    height: "fit-content",
    marginLeft: "0px",
    ".MuiListItemText-primary": {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "150%",
    },
    ".MuiListItemText-secondary": {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "150%",
    },
  };
  const listItemButton = {
    display: "flex",
    flexWrap: "wrap",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "150%",
    gap: "5px",
    width: littleSize ? "33%" : "fit-content",
    wordBreak: littleSize ? "break-word" : "",
    textTransform: "none",
  };
  const docIcons = {
    color: "#1876D1",
    height: "25px",
    width: "25px",
  };

  // console.log(currentLoadingDocument);
  // console.log(list[indexValue]);
  // console.log(list);
  return list.length > 0 &&
    (uploadedFiles.length === maxCount ||
      uploadedFiles.length === maxCount * list.length) ? (
    <FormControl
      sx={formFileUpload}
      onSubmit={handleSubmit}
      onDragEnter={multiple || multipleSingular ? null : handleDrag}
      onDragLeave={multiple || multipleSingular ? null : handleDrag}
      onDragOver={multiple || multipleSingular ? null : handleDrag}
      onDrop={multiple || multipleSingular ? null : handleDrop}
      // disabled={fileLimit}
    >
      <Input
        inputRef={inputRef}
        type="file"
        sx={inputFileUpload}
        onChange={handleChange}
        // disabled={fileLimit}
        multiple={!multiple || multipleSingular}
        inputProps={{
          multiple: !multiple || multipleSingular,
        }}
      />
      <List sx={insideBoxFiles}>
        {list.map((file, index) =>
          file.hide ? null : multipleSingular ? (
            <ListItem
              key={index}
              sx={listItem}
              onPointerEnter={(e) =>
                handleMouseEnter(e, index * maxCount > indexValue)
              }
              onPointerLeave={(e) =>
                handleMouseLeave(e, index * maxCount > indexValue)
              }
              disabled={index * maxCount > indexValue}
            >
              <ListItemIcon sx={listItemIcon}>
                {uploadedFiles.find((e) => e.name !== null) ? null : (
                  <InsertDriveFileOutlinedIcon sx={listIcon} />
                )}
              </ListItemIcon>
              <ListItemText
                sx={listItemText}
                primary={file.nombre || file.title}
                secondary={
                  uploadedFiles
                    .reduce((r, n, i) => {
                      n.name !== null && r.push(i);
                      return r;
                    }, [])
                    .some(
                      (i) => index * maxCount <= i && i < (index + 1) * maxCount
                    ) ? (
                    <React.Fragment>
                      {uploadedFiles.map((ufile, uindex) =>
                        index * maxCount <= uindex &&
                        uindex < (index + 1) * maxCount ? (
                          !ufile.name ? null : currentLoadingDocument.name ===
                            ufile.name ? (
                            <Typography sx={listItemSubTitle}>
                              <SvgIcon sx={docIcons}>{FileIcon(ufile)}</SvgIcon>
                              <BarraCarga
                                value={cargaValue}
                                archivo={ufile.name}
                              />
                              {/* <IconButton
                                disabled={
                                  index * maxCount > indexValue || cargaValue
                                }
                                onClick={(e) => onCancelClick(e, uindex)}
                                sx={docIcons}
                              >
                                <CancelOutlinedIcon />
                              </IconButton> */}
                            </Typography>
                          ) : waitingUploadFiles.includes(ufile) ? (
                            <Typography sx={listItemSubTitle}>
                              <SvgIcon sx={docIcons}>{FileIcon(ufile)}</SvgIcon>
                              <BarraCarga value={0.1} archivo={ufile.name} />
                              {/* <IconButton
                                onClick={(e) => onCancelClick(e, uindex)}
                                sx={docIcons}
                              >
                                <CancelOutlinedIcon />
                              </IconButton> */}
                            </Typography>
                          ) : (
                            <Typography sx={listItemSubTitle}>
                              <SvgIcon sx={docIcons}>{FileIcon(ufile)}</SvgIcon>
                              <Typography sx={listItemSubTitleText}>
                                {ufile.name}
                              </Typography>
                              <IconButton
                                disabled={index * maxCount > indexValue}
                                onClick={(e) => onDeleteClick(e, uindex)}
                                sx={docIcons}
                              >
                                <DeleteOutlinedIcon />
                              </IconButton>
                            </Typography>
                          )
                        ) : null
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Typography sx={listItemSubTitleText}>
                        Formatos admitidos:{" "}
                        {file.acceptedFormats
                          .map((format) =>
                            format
                              .split(".")
                              .slice(-1)[0]
                              .split("/")
                              .slice(-1)[0]
                              .toUpperCase()
                          )
                          .toString()
                          .replace(",", ", ")}
                      </Typography>
                    </React.Fragment>
                  )
                }
              />
              {file.nombre === "Cédula/s de identidad" ||
              file.title === "Cédula/s de identidad" ? (
                <IconButton onClick={(e) => setOpenCarnetModal(true)}>
                  <InfoOutlinedIcon />
                </IconButton>
              ) : (file.nombre === "Contrato Laboral" ||
                  file.title === "Contrato Laboral") &&
                userType !== "Admin" ? (
                <IconButton onClick={(e) => setOpenContratoModal(true)}>
                  <InfoOutlinedIcon />
                </IconButton>
              ) : null}
              <Button
                variant="text"
                sx={listItemButton}
                onClick={(e) => onUploadClick(e, index)}
                disabled={
                  index * maxCount > indexValue ||
                  cargaValue ||
                  uploadedFiles.reduce((n, val, i) => {
                    if (index * maxCount <= i && i < (index + 1) * maxCount) {
                      return n + (val.name !== null);
                    }
                    return n;
                  }, 0) === maxCount
                }
              >
                Subir documentos
                <FileUploadOutlinedIcon />
              </Button>
              {uploadedFiles
                .reduce((r, n, i) => {
                  n.titulo !== null && r.push(i);
                  return r;
                }, [])
                .some(
                  (i) => index * maxCount <= i && i < (index + 1) * maxCount
                ) ? (
                <Button
                  variant="text"
                  sx={listItemButton}
                  onClick={(e) => onDeleteAllClick(e, index)}
                  disabled={index * maxCount > indexValue || cargaValue}
                >
                  Eliminar todo
                  <DeleteOutlinedIcon />
                </Button>
              ) : null}
            </ListItem>
          ) : /* END multiSingular */ uploadedFiles[index].titulo ||
            uploadedFiles[index].name ? (
            currentLoadingDocument.name &&
            currentLoadingDocument.name === uploadedFiles[index].name ? (
              <ListItem
                key={index}
                sx={listItem}
                onPointerEnter={(e) =>
                  handleMouseEnter(e, multiple ? false : index > indexValue)
                }
                onPointerLeave={(e) =>
                  handleMouseLeave(e, multiple ? false : index > indexValue)
                }
                disabled={multiple ? false : index > indexValue}
              >
                <ListItemIcon sx={listItemIcon}>
                  <SvgIcon sx={listIcon}>
                    {FileIcon(uploadedFiles[index])}
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText
                  sx={listItemText}
                  primary={file.nombre}
                  secondary={
                    <React.Fragment>
                      <BarraCarga
                        value={cargaValue}
                        archivo={uploadedFiles[index].name}
                      />
                    </React.Fragment>
                  }
                />
              </ListItem>
            ) : (
              <ListItem
                key={index}
                sx={listItem}
                onPointerEnter={(e) =>
                  handleMouseEnter(e, multiple ? false : index > indexValue)
                }
                onPointerLeave={(e) =>
                  handleMouseLeave(e, multiple ? false : index > indexValue)
                }
                disabled={multiple ? false : index > indexValue}
              >
                <ListItemIcon sx={listItemIcon}>
                  <SvgIcon sx={listIcon}>
                    {FileIcon(uploadedFiles[index])}
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText
                  sx={listItemText}
                  primary={file.nombre}
                  secondary={
                    <React.Fragment>
                      <Typography sx={listItemSubTitleText}>
                        {uploadedFiles[index].titulo ||
                          uploadedFiles[index].name}
                      </Typography>
                    </React.Fragment>
                  }
                />
                {file.nombre === "Cédula/s de identidad" ? (
                  <IconButton onClick={(e) => setOpenCarnetModal(true)}>
                    <InfoOutlinedIcon />
                  </IconButton>
                ) : file.nombre === "Contrato laboral" &&
                  userType !== "Admin" ? (
                  <IconButton onClick={(e) => setOpenContratoModal(true)}>
                    <InfoOutlinedIcon />
                  </IconButton>
                ) : null}
                {uploadedFiles[index].estado == "Analizando" ? (
                  <Button variant="text" sx={listItemButton} disabled={true}>
                    Analizando...
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    sx={listItemButton}
                    onClick={(e) => onDeleteClick(e, index)}
                    disabled={
                      multiple
                        ? false || cargaValue
                        : index > indexValue || cargaValue
                    }
                  >
                    Eliminar
                    <DeleteOutlinedIcon />
                  </Button>
                )}
              </ListItem>
            )
          ) : (
            <ListItem
              key={index}
              sx={listItem}
              onPointerEnter={(e) =>
                handleMouseEnter(e, multiple ? false : index > indexValue)
              }
              onPointerLeave={(e) =>
                handleMouseLeave(e, multiple ? false : index > indexValue)
              }
              disabled={multiple ? false : index > indexValue}
            >
              <ListItemIcon sx={listItemIcon}>
                <InsertDriveFileOutlinedIcon sx={listIcon} />
              </ListItemIcon>
              <ListItemText
                sx={listItemText}
                primary={file.nombre}
                secondary={
                  <React.Fragment>
                    <Typography sx={listItemSubTitleText}>
                      Formatos admitidos:{" "}
                      {file.acceptedFormats
                        .map((format) =>
                          format
                            .split(".")
                            .slice(-1)[0]
                            .split("/")
                            .slice(-1)[0]
                            .toUpperCase()
                        )
                        .toString()
                        .replace(",", ", ")}
                    </Typography>
                  </React.Fragment>
                }
              />
              {file.nombre === "Cédula/s de identidad" ? (
                <IconButton onClick={(e) => setOpenCarnetModal(true)}>
                  <InfoOutlinedIcon />
                </IconButton>
              ) : file.nombre === "Contrato laboral" && userType !== "Admin" ? (
                <IconButton onClick={(e) => setOpenContratoModal(true)}>
                  <InfoOutlinedIcon />
                </IconButton>
              ) : null}
              <Button
                variant="text"
                sx={listItemButton}
                onClick={(e) => onUploadClick(e, index)}
                disabled={
                  multiple
                    ? false || cargaValue
                    : index > indexValue || cargaValue
                }
              >
                Subir documento{multipleSingular ? "s" : ""}
                <FileUploadOutlinedIcon />
              </Button>
            </ListItem>
          )
        )}
        {!multiple && hover ? (
          <InfoHover hover={hover}>
            Para poder cargar este documento, debe subir primero los documentos
            anteriores.
          </InfoHover>
        ) : null}
      </List>
      <AddOutlinedIcon sx={addIcon} />
      <CarnetModal
        openModal={openCarnetModal}
        setOpenModal={setOpenCarnetModal}
      />
      <ContratoModal
        openModal={openContratoModal}
        setOpenModal={setOpenContratoModal}
      />
      <CustomAlert
        openModal={openAlertModal}
        setOpenModal={setOpenAlertModal}
        error={errorState}
      >
        {alertText}
      </CustomAlert>
    </FormControl>
  ) : null;
}
