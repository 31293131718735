import React, { useState, useEffect } from "react";
import { IconButton, Box, Typography, Modal } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";
import SuccessIcon from "../../../animations/Check Icon/successIcon";
import ErrorIcon from "../../../animations/Error Icon/errorIcon";
import WarningIcon from "../../../animations/Warning Icon/warningIcon";

/* CONTROLLER VARIABLES */
// import {
//   setOpenSuccessModal,
//   getOpenSuccessModal,
//   setTextSuccessModal,
//   getTextSuccessModal,
// } from "../../../../controller/confirmModalVariables";
import { useDispatch, useSelector } from "react-redux";
import {
  currentErrorText,
  currentStateConfirmModal,
  currentSuccesText,
  currentInfoText,
  setErrorText,
  setOpenConfirmModal,
  setSuccessText,
  setInfoText,
} from "../../../../redux/confirmModalSlice";

export default function ConfirmationModal(props) {
  const dispatch = useDispatch();
  const openModal = useSelector(currentStateConfirmModal);
  const successText = useSelector(currentSuccesText);
  const errorText = useSelector(currentErrorText);
  const infoText = useSelector(currentInfoText);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      dispatch(setErrorText(""));
      dispatch(setSuccessText(""));
      dispatch(setInfoText(""));
      dispatch(setOpenConfirmModal(false));
      // setOpenSuccessModal(false);
      // setTextSuccessModal("");
    }
  }, [open]);

  useEffect(() => {
    // if (getOpenSuccessModal()) {
    //   setOpen(true);
    // }
    if (openModal) {
      setOpen(true);
    }
  }, [openModal]);

  const handleClose = () => {
    setOpen(false);
  };

  const container = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    height: "fit-content",
    maxHeight: "98vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: "6vh 4vw",
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin",
  };
  const textStyle = {
    fontWeight: 400,
    fontSize: "18px",
    textAlign: "center",
    lineHeight: "140%",
    color: "#818181",
    whiteSpace: "pre-line",
  };

  return (
    <Modal open={open} onClose={(e) => handleClose()}>
      {successText ? (
        <Box sx={container}>
          <Box sx={closeButtonBox}>
            <IconButton onClick={(e) => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Box>
          <SuccessIcon />
          <Typography sx={textStyle}>{successText}</Typography>
        </Box>
      ) : errorText ? (
        <Box sx={container}>
          <Box sx={closeButtonBox}>
            <IconButton onClick={(e) => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Box>
          <ErrorIcon />
          <Typography sx={textStyle}>{errorText}</Typography>
        </Box>
      ) : infoText ? (
        <Box sx={container}>
          <Box sx={closeButtonBox}>
            <IconButton onClick={(e) => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Box>
          <WarningIcon />
          <Typography sx={textStyle}>{infoText}</Typography>
        </Box>
      ) : (
        <Box></Box>
      )}
    </Modal>
  );
}
