import React, { useEffect, useState } from "react";
import { Outlet, Link, useOutletContext, useNavigate } from "react-router-dom";
import {
  TextField,
  Box,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";

/* IMAGES */
import logoName from "../../images/logo/bluename_noBack.png";

/* ICONS */
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../controller/windowSize";
import { transition } from "../../controller/utils";
import { useLoginMutation, useGetUserQuery } from "../../api/authApiSlice";

import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentAccess,
  setCredentials,
  setUser,
} from "../../redux/authSlice";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const navigate = useNavigate();
  const littleSize = littleSizeFunc();
  let content;
  const [
    currentValueUsr,
    setCurrentValueUsr,
    openSnackbar,
    setOpenSnackbar,
    snackbarMessage,
    setSnackbarMessage,
    snackbarColor,
    setSnackbarColor,
  ] = useOutletContext();

  const handleError = () => {
    setOpenSnackbar(true);
    setSnackbarMessage(
      "Lo sentimos, no pudimos encontrar una cuenta asociada al usuario y contraseña que ingresaste. Por favor, revisa tu usuario y contraseña e inténtalo nuevamente."
    );
    setSnackbarColor("#DE5C5C");
    setErrorValue(true);
  };

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [labelMarginUsr, setLabelMarginUsr] = useState(30);
  const [focusedUsr, setFocusedUsr] = useState(false);
  const [labelMarginPsw, setLabelMarginPsw] = useState(30);
  const [focusedPsw, setFocusedPsw] = useState(false);
  const [currentValuePsw, setCurrentValuePsw] = useState("");
  const [errorValue, setErrorValue] = useState(false);

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  const handleFocus = (variable, state, value) => {
    // User
    if (variable === 0) {
      setFocusedUsr(state);
      if (currentValueUsr !== "") {
        setLabelMarginUsr(0);
      } else {
        setLabelMarginUsr(value);
      }
    }
    // Password
    else {
      setFocusedPsw(state);
      if (currentValuePsw !== "") {
        setLabelMarginPsw(0);
      } else {
        setLabelMarginPsw(value);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // verificar si existe una cuenta con ese usuario y contraseña
    try {
      const userD = await login({
        email: currentValueUsr,
        password: currentValuePsw,
      }).unwrap();
      dispatch(setCredentials({ ...userD, user: currentValueUsr }));
      navigate("/");
    } catch (err) {
      handleError();
    }
  };
  const handleCloseSnack = () => {
    setOpenSnackbar(false);
  };

  const handleClickShowPassword = () => setPasswordVisibility((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  /* CSS */
  const ingreso_mitad = {
    width: littleSize ? "100vw" : "50vw",
    height: littleSize ? "fit-content" : "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: littleSize ? "center" : "flex-start",
    gap: littleSize ? "" : "6vh",
    alignItems: littleSize ? "center" : "stretch",
    padding: "3vh 5vw",
  };
  const logo_container = {
    display: "flex",
    justifyContent: littleSize ? "center" : "flex-start",
    alignItems: "center",
  };
  const formStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "0vh 3vw",
    gap: "33px",
  };
  const ingresar_plataforma_text = {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "140%",
    color: "#1876D1",
    paddingBottom: "10px",
  };
  const textFieldBox = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: "0px",
    gap: "11px",
  };
  const textField = {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    background: "#FCFBFB",
    borderRadius: "6px",
    borderRadius: "10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
    "input[type='password']::-ms-reveal": {
      display: "none",
    },
    "input[type='password']::-ms-clear": {
      display: "none",
    },
  };
  const ingresar_contrasena_olvidar_container = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: "0px",
    gap: "10px",
  };
  const ingresar_contrasena_olvidar_text = {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "150%",
    color: "#818181",
  };
  const ingresar_button = {
    display: "flex",
    flexDirection: "row",
    padding: "5px 20px",
    gap: "11px",
    color: "#1876D1",
    borderRadius: "10px",
    padding: "9px",
    height: "40px",
  };
  const ingresar_button_text = {
    textTransform: "capitalize",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    textAlign: "center",
    color: "#FCFBFB",
  };
  const snackbarStyle = {
    width: "100%",
    top: "0px !important",
    left: "0px",
    right: "0px",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    "& .MuiSnackbarContent-root": {
      justifyContent: "center",
      backgroundColor: snackbarColor,
      width: "inherit",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "150%",
      borderRadius: "inherit",
      top: "inherit",
    },
  };
  const progressStyle = {
    color: "#FCFBFB",
    padding: "1%",
  };
  const logoStyle = {
    width: "310px",
    height: "310px",
    justifyContent: "center",
    color: "#FCFBFB",
    transition: transition,
  };

  content = (
    <Box sx={ingreso_mitad}>
      <Box sx={logo_container}>
        <Box component={Link} to={`/`}>
          <Box
            component="img"
            loading="lazy"
            sx={logoStyle}
            alt="imagen"
            src={logoName}
          />
        </Box>
      </Box>
      <Box
        component="form"
        id="Login"
        onSubmit={handleSubmit}
        noValidate
        sx={formStyle}
      >
        <Typography sx={ingresar_plataforma_text}>
          Ingresa a la plataforma
        </Typography>
        <Box sx={textFieldBox}>
          <TextField
            error={errorValue}
            fullWidth
            required
            type="email"
            id="email"
            label="Email"
            sx={textField}
            InputLabelProps={{
              shrink: focusedUsr || currentValueUsr ? true : false,
              style: { marginLeft: labelMarginUsr },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineIcon
                    htmlColor={errorValue ? "#DE5C5C" : "#1876D1"}
                  />
                </InputAdornment>
              ),
            }}
            value={currentValueUsr}
            onChange={(e) => setCurrentValueUsr(e.target.value)}
            onFocus={() => handleFocus(0, true, 0)}
            onBlur={() => handleFocus(0, false, 30)}
          />
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            error={errorValue}
            fullWidth
            required
            id="password"
            label="Contraseña"
            type={passwordVisibility ? "text" : "password"}
            sx={textField}
            InputLabelProps={{
              shrink: focusedPsw || currentValuePsw ? true : false,
              style: { marginLeft: labelMarginPsw },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyOutlinedIcon
                    htmlColor={errorValue ? "#DE5C5C" : "#1876D1"}
                    sx={{ transform: "rotate(90deg)" }}
                  />
                </InputAdornment>
              ),
              endAdornment: currentValuePsw ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {passwordVisibility ? (
                      <VisibilityOffOutlinedIcon htmlColor={"#1876D1"} />
                    ) : (
                      <VisibilityOutlinedIcon htmlColor={"#1876D1"} />
                    )}
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            value={currentValuePsw}
            onChange={(e) => setCurrentValuePsw(e.target.value)}
            onFocus={() => handleFocus(1, true, 0)}
            onBlur={() => handleFocus(1, false, 30)}
          />
          <Box sx={ingresar_contrasena_olvidar_container}>
            <Typography
              component={Link}
              to="../reset"
              sx={ingresar_contrasena_olvidar_text}
            >
              ¿Olvidaste tu contraseña?
            </Typography>
          </Box>
        </Box>
        <Button
          disabled={isLoading}
          variant="contained"
          sx={ingresar_button}
          type="submit"
        >
          {isLoading ? (
            <CircularProgress sx={progressStyle} />
          ) : (
            <Typography sx={ingresar_button_text}> Ingresar </Typography>
          )}
        </Button>
      </Box>
    </Box>
  );
  return content;
}
