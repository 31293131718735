import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Button, Box, Modal } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "../../../animations/Warning Icon/warningIcon";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../../controller/windowSize";
import { useDispatch } from "react-redux";
import {
  setOpenConfirmModal,
  setSuccessText,
} from "../../../../redux/confirmModalSlice";

export default function FinishModal(props) {
  const {
    openModal,
    setOpenModal,
    handleContinue = () => {},
    handleBack,
    confirmationText = "",
    buttonText,
    navigatePath,
    confirmationValue = true,
  } = props;
  const navigate = useNavigate();
  const littleSize = littleSizeFunc();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setOpenModal(false);
    handleBack ? handleBack() : null;
  };
  const handleCrearTrabajador = () => {
    handleContinue();
    if (confirmationText) {
      dispatch(setSuccessText(confirmationText));
      dispatch(setOpenConfirmModal(true));
    }
    navigate(navigatePath);
    setOpenModal(false);
  };

  /* CSS */
  const modalBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    minWidth: "250px",
    maxWidth: "800px",
    height: "fit-content",
    maxHeight: "98vh",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: littleSize ? "4vh 5vw" : "8.9412vh 5.903vw",
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin",
  };
  const contentWithText = {
    gap: littleSize ? "1vh" : "5vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflowY: "auto",
  };
  const buttonBox = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "17px",
  };
  const button = {
    minWidth: "110px",
    textTransform: "none",
    borderRadius: "10px",
    fontWeight: 600,
    background: buttonText === "Eliminar" ? "#DE5C5C" : "#1876D1",
    ":hover": {
      background: buttonText === "Eliminar" ? "#c22f2f" : "#1876D1",
    },
  };
  const cancelButton = {
    minWidth: "110px",
    textTransform: "none",
    borderRadius: "10px",
    fontWeight: 600,
    border: "2px solid #1876D1",
    ":hover": {
      border: "2px solid #1876D1",
    },
  };

  return (
    <Modal
      open={openModal}
      onClose={(e) => setOpenModal(false)}
      disableRestoreFocus
    >
      <Box sx={modalBox}>
        <Box sx={closeButtonBox}>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={contentWithText}>
          <WarningIcon />
          {props.children}
          {buttonText ? (
            <Box sx={buttonBox}>
              <Button
                sx={cancelButton}
                variant="outlined"
                onClick={handleCloseModal}
              >
                Cancelar
              </Button>
              <Button
                disabled={!confirmationValue}
                sx={button}
                variant="contained"
                disableElevation
                onClick={handleCrearTrabajador}
              >
                {buttonText}
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Modal>
  );
}
