import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import {
  IconButton,
  Button,
  Typography,
  Box,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  TextField,
  CircularProgress
} from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";

/* CUSTOM LIBRARIES */
import PDFViewer from "../common/components/pdfViewer";
import LoadingRocket from "../common/things/loadingRocket";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { transition } from "../../../controller/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../redux/confirmModalSlice";
import {
  useLazyGetVerificacionQuery,
  useVerificacionMutation
} from "../../../api/verificacionesApiSlice";
import { maxHeight, padding } from "@mui/system";

export default function VerificacionModal(props) {
  const { setSelectedVerificacion } = useOutletContext();
  const { openModal, setOpenModal, lineaDocumentoSeleccionada } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const littleSize = littleSizeFunc();

  const [triggerGetData, { isLoading, isFetching }] =
    useLazyGetVerificacionQuery();
  const [verificar, verificarResult] = useVerificacionMutation();

  const lectura = lineaDocumentoSeleccionada
    ? lineaDocumentoSeleccionada.estado === "Resuelto"
    : "";

  const [verificacion, setVerificacion] = useState(null);
  const [preguntas, setPreguntas] = useState({});
  const [comentarios, setComentarios] = useState("");
  const [continueValue, setContinueValue] = useState(false);
  const [estadoValidacion, setEstadoValidacion] = useState("Validar");
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    if (openModal) {
      handleGetVerificacionData();
    }
  }, [openModal]);

  useEffect(() => {
    if (preguntas) {
      let estado = "Validar";
      let disabled = false;
      Object.keys(preguntas).map((key) => {
        if (preguntas[key].respuesta != "true") {
          estado = "Rechazar";
        }
        if (!preguntas[key].respuesta) {
          disabled = true;
        }
      });
      if (!disabled) {
        setEstadoValidacion(estado);
      }
      setDisableButton(disabled);
    }
  }, [preguntas]);

  const handleGetVerificacionData = async () => {
    const { data, isSuccess } = await triggerGetData(
      lineaDocumentoSeleccionada.id,
      false
    );
    if (isSuccess) {
      setVerificacion(data.data);
      setPreguntas(data.data.preguntas);
      setComentarios(data.data.comentarios);
    }
  };

  const handleCloseModal = () => {
    setContinueValue(false);
    setOpenModal(false);
    setSelectedVerificacion(null);
    setVerificacion(null);
    setPreguntas({});
    setComentarios(null);
  };

  const handleContinue = async (estado) => {
    try {
      await verificar({
        id: verificacion.id,
        preguntas: preguntas,
        comentarios: comentarios,
        estado: estado
      }).unwrap();
      dispatch(setSuccessText("¡Verificacion exitosa!"));
      handleCloseModal();
      navigate(pathname);
    } catch (e) {
      console.error(e);
      dispatch(setErrorText("¡Hubo un error, intente nuevamente más tarde!"));
    }
    dispatch(setOpenConfirmModal(true));
  };

  const handleChangePregunta = (value, key) => {
    let pregs = { ...preguntas };
    let p = { ...pregs[key] };
    p = { ...p, respuesta: value };
    pregs = { ...pregs, [key]: p };
    setPreguntas(pregs);
  };

  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin"
  };
  const modalPDF = {
    transition: transition,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    height: "99%",
    overflow: "auto",
    ".MuiBox-root": {
      borderRadius: "20px"
    }
  };
  const PDFbox = {
    display: "flex",
    height: "fit-content",
    width: "fit-content",
    maxWidth: "99vw",
    maxHeight: "99vh",
    justifyContent: "center",
    alignItems: "center",
    padding: littleSize ? "0 3vw" : "0 1.44vw",
    flexWrap: littleSize ? "wrap" : "nowrap",
    background: "#FCFCFC",
    overflow: littleSize ? "auto" : "hidden"
  };
  const column = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "auto",
    marginBottom: "auto",
    width: "fit-content",
    gap: "22px",
    height: "fit-content",
    maxHeight: "82vh",
    position: "relative"
  };
  const respuestas = {
    display: "flex",
    flexDirection: "column",
    marginTop: "auto",
    marginBottom: "auto",
    width: "fit-content",
    gap: "22px",
    padding: "5vmin 0px 0 0",
    height: "fit-content",
    maxHeight: "100%",
    overflow: "auto"
  };
  const columnCheck = {
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "344px",
    height: "fit-content",
    width: littleSize ? "100%" : "22vw",
    flexWrap: "wrap",
    gap: "3px",
    padding: "11px"
  };
  const checkBoxTitulo = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "344px",
    width: "100%",
    height: "fit-content",
    padding: "0px 11px",
    gap: "11px"
  };
  const checkBox = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "344px",
    width: "100%",
    height: "fit-content",
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    padding: "0px 11px",
    gap: "11px"
  };
  const opciones = {
    display: "flex",
    flexDirection: "row",
    gap: "28px",
    height: "fit-content",
    padding: "0px 27px 0 0"
  };
  const radioGroup = {
    height: "fit-content",
    justifyContent: littleSize ? "center" : "flex-start",
    alignItems: "center"
  };
  const textBox = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "fit-content",
    gap: "10px",
    paddingBottom: "11px"
  };
  const titleComentarios = {
    fontWeight: 600,
    fontSize: "18px"
  };
  const textFieldStyle = {
    width: "100%",
    height: "fit-content",
    background: "#FFFFFF",
    fontWeight: 600,
    " .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400
    }
  };
  const buttonBox = {
    position: littleSize ? "" : "absolute",
    right: littleSize ? "" : "2vmin",
    bottom: littleSize ? "" : "3vmin",
    width: littleSize ? "100%" : "fit-content",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "11px",
    padding: littleSize ? "11px 0 22px 0" : "0"
  };
  const buttonRed = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#DE5C5C",
    minWidth: "110px"
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1",
    minWidth: "110px"
  };

  return verificacion ? (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={modalPDF}>
        <Box sx={closeButtonBox}>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={PDFbox}>
          {verificacion?.plantilla ? (
            <PDFViewer
              openModal={openModal}
              setOpenModal={setOpenModal}
              title={
                verificacion?.plantilla.tipo_trabajador ? `Plantilla` : `Cédula`
              }
              subtitle={
                verificacion?.plantilla.tipo_trabajador
                  ? `${verificacion?.plantilla.tipo_documento.abreviacion} - ${verificacion?.plantilla.tipo_trabajador.nombre}`
                  : `${lineaDocumentoSeleccionada.nombre_trabajador}`
              }
              modal={false}
              id={verificacion?.plantilla.id}
            />
          ) : null}
          <PDFViewer
            openModal={openModal}
            setOpenModal={setOpenModal}
            title={"Por verificar"}
            subtitle={`${lineaDocumentoSeleccionada.tipo_documento} - ${lineaDocumentoSeleccionada.nombre_trabajador}`}
            modal={false}
            id={verificacion?.documento.id}
          />
          {isLoading || isFetching ? (
            <CircularProgress />
          ) : (
            <Box sx={column}>
              <Box sx={respuestas}>
                <Box sx={columnCheck}>
                  <Typography>
                    <b>¿Los siguientes valores son validos?</b>
                  </Typography>
                  <Box sx={checkBoxTitulo} component={FormGroup}>
                    <Typography></Typography>
                    <Box sx={opciones}>
                      <Typography>Si</Typography>
                      <Typography>No</Typography>
                    </Box>
                  </Box>
                  {Object.keys(preguntas).map((key) => (
                    <Box sx={checkBox} component={FormGroup}>
                      <Typography>{key.replace("_", " ")}</Typography>
                      <RadioGroup
                        row
                        sx={radioGroup}
                        value={preguntas[key].respuesta}
                        name={preguntas[key].pregunta}
                        onChange={(e) =>
                          handleChangePregunta(e.target.value, key)
                        }
                      >
                        <FormControlLabel
                          disabled={lectura}
                          value={true}
                          control={<Radio />}
                        />
                        <FormControlLabel
                          disabled={lectura}
                          value={false}
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Box>
                  ))}
                </Box>
                <Box sx={textBox}>
                  <Typography sx={titleComentarios}> Comentarios </Typography>
                  <TextField
                    sx={textFieldStyle}
                    placeholder="Comentarios"
                    disabled={lectura}
                    multiline
                    rows={5}
                    value={comentarios}
                    onChange={(e) => setComentarios(e.target.value)}
                  />
                </Box>
              </Box>
            </Box>
          )}
          {lectura ? (
            <Box sx={buttonBox}>
              <Button
                sx={button}
                disableElevation
                variant="contained"
                type="submit"
                onClick={handleCloseModal}
              >
                {"OK"}
              </Button>
            </Box>
          ) : (
            <Box sx={buttonBox}>
              <Button
                sx={buttonRed}
                disabled={disableButton}
                disableElevation
                variant="contained"
                type="submit"
                onClick={(e) => handleContinue("Rechazado")}
              >
                {"Rechazar"}
              </Button>
              <Button
                sx={button}
                disabled={disableButton || estadoValidacion !== "Validar"}
                disableElevation
                variant="contained"
                type="submit"
                onClick={(e) => handleContinue("Aprobado")}
              >
                {"Aprobar"}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  ) : (
    <LoadingRocket />
  );
}
