import React, { useState, useEffect } from "react";
import { Link, useOutletContext, useNavigate } from "react-router-dom";
import { Button, Box, Tooltip, CircularProgress } from "@mui/material";

/* ICONS */
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import SyncAltOutlinedIcon from "@mui/icons-material/SyncAltOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";

/* CONTROLLER VARIABLES */
import { actionButtonWidthHeight } from "../../../../controller/utils";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentType } from "../../../../redux/authSlice";
import { setEmpresa } from "../../../../redux/entidadesSlice";
import {
  useDownDocumentoBajadoMutation,
  useDownDocumentoMutation,
  useDownDocumentosDadoDeBajaMutation,
  useDownDocumentosEmpresaMutation,
  useDownDocumentosFaenaMutation,
  useDownDocumentosTrabajadorMutation,
} from "../../../../api/docsApiSlice";

export default function ActionButtonsCell(props) {
  const {
    variant,
    linea,
    setLineaSeleccionada,
    setOpenEditModal,
    setOpenExtenderModal,
    setOpenBajaModal,
    setOpenFacturarModal,
    setOpenSubirDocModal,
    setOpenSubirMultipleDocsModal,
    setOpenPDFViewModal,
    setOpenErrorModal,
    setOpenTicketModal,
    setOpenVerificacionModal,
  } = props;

  const userType = useSelector(selectCurrentType);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [start_download, downloadData] = useDownDocumentoMutation();
  const [start_download_bajado, downloadDataBajado] =
    useDownDocumentoBajadoMutation();
  const [start_download_zip, downloadZipData] =
    useDownDocumentosTrabajadorMutation();
  const [start_download_bajados_zip, downloadZipDataBajados] =
    useDownDocumentosDadoDeBajaMutation();
  const [start_download_empresa_zip, downloadZipDataEmpresa] =
    useDownDocumentosEmpresaMutation();
  const [start_download_faena_zip, downloadZipDataFaena] =
    useDownDocumentosFaenaMutation();

  const [trabajadores, setTrabajadores] = useState();
  const [empresas, setEmpresas] = useState();
  const [faenas, setFaenas] = useState();
  const [tickets, setTickets] = useState();
  const [verificaciones, setVerificaciones] = useState();
  const [documentos, setDocumentos] = useState();
  const [documentosSubcontratista, setDocumentosSubcontratista] = useState();
  const [facturacion, setFacturacion] = useState();

  const handleDownload = async (event) => {
    event.preventDefault();
    try {
      await start_download({
        id: linea.id,
        subtitle:
          (linea.titulo ? linea.titulo : "") +
          (linea.nombre ? " - " + linea.nombre : " - " + linea.documento),
      }).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDownloadBajado = async (event) => {
    event.preventDefault();
    try {
      await start_download_bajado({
        id: linea.id,
        subtitle: linea.titulo,
      }).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDownloadZip = async (event) => {
    event.preventDefault();
    try {
      await start_download_zip({
        id: linea.id,
        trabajador: linea.nombre,
      }).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDownloadZipBajados = async (event) => {
    event.preventDefault();
    try {
      await start_download_bajados_zip({
        id: linea.id,
        trabajador: linea.nombre,
      }).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDownloadZipEmpresa = async (event) => {
    event.preventDefault();
    try {
      await start_download_empresa_zip({
        id: linea.id,
        empresa: linea.nombre,
      }).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDownloadZipFaena = async (event) => {
    event.preventDefault();
    try {
      await start_download_faena_zip({
        id: linea.id,
        faena: linea.nombre,
      }).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  /* TRABAJADORES ACTION BUTTONS HANDLERS */
  const handleEditModal = (e) => {
    setLineaSeleccionada(linea);
    setOpenEditModal(true);
  };
  const handleExtendModal = (e) => {
    setLineaSeleccionada(linea);
    setOpenExtenderModal(true);
  };
  const handleListarFaenas = () => {
    dispatch(setEmpresa(linea));
    setLineaSeleccionada(linea);
  };
  const handleListarDocumentacion = () => {
    setLineaSeleccionada(linea);
  };
  const handleListarTrabajadores = () => {
    setLineaSeleccionada(linea);
  };
  const handleBajaModal = () => {
    setLineaSeleccionada(linea);
    setOpenBajaModal(true);
  };

  /* DOCUMENTO ACTION BUTTONS HANDLERS */
  const handleSubirDocModal = (e) => {
    setLineaSeleccionada(linea);
    setOpenSubirDocModal(true);
  };
  const handleSubirMultipleDocModal = (e) => {
    setLineaSeleccionada(linea);
    setOpenSubirMultipleDocsModal(true);
  };
  const handlePDFModal = (e) => {
    setLineaSeleccionada(linea);
    setOpenPDFViewModal(true);
  };
  const handleOpenErrorModal = (e) => {
    setLineaSeleccionada(linea);
    setOpenErrorModal(true);
  };

  /* CLIENTE ACTION BUTTONS HANDLERS */
  const handleTrabajadores = (e) => {
    dispatch(setEmpresa(linea));
    navigate("Trabajadores");
  };
  const handleDatosCliente = (e) => {
    dispatch(setEmpresa(linea));
    navigate("Datos cliente");
  };

  /* TICKETS ACTION BUTTONS HANDLERS */
  const handleOpenTicketModal = (e) => {
    setLineaSeleccionada(linea);
    setOpenTicketModal(true);
  };

  /* SUBCONTRATISTA y FACTURACION ACTION BUTTONS HANDLERS */
  const handleDocumentos = (e) => {
    setLineaSeleccionada(linea);
    dispatch(setEmpresa(linea));
    navigate("Ver documentos de subcontratista");
  };
  const handleMarcarFacturado = (e) => {
    dispatch(setEmpresa(linea));
    setLineaSeleccionada(linea);
    setOpenFacturarModal(true);
  };
  const handleDatosSubcontratista = (e) => {
    setLineaSeleccionada(linea);
    dispatch(setEmpresa(linea));
    navigate("Datos subcontratista");
  };
  const handleDeleteSubcontratista = (e) => {
    setLineaSeleccionada(linea);
    setOpenBajaModal(true);
    dispatch(setEmpresa(linea));
  };

  /* VERIFICACION ACTION BUTTONS HANDLERS */
  const handleOpenVerificacionAbierta = (e) => {
    setLineaSeleccionada(linea);
    setOpenVerificacionModal(true);
  };

  const handleOpenVerificacionCerrada = (e) => {
    setLineaSeleccionada(linea);
    setOpenVerificacionModal(true);
  };

  /* CSS */
  const cellBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    gap: "5px",
    margin: "0px",
    marginLeft: "auto",
    marginRight: "auto",
  };
  const buttonBox = {
    width: "100%",
    height: "100%",
  };
  const greenButton = {
    width: "28px",
    height: "28px",
    minWidth: actionButtonWidthHeight,
    minHeight: actionButtonWidthHeight,
    padding: "0px",
    borderRadius: "5px",
    border: "1.5px solid #48c999",
    color: "#48c999",
    "&:hover": {
      color: "#48c999",
      border: "1px solid #48c999",
    },
    ".MuiCircularProgress-root": {
      maxWidth: "20px",
      maxHeight: "20px",
      color: downloadZipData.isLoading ? "#818181" : "#48c999",
    },
  };
  const blueButton = {
    width: "28px",
    height: "28px",
    minWidth: actionButtonWidthHeight,
    minHeight: actionButtonWidthHeight,
    padding: "0px",
    borderRadius: "5px",
    border: "1.5px solid #1876D1",
    color: "#1876D1",
    ".MuiCircularProgress-root": {
      maxWidth: "20px",
      maxHeight: "20px",
      color: downloadZipData.isLoading ? "#818181" : "#1876D1",
    },
  };
  const redButton = {
    width: "28px",
    height: "28px",
    minWidth: actionButtonWidthHeight,
    minHeight: actionButtonWidthHeight,
    padding: "0px",
    borderRadius: "5px",
    border: "1.5px solid #DE5C5C",
    color: "#DE5C5C",
    "&:hover": {
      color: "#DE5C5C",
      border: "1px solid #DE5C5C",
    },
    ".MuiCircularProgress-root": {
      maxWidth: "20px",
      maxHeight: "20px",
      opacity: downloadZipData.isLoading ? "0.2" : "1",
    },
  };

  /* VIEWS */
  useEffect(() => {
    if (linea.estado) {
      setTrabajadores(
        /validado/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            <Tooltip title="Editar" placement="top-end" enterDelay={300} arrow>
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleEditModal}
                >
                  <EditOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Listar documentos"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  component={Link}
                  to="Listar documentacion"
                  onClick={handleListarDocumentacion}
                >
                  <FormatListBulletedOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            {userType === "Supp" ? null : (
              <Tooltip
                title="Descargar documentos"
                placement="top-end"
                enterDelay={300}
                arrow
              >
                <Box sx={buttonBox}>
                  <Button
                    variant="outlined"
                    sx={blueButton}
                    onClick={handleDownloadZip}
                    disabled={downloadZipData.isLoading}
                  >
                    {downloadZipData.isLoading ? (
                      <CircularProgress />
                    ) : (
                      <FileDownloadOutlinedIcon />
                    )}
                  </Button>
                </Box>
              </Tooltip>
            )}
            <Tooltip
              title="Extender contrato"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleExtendModal}
                >
                  <CalendarMonthOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Dar de baja"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={redButton}
                  onClick={handleBajaModal}
                >
                  <DeleteOutlineOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        ) : /procesando/i.test(linea.estado) ||
          /pendiente/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            <Tooltip title="Editar" placement="top-end" enterDelay={300} arrow>
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleEditModal}
                >
                  <EditOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Listar documentos"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  component={Link}
                  to="Listar documentacion"
                  onClick={handleListarDocumentacion}
                >
                  <FormatListBulletedOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            {userType === "Supp" ? null : (
              <Tooltip
                title="Descargar documentos"
                placement="top-end"
                enterDelay={300}
                arrow
              >
                <Box sx={buttonBox}>
                  <Button
                    variant="outlined"
                    sx={blueButton}
                    onClick={handleDownloadZip}
                    disabled={downloadZipData.isLoading}
                  >
                    {downloadZipData.isLoading ? (
                      <CircularProgress />
                    ) : (
                      <FileDownloadOutlinedIcon />
                    )}
                  </Button>
                </Box>
              </Tooltip>
            )}
            {/* <Tooltip
              title="Extender contrato"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleExtendModal}
                >
                  <CalendarMonthOutlinedIcon />
                </Button>
              </Box>
            </Tooltip> */}
            <Tooltip
              title="Dar de baja"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={redButton}
                  onClick={handleBajaModal}
                >
                  <DeleteOutlineOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        ) : /rechazado/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            <Tooltip title="Editar" placement="top-end" enterDelay={300} arrow>
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleEditModal}
                >
                  <EditOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Listar documentos"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  component={Link}
                  to="Listar documentacion"
                  onClick={handleListarDocumentacion}
                >
                  <FormatListBulletedOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            {userType === "Supp" ? null : (
              <Tooltip
                title="Descargar documentos"
                placement="top-end"
                enterDelay={300}
                arrow
              >
                <Box sx={buttonBox}>
                  <Button
                    variant="outlined"
                    sx={blueButton}
                    onClick={handleDownloadZip}
                    disabled={downloadZipData.isLoading}
                  >
                    {downloadZipData.isLoading ? (
                      <CircularProgress />
                    ) : (
                      <FileDownloadOutlinedIcon />
                    )}
                  </Button>
                </Box>
              </Tooltip>
            )}
            <Tooltip
              title="Revisar problema"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleOpenErrorModal}
                >
                  <ErrorOutlineOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Dar de baja"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={redButton}
                  onClick={handleBajaModal}
                >
                  <DeleteOutlineOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        ) : null
      );

      setEmpresas(
        /validado/i.test(linea.estado) || /procesando/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            <Tooltip title="Editar" placement="top-end" enterDelay={300} arrow>
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleEditModal}
                >
                  <EditOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Listar Faenas"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  component={Link}
                  to="Faenas"
                  onClick={handleListarFaenas}
                >
                  <Diversity3OutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Listar documentos"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  component={Link}
                  to="Listar documentacion"
                  onClick={handleListarDocumentacion}
                >
                  <FormatListBulletedOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            {userType === "Admin" ? (
              <Tooltip
                title="Descargar documentos"
                placement="top-end"
                enterDelay={300}
                arrow
              >
                <Box sx={buttonBox}>
                  <Button
                    variant="outlined"
                    sx={blueButton}
                    component={Link}
                    onClick={handleDownloadZipEmpresa}
                  >
                    {downloadZipDataEmpresa.isLoading ? (
                      <CircularProgress />
                    ) : (
                      <FileDownloadOutlinedIcon />
                    )}
                  </Button>
                </Box>
              </Tooltip>
            ) : null}
            {userType === "Admin" ? (
              <Tooltip
                title="Eliminar"
                placement="top-end"
                enterDelay={300}
                arrow
              >
                <Box sx={buttonBox}>
                  <Button
                    variant="outlined"
                    sx={redButton}
                    onClick={handleBajaModal}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </Button>
                </Box>
              </Tooltip>
            ) : null}
          </Box>
        ) : /rechazado/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            <Tooltip title="Editar" placement="top-end" enterDelay={300} arrow>
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleEditModal}
                >
                  <EditOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Listar Faenas"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  component={Link}
                  to="Faenas"
                  onClick={handleListarFaenas}
                >
                  <Diversity3OutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Listar documentos"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  component={Link}
                  to="Listar documentacion"
                  onClick={handleListarDocumentacion}
                >
                  <FormatListBulletedOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Revisar problema"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleOpenErrorModal}
                >
                  <ErrorOutlineOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            {userType === "Admin" ? (
              <Tooltip
                title="Eliminar"
                placement="top-end"
                enterDelay={300}
                arrow
              >
                <Box sx={buttonBox}>
                  <Button
                    variant="outlined"
                    sx={redButton}
                    onClick={handleBajaModal}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </Button>
                </Box>
              </Tooltip>
            ) : null}
          </Box>
        ) : /pendiente/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            <Tooltip title="Editar" placement="top-end" enterDelay={300} arrow>
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleEditModal}
                >
                  <EditOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Listar Faenas"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  component={Link}
                  to="Faenas"
                  onClick={handleListarFaenas}
                >
                  <Diversity3OutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Listar documentos"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  component={Link}
                  to="Listar documentacion"
                  onClick={handleListarDocumentacion}
                >
                  <FormatListBulletedOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            {userType === "Admin" ? (
              <Tooltip
                title="Eliminar"
                placement="top-end"
                enterDelay={300}
                arrow
              >
                <Box sx={buttonBox}>
                  <Button
                    variant="outlined"
                    sx={redButton}
                    onClick={handleBajaModal}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </Button>
                </Box>
              </Tooltip>
            ) : null}
          </Box>
        ) : null
      );

      setDocumentos(
        /analizando/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            <Tooltip
              title="Vista previa"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handlePDFModal}
                >
                  <SearchOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        ) : /sin subir/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            <Tooltip
              title="Subir documento"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={
                    setOpenSubirMultipleDocsModal
                      ? handleSubirMultipleDocModal
                      : handleSubirDocModal
                  }
                >
                  <FileUploadOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        ) : /aprobado/i.test(linea.estado) ||
          /no valido/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            {userType === "Admin" ? (
              <Tooltip
                title="Descargar documento"
                placement="top-end"
                enterDelay={300}
                arrow
              >
                <Box sx={buttonBox}>
                  <Button
                    variant="outlined"
                    sx={blueButton}
                    onClick={handleDownload}
                  >
                    {downloadZipData.isLoading ? (
                      <CircularProgress />
                    ) : (
                      <FileDownloadOutlinedIcon />
                    )}
                  </Button>
                </Box>
              </Tooltip>
            ) : null}
            <Tooltip
              title="Vista previa"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handlePDFModal}
                >
                  <SearchOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        ) : (
          <Box sx={cellBox}>
            <Tooltip
              title="Subir documento"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleSubirDocModal}
                >
                  <FileUploadOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Vistas previa"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handlePDFModal}
                >
                  <SearchOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Revisar problema"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleOpenErrorModal}
                >
                  <ErrorOutlineOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        )
      );

      setTickets(
        <Box sx={cellBox}>
          {/abierto/i.test(linea.estado) ||
          /necesita verificaci[oó]n/i.test(linea.estado) ? (
            <Tooltip
              title="Seguimiento"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleOpenTicketModal}
                >
                  <SyncAltOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          ) : (
            <Tooltip title="Ver" placement="top-end" enterDelay={300} arrow>
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleOpenTicketModal}
                >
                  <SearchOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          )}
        </Box>
      );

      setVerificaciones(
        <Box sx={cellBox}>
          {/abierto/i.test(linea.estado) ||
          /ticket de ayuda/i.test(linea.estado) ? (
            <Tooltip
              title="Resolver"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleOpenVerificacionAbierta}
                >
                  <DriveFileRenameOutlineOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          ) : (
            <Tooltip title="Ver" placement="top-end" enterDelay={300} arrow>
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleOpenVerificacionCerrada}
                >
                  <SearchOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          )}
        </Box>
      );

      setDocumentosSubcontratista(
        /aprobado/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            {userType === "Admin" ? (
              <Tooltip
                title="Descargar documento"
                placement="top-end"
                enterDelay={300}
                arrow
              >
                <Box sx={buttonBox}>
                  <Button
                    variant="outlined"
                    sx={blueButton}
                    component={Link}
                    download={
                      linea.documento ? `${linea.documento}` : `${linea.nombre}`
                    }
                  >
                    {downloadZipData.isLoading ? (
                      <CircularProgress />
                    ) : (
                      <FileDownloadOutlinedIcon />
                    )}
                  </Button>
                </Box>
              </Tooltip>
            ) : null}
            <Tooltip
              title="Vista previa"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handlePDFModal}
                >
                  <SearchOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        ) : /rechazado/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            <Tooltip
              title="Revisar problema"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleOpenErrorModal}
                >
                  <ErrorOutlineOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        ) : null
      );

      setFacturacion(
        /facturado/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            <Tooltip
              title="Ver datos de empresa"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleDatosSubcontratista}
                >
                  <SearchOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        ) : /facturar/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            <Tooltip
              title="Marcar como facturado"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={greenButton}
                  onClick={handleMarcarFacturado}
                >
                  <CheckOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title="Ver datos de empresa"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleDatosSubcontratista}
                >
                  <SearchOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        ) : /rechazado/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            <Tooltip
              title="Revisar problema"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleOpenErrorModal}
                >
                  <ErrorOutlineOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        ) : /en proceso/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            <Tooltip
              title="Ver datos de empresa"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleDatosSubcontratista}
                >
                  <SearchOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        ) : null
      );
    }

    setFaenas(
      <Box sx={cellBox}>
        <Tooltip title="Editar" placement="top-end" enterDelay={300} arrow>
          <Box sx={buttonBox}>
            <Button
              variant="outlined"
              sx={blueButton}
              onClick={handleEditModal}
            >
              <EditOutlinedIcon />
            </Button>
          </Box>
        </Tooltip>
        <Tooltip
          title="Trabajadores"
          placement="top-end"
          enterDelay={300}
          arrow
        >
          <Box sx={buttonBox}>
            <Button
              variant="outlined"
              sx={blueButton}
              component={Link}
              to="Trabajadores"
              onClick={handleListarTrabajadores}
            >
              <SupervisorAccountOutlinedIcon />
            </Button>
          </Box>
        </Tooltip>
        <Tooltip
          title="Listar documentos"
          placement="top-end"
          enterDelay={300}
          arrow
        >
          <Box sx={buttonBox}>
            <Button
              variant="outlined"
              sx={blueButton}
              component={Link}
              to="Listar documentacion"
              onClick={handleListarDocumentacion}
            >
              <FormatListBulletedOutlinedIcon />
            </Button>
          </Box>
        </Tooltip>
        {userType === "Admin" || userType === "A" ? (
          <Tooltip
            title="Descargar documentos"
            placement="top-end"
            enterDelay={300}
            arrow
          >
            <Box sx={buttonBox}>
              <Button
                variant="outlined"
                sx={blueButton}
                component={Link}
                onClick={handleDownloadZipFaena}
              >
                {downloadZipDataEmpresa.isLoading ? (
                  <CircularProgress />
                ) : (
                  <FileDownloadOutlinedIcon />
                )}
              </Button>
            </Box>
          </Tooltip>
        ) : null}
        {/rechazado/i.test(linea.estado) ? (
          <Box sx={cellBox}>
            <Tooltip
              title="Revisar problema"
              placement="top-end"
              enterDelay={300}
              arrow
            >
              <Box sx={buttonBox}>
                <Button
                  variant="outlined"
                  sx={blueButton}
                  onClick={handleOpenErrorModal}
                >
                  <ErrorOutlineOutlinedIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        ) : null}
        {userType === "Admin" || userType === "A" ? (
          <Tooltip
            title={
              linea.cantidad_trabajadores == 1
                ? `${linea.cantidad_trabajadores} trabajador activo`
                : linea.cantidad_trabajadores > 1
                ? `${linea.cantidad_trabajadores} trabajadores activos`
                : "Eliminar"
            }
            placement="top-end"
            enterDelay={300}
            arrow
          >
            <Box sx={buttonBox}>
              <Button
                variant="outlined"
                sx={redButton}
                onClick={handleBajaModal}
                disabled={linea.cantidad_trabajadores > 0}
              >
                <DeleteOutlineOutlinedIcon />
              </Button>
            </Box>
          </Tooltip>
        ) : null}
      </Box>
    );
  }, [linea, downloadZipData, downloadData]);

  const clientes = (
    <Box sx={cellBox}>
      <Tooltip title="Listar Faenas" placement="top-end" enterDelay={300} arrow>
        <Box sx={buttonBox}>
          <Button
            variant="outlined"
            sx={blueButton}
            component={Link}
            to="Faenas"
            onClick={handleListarFaenas}
          >
            <Diversity3OutlinedIcon />
          </Button>
        </Box>
      </Tooltip>
      <Tooltip
        title="Listar trabajadores"
        placement="top-end"
        enterDelay={300}
        arrow
      >
        <Box sx={buttonBox}>
          <Button
            variant="outlined"
            sx={blueButton}
            onClick={handleTrabajadores}
          >
            <SupervisorAccountOutlinedIcon />
          </Button>
        </Box>
      </Tooltip>
      <Tooltip
        title="Ver datos cliente"
        placement="top-end"
        enterDelay={300}
        arrow
      >
        <Box sx={buttonBox}>
          <Button
            variant="outlined"
            sx={blueButton}
            onClick={handleDatosCliente}
          >
            <SearchOutlinedIcon />
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );

  const subcontratistas = (
    <Box sx={cellBox}>
      <Tooltip
        title="Ver datos de subcontratista"
        placement="top-end"
        enterDelay={300}
        arrow
      >
        <Box sx={buttonBox}>
          <Button
            variant="outlined"
            sx={blueButton}
            onClick={handleDatosSubcontratista}
          >
            <SearchOutlinedIcon />
          </Button>
        </Box>
      </Tooltip>
      <Tooltip
        title="Ver documentos de subcontratista"
        placement="top-end"
        enterDelay={300}
        arrow
      >
        <Box sx={buttonBox}>
          <Button variant="outlined" sx={blueButton} onClick={handleDocumentos}>
            <FormatListBulletedOutlinedIcon />
          </Button>
        </Box>
      </Tooltip>
      <Tooltip
        title="Dar de baja a subcontratista"
        placement="top-end"
        enterDelay={300}
        arrow
      >
        <Box sx={buttonBox}>
          <Button
            variant="outlined"
            sx={redButton}
            onClick={handleDeleteSubcontratista}
          >
            <DeleteOutlineOutlinedIcon />
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );

  const bajados = (
    <Box sx={cellBox}>
      <Tooltip
        title="Listar documentos"
        placement="top-end"
        enterDelay={300}
        arrow
      >
        <Box sx={buttonBox}>
          <Button
            variant="outlined"
            sx={blueButton}
            component={Link}
            to="Listar documentacion"
            onClick={handleListarDocumentacion}
          >
            <FormatListBulletedOutlinedIcon />
          </Button>
        </Box>
      </Tooltip>
      {userType === "Admin" || userType === "A" ? (
        <Tooltip
          title="Descargar documentos"
          placement="top-end"
          enterDelay={300}
          arrow
        >
          <Box sx={buttonBox}>
            <Button
              variant="outlined"
              sx={blueButton}
              onClick={handleDownloadZipBajados}
              disabled={downloadZipDataBajados.isLoading}
            >
              {downloadZipDataBajados.isLoading ? (
                <CircularProgress />
              ) : (
                <FileDownloadOutlinedIcon />
              )}
            </Button>
          </Box>
        </Tooltip>
      ) : null}
    </Box>
  );

  const documentosBajados = (
    <Box sx={cellBox}>
      {userType === "Admin" ? (
        <Tooltip
          title="Descargar documento"
          placement="top-end"
          enterDelay={300}
          arrow
        >
          <Box sx={buttonBox}>
            <Button
              variant="outlined"
              sx={blueButton}
              onClick={handleDownloadBajado}
            >
              {downloadDataBajado.isLoading ? (
                <CircularProgress />
              ) : (
                <FileDownloadOutlinedIcon />
              )}
            </Button>
          </Box>
        </Tooltip>
      ) : null}
      <Tooltip title="Vista previa" placement="top-end" enterDelay={300} arrow>
        <Box sx={buttonBox}>
          <Button variant="outlined" sx={blueButton} onClick={handlePDFModal}>
            <SearchOutlinedIcon />
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );

  return variant === "trabajadores"
    ? trabajadores
    : variant === "documentos"
    ? documentos
    : variant === "clientes"
    ? clientes
    : variant === "tickets"
    ? tickets
    : variant === "subcontratista"
    ? subcontratistas
    : variant === "documentosSubcontratista"
    ? documentosSubcontratista
    : variant === "documentos_bajados"
    ? documentosBajados
    : variant === "facturacion"
    ? facturacion
    : variant === "bajados"
    ? bajados
    : variant === "empresas"
    ? empresas
    : variant === "verificaciones"
    ? verificaciones
    : variant === "faenas"
    ? faenas
    : null;
}
