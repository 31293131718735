import React, { useState, useEffect, useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import {
  Button,
  Card,
  Typography,
  Paper,
  Box,
  FormControl,
  CircularProgress,
} from "@mui/material";

/* ICONS */
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";

/* CUSTOM LIBRARIES */
import CustomTable from "../common/components/customTable";
import CustomSelect from "../common/components/customSelect";
import LoadingRocket from "../common/things/loadingRocket";
import InfoHover from "../common/things/infoHover";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { POLLING_INTERVAL, transition } from "../../../controller/utils";
import {
  headCellsDashboard_A,
  headCellsDashboard_Admin,
  headCellsDashboard_B,
} from "../../../controller/listas";
import { useSelector } from "react-redux";
import { useGetDashboardViewQuery } from "../../../api/dashboardApiSlice";
import { selectCurrentType } from "../../../redux/authSlice";

function PieChart(props) {
  const {
    percent,
    numPositive,
    numTotal = 0,
    numNegative,
    textPositive,
    textNegative,
  } = props;
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  /* CSS */
  const pieChart = {
    width: "7.638888vmin",
    height: "7.638888vmin",
    minHeight: "110px",
    minWidth: "110px",
    position: "relative",
  };
  const pieChartPositive = {
    position: "absolute",
    zIndex: 1,
    color: "#1876D1",
    "& .MuiCircularProgress-svg": {
      strokeLinecap: "round",
      transform: "rotate(180deg)",
    },
  };
  const pieChartNegative = {
    position: "absolute",
    color: "#4CC7CE",
    zIndex: 0,
  };
  const hoverPositive = {
    width: "20vw",
    maxWidth: "147px",
    minHeight: "58px",
    height: "fit-content",
    padding: "5px",
    left: "90%",
    top: "-10%",
    transition: transition,
    position: "absolute",
    visibility: isHover ? "visible" : "hidden",
    opacity: isHover ? "1" : "0",
    zIndex: 2000,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFFFF",
    border: "1px solid #1876D1",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: "14px",
  };
  const hoverNegative = {
    width: "20vw",
    maxWidth: "147px",
    minHeight: "58px",
    height: "fit-content",
    padding: "5px",
    top: "55%",
    left: "90%",
    transition: transition,
    position: "absolute",
    visibility: isHover ? "visible" : "hidden",
    opacity: isHover ? "1" : "0",
    zIndex: 2,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFFFF",
    border: "1px solid #4CC7CE",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: "14px",
  };
  const hoverTotal = {
    width: "10vw",
    maxWidth: "80px",
    minHeight: "58px",
    height: "fit-content",
    padding: "5px",
    top: "22.5%",
    left: "10%",
    transition: transition,
    position: "absolute",
    visibility: isHover ? "visible" : "hidden",
    opacity: isHover ? "1" : "0",
    zIndex: 2,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFFFF",
    border: "1px solid #818181",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: "14px",
  };
  const tituloHoverPos = {
    fontWeight: 700,
    fontSize: "16px",
    color: "#1876D1",
  };
  const subtituloHoverPos = {
    fontWeight: 500,
    fontSize: "16px",
    color: "#1876D1",
  };
  const tituloHoverNeg = {
    fontWeight: 700,
    fontSize: "16px",
    color: "#4CC7CE",
  };
  const subtituloHoverNeg = {
    fontWeight: 500,
    fontSize: "16px",
    color: "#4CC7CE",
  };
  const tituloHoverTotal = {
    fontWeight: 700,
    fontSize: "16px",
    color: "#818181",
  };
  const subtituloHoverTotal = {
    fontWeight: 500,
    fontSize: "16px",
    color: "#818181",
  };

  return (
    <Box
      sx={pieChart}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CircularProgress
        sx={pieChartPositive}
        size="100%"
        thickness={7}
        disableShrink
        variant="determinate"
        value={numPositive === 0 && numNegative === 0 ? 0 : percent}
      />
      <CircularProgress
        sx={pieChartNegative}
        size="100%"
        thickness={7}
        variant="determinate"
        value={numPositive === 0 && numNegative === 0 ? 0 : 100}
      />
      <Box sx={hoverPositive}>
        <Typography sx={tituloHoverPos}>{textPositive}</Typography>
        <Typography sx={subtituloHoverPos}>
          {numPositive}({percent || ""}%)
        </Typography>
      </Box>
      <Box sx={hoverNegative}>
        <Typography sx={tituloHoverNeg}>{textNegative}</Typography>
        <Typography sx={subtituloHoverNeg}>
          {numNegative}({percent ? 100 - percent : ""}%)
        </Typography>
      </Box>
      {numTotal ? (
        <Box sx={hoverTotal}>
          <Typography sx={tituloHoverTotal}>Totales</Typography>
          <Typography sx={subtituloHoverTotal}>{numTotal}</Typography>
        </Box>
      ) : null}
    </Box>
  );
}

function DashboardCard(props) {
  const { title, hoverElement } = props;
  const littleSize = littleSizeFunc();
  const [hover, setHover] = useState(false);

  const handleMouseEnter = (event) => {
    event.preventDefault();
    setHover(true);
  };
  const handleMouseLeave = (event) => {
    event.preventDefault();
    setHover(false);
  };

  /* CSS */
  const card = {
    transition: transition,
    background: "#FFFFFF",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    minWidth: littleSize ? "225px" : "275px",
    width: "32%",
    // width: "50vw",
    height: "251px",
    maxWidth: "350px",
  };
  const insideCard = {
    transition: transition,
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    alignItems: "center",
    justifyContent: "center",
    // gap: "21px",
  };
  const tituloCard = {
    transition: transition,
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#333840",
    padding: "1.6vh 7.2vw 0vh 7.2vw",
    flexWrap: "wrap",
  };

  /* Futura posible funcionalidad */
  const iconBox = {
    width: "98%",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  };
  const iconCard = {
    position: "absolute",
  };

  return (
    <Card sx={card}>
      {/* <Box sx={iconBox}>
        <IconButton sx={iconCard}>
          <MoreVertIcon />
        </IconButton>
      </Box> */}
      <Box sx={insideCard}>
        <Typography sx={tituloCard}>{title}</Typography>
        <Box
          onPointerEnter={handleMouseEnter}
          onPointerLeave={handleMouseLeave}
        >
          {props.children}
        </Box>
        {hoverElement ? (
          <InfoHover persist hover={hover}>
            {hoverElement}
          </InfoHover>
        ) : null}
      </Box>
    </Card>
  );
}

export default function Dashboard() {
  const userType = useSelector(selectCurrentType);
  const littleSize = littleSizeFunc();

  const { currentData, isSuccess, isLoading, isFetching } =
    useGetDashboardViewQuery(null, {
      // keepUnusedDataFor: 3,
      pollingInterval: POLLING_INTERVAL,
    });

  const [filtroEmpresas, setFiltroEmpresas] = useState("");
  const [empresa, setEmpresa] = useState("");

  const [mes, setMes] = useState("");
  const [graficoTabla, setGraficoTabla] = useState(true);

  const [tableData, setTableData] = useState([]);
  const [tableDataFiltrada, setTableDataFiltrada] = useState([]);

  useEffect(() => {
    if (isSuccess && currentData) {
      setTableData([...currentData.data.totales]);
      setTableDataFiltrada([...currentData.data.totales]);
      setFiltroEmpresas([...currentData.data.totales_merged]);
    }
  }, [currentData, isSuccess, isFetching]);

  // Filtros
  useEffect(() => {
    if (isSuccess && currentData) {
      if (tableData.length > 0 || tableData === currentData.data) {
        let listaD =
          tableData.lenght > tableDataFiltrada.lenght
            ? [...tableDataFiltrada]
            : [...tableData];
        if (empresa) {
          listaD = listaD
            .map((empresaObj) => {
              if (empresaObj.rut === JSON.parse(empresa).rut) {
                return empresaObj;
              }
              return null;
            })
            .filter((empresaObj) => empresaObj !== null);
          setTableDataFiltrada(listaD);
        }
        if (mes) {
          listaD = listaD
            .map((empresaObj) => {
              if (empresaObj.mes === mes) {
                return empresaObj;
              }
              return null;
            })
            .filter((empresaObj) => empresaObj !== null);
          setTableDataFiltrada(listaD);
        } else {
          setTableDataFiltrada(listaD);
        }
      }
    }
  }, [empresa, mes, currentData, isFetching, isSuccess]);

  const handleButtonGrafico = () => {
    setGraficoTabla(true);
  };
  const handleButtonTabla = () => {
    setGraficoTabla(false);
  };
  const handleLimpiaFiltros = () => {
    setMes("");
    setEmpresa("");
  };

  /* CSS */
  const contenido = {
    transition: transition,
    minHeight: "58vh",
    height: "fit-content",
    // maxHeight: "84vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "5.17vh",
  };
  const barraFiltros = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: littleSize ? "center" : "start",
    gap: "11px",
  };
  const filtrosBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    flexWrap: "wrap",
    alignItems: "start",
    padding: "0px 3vw",
    gap: "11px",
  };
  const formControl = {
    transition: transition,
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "start",
    width: "16vw",
    minWidth: "210px",
  };
  const buttonsBox = {
    width: "fit-content",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "start",
    gap: "1vh 1vw",
  };
  const buttonLimparFiltros = {
    height: "fit-content",
    width: "fit-content",
    transition: transition,
    textTransform: "none ",
    borderRadius: "12px",
    background: "#1876D1",
    color: "#FCFBFB",
    fontWeight: 800,
    fontSize: "12px",
    gap: "5px",
  };
  const buttonGraficoTabla = {
    flexGrow: 1,
    transition: transition,
    minWidth: "100px",
    textTransform: "none",
    borderRadius: "16.4109px",
    background: "#D9D9D9",
    color: "#333840",
    fontWeight: 600,
    fontSize: "14px",
    "&:hover": {
      background: "#818181",
    },
    "&.Mui-disabled": {
      background: "#1876D1",
      color: "#FCFBFB",
    },
  };
  const gaficoBox = {
    height: "fit-content",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "11px",
    // padding: "0 0 2.2vh 0",
    transition: transition,
  };
  const loadingBox = {
    height: "50vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "11px",
    transition: transition,
  };
  const contenidoCard = {
    transition: transition,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    // left: "1.2vw",
    position: "relative",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "2.4vh 3vw 3vh 4vw",
    gap: "1vw",
  };
  const contenidoCardNum = {
    transition: transition,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "5.059vh 4.3056vw 7.88vh 4.3056vw",
  };
  const pieChartBox = {
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    justifyContent: "flex-start",
    position: "relative",
    padding: "0.706vh 0vw",
  };
  const infoChart = {
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "5px",
    padding: "4.235vh 0.347vw 4.235vh 0.347vw",
    // position: 'relative',
  };
  const infoChartRow = {
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
  };
  const pointPositive = {
    maxWidth: "12px",
    minWidth: "12px",
    maxHeight: "12px",
    minHeight: "12px",
    borderRadius: "100%",
    background: "#1876D1",
  };
  const pointNegative = {
    maxWidth: "12px",
    minWidth: "12px",
    maxHeight: "12px",
    minHeight: "12px",
    borderRadius: "100%",
    background: "#4CC7CE",
  };
  const infoChartText = {
    fontWeight: 500,
    fontSize: littleSize ? "12px" : "16px",
    lineHeight: "100%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#333840",
    width: "100px",
    wordBreak: "break-word",
  };
  const bigNumber = {
    width: "14.792vw",
    height: "6.94vh",
    fontWeight: 700,
    fontSize: "90px",
    lineHeight: "120%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#1876D1",
  };

  const cards = useMemo(() => {
    if (isLoading || !currentData) {
      return [];
    }
    if (userType === "B") {
      return [
        {
          title: "Facturas",
          content: (
            <Box sx={contenidoCard}>
              <Box sx={pieChartBox}>
                <PieChart
                  percent={
                    empresa
                      ? Math.round(
                          (JSON.parse(empresa).facturas_facturadas /
                            (JSON.parse(empresa).facturas_rechazadas +
                              JSON.parse(empresa).facturas_pendientes +
                              JSON.parse(empresa).facturas_por_facturar +
                              JSON.parse(empresa).facturas_facturadas)) *
                            100
                        )
                      : Math.round(
                          (currentData.data.resumen.facturas_facturadas /
                            currentData.data.resumen.facturas_totales) *
                            100
                        )
                  }
                  textPositive="Facturadas"
                  textNegative="No facturadas"
                  numTotal={
                    empresa
                      ? JSON.parse(empresa).facturas_facturadas
                      : currentData.data.resumen.facturas_totales
                  }
                  numNegative={
                    empresa
                      ? JSON.parse(empresa).facturas_rechazadas +
                        JSON.parse(empresa).facturas_pendientes +
                        JSON.parse(empresa).facturas_por_facturar
                      : currentData.data.resumen.facturas_totales -
                        currentData.data.resumen.facturas_facturadas
                  }
                  numPositive={
                    empresa
                      ? JSON.parse(empresa).facturas_facturadas
                      : currentData.data.resumen.facturas_facturadas
                  }
                />
              </Box>
              <Box sx={infoChart}>
                <Box sx={infoChartRow}>
                  <Paper sx={pointPositive} />
                  <Typography sx={infoChartText}>Facturadas</Typography>
                </Box>
                <Box sx={infoChartRow}>
                  <Paper sx={pointNegative} />
                  <Typography sx={infoChartText}>No facturadas</Typography>
                </Box>
              </Box>
            </Box>
          ),
        },
        {
          title: "Subcontratistas",
          content: (
            <Box sx={contenidoCardNum}>
              <Box sx={bigNumber}>
                {empresa ? null : currentData.data.resumen.empresas_count}
              </Box>
            </Box>
          ),
        },
        {
          title: "Faenas",
          content: (
            <Box sx={contenidoCardNum}>
              <Typography sx={bigNumber}>
                {empresa
                  ? JSON.parse(empresa).faenas_count
                  : currentData.data.resumen.faenas_count}
              </Typography>
            </Box>
          ),
        },
        {
          title: "Cantidad trabajadores",
          content: (
            <Box sx={contenidoCardNum}>
              <Box sx={bigNumber}>
                {empresa
                  ? JSON.parse(empresa).trabajadores_count
                  : currentData.data.resumen.trabajadores_count}
              </Box>
            </Box>
          ),
        },
        {
          title: "Cantidad de tipo de trabajadores",
          content: (
            <Box sx={contenidoCardNum}>
              <Box sx={bigNumber}>
                {empresa
                  ? JSON.parse(empresa).tipos_trabajadores_count
                  : currentData.data.resumen.tipos_trabajadores_count}
              </Box>
            </Box>
          ),
        },
      ];
    } else if (userType === "A") {
      return [
        {
          title: "Documentación cargada",
          content: (
            <Box sx={contenidoCard}>
              <Box sx={pieChartBox}>
                <PieChart
                  percent={
                    empresa
                      ? Math.round(
                          ((JSON.parse(empresa).documentos_totales -
                            JSON.parse(empresa).docs_sin_subir) /
                            JSON.parse(empresa).documentos_totales) *
                            100
                        )
                      : Math.round(
                          ((currentData.data.resumen.documentos_totales -
                            currentData.data.resumen.docs_sin_subir) /
                            currentData.data.resumen.documentos_totales) *
                            100
                        )
                  }
                  textPositive="Cargados"
                  textNegative="Sin cargar"
                  numTotal={
                    empresa
                      ? JSON.parse(empresa).documentos_totales
                      : currentData.data.resumen.documentos_totales
                  }
                  numNegative={
                    empresa
                      ? JSON.parse(empresa).docs_sin_subir
                      : currentData.data.resumen.docs_sin_subir
                  }
                  numPositive={
                    empresa
                      ? JSON.parse(empresa).documentos_totales -
                        JSON.parse(empresa).docs_sin_subir
                      : currentData.data.resumen.documentos_totales -
                        currentData.data.resumen.docs_sin_subir
                  }
                />
              </Box>
              <Box sx={infoChart}>
                <Box sx={infoChartRow}>
                  <Paper sx={pointPositive} />
                  <Typography sx={infoChartText}>Cargados</Typography>
                </Box>
                <Box sx={infoChartRow}>
                  <Paper sx={pointNegative} />
                  <Typography sx={infoChartText}>Sin cargar</Typography>
                </Box>
              </Box>
            </Box>
          ),
        },
        {
          title: "Documentación procesada",
          content: (
            <Box sx={contenidoCard}>
              <Box sx={pieChartBox}>
                <PieChart
                  percent={
                    empresa
                      ? JSON.parse(empresa).tasa_aprobacion
                      : Math.round(currentData.data.resumen.tasa_aprobacion)
                  }
                  textPositive="Aprobados"
                  textNegative="Rechazados"
                  numNegative={
                    empresa
                      ? JSON.parse(empresa).documentos_rechazados
                      : currentData.data.resumen.documentos_rechazados
                  }
                  numPositive={
                    empresa
                      ? JSON.parse(empresa).documentos_aprobados
                      : currentData.data.resumen.documentos_aprobados
                  }
                />
              </Box>
              <Box sx={infoChart}>
                <Box sx={infoChartRow}>
                  <Paper sx={pointPositive} />
                  <Typography sx={infoChartText}>Aprobados</Typography>
                </Box>
                <Box sx={infoChartRow}>
                  <Paper sx={pointNegative} />
                  <Typography sx={infoChartText}>Rechazados</Typography>
                </Box>
              </Box>
            </Box>
          ),
        },
        {
          title: "Cantidad de faenas",
          content: (
            <Box sx={contenidoCardNum}>
              <Typography sx={bigNumber}>
                {empresa
                  ? JSON.parse(empresa).faenas_count
                  : currentData.data.resumen.faenas_count}
              </Typography>
            </Box>
          ),
        },
        {
          title: "Cantidad de clientes",
          content: (
            <Box sx={contenidoCardNum}>
              <Typography sx={bigNumber}>
                {empresa ? null : currentData.data.resumen.empresas_count}
              </Typography>
            </Box>
          ),
        },
        {
          title: "Trabajadores activos",
          content: (
            <Box sx={contenidoCardNum}>
              <Typography sx={bigNumber}>
                {empresa
                  ? JSON.parse(empresa).trabajadores_count
                  : currentData.data.resumen.trabajadores_count}
              </Typography>
            </Box>
          ),
        },
        {
          title: "Trabajadores inactivos",
          content: (
            <Box sx={contenidoCardNum}>
              <Typography sx={bigNumber}>
                {empresa
                  ? null
                  : currentData.data.resumen.trabajadores_inactivos_count}
              </Typography>{" "}
            </Box>
          ),
        },
      ];
    } else if (userType === "Admin") {
      return [
        {
          title: "Documentos procesados",
          content: (
            <Box sx={contenidoCard}>
              <Box sx={pieChartBox}>
                <PieChart
                  percent={
                    empresa
                      ? JSON.parse(empresa).tasa_aprobacion
                      : Math.round(currentData.data.resumen.tasa_aprobacion)
                  }
                  textPositive="Aprobados"
                  textNegative="Rechazados"
                  numTotal={
                    empresa
                      ? JSON.parse(empresa).documentos_totales
                      : currentData.data.resumen.documentos_totales
                  }
                  numNegative={
                    empresa
                      ? JSON.parse(empresa).documentos_rechazados
                      : currentData.data.resumen.documentos_rechazados
                  }
                  numPositive={
                    empresa
                      ? JSON.parse(empresa).documentos_aprobados
                      : currentData.data.resumen.documentos_aprobados
                  }
                />
              </Box>
              <Box sx={infoChart}>
                <Box sx={infoChartRow}>
                  <Paper sx={pointPositive} />
                  <Typography sx={infoChartText}>Aprobados</Typography>
                </Box>
                <Box sx={infoChartRow}>
                  <Paper sx={pointNegative} />
                  <Typography sx={infoChartText}>Rechazados</Typography>
                </Box>
              </Box>
            </Box>
          ),
        },
        {
          title: "Cantidad empresas",
          content: (
            <Box sx={contenidoCardNum}>
              <Box sx={bigNumber}>
                {empresa
                  ? (JSON.parse(empresa).empresas_outsourcing || 0) +
                    (JSON.parse(empresa).empresas_mandantes || 0)
                  : currentData.data.resumen.empresas_count}
              </Box>
            </Box>
          ),
          hoverElement: (
            <Box>
              <p>
                <b>Empresas Outsourcing:</b>
                <br />
                {empresa
                  ? JSON.parse(empresa).empresas_outsourcing || 0
                  : currentData.data.resumen.empresas_outsourcing}
              </p>
              <p>
                <b>Empresas Mandantes:</b>
                <br />{" "}
                {empresa
                  ? JSON.parse(empresa).empresas_mandantes || 0
                  : currentData.data.resumen.empresas_mandantes}
              </p>
            </Box>
          ),
        },
        {
          title: "Cantidad trabajadores",
          content: (
            <Box sx={contenidoCardNum}>
              <Typography sx={bigNumber}>
                {empresa
                  ? JSON.parse(empresa).trabajadores_count
                  : currentData.data.resumen.trabajadores_count}
              </Typography>
            </Box>
          ),
        },
        {
          title: "Tickets ayuda",
          content: (
            <Box sx={contenidoCard}>
              <Box sx={pieChartBox}>
                <PieChart
                  percent={
                    empresa
                      ? Math.round(
                          (JSON.parse(empresa).tickets_ayuda_resueltos /
                            JSON.parse(empresa).tickets_ayuda_totales) *
                            100
                        )
                      : Math.round(
                          (currentData.data.resumen.tickets_ayuda_resueltos /
                            currentData.data.resumen.tickets_ayuda_totales) *
                            100
                        )
                  }
                  textPositive="Resueltos"
                  textNegative="Sin resolver"
                  numNegative={
                    empresa
                      ? JSON.parse(empresa).tickets_ayuda_totales -
                        JSON.parse(empresa).tickets_ayuda_resueltos
                      : currentData.data.resumen.tickets_ayuda_totales -
                        currentData.data.resumen.tickets_ayuda_resueltos
                  }
                  numPositive={
                    empresa
                      ? JSON.parse(empresa).tickets_ayuda_resueltos
                      : currentData.data.resumen.tickets_ayuda_resueltos
                  }
                />
              </Box>
              <Box sx={infoChart}>
                <Box sx={infoChartRow}>
                  <Paper sx={pointPositive} />
                  <Typography sx={infoChartText}>Resueltos</Typography>
                </Box>
                <Box sx={infoChartRow}>
                  <Paper sx={pointNegative} />
                  <Typography sx={infoChartText}>Sin resolver</Typography>
                </Box>
              </Box>
            </Box>
          ),
        },
        {
          title: "Verificaciones pendientes",
          content: (
            <Box sx={contenidoCard}>
              <Box sx={pieChartBox}>
                <PieChart
                  percent={
                    empresa
                      ? Math.round(
                          (JSON.parse(empresa).verificaciones_resueltas /
                            JSON.parse(empresa).verificaciones_totales) *
                            100
                        )
                      : Math.round(
                          (currentData.data.resumen.verificaciones_resueltas /
                            currentData.data.resumen.verificaciones_totales) *
                            100
                        )
                  }
                  textPositive="Resueltas"
                  textNegative="Sin resolver"
                  numNegative={
                    empresa
                      ? JSON.parse(empresa).verificaciones_totales -
                        JSON.parse(empresa).verificaciones_resueltas
                      : currentData.data.resumen.verificaciones_totales -
                        currentData.data.resumen.verificaciones_resueltas
                  }
                  numPositive={
                    empresa
                      ? JSON.parse(empresa).verificaciones_resueltas
                      : currentData.data.resumen.verificaciones_resueltas
                  }
                />
              </Box>
              <Box sx={infoChart}>
                <Box sx={infoChartRow}>
                  <Paper sx={pointPositive} />
                  <Typography sx={infoChartText}>Resueltas</Typography>
                </Box>
                <Box sx={infoChartRow}>
                  <Paper sx={pointNegative} />
                  <Typography sx={infoChartText}>Sin resolver</Typography>
                </Box>
              </Box>
            </Box>
          ),
        },
      ];
    } else if (userType === "Supp") {
      return [
        {
          title: "Documentación procesados",
          content: (
            <Box sx={contenidoCard}>
              <Box sx={pieChartBox}>
                <PieChart
                  percent={
                    empresa
                      ? JSON.parse(empresa).tasa_aprobacion
                      : Math.round(currentData.data.resumen.tasa_aprobacion)
                  }
                  textPositive="Aprobados"
                  textNegative="Rechazados"
                  numTotal={
                    empresa
                      ? JSON.parse(empresa).documentos_totales
                      : currentData.data.resumen.documentos_totales
                  }
                  numNegative={
                    empresa
                      ? JSON.parse(empresa).documentos_rechazados
                      : currentData.data.resumen.documentos_rechazados
                  }
                  numPositive={
                    empresa
                      ? JSON.parse(empresa).documentos_aprobados
                      : currentData.data.resumen.documentos_aprobados
                  }
                />
              </Box>
              <Box sx={infoChart}>
                <Box sx={infoChartRow}>
                  <Paper sx={pointPositive} />
                  <Typography sx={infoChartText}>Aprobados</Typography>
                </Box>
                <Box sx={infoChartRow}>
                  <Paper sx={pointNegative} />
                  <Typography sx={infoChartText}>Rechazados</Typography>
                </Box>
              </Box>
            </Box>
          ),
        },
        {
          title: "Cantidad de trabajadores",
          content: (
            <Box sx={contenidoCardNum}>
              <Typography sx={bigNumber}>
                {empresa
                  ? JSON.parse(empresa).trabajadores_count
                  : currentData.data.resumen.trabajadores_count}
              </Typography>
            </Box>
          ),
        },
        {
          title: "Tickets ayuda",
          content: (
            <Box sx={contenidoCard}>
              <Box sx={pieChartBox}>
                <PieChart
                  percent={
                    empresa
                      ? Math.round(
                          (JSON.parse(empresa).tickets_ayuda_resueltos /
                            JSON.parse(empresa).tickets_ayuda_totales) *
                            100
                        )
                      : Math.round(
                          (currentData.data.resumen.tickets_ayuda_resueltos /
                            currentData.data.resumen.tickets_ayuda_totales) *
                            100
                        )
                  }
                  textPositive="Resueltos"
                  textNegative="Sin resolver"
                  numNegative={
                    empresa
                      ? JSON.parse(empresa).tickets_ayuda_totales -
                        JSON.parse(empresa).tickets_ayuda_resueltos
                      : currentData.data.resumen.tickets_ayuda_totales -
                        currentData.data.resumen.tickets_ayuda_resueltos
                  }
                  numPositive={
                    empresa
                      ? JSON.parse(empresa).tickets_ayuda_resueltos
                      : currentData.data.resumen.tickets_ayuda_resueltos
                  }
                />
              </Box>
              <Box sx={infoChart}>
                <Box sx={infoChartRow}>
                  <Paper sx={pointPositive} />
                  <Typography sx={infoChartText}>Resueltos</Typography>
                </Box>
                <Box sx={infoChartRow}>
                  <Paper sx={pointNegative} />
                  <Typography sx={infoChartText}>Sin resolver</Typography>
                </Box>
              </Box>
            </Box>
          ),
        },
        {
          title: "Verificaciones pendientes",
          content: (
            <Box sx={contenidoCard}>
              <Box sx={pieChartBox}>
                <PieChart
                  percent={
                    empresa
                      ? Math.round(
                          (JSON.parse(empresa).verificaciones_resueltas /
                            JSON.parse(empresa).verificaciones_totales) *
                            100
                        )
                      : Math.round(
                          (currentData.data.resumen.verificaciones_resueltas /
                            currentData.data.resumen.verificaciones_totales) *
                            100
                        )
                  }
                  textPositive="Resueltas"
                  textNegative="Sin resolver"
                  numNegative={
                    empresa
                      ? JSON.parse(empresa).verificaciones_totales -
                        JSON.parse(empresa).verificaciones_resueltas
                      : currentData.data.resumen.verificaciones_totales -
                        currentData.data.resumen.verificaciones_resueltas
                  }
                  numPositive={
                    empresa
                      ? JSON.parse(empresa).verificaciones_resueltas
                      : currentData.data.resumen.verificaciones_resueltas
                  }
                />
              </Box>
              <Box sx={infoChart}>
                <Box sx={infoChartRow}>
                  <Paper sx={pointPositive} />
                  <Typography sx={infoChartText}>Resueltas</Typography>
                </Box>
                <Box sx={infoChartRow}>
                  <Paper sx={pointNegative} />
                  <Typography sx={infoChartText}>Sin resolver</Typography>
                </Box>
              </Box>
            </Box>
          ),
        },
      ];
    }
  }, [, userType, empresa, mes, isLoading, currentData]);

  return (
    <Box sx={contenido}>
      <Box sx={barraFiltros}>
        {mes || empresa ? (
          <Box sx={formControl}>
            <Button
              onClick={handleLimpiaFiltros}
              disableElevation={true}
              variant="contained"
              sx={buttonLimparFiltros}
            >
              <FilterListOffOutlinedIcon />
              Restablecer filtros
            </Button>
          </Box>
        ) : null}
        <Box sx={filtrosBox}>
          <FormControl sx={formControl}>
            <CustomSelect
              valueName={"nombre"}
              label={
                userType === "Admin" || userType === "Supp"
                  ? "Filtrar por empresa"
                  : userType === "A"
                  ? "Filtrar por mandante"
                  : "Filtrar por subcontratista"
              }
              value={empresa}
              setValue={setEmpresa}
              object
            >
              {filtroEmpresas}
            </CustomSelect>
          </FormControl>
          {/* <FormControl sx={formControl}>
            <CustomSelect label="Filtrar por mes" value={mes} setValue={setMes}>
              {listaMeses}
            </CustomSelect>
          </FormControl> */}
        </Box>
        <Box sx={buttonsBox}>
          <Button
            onClick={handleButtonGrafico}
            disabled={graficoTabla}
            disableElevation={true}
            variant="contained"
            sx={buttonGraficoTabla}
          >
            Gráfico
          </Button>
          <Button
            onClick={handleButtonTabla}
            disabled={!graficoTabla}
            disableElevation={true}
            variant="contained"
            sx={buttonGraficoTabla}
          >
            Tabla
          </Button>
        </Box>
      </Box>
      {isLoading ? (
        <Box sx={loadingBox}>
          <LoadingRocket dialog={false} dark />
        </Box>
      ) : graficoTabla ? (
        <Box sx={gaficoBox}>
          {cards.map((values, index) => (
            <DashboardCard
              title={values.title}
              hoverElement={values.hoverElement ? values.hoverElement : null}
            >
              {values.content}
            </DashboardCard>
          ))}
        </Box>
      ) : (
        <CustomTable
          headCells={
            userType === "Admin"
              ? headCellsDashboard_Admin
              : userType === "A"
              ? headCellsDashboard_A
              : userType === "B"
              ? headCellsDashboard_B
              : []
          }
          rows={tableDataFiltrada}
          setRows={setTableDataFiltrada}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
}
